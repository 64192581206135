import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";


export default async function addFlightInsurance(orderId,data) {
  var result = {return: 0,msg: 'Error',data: {}}

  let token = store.getState().user.userData.accessToken;
  
  // await axios.get(path.api+`/flights/order/${id}`,{
  //   headers: {Authorization: `Bearer ${token}`}
  // })
  console.log('data ',data);
  await fetchServer({method: "POST",url: path.api+`/flights/insurance/add/travelers/${orderId}`,data,
      headers: {Authorization: `Bearer ${token}`},
  })
  .then((res) => {
    console.log(" => ",{...res})
    if(res) {
      if(res?.data?.success) {
        result = {return: 1,msg: "Successfull",data: res.data.data};
      } else if(res?.error) result['msg'] = res?.error?.message;
    } 
  })
  .catch((err) => {
    console.log("Network Error: ",err);
    
    // result = {return: 1,msg: 'Successfull',data:[]}
  })

  return result;
}