import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";

export async function removeMarkup(id) {
  var response = {return: 0,msg: "Failed removing markup",data: null}

  let token = store.getState().user.userData.accessToken;
  // console.log('got : ',id)

  await fetchServer({method: 'DELETE',url: path.api+"/settings/fare-rules/"+id,
    headers: {
      Authorization: `Bearer ${token}`
    } 
  })
  .then((res) => {
    console.log("S_Res -- ",res)
    if(res && res.data) {
      if(res?.data?.success)
        response = {return: 1,msg: "Successfull",data: res.data.data}
    }
    else if(res.error)
      response['msg'] = res.error.message
  })
  .catch(e => console.log("Network Error: ",e))
  
  return response;
}