import React, { useState } from 'react'
import { setModal } from '../../redux/reducers/modal/modalSlice';
import { useDispatch } from 'react-redux';
import { removeMarkup } from '../../controllers/settings/removeMarkup';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';

export default function RemoveMarkupModal({id,callback}) {
  const [loading,setLoading] = useState();
 
  const dispatch = useDispatch();
  

  async function handleRemove() {
    setLoading(true);
    const res = await removeMarkup(id);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Markup Removed']))
      callback && callback()
    }
    else dispatch(setAlertMsg(['error','Unable to remove markup!']))

    dispatch(setModal(false));
    // console.log('removing',id)
  } 
  return (
    <div className='h-full flex items-center'>
      <div className='flex flex-col bg-secondary rounded-md p-5 pt-10 items-center gap-2 relative'>
        <div className='btn_close' onClick={() => dispatch(setModal(false))}>X</div>
        <h2>Are you sure you want to remove Markup?</h2>
        <button className='btn2 flex gap-2' disabled={loading} onClick={handleRemove}>
          {loading?(<div className='load'></div>):null}
          Yes, Remove.
        </button>
      </div>
    </div>
  )
}
