import mergeRecursive from "../../features/utils/mergeRecursive";

export const staffTemp = {
  "id": "",
  "username": "",
  "firstName": "",
  "email": "",
  "lastName": "",
  "password": "",
  "profile": "",
  "isAdmin": "",
  "phoneNumber": "",
  "type": "",
  "customSettingid": "",
  "gender": "",
  "companyName": "",
  "addressLine1": "",
  "addressLine2": "",
  "postalCode": "",
  "cityName": "",
  "countryCode": "",
  "_count": {
    "approvedApiRequests": 0,
    "bookingData": "",
    "bookedTrips": 0
  },
  "status": 'Active'
}

export const staffSignupData = {
  "email": "",
  "password": "",
  "firstName": "",
  "lastName": "",
  "phoneNumber": "",
  "cityName": "",
  "countryCode": "",
  "addressLine1": "",
  "addressLine2": "",
  "postalCode": "",
  "title": "",
  "dob": "",
  "employeeId": "",
  "employeeDepartment": "",
  "employeeDesignation": "",
  "nationalId": "",
  "nationalIdExpiry": "",
  "nationality":""
}

export function templateStaff(obj) {
  let data = mergeRecursive({...obj},staffTemp);
  return {...data,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phoneNumber,
    email: data.email,
    role: data?.staffRole?.roleName || 'No role',
    status: data.isActive ? 'Active' : 'Inactive'
  };
}
export function templateStaffReport(obj) {
  let data = mergeRecursive({...obj},staffTemp);
  
  let res = {
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phoneNumber,
    email: data.email,
    role: data?.staffRole?.roleName || 'No role',
    status: data.isActive ? 'Active' : 'Inactive',
    createdDate: data?.createdDate,
    updatedDate: data?.updatedDate,
  };

  res = Object.values(res);

  return res;
}
