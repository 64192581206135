import React, { useEffect, useState } from 'react'
import { customerTemp } from '../../../data/customer/customerData'
import { Breadcrumbs, Skeleton } from '@mui/material';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Cancel, Email, Message, Phone } from '@mui/icons-material';
import BookingHistory from '../../../components/booking/BookingHistory';
import { getUser } from '../../../controllers/user/getUser';
import EditProfile from '../../../components/user/EditProfile';
import ProfilePicture from '../../../components/user/ProfilePicture';

export default function Index() {
  const [data,setData] = useState(customerTemp);
  const {id} = useParams();
  const [searchParam] = useSearchParams();
  const edit = searchParam.get('edit')
  const [loading,setLoading] = useState(false);


  useEffect(() => {
    load();
  },[id])
  async function load() {
    setLoading(true);
    const res = await getUser(id)
    setLoading(false);
    if(res.return)
      setData(res.data);
  }

  return (
    <div className='p-10 w-full flex flex-col gap-10'>
      <Breadcrumbs separator="/" className='text-theme1'>
        <Link to="/customers">Customers</Link>
        <h5 className='text-primary/60'>{data.firstName} {data.lastName}</h5>
      </Breadcrumbs>
      <div className='flex gap-4 justify-between p-3'>
        <div className='flex-1 flex flex-col gap-4'>
          <div className='flex gap-4 items-center'>
            <div className='bg-primary/40 rounded-md w-20 h-20 overflow-hidden'>
              <ProfilePicture data={data} handleReturn={load} />
            {/* {data.profile ? 
              <img src={data.profile} alt='Profile' className='w-full h-full' />
            :null} */}
            </div>
            <div>
              <h5 className='capitalize'>
                {loading?<Skeleton className='w-24 h-6' />:(
                  `${data.firstName} ${data.lastName}`
                )}
              </h5>
            </div>
          </div>
          <div className='flex flex-wrap gap-4 items-center'>
            {/* <button className='btn btn_nofocus'>Edit Profile</button> */}
            <Link to={"?edit=true"} className='btn2 text-xs '>Edit Profile</Link>
            {/* <button className='btn btn_err text-secondary'><Cancel className='w-[14px] h-[14px]' /></button> */}
          </div>
        </div>
        <div className='flex-1 flex flex-col gap-3'>
          <div className='flex gap-3 items-center'>
            <div className='circle text-theme1 border border-theme1 p-1'><Phone className='sqr4' /></div>
            <p>{!loading ? data.phoneNumber : <Skeleton className='h-6 w-20' />}</p>
          </div>
          <div className='flex gap-3 items-center'>
            <div className='circle text-theme1 border border-theme1 p-1'><Email className='sqr4' /></div>
            <p>{!loading ? data.email : <Skeleton className='h-6 w-20' />}</p>
          </div>
          <div className='flex gap-3 items-center'>
            <div className='circle text-theme1 border border-theme1 p-1'><Message className='sqr4' /></div>
            <p>{!loading ? data.addressLine1 : <Skeleton className='h-6 w-20' />}</p>
            <p>{!loading ? data.addressLine2 : <Skeleton className='h-6 w-20' />}</p>
          </div>
        </div>

      </div>
      {edit ? <EditProfile reload={load} userData={data} />:null}
      <hr />
      <BookingHistory obj={data} />
    </div>
  )
}
