import axios from "axios";
import { store } from "../redux/store";
import { logout } from "../redux/reducers/user/userSlice";

export default async function fetchServer({method,url,data,headers,cancelToken=undefined}) {
  console.log("fetching server")
  const res = await axios({
    method,
    url,
    data,
    headers,
    cancelToken,
  })
  .catch(err => {
    if(err?.response?.status === 401) {
      window.localStorage.removeItem("userData");
      store.dispatch(logout());
    }
    return err?.response?.data;
  });
  return res;
}
