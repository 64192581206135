export const cwtCompaniesData = {
      "AMA_ProfileReadRS": {
        "@attributes": {
          "Version": "12.2"
        },
        "Success": [],
        "Profiles": {
          "ProfileInfo": [
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "11",
                    "ID": "0R5LSV",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Arcelor Mittal"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "5",
                    "ID": "GHQ48R",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "B M Gates Foundation"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "23",
                    "ID": "KEQMFA",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Baker Hughes Pressure Control System"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "32",
                    "ID": "UDVSF3",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Bakerhughes Global"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "20",
                    "ID": "HBW081",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Bat Foundation Ltd"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "19",
                    "ID": "K0PHHK",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Bat Marketing Nig Ltd"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "4",
                    "ID": "KW985M",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Bat Nigeria Iseyin"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "15",
                    "ID": "RX0NS9",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Bat Nigeria Ltd"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "4",
                    "ID": "DX6WMW",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Bat Nigeria Zaria"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "11",
                    "ID": "ZQQ5NU",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Bic"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "2",
                    "ID": "50ST53",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Business Travel Management"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "6",
                    "ID": "X25ZVS",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Citi"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "25",
                    "ID": "APKKJE",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Dar Al Handasah"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "51",
                    "ID": "Q2JW91",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Ericsson Nigeria"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "43",
                    "ID": "Q4JDKR",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Frieslandcampina"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "9",
                    "ID": "JN5NQQ",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "General Electric Bakerhughes"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "5",
                    "ID": "M7VQJ6",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Google"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "8",
                    "ID": "5FKS90",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Gq Nov Oil And Gas"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "31",
                    "ID": "WK2JLG",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Hmd Global"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "4",
                    "ID": "AL6J7G",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Hp Inc"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "14",
                    "ID": "RB218R",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "International Sos"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "23",
                    "ID": "WCN5KL",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Jpmorgan Chase"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "33",
                    "ID": "LLFNJB",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Lloyds Register"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "5",
                    "ID": "EHA60V",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Lloyds Register West Africa Limited"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "82",
                    "ID": "XGCF62",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Mastercard"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "22",
                    "ID": "8S1EFK",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Mbcc Group"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "39",
                    "ID": "3R5XDZ",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Michelin Nigeria"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "33",
                    "ID": "GHZ7X2",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Monsanto"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "9",
                    "ID": "DQK1Z8",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Ng Nov Corporate Finance"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "9",
                    "ID": "5SFBB5",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Ng Nov Corporate Hr"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "8",
                    "ID": "44P41C",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Ng Nov Fiber Glass"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "9",
                    "ID": "LQ68XM",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Ng Nov Imo"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "8",
                    "ID": "PGMS2W",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Ng Nov Pps"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "7",
                    "ID": "42SF52",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Ng Nov Rig Solutions"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "7",
                    "ID": "XMH0V3",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Ng Nov Solids Control"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "9",
                    "ID": "54GLQ2",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Ng Nov Xl Systems"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "2",
                    "ID": "8HUXHJ",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Ngtccc"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "12",
                    "ID": "5363GQ",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Nov Downhole"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "10",
                    "ID": "N08CLJ",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Nov Oil And Gas"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "60",
                    "ID": "MSDR44",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Procter Gamble"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "18",
                    "ID": "QHM9G2",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Refinitiv"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "10",
                    "ID": "3F3TWC",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Reuters Limited Ng Pe"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "30",
                    "ID": "DZD15H",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Siemens"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "11",
                    "ID": "KLTFGX",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Siemens Dresser"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "10",
                    "ID": "LEUSEA",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Tccc Ng"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "12",
                    "ID": "K00F3G",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Template"
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "38",
                    "ID": "GNXP2T",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "3",
                  "Status": "A"
                },
                "CompanyInfo": {
                  "CompanyName": "Thomson Reuters"
                }
              }
            }
          ]
        }
      },
      "status": "OK"
}

export const cwtCompanyData = {
      "AMA_ProfileReadRS": {
        "@attributes": {
          "Version": "12.2"
        },
        "Success": [],
        "Profiles": {
          "ProfileInfo": {
            "UniqueID": [
              {
                "@attributes": {
                  "Type": "21",
                  "Instance": "1",
                  "ID": "EMG1D9",
                  "ID_Context": "CSX"
                }
              },
              {
                "@attributes": {
                  "Type": "9",
                  "ID": "LOSN825CW",
                  "ID_Context": "CSX"
                }
              }
            ],
            "Profile": {
              "@attributes": {
                "ProfileType": "3",
                "Status": "A"
              },
              "PrefCollections": {
                "PrefCollection": {
                  "AirlinePref": {
                    "AirportOriginPref": {
                      "@attributes": {
                        "LocationCode": "SYD"
                      }
                    }
                  }
                }
              },
              "CompanyInfo": {
                "CompanyName": "Bat Nigeria Ltd",
                "AddressInfo": {
                  "@attributes": {
                    "FormattedInd": "1",
                    "TransferIndicator": "A",
                    "DefaultInd": "1",
                    "UseType": "2"
                  },
                  "AddressLine": "55 STREET",
                  "CityName": "London",
                  "CountryName": {
                    "@attributes": {
                      "Code": "GB"
                    }
                  },
                  "AddresseeName": {
                    "Surname": "CLAIRE"
                  }
                },
                "TelephoneInfo": {
                  "@attributes": {
                    "PhoneLocationType": "6",
                    "PhoneTechType": "1",
                    "PhoneNumber": "NCE12345678",
                    "DefaultInd": "0",
                    "TransferIndicator": "A"
                  }
                },
                "TravelCategories": {
                  "TripPurpose": "Business"
                }
              }
            }
          }
        }
      },
      "status": "OK"
}

export const cwtTravellersData = {
      "AMA_ProfileReadRS": {
        "@attributes": {
          "Version": "12.2",
          "MoreIndicator": "1"
        },
        "Success": [],
        "Profiles": {
          "ProfileInfo": [
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "3",
                    "ID": "VEP18Y",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1990-10-22"
                  },
                  "PersonName": {
                    "GivenName": "Ogheneosume Mr",
                    "Surname": "Abada Efajemu"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Ogheneosume_abada-Efajemu@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*hasnain_ishtiaq@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1258",
                    "ID": "8N9ZDU",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1978-11-15"
                  },
                  "PersonName": {
                    "GivenName": "Emmanuel Ujah Mr",
                    "Surname": "Abah"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Emmanuel.abah@bakerhughes.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "B-Pax*234-12809600x9645",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Pax*234-7034129933",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "CitizenCountryName": {
                    "@attributes": {
                      "Code": "NG"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bakerhughes Global"
                  },
                  "Document": {
                    "@attributes": {
                      "DocID": "A50516575",
                      "DocType": "2",
                      "EffectiveDate": "2018-06-19",
                      "ExpireDate": "2023-06-18",
                      "DocIssueCountry": "NG"
                    }
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1257",
                    "ID": "GZNUXQ",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Chukwuneku",
                    "Surname": "Abamba"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Chukwuneku.abamba@bakerhughes.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bakerhughes Global"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1",
                    "ID": "NUPTRL",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1984-10-21"
                  },
                  "PersonName": {
                    "GivenName": "Osita Chukwuma Mr",
                    "Surname": "Abana"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Oabana@coca-Cola.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Arr*lyhayes@coca-Cola.com/parr",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "B-Pax*9178644151",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Pax*49-17626001213",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "H-Pax*9178644151",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "B-Arr1*hayes/lyndsey*1-4046763730",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Tccc Ng"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "13",
                    "ID": "3ECN89",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1984-12-11"
                  },
                  "PersonName": {
                    "GivenName": "Michael Joseph Mr",
                    "Surname": "Abanda"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Joseph_abanda@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "B-Aut1*olorunfemi/victoria*234-9037774238",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*victoria_olorunfemi@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "B-Pax*2347017743167",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Pax*234-7017743167",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "H-Pax*2348062908742",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Marketing Nig Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1252",
                    "ID": "D4WVX6",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Peter Ozoemena",
                    "Surname": "Abara"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Peter.abara@bakerhughes.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bakerhughes Global"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "922",
                    "ID": "9ZK6ED",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Ifeanyi",
                    "Surname": "Abaraoha"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Augustine.abaraoha@bakerhughes.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bakerhughes Global"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "13",
                    "ID": "KVV1L0",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Adegboyega Mr",
                    "Surname": "Abass"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Adegboyega.abass@frieslandcampina.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Aut*galjaard/marc*491728988761",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*marc.galjaard@frieslandcampina.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Pax*07036763061",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Frieslandcampina"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1",
                    "ID": "L7JV1P",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Ahmed",
                    "Surname": "Abass"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Ahmed.abass@ericsson.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Aut*elsisy/ahmed*20-1063670361",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*ahmad.behairy@ericsson.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Ericsson Nigeria"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "11",
                    "ID": "SS9N1X",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1969-03-27"
                  },
                  "PersonName": {
                    "GivenName": "Saliman Omolaja Mr",
                    "Surname": "Abatan"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Saliman-Omolaja.abatan@michelin.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Alt*omolaj@yahoo.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "B-Aut1*owolabi/richard*234-13423808x2705",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*richard.owolabi@michelin.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Pax*234-8033396612",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Michelin Nigeria"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "105",
                    "ID": "K4SC7J",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1974-03-31"
                  },
                  "PersonName": {
                    "GivenName": "Peter Bankole Mr",
                    "Surname": "Abayomi"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Abayomi.pb@pg.com/work/iti",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "B-Pax*234-7045093369",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "CitizenCountryName": {
                    "@attributes": {
                      "Code": "NG"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Procter Gamble"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "281",
                    "ID": "LYW4ZB",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Lawrence",
                    "Surname": "Abby"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Lawrence.abby@bakerhughes.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bakerhughes Global"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1",
                    "ID": "8ER7HK",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Abdallah Aliyu Mstr",
                    "Surname": "Abdallah"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Abimbola_oladapo@bat.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1",
                    "ID": "72ASAD",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Fatima Zahra Aliyu Miss",
                    "Surname": "Abdallah"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Abimbola_oladapo@bat.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1",
                    "ID": "07H10L",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Hafsat Aliyu Miss",
                    "Surname": "Abdallah"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Abimbola_oladapo@bat.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1",
                    "ID": "DU03Y5",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Jafaar Aliyu Mstr",
                    "Surname": "Abdallah"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Abimbola_oladapo@bat.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "5",
                    "ID": "U7Z8PG",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1989-12-11"
                  },
                  "PersonName": {
                    "GivenName": "Joshua Mr",
                    "Surname": "Abdul"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Joshua_abdul@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*yusuf_gamba@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Marketing Nig Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "4",
                    "ID": "FS2PWY",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Moyosoreoluwa Ms",
                    "Surname": "Abdul"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Moyosoreoluwa.abdul@frieslandcampina.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*erhabor.blessing@frieslandcampina.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Frieslandcampina"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "7",
                    "ID": "SEPUFU",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1986-07-18"
                  },
                  "PersonName": {
                    "GivenName": "Abdullahi Mr",
                    "Surname": "Abdul Malik"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Abdullahi_abdul-Malik@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*haruna_usman@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Marketing Nig Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "5",
                    "ID": "F1Q7PL",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Ismail Mr",
                    "Surname": "Abdulazeez"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Ismail.abdulazeez@frieslandcampina.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*musa.bulama@frieslandcampina.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Pax*0802355002508066603333",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Frieslandcampina"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "10",
                    "ID": "98VYR8",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Abdulkadir Mr",
                    "Surname": "Abdullahi"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Abdulkadir.abdullahi@frieslandcampina.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*roger.adou@frieslandcampina.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Pax*08060833525",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Frieslandcampina"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "2",
                    "ID": "DENBUS",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Abdulkadir Ozovehe Mr",
                    "Surname": "Abdullahi"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Abdulkadir.abdullahi@frieslandcampina.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Frieslandcampina"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "4",
                    "ID": "ELLRD2",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Abubakar Dankama Mr",
                    "Surname": "Abdullahi"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Gregory.goldberg@nov.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Arr*gregory.goldberg@nov.com/parr",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Arr*ingunn.andersen@nov.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Arr*marien.ibiaho@nov.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "B-Arr2*andersen/ingunn*47-98233277",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Ibiaho Ognongo/marien Joel*33-677436461",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Ng Nov Pps"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "7",
                    "ID": "UXPR4M",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1969-05-12"
                  },
                  "PersonName": {
                    "GivenName": "Ahmed Imam Mr",
                    "Surname": "Abdullahi"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Ahmed_abdullahi@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*mohammed_idris1@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Marketing Nig Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "17",
                    "ID": "S79DKK",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1967-12-30"
                  },
                  "PersonName": {
                    "GivenName": "Idris Atogbo Mr",
                    "Surname": "Abdullahi"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Idris_atogbo_abdullahi@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*valentine_ebikade@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "2",
                    "ID": "C0S2SY",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Mukhtar Mr",
                    "Surname": "Abdullahi"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Missingemail010352@dar.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Dar Al Handasah"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1",
                    "ID": "LXY6LJ",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Sa Adatu Mrs",
                    "Surname": "Abdullahi"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Abimbola_oladapo@bat.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "10",
                    "ID": "X82QUG",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1982-07-12"
                  },
                  "PersonName": {
                    "GivenName": "Dauda Anavami Mr",
                    "Surname": "Abdulmalik"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Dauda_abdulmalik@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*chris_onwusi@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "B-Pax*07088690643",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "H-Pax*08032823404",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Marketing Nig Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1",
                    "ID": "QTUJQ9",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Ishaq",
                    "Surname": "Abdulmutalib"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Ngozi.onefeli@frieslandcampina.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Frieslandcampina"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "5",
                    "ID": "NXBYNP",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1983-06-19"
                  },
                  "PersonName": {
                    "GivenName": "Abdulrazaq Mr",
                    "Surname": "Abdulrahman"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Abdulrazaq_abdulrahman@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*samaila_saleh@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Marketing Nig Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "13",
                    "ID": "4HKQ7J",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Muniru Omotayo Mr",
                    "Surname": "Abdulraufu"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Muniru.abdulraufu@frieslandcampina.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*bola.balogun@frieslandcampina.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Pax*08068607002",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Frieslandcampina"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1",
                    "ID": "VZSQET",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1993-12-25"
                  },
                  "PersonName": {
                    "GivenName": "Yusuf Mr",
                    "Surname": "Abdulrazaq"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Damilola_yomi-Faseun@bat.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bat Marketing Nig Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1",
                    "ID": "1UPRUK",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Braimah Mr",
                    "Surname": "Abdulreheem"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Ahmed_azumi@bat.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "10",
                    "ID": "8X78H0",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Abdullahi Luqman Mr",
                    "Surname": "Abdulsalam"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Lukman.abdulsalaam@frieslandcampina.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*seyi.fasetire@frieslandcampina.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Pax*07058665607",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Frieslandcampina"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "3",
                    "ID": "76UW0K",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Audu Mr",
                    "Surname": "Abdulsalam"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Audu.abdulsalam@bicworld.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Pax*234-9120117057",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "CitizenCountryName": {
                    "@attributes": {
                      "Code": "NG"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bic"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "10",
                    "ID": "6AJPLF",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1970-06-10"
                  },
                  "PersonName": {
                    "GivenName": "Adeyinka Mr",
                    "Surname": "Abe"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Adeyinka_abe@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*toluwalope_ige@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "2",
                    "ID": "V6ESY6",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Deborah Mrs",
                    "Surname": "Abe"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Deborah.abe@frieslandcampina.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*roger.adou@frieslandcampina.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Frieslandcampina"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "3",
                    "ID": "C3GDWF",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Oluwatoba",
                    "Surname": "Abe"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Oluwatoba.abe@ericsson.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*obumneke.onwumelu@ericsson.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Ericsson Nigeria"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "9",
                    "ID": "4H7RNL",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Lanrewaju Muyiwa Mr",
                    "Surname": "Abegunde"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Muyiwa.abegunde@frieslandcampina.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*adekola.lamidi@frieslandcampina.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Pax*2348029176888",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Frieslandcampina"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "12",
                    "ID": "4XL7SA",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1990-07-11"
                  },
                  "PersonName": {
                    "GivenName": "Oluwabori Isaac Mr",
                    "Surname": "Abegunde"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Oluwabori_abegunde@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*lamidi_akeem@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "369",
                    "ID": "00HH0H",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Cletus",
                    "Surname": "Abhulimhen"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Cletus.abhulimhen@bakerhughes.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bakerhughes Global"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1260",
                    "ID": "VA8SNT",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Paul Agiliga",
                    "Surname": "Abia"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Paul.abia@bakerhughes.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bakerhughes Global"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "30",
                    "ID": "R1A9X3",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Ashraf Olaniyi",
                    "Surname": "Abidogun"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Ashrafolaniyi.abidogun@bakerhughes.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Baker Hughes Pressure Control System"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "46",
                    "ID": "2YL69J",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1993-01-27"
                  },
                  "PersonName": {
                    "GivenName": "Teddy Oghenetega Mr",
                    "Surname": "Abilogu"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Abilogu.to@pg.com/work/iti",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "CitizenCountryName": {
                    "@attributes": {
                      "Code": "NG"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Procter Gamble"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "2",
                    "ID": "Z5VEEG",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Abiodun Kayode",
                    "Surname": "Abimbola"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Abiodun.kayode.abimbola@ericsson.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*kenneth.o.adogame@ericsson.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Ericsson Nigeria"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "7",
                    "ID": "17D18Y",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1976-08-26"
                  },
                  "PersonName": {
                    "GivenName": "Olaseni",
                    "Surname": "Abimbola"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Olumoroti_olaseni@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*chiazor_okoye@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "4",
                    "ID": "TBEASW",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "@attributes": {
                    "BirthDate": "1975-01-11"
                  },
                  "PersonName": {
                    "GivenName": "Olusesan Simon Mr",
                    "Surname": "Abimbola"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Olusesan_abimbola@bat.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*toluwalope_ige@bat.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Bat Nigeria Ltd"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "1",
                    "ID": "N9PFK2",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Ayokunle",
                    "Surname": "Abiodun"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Ayokunle.abiodun@ericsson.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Aut*sow/aboubacar*225-01000514",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*aboubacar.sow@ericsson.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Ericsson Nigeria"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "2",
                    "ID": "9YG7SC",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Babatanisewu",
                    "Surname": "Abiodun"
                  },
                  "Telephone": [
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Babatanisewu.abiodun@ericsson.com/work",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "M-Aut*sow/aboubacar*225-01000514",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    },
                    {
                      "@attributes": {
                        "PhoneLocationType": "8",
                        "PhoneTechType": "1",
                        "PhoneNumber": "E-Aut1*aboubacar.sow@ericsson.com",
                        "DefaultInd": "0",
                        "TransferIndicator": "A"
                      }
                    }
                  ],
                  "RelatedCompany": {
                    "CompanyName": "Ericsson Nigeria"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            },
            {
              "UniqueID": [
                {
                  "@attributes": {
                    "Type": "21",
                    "Instance": "2",
                    "ID": "C9UQBL",
                    "ID_Context": "CSX"
                  }
                },
                {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "CSX"
                  }
                }
              ],
              "Profile": {
                "@attributes": {
                  "ProfileType": "1",
                  "Status": "A"
                },
                "Customer": {
                  "PersonName": {
                    "GivenName": "Samson Olalekan Mr",
                    "Surname": "Abiodun"
                  },
                  "Telephone": {
                    "@attributes": {
                      "PhoneLocationType": "8",
                      "PhoneTechType": "1",
                      "PhoneNumber": "E-Abiodun.samson@bicworld.com/work",
                      "DefaultInd": "0",
                      "TransferIndicator": "A"
                    }
                  },
                  "RelatedCompany": {
                    "CompanyName": "Bic"
                  }
                },
                "UserID": {
                  "@attributes": {
                    "Type": "9",
                    "ID": "LOSN825CW",
                    "ID_Context": "KeyAccounter"
                  }
                }
              }
            }
          ]
        }
      },
      "status": "OK"
}