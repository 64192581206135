import mergeRecursive from "../../features/utils/mergeRecursive"
import { clone } from "../../features/utils/objClone"

export const queueTemp =                 {
  "paxName": {
      "paxDetails": {
          "surname": "ABEBE",
          "type": "0",
          "quantity": 1
      }
  },
  "recLoc": {
      "reservation": {
          "controlNumber": "QINCJ3"
      }
  },
  "segment": {
      "flightDate": {
          "departureDate": "01NOV23"
      },
      "boardPointDetails": {
          "trueLocation": "LHR"
      },
      "offpointDetails": {
          "trueLocation": " "
      },
      "companyDetails": {
          "marketingCompany": "HH"
      },
      "flightIdentification": {
          "flightNumber": "L"
      }
  },
  "agent": {
      "originatorDetails": {
          "inHouseIdentification1": "LOSN828HR",
          "inHouseIdentification2": "--"
      }
  },
  "pnrdates": [
      {
          "timeMode": "700",
          "dateTime": {
              "year": "2023",
              "month": 7,
              "day": 24
          }
      },
      {
          "timeMode": "701",
          "dateTime": {
              "year": "2023",
              "month": 7,
              "day": 24,
              "hour": "00",
              "minutes": "00"
          }
      },
      {
          "timeMode": "711",
          "dateTime": {
              "year": "2023",
              "month": 7,
              "day": 24,
              "hour": "14",
              "minutes": "59"
          }
      }
  ]
}


export const templateQueue = (obj) => {
  const data = mergeRecursive(clone(obj),clone(queueTemp))

  let result = {
    officeId: data.agent.originatorDetails.inHouseIdentification1,
    name: data.paxName.paxDetails.surname,
    PNR: data.recLoc.reservation.controlNumber,
    departureDate: data.segment.flightDate.departureDate,
    location: data.segment.boardPointDetails.trueLocation,
    ...data
  }

  return result;
}