import React, { useEffect, useState } from 'react'
import PromoCode from './PromoCode'
import { useDispatch } from 'react-redux';
import { walletPay } from '../../controllers/payment/walletPay';
import { Link } from 'react-router-dom';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import ModalLocal from '../mini/ModalLocal';
import { ListBanksReq } from '../../controllers/payment/listBanks';
import { MenuItem, TextField } from '@mui/material';
import { bankPay } from '../../controllers/payment/bankPay';
import { confirmBankTransaction } from '../../controllers/payment/confirmBankTransaction';
import { Cancel, ShareOutlined } from '@mui/icons-material';
import { encrypt } from '../../features/utils/crypto';
import PaymentMethod from './PaymentMethod';
import { path } from '../../config';

export default function FlightPayment({order,children,returnData}) {

  const [loading,setLoading] = useState(false);
  const [res,setRes] = useState({success: false,msg: ''})
  const [meth,setMeth] = useState('wallet');
  const [listBanks,setListBanks] = useState(false);
  const [banks,setBanks] = useState([]);
  const [banksLoading,setBanksLoading] = useState(false);
  const [selectedBank,setSelectedBank] = useState();

  const dispatch = useDispatch();

  console.log(' --> ',order)
  useEffect(() => {
    setRes({success: false,msg: ''});
    setSelectedBank()
  },[order])

  async function loadBanksList() {
    let params = {
      limit: 10,
      skip: 0,
    }
    setListBanks(true);
    setBanksLoading(true);
    const res = await ListBanksReq((new URLSearchParams(params)).toString())
    setBanksLoading(false);
    if(res.return) {
      setBanks(res.data);
    }
  }

  // async function handleSubmit() {
  //   if(!order) return false;

  //   setLoading(true);

  //   const res = await walletPay(order.id);
  //   if(res.return) {
  //     setRes({success: true})
  //     dispatch(setAlertMsg(['success','Payment Successfull']))
  //     if(returnData)
  //       returnData(res)
  //   } else dispatch(setAlertMsg(['error','Failed on payment']));
  //   setLoading(false);
  // }

  async function handleSubmit(msg) {  

    let orderId = order.id;
    let userId = order.userId;
    if(order.row)
      userId = order.row.userId; 
    if(meth === 'wallet') {
      setLoading(true);
      // let userId = order.userId;
      // if(order.row)
      //   userId = order.row.bookedBy && order.row.bookedBy.id;
      const res = await walletPay(orderId,userId);
      if(res.return) {
        setRes({success: true})
        dispatch(setAlertMsg(['success','Payment Successfull']))
        if(returnData)
          returnData(res)
      } else dispatch(setAlertMsg(['error',res.msg]));
      setLoading(false);
    } else if(meth === 'bank') {
      setLoading(true);
      let res = {return: false};
      if(!order.row || (order.row && order.row.transactions && !order.row.transactions.length))
        res = await bankPay(order.id);
      setLoading(false);
      if(res.return) {
        if(msg === 'verify') {
          setLoading(true);
          const confirmed = await confirmBankTransaction(orderId)
          setLoading(false);
          if(confirmed.return) {
            dispatch(setAlertMsg(['success','Payment Successfull']))
          } else  {
            dispatch(setAlertMsg(['warning','Payment Still Pending']))
          }
          if(returnData)
            returnData(confirmed.return)
          
          return true;
        }
        if(returnData)
          returnData(res)
        setRes({success: true,msg: 'Verify Payment Later'})

      } else dispatch(setAlertMsg(['error','Failed on payment']));
      setLoading(false);
    }
  }


  // function handleShare() {
  //   let val = `${path.site}/search/flight/offers?q=${id}`;
    
  //   const tempInput = document.createElement('input');
  //   tempInput.value = val;
  //   document.body.appendChild(tempInput);

  //   // Select the text in the input element
  //   tempInput.select();
  //   tempInput.setSelectionRange(0, 99999); // For mobile devices

  //   // Execute the copy command
  //   document.execCommand('copy');

  //   // Remove the temporary input element
  //   document.body.removeChild(tempInput);

  //   dispatch(setAlertMsg(['success','Copied']))
  // }


  return res.success ? (
    <div className='flex flex-col gap-3 items-center justify-center'>
      <h2 className='text-green-500'>Payment Confirmed</h2>
      <p>Flight offer is successful.</p>
      <div className='flex gap-2 flex-wrap justify-evenly w-full py-5'>
        {/* <button onClick={handeHotelSearch} className='flex-1 btn1 text-center rounded-lg' >Find a hotel</button> */}
        <Link className='flex-1 btn2 text-center' to="/search#Flight">Book another flight</Link>
        {/* <button className='btn2' onClick={handleShare}><ShareOutlined /></button> */}
      </div>
    </div>
    ):(
    <div className='bg-secondary rounded-lg p-4'>
      <PaymentMethod packageName='flight' order={order} returnData={returnData} />
      <PromoCode id={order?.id} type="flight" returnData={returnData} />
      {/* <FlightPayment order={bookingData.orderData} returnData={handleReturn} /> */}
    </div>
  )

  // return res.success ? (
  //   <div className='flex flex-col justify-center items-center p-5 gap-4 py-10 bg-secondary rounded-md'>
  //     <h3>Your flight order was successful.</h3>
  //     <Link className='btn1' to="/search#Flight">Order another Flight</Link>
  //     <Link className='btn2' to="/search#Hotel">Order a hotel</Link>
  //   </div>
  // ) : (
  //   <form onSubmit={(ev) => ev.preventDefault()} className='bg-secondary p-5 flex flex-col gap-4 relative'>
  //     {/* <div className='relative p-4'> */}

  //     {/* </div> */}

  //     <h5>Payment method</h5>
  //     <p>Choose your payment method</p>
  //     <div className='flex justify-stretch gap-4 py-4'>
  //       {/* <label className='flex gap-2'>
  //         <input type='radio' name='paymeth' />
  //         Credit / Debit Card
  //       </label> */}
  //       <ModalLocal open={listBanks} setOpen={setListBanks}>
  //         <div className=' bg-secondary p-5 flex flex-col gap-5 relative'>
  //           <div className='py-5 -m-4 relative '>
  //             <div className='btn_close z-10' onClick={() => setListBanks(false)}>X</div>
  //             {/* <Cancel className='btn_close z-10' onClick={() => setListBanks(false)} />  */}
  //           </div>

  //           {banksLoading?(
  //             <div className='w-full flex items-center border-primary justify-center p-2'>
  //               <div className='load'></div>
  //             </div>
  //           ):null}
  //           {selectedBank ? (
  //             <div className='flex flex-col'>
  //               <div className='flex justify-between gap-2'>
  //                 Account No : <span> {selectedBank.accountNumber}</span>
  //               </div>
  //               <div className='flex justify-between gap-2'>
  //                 Country : <span> {selectedBank.country}</span>
  //               </div>
  //             </div>
  //           ):null}
  //           <TextField label="Select Banks" value={selectedBank && selectedBank.bankName} select size='small' className='min-w-[250px]'>
  //             <MenuItem></MenuItem>
  //             {banks.map((obj,i) => (
  //               <MenuItem value={obj.bankName} onClick={() => setSelectedBank(obj)}>{obj.bankName}</MenuItem>
  //             ))}
  //           </TextField>
  //           <button className='btn2' onClick={() => handleSubmit('verify')}>{loading ? "Please Wait..." : "Verify"}</button>

  //           <button className='btn1' onClick={() => handleSubmit()}>Verify Later</button>
  //         </div>
  //       </ModalLocal>

  //       <label className='flex gap-2'>
  //         <input type='radio' name='paymeth' checked={meth === 'bank'} onChange={(ev) => setMeth(ev.target.value)} onClick={() => loadBanksList()} value="bank" />
  //         Bank Transfer
  //       </label>
  //       {((order.bookedBy && order.bookedBy.type !== 'CUSTOMER') || !order.bookedBy ? (
  //         <label className='flex gap-2'>
  //           <input type='radio' name='paymeth' checked={meth === 'wallet'} onChange={(ev) => setMeth(ev.target.value)} value="wallet" />
  //           Wallet Balance
  //         </label>
  //       ) : null)}
  //     </div>


  //     <PromoCode id={order && order.id} returnData={returnData}/>
  //     <div className='p-3 flex gap-6 justify-between text-primary/80'>
  //       {children}
  //       <button className='btn2' onClick={handleSubmit}>{loading ? "Please Wait..." : "Make Payment"}</button>
  //     </div>

  //   </form>
  // )
}
