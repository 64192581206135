import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Box, MenuItem } from '@mui/material';
import Pagination from '../../../components/mini/Pagination';
import { setModal, setModalComp } from '../../../redux/reducers/modal/modalSlice';
import DotMenu from '../../../components/mini/DotMenu';
import { def } from '../../../config';
import { Link } from 'react-router-dom';
import getCurrencies from '../../../controllers/settings/currency/getCurrencies';
import RemoveBankModal from './RemoveBankModal';
import getBanksReq from '../../../controllers/settings/banks/getBanks';



function ActionCol({params,reload}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();


  function handleRemove() {
    setAnchorEl(null);
    dispatch(setModal(true))
    let id = params.id;
    
    dispatch(setModalComp(<RemoveBankModal returnData={reload} id={id} />))

  }
  return (
    <div className='flex justify-between w-full relative'>
      <span>{params.value}</span>
      <DotMenu  anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true}>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
        <MenuItem>
          <Link className='w-full' to={"edit/"+params.id}>Edit</Link>
        </MenuItem>
      </DotMenu>
    </div>
  )
}


const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'paid'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'pending'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'refund'}]},
];


export default function Index() {
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  const apiRef = useGridApiRef();

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setHeaderTitle('Banks'))

    load();
  },[dispatch])

  async function load() {
    setLoading(true);
    const res = await getBanksReq();
    setLoading(false);
    if(res.return) {
      setData(res.data)
    }
  }

  const columns = [
    {field: 'bankName',headerName: 'Bank Name',flex:1,minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex gap-4 items-center'>
            {params.value}
          </div>
        )
      }
    },
    {field: 'bankCode',headerName: 'Bank Code',flex: 1,minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col'>
            {params.value}
          </div>
        )
      }
    },
    {field: 'country',headerName: 'Country',minWidth: 170,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex '>
            {params.row.symbol}
            {params.value}
          </div>
        )
      }
    },
    {field: 'accountNumber',headerName: 'Account No',minWidth: 170,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex '>
            {params.row.symbol}
            {params.value}
          </div>
        )
      }
    },
    {field: 'accountHolder',headerName: 'Account Holder',flex: 1,minWidth: 200,
      renderCell: (params) => {
        // if(!params.value)
        // return null;
  
        return (
          <ActionCol reload={load} params={params} />
        )
      }
    },
  ];
  
  return (
    <div className='w-full bg-[#f3f3f3] p-4'>
      <div className='w-full bg-secondary p-4'>
        <div className='flex justify-end pb-4'>
          <Link to="create" className='btn2'>Add Bank</Link>
        </div>
        <Box className=''>
          <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
            // getRowId={(row) => row.id}
            apiRef={apiRef}
            initialState={{
              sorting: {
                sortModel: [{field: "bookingDate",sort: 'desc'}]
              },
            }}
            filterModel={filter}
            onFilterModelChange={(newFilter) => setFilter(newFilter)}
            
            // slots={{pagination: Pagination}}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}

            // onCellEditStart={params => setRowId(params.id)}
            // hideFooterPagination
            checkboxSelection
          />
          <Pagination perPage={10} total={46} apiRef={apiRef} />
        </Box>
      </div>
    </div>
  )
}
