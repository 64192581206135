import { Box, MenuItem, TextField } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/mini/SearchInput';
import Pagination from '../../../components/mini/Pagination';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import DotMenu from '../../../components/mini/DotMenu';
import { getSettings } from '../../../controllers/settings/getSettings';
import { setModal, setModalComp } from '../../../redux/reducers/modal/modalSlice';
import { getPNRExpiry } from '../../../controllers/settings/PNR/getPNRExpiry';
import ModalLocal from '../../../components/mini/ModalLocal';
import { userTypes } from '../../../data/ENUM/UserType';
import { setPNRExpiry } from '../../../controllers/settings/PNR/setPNRExpiry';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  {
    id: 1, customer: 'Yona',date: '24th Oct, 2023 8:29AM',
    product: "Flight",
    paymentMethod: 'Card Payment',
    amount: 12412, status: 'Pending'
  }
];
[...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))




function ActionCol({params}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();


  return (
    <div className='flex justify-between gap-2 w-full relative'>
      <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
        {params.value}
      </p>

      <DotMenu  anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true}>
        {/* <MenuItem onClick={handleRemove}>Remove</MenuItem> */}
        <Link to={"/settings/points/edit/"+params.id}>
          <MenuItem>Edit</MenuItem>
        </Link>
      </DotMenu>
    </div>
  )
}

const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'paid'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'pending'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'refund'}]},
];



export default function Expiry() {
  const [data,setData] = useState([]);
  const {type} = useParams();
  const [loading,setLoading] = useState(false);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})


  // const [userType,setUserType] = useState("");
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData,setFetchedData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(type);
    dispatch(setHeaderTitle("PNR Expiry"))
    load();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  async function load() {
    setLoading(true);
    const res = await getPNRExpiry();
    if(res.return) {
      // let data = res.data.map(d => d.fareRules.map(obj => ({for: d['for'] || (d['customUser'].firstName+" "+d['customUser'].lastName),...obj}) )).flat(1);
      // console.log('flatted', data)
      let data = res.data.map((d,i) => ({id: i,...d}))
      setFetchedData(data);
      setData(data);
    }
    setLoading(false);
  }


  const columns = [
    {field: 'forUserType',headerName: 'For',flex:1,minWidth: 200,
      renderCell: (params) => {
        // if(!params.value) return null;
        return (
          <div className='flex gap-4 items-center'>
            {params.value || " - "}
          </div>
        )
      }
    },
    {field: 'expireInHours',headerName: 'Expire In Hours',flex: 1,minWidth: 130,
      renderCell: (params) => {
        // if(!params.value)
        // return null;
  
        return (
          <div className='flex justify-between w-full relative'>
            <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
              {params.value}
            </p>
          </div>
        )
      }
    },
    {field: 'notifyExpireInHours',headerName: 'Notify In Hours',flex: 1,minWidth: 130,
      renderCell: (params) => {
        // if(!params.value)
        // return null;
  
        return (
          <div className='flex justify-between w-full relative'>
            <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
              {params.value}
            </p>
          </div>
        )
      }
    },
  ];
  
  function handleSearch(val) {
    setData(fetchedData);

    console.log(" -> -> ",data)
    val = val.toLowerCase();
    setData(data => data.filter(obj => (
      obj.forUserType.toLowerCase().includes(val) ||
      obj.expireInHours.toString().toLowerCase().includes(val) ||
      obj.notifyExpireInHours.toString().toLowerCase().includes(val)
    )))
  }

  const [createModal,setCreateModal] = useState(false);
  const [expireData,setExpireData] = useState({
    "notifyExpireInHours": '',
    "expireInHours": '',
    "forUserType": ''
  })
  
  async function handleExpirySet(ev) {
    ev.preventDefault();

    let {forUserType,...modData} = {...expireData};
    if((forUserType !== 'All Users'))
      modData = expireData;
      

    setLoading(true);
    const res = await setPNRExpiry(modData);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Expiry time set.']));
      load();
    } else dispatch(setAlertMsg(['error',res.msg]))
  } 
  
  return (
    <div className='w-full p-4 bg-[#f3f3f3]'>
      
      <Box className='bg-secondary px-6'>
        <div className='flex gap-4 justify-between items-center my-3 p-3'>
          <SearchInput className='text-theme1' onChange={handleSearch} />
          <button className='btn2' onClick={() => setCreateModal(true)}>Set Expiry Time</button>
          <ModalLocal open={createModal} setOpen={setCreateModal}>
            <form onSubmit={handleExpirySet} className='h-full flex items-center'>
              <div className='flex flex-col bg-secondary rounded-md p-5 pt-10 items-center gap-4 relative'>
                <div className='btn_close' onClick={() => setCreateModal(false)}>X</div>
                <TextField select size='small' label='User Type' className='w-full'
                  value={expireData.forUserType}
                  onChange={(ev) => setExpireData(d => ({...d,forUserType: ev.target.value}))}
                >
                  <MenuItem value={'All Users'} className='capitalize'>{"All Users"}</MenuItem>
                  {userTypes.map((val,i) => (
                    <MenuItem value={val} className='capitalize'>{val.toLowerCase().replace('_',' ')}</MenuItem>
                  ))}
                </TextField>
                <TextField label='Expire in' size='small' type='number'
                  value={expireData.expireInHours}
                  onChange={(ev) => setExpireData(d => ({...d,expireInHours: ev.target.value}))}
                  InputProps={{
                    endAdornment: 'Hr'
                  }}
                />
                <TextField label='Notify Expire' size='small' type='number'
                  value={expireData.notifyExpireInHours}
                  onChange={(ev) => setExpireData(d => ({...d,notifyExpireInHours: ev.target.value}))}
                  InputProps={{
                    endAdornment: 'Hr'
                  }}
                />
                <button className='btn2 flex items-center gap-2 w-full'>
                  {loading?<div className='load'></div>:null}
                  Set
                </button>
              </div>
            </form>
          </ModalLocal>
        </div>

        <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "bookingDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
    </div>
  )
}
