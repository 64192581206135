import React from 'react'

export default function PageNotFound() {
  return (
    <div className='flex flex-col gap-4 py-10 justify-center items-center'>
      <h1>404 | Page not found</h1>
      <h3>This is not the page you are looking for!</h3>
    </div>
  )
}
