import { Breadcrumbs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import TransactionDetail from './TransactionDetail';
import { templateTransactions, transactionTemp } from '../../../data/transactions/transaction';
import { getTransaction } from '../../../controllers/transactions/getTransaction';


let temp = transactionTemp;

export default function Index() {
  const [data,setData] = useState(temp);
  const {id} = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setHeaderTitle("Transaction Details"));
    load(id);
  },[id,dispatch])
  
  async function load(id) {
    const res = await getTransaction(id);
    if(res.return) {
      let data = templateTransactions(res.data)
      setData(data);
    }
  }

  
  return (
    <div className='w-full p-4 m-2'>
      <Breadcrumbs separator=">">
        <Link to="/transactions">Orders</Link>,
        <span >{data.bookedTrip.bookingId}</span>
      </Breadcrumbs>


      <div className='flex gap-4 flex-wrap'>
        <div className='flex-1 flex flex-col gap-10 pb-4'>
          <div className='flex flex-col gap-3 py-5 '>
            <div>
              <div className='flex justify-between items-center'>
                <h5>{data.user.type}</h5>
              </div>
            </div>
            <hr />
            <table className='w-full mx-2'>
              <thead>
                <tr>
                  <td>Name</td>
                  <td className='flex-1'>Email</td>
                  <td>Phone</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data.user.firstName} {data.user.lastName}</td>
                  <td>{data.user.email}</td>
                  <td>{data.user.phoneNumber}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='mt-4 flex flex-col gap-6'>
            <div className='flex flex-col gap-4'>
              <h4>Flight Booking</h4>
              <hr />
              <TransactionDetail data={data.bookedTrip} />
            </div>
            <hr />
            <div>
              <h4>Hotel Booking</h4>
            </div>
            <hr />
            <div>
              <h4>Honeymoon Gateway</h4>
            </div>
            <hr />
          </div>
        </div>
        <div>
          <MinDetail data={data} />
        </div>
      </div>
    </div>
  )
}

function MinDetail({data}) {
  return (
    <div>
      <h5 className='px-4'>Details</h5>
      <hr />
      <div className='px-4 pt-5 flex flex-col gap-4'>
        <div>
          <div>Status</div>
          {
            data.status === 'Confirmed' ? 
              <span className='text-xs p-2 inline-block rounded-md !border-[#45BF43] border !bg-[#58ffb038]'>Confirmed</span>
              :
              <span className='text-xs p-2 inline-block rounded-md !border-[#aaa] border'>{data.transactionStatus}</span>
          }
        </div>
        <div>
          <div>Payment Method</div>
          <b>{data.from}</b>
        </div>
        <div>
          <span>Payment Reference</span>
          <div>
            <b className='target'>{data.paymentReference}</b>
          </div>
        </div>
        <div>
          <div>Confirmed by</div>
          <b>{data.confirmedBy?.firstName} {data?.confirmedBy?.middleName} {data?.confirmedBy?.lastName}</b>
        </div>
      </div>
    </div>
  )
}