import { Box, MenuItem } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../components/mini/SearchInput';
import Pagination from '../../components/mini/Pagination';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../redux/reducers/titleSlice';
import DotMenu from '../../components/mini/DotMenu';
import { getSettings } from '../../controllers/settings/getSettings';
import AddMarkup from './AddMarkup';
import { setModal, setModalComp } from '../../redux/reducers/modal/modalSlice';
import RemoveMarkupModal from './RemoveMarkupModal';
import { Icon } from '@iconify/react';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  {
    id: 1, customer: 'Yona',date: '24th Oct, 2023 8:29AM',
    product: "Flight",
    paymentMethod: 'Card Payment',
    amount: 12412, status: 'Pending'
  }
];
[...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))




function ActionCol({params,reload,edit}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();


  function handleRemove() {
    setAnchorEl(null);
    dispatch(setModal(true))
    let id = params.id;
    console.log("params: ",params);
    dispatch(setModalComp(<RemoveMarkupModal callback={reload} id={id} />))

  }
  return (
    <div className='flex gap-2 w-full relative'>
      <button onClick={() => edit && edit(params?.row)} className='border p-2 rounded-md bg-primary/10'>
        <Icon icon='tabler:edit' />
      </button>
      <button onClick={handleRemove} className='border p-2 rounded-md bg-primary/10'>
        <Icon icon='mi:delete' className='text-red-500' />
      </button>
      {/* <DotMenu  anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true}>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
      </DotMenu> */}
    </div>
  )
}

const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'paid'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'pending'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'refund'}]},
];



export default function Index() {
  const [data,setData] = useState([]);
  const {type} = useParams();
  const [loading,setLoading] = useState(false);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  const [editObj,setEditObj] = useState();


  const [userType,setUserType] = useState("");
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData,setFetchedData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(type);
    dispatch(setHeaderTitle("Markups"))
    load();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userType])
  async function load() {
    setLoading(true);
    let q = {
      for: userType.toUpperCase()
    }
    const res = await getSettings(new URLSearchParams(q));
    if(res.return) {
      let data = res.data.map(d => d.fareRules.map(obj => ({for: d['for'] || (d['customUser']?.firstName+" "+d['customUser']?.lastName),...obj}) )).flat(1);
      console.log('flatted', data)
      data = data?.sort((a,b) => new Date(b.updatedDate) - new Date(a.updatedDate))
      setFetchedData(data)
      setData(data);
    }
    setLoading(false);
  }

  const columns = [
    {field: 'for',headerName: 'User',flex:1,minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex gap-4 items-center'>
            {params.value}
          </div>
        )
      }
    },
    {field: 'markUp',headerName: 'Value',flex: 1,minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
        return null;
  
        return (
          <div className='flex gap-1 justify-between w-full relative'>
            {params?.row?.currency || 'NGN'}
            <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
              {params.value}
            </p>
          </div>
        )
      }
    },
    {field: 'flightProvider', headerName: 'Provider',flex: 1,
      renderCell: (params) => (
        params.value || ''
      )
    },
    {field: 'fareRuleType',headerName: 'Type',flex: 1,minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex justify-between w-full relative'>
            <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
              {params.value}
              {params.row?.airLines?.length ? 
                <span> ({
                  params.row?.airLines?.join(', ')
                })</span>
              :''}
            </p>
          </div>
        )
      }
    },
    {field: 'action',headerName: 'Action',flex: 1,minWidth: 130,
      renderCell: (params) => {
        // if(!params.value)
        // return null;
  
        return (
          <ActionCol params={params} reload={load} edit={handleEdit} />
        )
      }
    },
  ];

  function handleEdit(obj) {
    document.getElementById('top')?.scrollIntoView({behavior: 'smooth'})
    setEditObj(obj);
  }
  
  function handleSearch(val) {
    if(!val)
      return setData(fetchedData);

    console.log(fetchedData)
    val = val?.toLowerCase();

    setData((fetchedData||[])?.filter(obj => (
      obj?.for?.toLowerCase()?.includes(val) ||
      obj?.customer?.toLowerCase()?.includes(val) ||
      obj?.fareRuleType?.toLowerCase()?.includes(val) ||
      obj?.markType?.toLowerCase()?.includes(val) ||
      obj?.markUp?.toString()?.toLowerCase()?.includes(val) ||
      obj?.flightType?.toLowerCase()?.includes(val)
      // (new Date(obj?.date)).toDateString()?.toLowerCase()?.includes(val) ||
      // (parseInt(obj?.amount) <= val) ||
      // obj?.status?.includes(val)
    )))
  }
  
  return (
    <div className='w-full p-4 m-2 bg-[#f3f3f3]' id='top'>
      
      <AddMarkup reload={load} returnData={(val) => setUserType(val)} setUpdateObj={setEditObj} updateObj={editObj} />
      <Box className='bg-secondary'>
        <div className='flex gap-4 justify-between items-center my-3 p-3'>
          <SearchInput className='text-theme1' onChange={handleSearch} />
        </div>

        <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "createdDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
    </div>
  )
}
