import { MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FlightSearchInput from '../../components/search/FlightSearchInput'
import FetchUsersInput from '../../components/form/FetchUsersInput';
import { useDispatch } from 'react-redux';
import { setBookingData } from '../../redux/reducers/flight/flightBookingSlice';
import HotelSearchInput from '../../components/search/HotelSearchInput';
import { useLocation, useSearchParams } from 'react-router-dom';
import TourSearchInput from '../../components/search/TourSearchInput';
import { setHotelBooking } from '../../redux/reducers/hotel/hotelBookingSlice';
import { setTourBooking } from '../../redux/reducers/tour/tourBookingSlice';
import CWTForm from './CWTForm';

export default function Index({newWindow}) {
  const [userType,setUserType] = useState("");
  const location = useLocation()
  let cat = location.hash.substr(1) || false;
  const [category,setCategory] = useState(cat || "Flight");
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();


  useEffect(() => {
    let obj = {}
    obj = {userType}
    if(category === 'Flight')
      dispatch(setBookingData(obj))
    else if(category === 'Hotel')
      dispatch(setHotelBooking(obj))
    else if(category === 'Package')
      dispatch(setTourBooking(obj))
    
    //eslint-disable-next-line
  },[dispatch,userType,category])

  function addUser(val) {
    if(category === 'Hotel')
      dispatch(setHotelBooking({as: val}))
    else if(category === 'Flight')
      dispatch(setBookingData({as: val}))
    else if(category === 'Package')
      dispatch(setTourBooking({as: val}))
  }

  function addCWT(obj) {
    if(category === 'Hotel')
      dispatch(setHotelBooking({cwt: obj?.Profile?.CompanyInfo?.CompanyName,cwtObj: {...obj,office: userType?.split('-')[1]}}))
    if(category === 'Flight')
      dispatch(setBookingData({cwt: obj?.Profile?.CompanyInfo?.CompanyName,cwtObj: {...obj,office: userType?.split('-')[1]}}))
    if(category === 'Package')
      dispatch(setTourBooking({cwt: obj?.Profile?.CompanyInfo?.CompanyName,cwtObj: {...obj,office: userType?.split('-')[1]}}))
  }

  function handleCat(val) {
    setCategory(val);
    window.history.pushState(null, null, `#${val}`);
  }
  return (
    <div className='w-full p-4 bg-[#f3f3f3]'>
      <div className='bg-secondary p-4'>
        <div className='flex gap-5 flex-wrap '>
          <TextField select label='Search' size='small' className='min-w-[100px]'
            value={category} 
            onChange={(ev) => handleCat(ev.target.value)}
          >
            <MenuItem value='Flight'>Flight</MenuItem>
            <MenuItem value='Hotel'>Hotel</MenuItem>
            <MenuItem value='Package'>Package</MenuItem>
          </TextField>
          {searchParam.get("refCode") ? null : (
            <TextField select value={userType} 
              onChange={(ev) => setUserType(ev.target.value)} label="Select BTM Holidays,Channel or Company" 
              size='small' 
              className='min-w-[350px]'>
              <MenuItem value="Customer">BTM Holidays</MenuItem>
              <MenuItem value="Sub_Agent">Channel</MenuItem>
              <MenuItem value="Corporate">Company</MenuItem>
              {/* <div className='flex justify-stretch'> */}
                <MenuItem className='flex-1' value="CWT-los">CWT Los</MenuItem>
                <MenuItem className='flex-1' value="CWT-los2">CWT Los 2</MenuItem>
                <MenuItem className='flex-1' value="CWT-dxb">CWT DXB</MenuItem>
              {/* </div> */}
            </TextField>
          )}
          {
            userType !== "" && !userType?.includes('CWT') ? (
              <FetchUsersInput label={userType} onChange={addUser} />
            ) :
            userType?.includes('CWT') ?
              <CWTForm office={userType?.split('-')[1]} callback={addCWT} />
              // <FetchCWTPassengers data={{office: userType?.split('-')[1]}} onChange={addUser} />
            : null
          }
        </div>
        {categoryInput(category,newWindow)}
      </div>
    </div>
  )
}

function categoryInput(category,newWindow) {
  if(category === 'Hotel')
    return <HotelSearchInput newWindow={newWindow} />
  else if(category === 'Package')
    return <TourSearchInput newWindow={newWindow} />
  else
    return <FlightSearchInput cur={1} newWindow={newWindow} />
}
