import { Add, ArrowBack } from '@mui/icons-material'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { clone } from '../../../features/utils/objClone'
import { useDispatch } from 'react-redux'
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice'
import CountriesInput from '../../../components/form/CountriesInput'
import { bankData } from '../../../data/settings/bank/bankData'
import updateBankReq from '../../../controllers/settings/banks/updateBank'
import getSingleBank from '../../../controllers/settings/banks/getSingleBank'


export default function EditBank() {
  const [data,setData] = useState(clone(bankData));
  const [loading,setLoading] = useState(false);
  const {id} = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    load();
  },[])

  async function load() {
    setLoading(true);
    const res = await getSingleBank(id);
    setLoading(false);
    if(res.return) {
      setData(res.data)
    }
  }

  async function handleSubmit() {
        
    let filtered = clone(data);
    filtered = Object.fromEntries(Object.entries(filtered).filter(([key, value]) => value && value !== ""));
    
    setLoading(true);
    const res = await updateBankReq(filtered,id);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Bank Update Successfull.']))
    } else dispatch(setAlertMsg(['error','Failed Updating Bank']))

  }

  return (
    <div className='w-full bg-[#f3f3f3] p-4'>
      <div className='bg-secondary p-4'>
        <Link to="/settings/banks" className='flex gap-4'>
          <ArrowBack />
          <h3>Edit Bank</h3>
        </Link>
        <div className='inline-block py-4'>
          <div className='flex flex-col gap-2 w-auto'>
            <TextField size='small' label='Bank Name'
              value={data.bankName} onChange={(ev) => setData(data => ({...data,bankName: ev.target.value}))} 
            />
            <TextField size='small' label='Bank Code'
              value={data.bankCode} onChange={(ev) => setData(data => ({...data,bankCode: ev.target.value}))} 
            />
            <TextField size='small' label='Account Holder'
              value={data.accountHolder} onChange={(ev) => setData(data => ({...data,accountHolder: ev.target.value}))} 
            />
            {/* <textarea placeholder='Description' className='min-w-[50%]'></textarea> */}
            <TextField size='small' label='Account Number'
              value={data.accountNumber} onChange={(ev) => setData(d => ({...data,accountNumber: ev.target.value}))}
            />
            <CountriesInput label={'Country'}
              value={data.country} onChange={(val) => setData(d => ({...data,country: val.name || val}))}
            />
            <button className='btn2 flex gap-2' onClick={handleSubmit}>
              {loading?<div className='load'></div>:null}
              Save Bank</button>
          </div>
        </div>
      </div>
    </div>
  )
}
