import React from 'react'
import { ArrowBack, LocationOn } from '@mui/icons-material';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import RoomDisplay from '../../../components/hotel/RoomDisplay';


export default function Confirmation({back,data}) {
  const {hotelBooking} = useSelector(state => state.hotelBooking);

  let guests = [];

  try {
    hotelBooking.booked.data.guests.map(guest => {
      guests.push(guest)
      // room.booked_guests.map(guest => guests.push(guest))
      return true;
    })
  } catch(ex) {}

  console.log('data : ',hotelBooking)

  return (
    <div className='flex flex-col gap-3 flex-1'>
      <Breadcrumbs separator=">" className='text-xs'>
        <Link to="/search/hotels">Hotels</Link>
        <span className='cursor-pointer' onClick={() => back && back()}>Payment</span>
        <span>Confirmation</span>
      </Breadcrumbs>
      <div className='bg-secondary rounded-md flex flex-col '>
        <RoomDisplay review />
      </div>
      <div className='flex flex-col gap-5 '>
        <small className='text-green-400'>Booking has been confirmed</small>
        <Link to="/search#Hotel" className='flex gap-2 items-center'>
          <ArrowBack />
          <h3>Book More</h3></Link>
        <div className='flex gap-2'>
          <img src={require('../../../assets/img/hotel.png')} alt='Hotel' className='w-16' />
          <div className='p-2 flex-1'>
            <h2 className='capitalize'>{data.name.toLowerCase()}</h2>
            <small className='capitalize'>
              <LocationOn className='sqr5' />
              {data.address.toLowerCase()}</small>
          </div>
        </div>
        <div className='flex'>
          <div className='flex flex-col p-2 '>
            <div className='rounded-full bg-blue-500 sqr2'></div>
            <div className='vr h-full border-blue-500'></div>
            <div className='rounded-full bg-blue-500 sqr2'></div>
          </div>
          <div className='flex flex-1 flex-col gap-4 justify-between'>
            <div className=''>{moment(data.start_date).format('ddd, DD MMM YYYY, HH:MM')}</div>
            <small>Duration 1 night</small>
            <div className=''>{moment(data.end_date).format('ddd, DD MMM YYYY, HH:MM')}</div>
          </div>
        </div>
        <div className='flex flex-col gap-3'>
          <h3>Guest</h3>
          <div className='flex gap-3'>
            {/* <div className=''>
              <button className='btn2'>One Adult</button>
            </div> */}
            <table className='flex-1'>
              <thead>
                <tr>
                  <td>Title</td>
                  <td>Name</td>
                  <td>type</td>
                </tr>
              </thead>
              <tbody>
                {guests.map((guest,i) => (
                  <tr key={i}>
                    <td>{guest.title}</td>
                    <td>{guest.first_name} {guest.last_name}</td>
                    <td>{guest.type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='flex gap-4 justify-between py-4'>
          <Link to={`/search#Hotel`} className='btn1 rounded-md'>Go to search</Link>
          <Link to={`/hotels/details/`+hotelBooking?.booked?.orderData?.id} className='btn2'>Continue</Link>
        </div>
      </div>
    </div>
  )
}
