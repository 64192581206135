import { ArticleOutlined } from '@mui/icons-material';
import { Box, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../components/mini/SearchInput';
import Pagination from '../../components/mini/Pagination';
import { Avatar } from "@mui/material";
import DotMenu from '../../components/mini/DotMenu';
import { Link, useParams } from 'react-router-dom';
import getHotelOrders from '../../controllers/hotel/gethotelOrders';
import templateBookedHotels, { templateBookedHotelReport } from '../../data/hotel/tableTemplate';
import downloadCSV from '../../features/utils/downloadCSV';
import { formatMoney } from '../../features/utils/formatMoney';
import { def } from '../../config';
import moment from 'moment';
import { paymentStatus } from '../../data/ENUM/PaymentStatus';
import { useDispatch } from 'react-redux';
import { setModal, setModalComp } from '../../redux/reducers/modal/modalSlice';
import HotelPayment from '../../components/payment/HotelPayment';
import { confirmBankTransaction } from '../../controllers/payment/confirmBankTransaction';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  {
    id: 1, customer: 'Yona',dateOrder: '24th Oct , 2023',
    checkIn: '24th Oct , 2023',
    checkOut: '24th Oct , 2023',
    hotelName: 'Sheraton', roomType: {type: 'Queen A - 2345',no: '0024'},status: 'Not Paid'
  }
];
// [...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))

function StatusCol({params,reload}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();

  let method;
  let transId;
  try {
    method = params.row.transactions[0].from
    transId = params.row.transactions[0].id;
  } catch(ex) {}

  async function verifyPayment() {
    const res = await confirmBankTransaction(transId)
    if(res.return) {
      setAnchorEl(null);
      reload();
      dispatch(setAlertMsg(['success','Payment Confirmed']))
    } else dispatch(setAlertMsg(['warning','Payment still pending']))
  }


  function handlePayment() {
    dispatch(setModal(false));
    reload();
  }
  async function pay() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    console.log(params.id)
    console.log(params.row)
    dispatch(setModal(true))
    dispatch(setModalComp(
      <div className='relative bg-secondary p-2 px-4 rounded-lg'>
        <div className='btn_close z-10 ' onClick={() => dispatch(setModal(false))}>X</div>
        <HotelPayment returnData={handlePayment} orderData={params && params.row} />
      </div>
    ))
  }


  return (
    <div className='flex justify-between w-full relative'>
      <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
        {paymentStatus[params.value]}
      </p>
      <DotMenu anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true}>
        <MenuItem><Link to={`/hotels/details/${params.id}`}>Detail</Link></MenuItem>
        {method === 'BANK' ? 
          <MenuItem onClick={verifyPayment}>Verify</MenuItem>
        :null}
        {!method ? 
          <MenuItem onClick={pay}>Pay</MenuItem>
        :null}
      </DotMenu>
    </div>
  )
}

const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'Paid'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'PENDING_PAYMENT'}]},
];


export default function Index() {
  const [data,setData] = useState(rows);
  const [loading,setLoading] = useState(true);
  const [filter,setFilter] = useState(filterItems[0]);
  const {page} = useParams();
  const [type,setType] = useState(page || "");
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData,setFetchedData] = useState(null);

  console.log(' type now : ',type)

  const columns = [
  
    {field: 'customer',headerName: 'Booked by',flex:1,minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex gap-4 items-center'>
            <Avatar>{params.value[0]}</Avatar>
            {params.value}
          </div>
        )
      }
    },
    {field: 'updatedDate',headerName: 'Order date',minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col'>
            {moment(params.value).format("DD-MM-YYYY")}
            <small>{(new Date(params.value)).toLocaleTimeString()}</small>
          </div>
        )
      }
    },
    {field: 'hotelName', headerName: 'Hotel name',minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        
        return (
          <div className='flex flex-col gap-0'>
            <b className='text-theme1 !font-normal capitalize' title={params.value}>{params.value.toLowerCase()}</b>
          </div>
        )
      },
    },
    {field: 'checkIn',headerName: 'Check in',minWidth: 130,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex flex-col '>
            {moment(params.value).format("DD-MM-YYYY")}
            {/* <small>{params.value.time}</small> */}
          </div>
        )
      }
    },
    {field: 'checkOut', headerName: 'Check out',minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col '>
            {moment(params.value).format("DD-MM-YYYY")}
            {/* <small>{params.value.time}</small> */}
          </div>
        )
      }
    },
  
    {field: 'bookingId',headerName: 'Booking code',minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col gap-1'>
            {params.value}
          </div>
        )
      }
    },
    {field: 'amount',headerName: 'Amount',minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col gap-1'>
            {def.currency}{formatMoney(params.value)}
          </div>
        )
      }
    },
    {field: 'status',headerName: 'Status',flex: 1,minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
        return null;
  
        return (
          <StatusCol params={params} reload={load} />
        )
      }
    },
  ];
  
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  useEffect(() => {
    // if(page)
    filterType(page || "All");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page])
  async function load() {
    const res = await getHotelOrders();
    setLoading(false);
    if(res.return) {
      let data = res.data.map((row) => templateBookedHotels(row))
      setFetchedData(data);
      setData(data);
    }
  }

  function filterType(val) {
    setType(val);
    setData(fetchedData);
    val = val.toLowerCase();
    if(val === "all") return true;
   
    console.log('what data is : ',data)
    try {
      setData(data => data.filter(obj => (
        obj.bookedBy.type.toLowerCase() === val.toLowerCase()
      )))
    } catch(ex) {}
  }


  function handleSearch(val) {
    setData(fetchedData);

    val = val.toLowerCase();
    setData(data => data.filter(obj => (
      obj.customer.toLowerCase().includes(val) ||
      (new Date(obj.updatedDate)).toDateString().toLowerCase().includes(val) ||
      ((obj.checkIn)).toLowerCase().includes(val) ||
      ((obj.checkOut)).toLowerCase().includes(val) ||
      obj.bookingId.toLowerCase().includes(val) ||
      obj.hotelName.toLowerCase().includes(val) ||
      obj.status.includes(val)
    )))
  }

  function generateReport(data,columns) {
    
    const csvData = data.map((row) => templateBookedHotelReport(row));
    csvData.unshift(columns.map(col => col.headerName));
    // console.log('csvData', csvData)

    downloadCSV(csvData,'hotel_booking')
  }

  
  return (
    <div className='w-full p-4 m-2'>
      <div className='py-5'>
        <TextField select value={type} onChange={(ev) => filterType(ev.target.value)} label="Select Customer,Agent or Company" size='small' className='min-w-[350px]'>
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Customer">Customer</MenuItem>
          <MenuItem value="Sub_Agent">Agent</MenuItem>
          <MenuItem value="Corporate">Company</MenuItem>
        </TextField>
      </div>
      <div className='flex gap-4 justify-between items-center my-3'>
        <Tabs value={filter.items[0].id} onChange={(ev,newVal) => setFilter(filterItems[newVal])} 
         variant='scrollable'
         scrollButtons={false}
         className='shadow-md  '>
          <Tab label={`All (${data && data.length})`} sx={{textTransform: 'capitalize'}} />
          <Tab label="Paid" sx={{textTransform: 'capitalize'}}/>
          <Tab label="Pending" sx={{textTransform: 'capitalize'}}/>
        </Tabs>
        <SearchInput className='text-theme1' onChange={handleSearch} />
        <button className='btn2 whitespace-nowrap'  onClick={() => generateReport(data,columns)}><ArticleOutlined /> Generate Report</button>
      </div>
      <Box className=''>
        <DataGrid rows={data||[]} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "updatedDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
    </div>
  )
}
