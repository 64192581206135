import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { setModal } from '../../../redux/reducers/modal/modalSlice';
import removeBankReq from '../../../controllers/settings/banks/removeBank';

export default function RemoveBankModal({id,returnData}) {
  const [loading,setLoading] = useState();
 
  const dispatch = useDispatch();
  

  async function handleRemove() {
    setLoading(true);
    const res = await removeBankReq(id);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Bank Removed']))
      returnData && returnData()
    }
    else dispatch(setAlertMsg(['error','Unable to remove Bank!']))

    dispatch(setModal(false));
    // console.log('removing',id)
  } 
  return (
    <div className='h-full flex items-center'>
      <div className='flex flex-col bg-secondary rounded-md p-5 pt-10 items-center gap-2 relative'>
        <div className='btn_close' onClick={() => dispatch(setModal(false))}>X</div>
        <h2>Are you sure you want to remove Bank?</h2>
        <button className='btn2 flex gap-2' disabled={loading} onClick={handleRemove}>
          {loading?(<div className='load'></div>):null}
          Yes, Remove.
        </button>
      </div>
    </div>
  )
}
