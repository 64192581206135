import { Bed} from '@mui/icons-material'
import { DatePicker } from 'antd';
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
// import moment from 'moment/moment';
import { offerSearchTemp } from '../../data/flight/offerSearchData';
import moment from 'moment/moment';
import { decrypt, encrypt } from '../../features/utils/crypto';
import { clone } from '../../features/utils/objClone';
import { useDispatch, useSelector } from 'react-redux';
import { setBookingData } from '../../redux/reducers/flight/flightBookingSlice';
import CountriesInput from '../form/CountriesInput';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import CitySearch from './CitySearch';


const {RangePicker} = DatePicker;

export default function TourSearchInput({cur,gotQ,newWindow}) {
  const [type] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {tourBooking} = useSelector(state => state.tourBooking);
  const [searchParam] = useSearchParams();
  const q = useMemo(() => searchParam.get('q'),[searchParam]);
  const [qObj,setQObj] = useState();
  const [data,setData] = useState({});


  // const [data,setData] = useState(offerSearchTemp);
  // console.log('rendering SearchINputs: ',qObj)
  // console.log('TC: ',travelClass)
  // const handleSetPassengers = useCallback((newPassengers) => {
  //   setPassengers(newPassengers);
  // }, []);

  useEffect(() => {
    if(q) {
      let obj = JSON.parse(decrypt(q));
      setQObj(obj)
      setData(obj)
    }
  },[q])

  function getCityAndCountry(addressComponents) {
    let city = '';
    let country = '';
  
    for (const component of (addressComponents || [])) {
      if (component.types.includes('locality') || component.types.includes("administrative_area")) {
        city = component.long_name;
      } else if (component.types.includes('country')) {
        country = component.long_name;
      }
    }
  
    return { city, country };
  }


  function handleSearch(ev,dataInp) {
    ev.preventDefault();

    if(!(tourBooking?.as || tourBooking?.cwt || tourBooking?.userType))
    return dispatch(setAlertMsg(['error','Please Select Chanel/Company']))

    let searchObj = getCityAndCountry(data?.address_components);
    if(!searchObj?.city && !searchObj?.country && (qObj?.city || qObj?.country))
      searchObj = qObj;
    // console.log("Search Request - ",searchObj);
    // setData(newData);

    let enc = encrypt(JSON.stringify(searchObj));

            // SET TIME ------
    // dispatch(setBookingData({...bookingData}))

    // setTimeout(() => {
    //   dispatch(setModal(true))
    //   dispatch(setModalComp(PriceTimeout))
    // },priceTimeout)

    if(newWindow)
      window.open(`/search/tours?q=${enc}`);
    else
      navigate(`/search/tours?q=${enc}`);

  }

  return (
    <div className='py-5 flex flex-col gap-4'>
      <form onSubmit={handleSearch} className='flex flex-wrap gap-2'>
        <div className='flex-1'>
          <CitySearch className='w-full' value={`${data?.city ? data?.city+', ' : ''}${data?.country||''}` || ''} onChange={(obj) => setData(obj)} />
        </div>
        <button className='btn2 !rounded-sm '>Search</button>
      </form>
      {/* {
        (type === 1) ?
          <OneWayInput handleSearch={handleSearch} q={qObj} />
        :
        (type === 2) ? 
          <RoundTripInput handleSearch={handleSearch} q={qObj} />
        : null
      } */}

    </div>
  )
}


function Loc({returnData,value}) {
  const [data,setData] = useState((value) || "");

  useEffect(() => {
    if(value)
      setData(value)
  },[value])

  function handleChange(val) {
    setData(val)
    if(returnData)
      returnData(val);
  }
  return (
    <div className='flex gap-2 flex-1 justify-stretch relative'>
      <CountriesInput className={'flex-1 w-full'} label={'Enter Destination'} value={data} onChange={(val) => handleChange(val.name || val)} icon={<Bed />} />
      {/* <IataInput className='flex-1 w-full ' label="Enter Destination" val={data} returnData={(val) => {console.log(val);handleChange(val)}}
        icon={<Bed />}
      /> */}
      {/* <IataInput className='flex-1' label="To" val={data.arrivalLocation} returnData={(val) => handleChange({...data,arrivalLocation: val.iata || val})} 
        icon={<Flight className='rotate-180' />}
      /> */}
    </div>
  )
}

function OneWayInput({handleSearch,q}) {
  const [data,setData] = useState(q || offerSearchTemp);

  useEffect(() => {
    if(q)
      setData(q)
  },[q])

  // function setDate(ev) {
  //   let temp = clone(data);
  //   temp.destinations[0].date = ev.target.value;
  //   setData(temp);
  // }
  function setLoc(val) {
    let temp = clone(data);
    temp.destinations[0] = {...temp.destinations[0],arrivalLocation: val};
    setData(temp);
  } 
  return (
    <form onSubmit={(ev) => handleSearch(ev,data)} className='flex gap-2 '>

      <Loc returnData={setLoc} value={data.destinations[0].arrivalLocation} />
      {/* <TextField type='date' label='Depart' size='small' name='date' className='flex-1'
        value={data.destinations[0].date}
        onChange={setDate}
        InputLabelProps={{
          shrink: true,
        }}/> */}
      {/* <Persons returnData={(val) => setData({...data,passengers: val})} /> */}
      <button className='btn2 !rounded-sm' disabled={!data?.destinations[0]?.arrivalLocation}>Search</button>

    </form>

  )
}

function RoundTripInput({handleSearch,q}) {
  const [data,setData] = useState(q || offerSearchTemp)

  // useEffect(() => {
  //   if(q)
  //     setData(q)
  // },[q])
  // useEffect(() => {
  //   console.log(' --- round trip date range')
  //   console.log(getRoundDates())
  //   // console.log(data.destinations.map(d =>  (moment(d.date).isValid() ? moment(d.date) : "")))
  // },[data])

  // const roundDates = [moment("2023-6-16"),moment()]
  // const roundDates = useMemo(() => getRoundDates(data.destinations),[data.destinations])

  let date = data.destinations.map(d =>  (moment(d.date).isValid() ? moment(d.date) : null));
  // for(let i=2;i>date.length;i--) {
  //   date.push('')
  // }
  console.log('date to be : ',date)

  function handleChange(dates) {
    if(!Array.isArray(dates))
      return false;
    
    
    let temp = clone(data);
    temp.destinations[0].date = moment(dates[0].$d).format("YYYY-MM-DD");

    if(!dates[1]) dates[1] = "";
    
    temp.destinations[1] = {
      "departureLocation": data.destinations[0].arrivalLocation,
      "arrivalLocation": data.destinations[0].departureLocation,
      "date": moment(dates[1].$d).format("YYYY-MM-DD")
    };

    setData(temp);
  }
  function setLoc(val) {
    let temp = clone(data);
    temp.destinations[0] = {...temp.destinations[0],...val};
    setData(temp);
  } 
  return (
    
    <form onSubmit={(ev) => handleSearch(ev,data)} className='flex justify-stretch gap-2'>
      <Loc returnData={setLoc} />
      <RangePicker placeholder={["Departure Date","Return Date"]} 
        // value={[moment('2023-06-16'),moment('2023-06-30')]}
        onChange={handleChange}
        />
        {/* // value={data.destinations.map(d =>  (moment(d.date).isValid() ? moment(d.date) : ""))} */}
      {/* <Persons returnData={(val) => setData({...data,passengers: val})} /> */}
      <button className='btn2 !rounded-sm '>Search</button>
    </form>

  )
}
