import { path } from "../../config";
import fetchServer from "../fetchServer";
import { store } from "../../redux/store";

export async function signup(data) {
  let result = {return: 0,msg: "Something went wrong creating Customer."}

  let token = store.getState().user.userData.accessToken;

  await fetchServer({method: 'POST',url: path.api+"/users/signup",data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then((res) => {
    console.log('serv => ',res);
    if(res && res.data)
      result = {return: 1,msg: "Successfull",data: res.data}
    else result['msg'] = res.error.message;
  })
  .catch((e) => {
    console.log("Network Error: ",e)
  })

  return result;

}