import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";


export default async function getTourProOffers(params,userId,userType) {
  var result = {return: 0,msg: 'Error',data: {}}

  let token = store.getState().user.userData.accessToken;
  
  let headers = {} 
  if(token)
    headers = {Authorization: `Bearer ${token}`}

  if(userId)
    headers['user-id'] = userId;
  if(userType)
    headers['user-type'] = userType?.toUpperCase();

  
  await fetchServer({method: "GET",url: path.api+`/tourpro`+(params && params !== "" ? `?${params}`:''),
    headers,
  })
  .then((res) => {
    console.log('[c]: ',res)
    if(res) {
      if(res?.data?.success) {
        const {success,data,...cat} = res.data;
        result = {return: 1,msg: "Successfull",data: res.data.data,cat};
      } else if(res?.error) result['msg'] = res?.error?.message;
    } 
  })
  .catch((err) => {
    console.log("Network Error");
    result.msg = "Network Error";
  })

  return result;
}