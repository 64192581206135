import { ArticleOutlined, Cancel, CancelRounded } from '@mui/icons-material';
import { Box, Button, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../components/mini/SearchInput';
import Pagination from '../../components/mini/Pagination';
import getFlightOrders from '../../controllers/flight/getFlightOrders';
import template from '../../features/flights/tableTemplate';
import { Avatar } from "@mui/material";
import DotMenu from '../../components/mini/DotMenu';
import { Link } from 'react-router-dom';
import { CancelPNRModal } from '../../components/flight/CancelPNRModal';
import { setModal, setModalComp } from '../../redux/reducers/modal/modalSlice';
import { useDispatch} from 'react-redux';
import { IssueTicketModal } from '../../components/flight/IssueTicketModal';
import FlightPayment from '../../components/payment/FlightPayment';
import { getPromoCode } from '../../controllers/settings/promo/getPromoCode';
import { formatMoney } from '../../features/utils/formatMoney';
import { paymentStatus } from '../../data/ENUM/PaymentStatus';
import { confirmBankTransaction } from '../../controllers/payment/confirmBankTransaction';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import { def } from '../../config';
import ModalLocal from '../../components/mini/ModalLocal';
import { RequestTicketModal } from '../../components/flight/RequestTicketModal';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  // {
  //   id: 1, customer: 'Yeabsira Abebe',bookingDate: {date: '24th Oct , 2023',time: '8:29AM'},tripDetails: {airline:"Emirates",path:"LOS-DXB"},tripDate: {date: '24th Oct , 2023',time: '8:29AM'},
  //   PNR: 'GB234', amount: 123123,status: 'cancelled'
  // }
];
// [...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))



function PromoCodeFromId({id}) {
  const [val,setVal] = useState('');
  useEffect(() => {
    async function load() {
      const res = await getPromoCode(id);
      if(res.return)
        setVal(res.data.code)
    }
    load()
  },[id])

  return (
    <div>{val}</div>
  )
}

const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'PENDING_PAYMENT'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'PAID_FOR'}]},
  {items:[{id: 3,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 4,field: 'status', operatorValue: 'is', value: 'ticketed'}]},
];

function StatusCol({params,reload,handleVerifyPayment}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(params.row)
  },[anchorEl])

  let method;
  let transId;
  try {
    method = params.row.transactions[0].from
    transId = params.row.transactions[0].id
  } catch(ex) {}


  function cancelPNR() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    dispatch(setModal(true))
    let id = params.id;

    function handleReturn() {
      reload();
      setTimeout(() => dispatch(setModal(false)),2000)
    }
    dispatch(setModalComp(
      <CancelPNRModal id={id} returnData={handleReturn}  />
    ))
  }
  function issueTicket() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    dispatch(setModal(true))
    let id = params.id;

    dispatch(setModalComp(
      <IssueTicketModal supplier={params.row.flightProvider} returnData={() => {reload();dispatch(setModal(false))}} userId={params.row.bookedBy && params.row.bookedBy.type !== 'CUSTOMER' && params.row.bookedBy.id} id={id}  />
    ))

  }
  function requestTicket() {
    setAnchorEl(null);
    dispatch(setModal(true))
    let id = params.id;

    dispatch(setModalComp(
      <RequestTicketModal supplier={params.row.flightProvider} returnData={() => {reload();dispatch(setModal(false))}} userId={params.row.bookedBy && params.row.bookedBy.type !== 'CUSTOMER' && params.row.bookedBy.id} id={id}  />
    ))

  }

  async function pay() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    dispatch(setModal(true))
    dispatch(setModalComp(
      <div className='relative'>
        <CancelRounded className='btn_close z-10' onClick={() => dispatch(setModal(false))} />
        {/* <div className='btn_close z-10' onClick={() => dispatch(setModal(false))}>X</div> */}
        <FlightPayment returnData={() => {reload();dispatch(setModal(false))}} order={params.row} />
      </div>
    ))
  }


  return (
    <div className='flex justify-between w-full relative'>
      <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
        {paymentStatus[params.value]}
      </p>
      
      <DotMenu anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true} >
        <MenuItem><Link to={`/flights/details/${params.id}`}>View Detail</Link></MenuItem>
        {params.value === 'PAID_FOR' ? 
          <MenuItem onClick={requestTicket}>Request Ticket</MenuItem>
          // <MenuItem onClick={issueTicket}>Issue Ticket</MenuItem>
        :null}
        {params.value === 'READY_TO_TICKET' ? 
          <MenuItem onClick={issueTicket}>Issue Ticket</MenuItem>
        :null}
        <MenuItem onClick={cancelPNR}>Cancel PNR</MenuItem>
        {params.value !== 'PAID_FOR' && (method === 'BANK' || method === 'PAY_JEJE') ? 
          <MenuItem onClick={() => { setAnchorEl(null); handleVerifyPayment(transId)}}>Verify bank payment</MenuItem>
        :null}
        {!method ? 
          <MenuItem onClick={pay}>Pay</MenuItem>
        :null}
        {/* <MenuItem><Link to={`/flights/details/${params.id}`}>Add Bags</Link></MenuItem> */}
        {/* <MenuItem><Link to={`/flights/details/${params.id}`}>Add Seats</Link></MenuItem> */}
      </DotMenu>
    </div>
  )
}


export default function Index() {
  const [data,setData] = useState(rows);
  const [loading,setLoading] = useState(true);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  const [type,setType] = useState("");
  const [sup,setSup] = useState("");

  const dispatch = useDispatch();
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData,setFetchedData] = useState(null);

  const [openVerifyPayment,setOpenVerifyPayment] = useState(false);
  const [loadingVerify,setLoadingVerify] = useState(false);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  async function load() {
    const res = await getFlightOrders();
    setLoading(false);
    if(res.return) {
      // console.log("here",res.data);
      let data = res.data.map((row) => template(row))
      setFetchedData(data);
      setData(data);
    }
  }

  async function verifyPayment(transId) {
    setLoadingVerify(true);
    const res = await confirmBankTransaction(transId)
    setLoadingVerify(false);
    if(res.return) {
      load();
      dispatch(setAlertMsg(['success','Payment Confirmed']))
    } else dispatch(setAlertMsg(['warning','Payment still pending']))
    setOpenVerifyPayment(false);
  }


  const columns = [
    {field: 'customer',headerName: 'Customer',flex:1,minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <Link to={`/flights/details/${params.id}`}>
  
            <div className='flex gap-4 items-center'>
              <Avatar>{params.value[0]}</Avatar>
              {params.value}
            </div>
          </Link>
        )
      }
    },
    {field: 'bookingDate',headerName: 'Booking date',minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col'>
            {(new Date(params.value)).toDateString()}
            <small>{(new Date(params.value)).toLocaleTimeString()}</small>
          </div>
        )
      }
    },
    {field: 'tripDetails',headerName: 'Trip details',minWidth: 170,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex flex-col'>
            {params.value.airline}
            <small>{params.value.path.origin} - {params.value.path.destination}</small>
          </div>
        )
      }
    },
    {field: 'departureDate', headerName: 'Flight date',minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col '>
            {params.value.date}
            <small>{params.value.time}</small>
          </div>
        )
      }
    },
    // {field: 'returnDate', headerName: 'Return Date',minWidth: 130,
    //   renderCell: (params) => {
    //     if(!params.value)
    //       return null;
  
    //     return (
    //       <div className='flex flex-col '>
    //         {params.value.date}
    //         <small>{params.value.time}</small>
    //       </div>
    //     )
    //   }
    // },
    {field: 'PNR', headerName: 'PNR',
      valueGetter: (params) => {
        if(!params.value) return null;
        
        return `${params.value.code || ""} - ${params.value.supplier || ""}`
      },
      valueFormatter: (params) => {
        if(!params.value) return null;
        
        let obj = params.value.split(" - ")
        // console.log({code: obj[1],id: obj[0]})
        return {supplier: obj[1],code: obj[0]};
      },
      renderCell: (params) => {
        if(!params.value) return null;
        
        return (
          <Link to={`/flights/details/${params.id}`}>
            <div className='flex flex-col gap-0'>  
              <b className='text-theme1 !font-normal'>{params.formattedValue.code}</b>
              <small className='!font-normal'>{params.formattedValue.supplier}</small>
            </div>
          </Link>
        )
      },
    },
    {field: 'amount',headerName: 'Amount',
      valueGetter: (params) => {
        if(!params.value) return null;
        
        return `${params.value.price || ""} - ${params.value.promo || ""}`
      },
      valueFormatter: (params) => {
        if(!params.value) return null;
        
        let obj = params.value.split(" - ")
        // console.log({code: obj[1],id: obj[0]})
        return {promo: obj[1],price: obj[0]};
      },
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col gap-0'>  
            {def.currency}{formatMoney(params.formattedValue.price)}
            <small><PromoCodeFromId id={params.formattedValue.promo} /></small>
          </div>
        )
      }
    },
    {field: 'status',headerName: 'Status',flex: 1,minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
        return null;
  
        return <StatusCol reload={load} params={params} handleVerifyPayment={setOpenVerifyPayment}/>
      }
    },
  ];
  

  function filterType(val) {
    setType(val);
    filterSupplier('all')
    setData(fetchedData);
    val = val.toLowerCase();
    if(val === "all") return true;
    
    setData(data => data.filter(obj => (
      obj.bookedBy.type.toLowerCase() === val.toLowerCase()
    )))
  }

  function filterSupplier(val) {
    setSup(val);
    val = val.toLowerCase();
    if(val === "all") 
      return setData(fetchedData);
    
    setData(data => data.filter(obj => (
      obj.PNR.supplier.toLowerCase() === val.toLowerCase()
    )))
  }

  function handleSearch(val) {
    setData(fetchedData);

    val = val.toLowerCase();
    try {
      setData(data => data.filter(obj => (
        obj?.customer?.toLowerCase().includes(val) ||
        (new Date(obj?.bookingDate)).toDateString().toLowerCase().includes(val) ||
        obj?.PNR?.supplier.toLowerCase().includes(val) ||
        obj?.PNR?.code.toLowerCase().includes(val) ||
        obj?.tripDetails?.airline?.toLowerCase()?.includes(val) ||
        obj?.tripDetails?.path?.origin?.toLowerCase()?.includes(val) ||
        obj?.tripDetails?.path?.destination?.toLowerCase()?.includes(val) ||
        (obj?.amount?.promo?.toLowerCase().includes(val)) ||
        (parseInt(obj?.amount?.price) <= val) ||
        obj.status.includes(val)
      )))
    } catch(ex) {/*Do nothing*/}
  }

  function generateReport(data,cols) {
    const getSource = (type) => {
      if(type === 'customer')
        return 'BTM Holidays'
      else if(type === 'sub_agent')
        return 'Channel'
      
      return 'TBS'
    }
    const columns = [{field: 'source',headerName: 'Source'},...cols];
    const csvData = data.map((row) => columns.map((col) => {
      let res = '';
      let value = row[col.field] || undefined;

      // console.log(col)
      switch(col.field) {
        case 'source':
          res = getSource(row?.bookedBy?.type?.toLowerCase());
          break;
        case 'bookingDate': 
          res = (new Date(value)).toDateString() + " " + (new Date(value)).toLocaleTimeString();
          break;
        case 'tripDetails':
          res = value.path.origin +" - "+ value.path.destination
          res += " => "+value.airline;
          break;
        case 'departureDate':
          res = `${value.date}`;
          res += value.time
          break;
        case 'PNR':
          res = value.code + ' : ' + value?.supplier;
          break;
        case 'amount':
          res = value?.price;
          break;
        default: return value;
      }

      return res;
      
    }));

    // return console.log('csvData', csvData)

    
    // Prepend the column headers to the CSV data
    csvData.unshift(columns.map(col => col.headerName));

    const csvString = csvData.map(row => row.join(',')).join('\n');


    // Create a Blob object from the CSV data
    const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8'});

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Flight_Booking.csv';

    // Click the download link to initiate the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  return (
    <div className='w-full p-4 bg-[#f3f3f3]'>
    <div className='w-full p-4 bg-secondary'>
      <div className='flex flex-wrap gap-4 items-between'>
        <div className='py-5'>
          <TextField select value={type} onChange={(ev) => filterType(ev.target.value)} label="Select BTM Holidays,Channel or Corporate" size='small' className='min-w-[350px]'>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Customer">BTM Holidays</MenuItem>
            <MenuItem value="Sub_Agent">Channel</MenuItem>
            <MenuItem value="Corporate">TBS</MenuItem>
          </TextField>
        </div>
        <div className='py-5'>
          <TextField select value={sup} onChange={(ev) => filterSupplier(ev.target.value)} label="Select Suppliers" size='small' className='min-w-[350px]'>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="amadeus ng">Amadeus NG</MenuItem>
            <MenuItem value="amadeus dxb">Amadeus DXB</MenuItem>
            <MenuItem value="verteil">Verteil</MenuItem>
          </TextField>
        </div>
      </div>
      <div className='flex gap-4 justify-between items-center my-3'>
        <Tabs value={filter.items[0].id} onChange={(ev,newVal) => setFilter(filterItems[newVal])} 
         variant='scrollable'
         scrollButtons={false}
         className='shadow-md'>
          <Tab label={`All Flights (${data.length})`} sx={{textTransform: 'capitalize'}}/>
          <Tab label="Pending" sx={{textTransform: 'capitalize'}}/>
          <Tab label="Paid" sx={{textTransform: 'capitalize'}}/>
          <Tab label="Cancelled" sx={{textTransform: 'capitalize'}}/>
          <Tab label="Ticketed" sx={{textTransform: 'capitalize'}}/>
        </Tabs>
        <SearchInput className='text-theme1' onChange={handleSearch} />
        <button className='btn2 whitespace-nowrap' onClick={() => generateReport(data,columns)}><ArticleOutlined /> Generate Report</button>
      </div>
      <Box className=''>
        <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "bookingDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
      <ModalLocal open={openVerifyPayment} setOpen={setOpenVerifyPayment}>
        <div className='flex flex-col gap-6 bg-secondary p-10 rounded-md'>
          <h4>Are you sure you want to verify payment?</h4>
          <div className='flex gap-4 '>
            <button onClick={() => setOpenVerifyPayment(false)}>Cancel</button>
            <button onClick={() => verifyPayment(openVerifyPayment)} className='flex-1 btn2 flex gap-2 items-center'>
              {loadingVerify?<div className='load'></div>:null}
              Confirm</button>
          </div>
        </div>
      </ModalLocal>
      </div>
    </div>
  )
}
