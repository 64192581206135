import { useEffect, useState } from "react";
import Collapse from "../../mini/Collapse";

export default function FilterSuplier({returnData,clear,orgi}) {
  // console.log(' --------- ',suppliers)
  const [suplier,setSuplier] = useState([
  // {
  //   "name": "Amadeus NG",
  //   "id": "AmadeusNG",
  //   "value": false
  // },
  // {
  //   "name": "Amadeus DXB",
  //   "id": "AmadeusDXB",
  //   "value": false
  // },
  // {
  //   "name": "Travelport",
  //   "id": "travelPortOriginal",
  //   "value": false
  // },
  // {
  //   "name": 'Verteil',
  //   "id": 'verteilOriginal',
  //   "value": false
  // }
])

useEffect(() => {
  setSuplier(suplier => suplier?.map(obj => ({...obj,value:false})))
},[clear])

useEffect(() => {
  let suppliers = [...new Set(orgi?.map(obj => obj.supplier))]?.map(supplier => ({name: supplier?.replace('_',' '),id: supplier,value: false}))
  setSuplier(suppliers)
},[orgi])

console.log(suplier,orgi)



function handleCheck(val,i) {
  let temp = [...suplier];
  temp[i].value = val;
  setSuplier(temp);

  returnData(suplier)
}
return (
  <Collapse show label={<h5>Suplier</h5>}>
  {suplier.map((obj,i) => (
    <label key={i} className='flex gap-4 justify-between'>
      <span className='flex gap-2'>
        <input name='amadeuSuplier' checked={obj.value} onChange={(ev) => handleCheck(ev.target.checked,i)} type='checkbox' />
        <span className='capitalize'>{obj.name?.toLowerCase()}</span>
      </span>
    </label>
  ))}
</Collapse>
)
}
