export const importPNRTemp = {
  "id": "",
  "pnrCode": "",
  "pnrData": {
      "activePNRimage": {
          "amadeusId": {
              "reservation": {
                  "controlNumber": ""
              }
          },
          "pnrHeader": {
              "creditLine": {
                  "officeData": {
                      "officeId": "",
                      "agentCode": ""
                  }
              },
              "sbrPosDetails": {
                  "sbrPreferences": {
                      "userPreferences": {
                          "codedCountry": ""
                      }
                  },
                  "sbrSystemDetails": {
                      "deliveringSystem": {
                          "companyId": "",
                          "locationId": ""
                      }
                  },
                  "sbrUserIdentification": {
                      "originatorTypeCode": "",
                      "originIdentification": {
                          "originatorId": "",
                          "inHouseIdentification1": ""
                      }
                  }
              },
              "technicalData": {
                  "number": "",
                  "pnrPurgeDate": ""
              },
              "relatedProduct": {
                  "quantity": ""
              },
              "reservationInfo": {
                  "reservation": {
                      "date": "",
                      "time": "",
                      "companyId": "",
                      "controlType": "",
                      "controlNumber": ""
                  }
              },
              "securityInformation": {
                  "queueingInformation": {
                      "queueingOfficeId": ""
                  },
                  "secondRpInformation": {
                      "creationDate": "",
                      "creationTime": "",
                      "agentSignature": "",
                      "creatorIataCode": "",
                      "creationOfficeId": ""
                  },
                  "responsibilityInformation": {
                      "agentId": "",
                      "iataCode": "",
                      "officeId": "",
                      "typeOfPnrElement": ""
                  }
              },
              "sbrUpdatorPosDetails": {
                  "sbrPreferences": {
                      "userPreferences": {
                          "codedCountry": ""
                      }
                  },
                  "sbrSystemDetails": {
                      "deliveringSystem": {
                          "companyId": "",
                          "locationId": ""
                      }
                  },
                  "sbrUserIdentification": {
                      "originatorTypeCode": "",
                      "originIdentification": {
                          "inHouseIdentification1": ""
                      }
                  }
              },
              "sbrCreationPosDetails": {
                  "sbrPreferences": {
                      "userPreferences": {
                          "codedCountry": ""
                      }
                  },
                  "sbrSystemDetails": {
                      "deliveringSystem": {
                          "companyId": "",
                          "locationId": ""
                      }
                  },
                  "sbrUserIdentification": {
                      "originatorTypeCode": "",
                      "originIdentification": {
                          "originatorId": "",
                          "inHouseIdentification1": ""
                      }
                  }
              }
          },
          "travellerInfo": [
              {
                  "passengerData": [
                      {
                          "dateOfBirth": {
                              "dateAndTimeDetails": {
                                  "date": ""
                              }
                          },
                          "travellerInformation": {
                              "passenger": {
                                  "type": "",
                                  "firstName": "",
                                  "infantIndicator": ""
                              },
                              "traveller": {
                                  "surname": "",
                                  "quantity": ""
                              }
                          }
                      },
                      {
                          "dateOfBirth": {
                              "dateAndTimeDetails": {
                                  "date": ""
                              }
                          },
                          "travellerInformation": {
                              "passenger": {
                                  "type": "",
                                  "firstName": ""
                              },
                              "traveller": {
                                  "surname": "",
                                  "quantity": ""
                              }
                          }
                      }
                  ],
                  "elementManagementPassenger": {
                      "lineNumber": "",
                      "segmentName": "",
                      "elementReference": {
                          "number": "",
                          "qualifier": ""
                      }
                  }
              },
              {
                  "passengerData": {
                      "dateOfBirth": {
                          "dateAndTimeDetails": {
                              "date": ""
                          }
                      },
                      "travellerInformation": {
                          "passenger": {
                              "type": "",
                              "firstName": ""
                          },
                          "traveller": {
                              "surname": "",
                              "quantity": ""
                          }
                      }
                  },
                  "elementManagementPassenger": {
                      "lineNumber": "",
                      "segmentName": "",
                      "elementReference": {
                          "number": "",
                          "qualifier": ""
                      }
                  }
              }
          ],
          "dataElementsMaster": {
              "marker2": [],
              "marker3": [],
              "dataElementsIndiv": [
                  {
                      "otherDataFreetext": {
                          "longFreetext": "",
                          "freetextDetail": {
                              "type": "",
                              "subjectQualifier": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "",
                          "freetextDetail": {
                              "type": "",
                              "subjectQualifier": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "",
                          "freetextDetail": {
                              "type": "",
                              "subjectQualifier": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "",
                                  "qualifier": ""
                              },
                              {
                                  "number": "",
                                  "qualifier": ""
                              }
                          ]
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "",
                          "freetextDetail": {
                              "type": "",
                              "subjectQualifier": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "",
                                  "qualifier": ""
                              },
                              {
                                  "number": "",
                                  "qualifier": ""
                              }
                          ]
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "",
                          "freetextDetail": {
                              "type": "",
                              "subjectQualifier": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "",
                                  "qualifier": ""
                              },
                              {
                                  "number": "",
                                  "qualifier": ""
                              }
                          ]
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "",
                          "freetextDetail": {
                              "type": "",
                              "subjectQualifier": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "",
                          "freetextDetail": {
                              "type": "",
                              "subjectQualifier": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "",
                          "freetextDetail": {
                              "type": "",
                              "subjectQualifier": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "",
                                  "qualifier": ""
                              },
                              {
                                  "number": "",
                                  "qualifier": ""
                              }
                          ]
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "",
                          "freetextDetail": {
                              "type": "",
                              "subjectQualifier": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "",
                                  "qualifier": ""
                              },
                              {
                                  "number": "",
                                  "qualifier": ""
                              }
                          ]
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "",
                          "freetextDetail": {
                              "type": "",
                              "subjectQualifier": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "",
                                  "qualifier": ""
                              },
                              {
                                  "number": "",
                                  "qualifier": ""
                              }
                          ]
                      }
                  },
                  {
                      "miscellaneousRemarks": {
                          "remarks": {
                              "type": "",
                              "category": "",
                              "freetext": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "miscellaneousRemarks": {
                          "remarks": {
                              "type": "",
                              "freetext": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "miscellaneousRemarks": {
                          "remarks": {
                              "type": "",
                              "freetext": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "miscellaneousRemarks": {
                          "remarks": {
                              "type": "",
                              "freetext": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "pnrSecurity": {
                          "security": {
                              "accessMode": "",
                              "identification": ""
                          },
                          "indicator": "",
                          "securityInfo": {
                              "officeId": "",
                              "agentCode": "",
                              "creationDate": ""
                          }
                      },
                      "elementManagementData": {
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "serviceRequest": {
                          "ssr": {
                              "type": "",
                              "status": "",
                              "freeText": "",
                              "quantity": "",
                              "companyId": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "",
                                  "qualifier": ""
                              },
                              {
                                  "number": "",
                                  "qualifier": ""
                              }
                          ]
                      }
                  },
                  {
                      "serviceRequest": {
                          "ssr": {
                              "type": "",
                              "status": "",
                              "freeText": "",
                              "quantity": "",
                              "companyId": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "",
                                  "qualifier": ""
                              },
                              {
                                  "number": "",
                                  "qualifier": ""
                              }
                          ]
                      }
                  },
                  {
                      "serviceRequest": {
                          "ssr": {
                              "type": "",
                              "status": "",
                              "freeText": "",
                              "quantity": "",
                              "companyId": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "",
                                  "qualifier": ""
                              },
                              {
                                  "number": "",
                                  "qualifier": ""
                              }
                          ]
                      }
                  },
                  {
                      "serviceRequest": {
                          "ssr": {
                              "type": "",
                              "freeText": [
                                  "PLS ADV TKT NBR BY 17JUL23/1015Z OR LH OPTG/MKTG FLTS WILL BE CANX / A",
                                  "PPLIC FARE RULE APPLIES IF IT DEMANDS"
                              ],
                              "companyId": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "serviceRequest": {
                          "ssr": {
                              "type": "",
                              "freeText": "",
                              "companyId": ""
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "ticketElement": {
                          "ticket": {
                              "date": "",
                              "officeId": "",
                              "indicator": "",
                              "queueNumber": "",
                              "queueCategory": ""
                          },
                          "passengerType": ""
                      },
                      "elementManagementData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  }
              ],
              "dataElementsStruct": [
                  {
                      "fopGroup": {
                          "fopFreeflow": {
                              "freeText": "",
                              "freeTextDetails": {
                                  "source": "",
                                  "encoding": "",
                                  "textSubjectQualifier": ""
                              }
                          },
                          "fopPNRDetails": {
                              "fopDetails": {
                                  "fopCode": "",
                                  "fopStatus": ""
                              }
                          },
                          "fopSequenceNumber": {
                              "sequenceDetails": {
                                  "number": ""
                              }
                          }
                      },
                      "elementManagementStructData": {
                          "lineNumber": "",
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "idElements": {
                          "idsValue": {
                              "idSection": {
                                  "primeId": "",
                                  "referenceQualifier": ""
                              }
                          }
                      },
                      "elementManagementStructData": {
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      },
                      "referenceForStructDataElement": {
                          "reference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  },
                  {
                      "idElements": {
                          "idsValue": {
                              "idSection": {
                                  "primeId": "",
                                  "referenceQualifier": ""
                              }
                          }
                      },
                      "elementManagementStructData": {
                          "segmentName": "",
                          "elementReference": {
                              "number": "",
                              "qualifier": ""
                          }
                      },
                      "referenceForStructDataElement": {
                          "reference": {
                              "number": "",
                              "qualifier": ""
                          }
                      }
                  }
              ]
          },
          "pricingRecordGroup": {
              "listOfTSTs": [
                  {
                      "taxInfo": [
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": ""
                                  },
                                  "taxNature": "",
                                  "taxQualifier": "",
                                  "taxIdentification": {
                                      "taxIdentifier": ""
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "",
                                      "fareCurrency": "",
                                      "fareDataQualifier": ""
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": ""
                                  },
                                  "taxNature": "",
                                  "taxQualifier": "",
                                  "taxIdentification": {
                                      "taxIdentifier": ""
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "",
                                      "fareCurrency": "",
                                      "fareDataQualifier": ""
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": ""
                                  },
                                  "taxNature": "",
                                  "taxQualifier": "",
                                  "taxIdentification": {
                                      "taxIdentifier": ""
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "",
                                      "fareCurrency": "",
                                      "fareDataQualifier": ""
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": ""
                                  },
                                  "taxNature": "",
                                  "taxQualifier": "",
                                  "taxIdentification": {
                                      "taxIdentifier": ""
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "",
                                      "fareCurrency": "",
                                      "fareDataQualifier": ""
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": ""
                                  },
                                  "taxNature": "",
                                  "taxQualifier": "",
                                  "taxIdentification": {
                                      "taxIdentifier": ""
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "",
                                      "fareCurrency": "",
                                      "fareDataQualifier": ""
                                  }
                              }
                          }
                      ],
                      "tktDate": [
                          {
                              "dateTime": {
                                  "day": "",
                                  "year": "",
                                  "month": ""
                              },
                              "businessSemantic": ""
                          },
                          {
                              "dateTime": {
                                  "day": "",
                                  "year": "",
                                  "month": ""
                              },
                              "businessSemantic": ""
                          }
                      ],
                      "bankerRates": {
                          "firstRateDetail": {
                              "amount": ""
                          }
                      },
                      "tstRecordId": {
                          "uniqueReference": ""
                      },
                      "paxReference": {
                          "referenceDetails": {
                              "type": "",
                              "value": ""
                          }
                      },
                      "securityInfo": {
                          "officeDetails": {
                              "originIdentification": {
                                  "agentsignin": "",
                                  "confidentialOffice": ""
                              }
                          }
                      },
                      "itineraryInfo": {
                          "fareQualifier": {
                              "fareBasisDetails": {
                                  "primaryCode": "",
                                  "fareBasisCode": "",
                                  "discTktDesignator": ""
                              }
                          },
                          "segmentReference": {
                              "refDetails": {
                                  "refNumber": "",
                                  "refQualifier": ""
                              }
                          },
                          "connexInformation": {
                              "connecDetails": {
                                  "connexType": ""
                              }
                          },
                          "validityInformation": [
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              },
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              }
                          ],
                          "bagAllowanceInformation": {
                              "bagAllowanceDetails": {
                                  "baggageType": "",
                                  "baggageQuantity": ""
                              }
                          }
                      },
                      "generalTSTinfo": {
                          "fcmi": "",
                          "tstNumber": "",
                          "tstInformation": {
                              "tstIndicator": ""
                          }
                      },
                      "otherPricingInfo": {
                          "attributeDetails": [
                              {
                                  "attributeType": "",
                                  "attributeDescription": ""
                              },
                              {
                                  "attributeType": "",
                                  "attributeDescription": ""
                              }
                          ]
                      },
                      "statusInformation": {
                          "firstStatusDetails": {
                              "tstFlag": ""
                          },
                          "otherStatusDetails": {
                              "tstFlag": ""
                          }
                      },
                      "fareDataInformation": {
                          "fareDataSupInformation": [
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              },
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              },
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              },
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              }
                          ],
                          "fareDataMainInformation": {
                              "fareDataQualifier": ""
                          }
                      },
                      "fareOriginDestination": {
                          "origin": "",
                          "destination": ""
                      }
                  },
                  {
                      "taxInfo": [
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": ""
                                  },
                                  "taxNature": "",
                                  "taxQualifier": "",
                                  "taxIdentification": {
                                      "taxIdentifier": ""
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "",
                                      "fareCurrency": "",
                                      "fareDataQualifier": ""
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": ""
                                  },
                                  "taxNature": "",
                                  "taxQualifier": "",
                                  "taxIdentification": {
                                      "taxIdentifier": ""
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "",
                                      "fareCurrency": "",
                                      "fareDataQualifier": ""
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": ""
                                  },
                                  "taxNature": "",
                                  "taxQualifier": "",
                                  "taxIdentification": {
                                      "taxIdentifier": ""
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "",
                                      "fareCurrency": "",
                                      "fareDataQualifier": ""
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": ""
                                  },
                                  "taxNature": "",
                                  "taxQualifier": "",
                                  "taxIdentification": {
                                      "taxIdentifier": ""
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "",
                                      "fareCurrency": "",
                                      "fareDataQualifier": ""
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": ""
                                  },
                                  "taxNature": "",
                                  "taxQualifier": "",
                                  "taxIdentification": {
                                      "taxIdentifier": ""
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "",
                                      "fareCurrency": "",
                                      "fareDataQualifier": ""
                                  }
                              }
                          }
                      ],
                      "tktDate": [
                          {
                              "dateTime": {
                                  "day": "",
                                  "year": "",
                                  "month": ""
                              },
                              "businessSemantic": ""
                          },
                          {
                              "dateTime": {
                                  "day": "",
                                  "year": "",
                                  "month": ""
                              },
                              "businessSemantic": ""
                          }
                      ],
                      "bankerRates": {
                          "firstRateDetail": {
                              "amount": ""
                          }
                      },
                      "tstRecordId": {
                          "uniqueReference": ""
                      },
                      "paxReference": {
                          "referenceDetails": {
                              "type": "",
                              "value": ""
                          }
                      },
                      "securityInfo": {
                          "officeDetails": {
                              "originIdentification": {
                                  "agentsignin": "",
                                  "confidentialOffice": ""
                              }
                          }
                      },
                      "itineraryInfo": {
                          "fareQualifier": {
                              "fareBasisDetails": {
                                  "primaryCode": "",
                                  "fareBasisCode": "",
                                  "ticketDesignator": "",
                                  "discTktDesignator": ""
                              }
                          },
                          "segmentReference": {
                              "refDetails": {
                                  "refNumber": "",
                                  "refQualifier": ""
                              }
                          },
                          "connexInformation": {
                              "connecDetails": {
                                  "connexType": ""
                              }
                          },
                          "validityInformation": [
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              },
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              }
                          ],
                          "bagAllowanceInformation": {
                              "bagAllowanceDetails": {
                                  "baggageType": "",
                                  "baggageQuantity": ""
                              }
                          }
                      },
                      "generalTSTinfo": {
                          "fcmi": "",
                          "tstNumber": "",
                          "tstInformation": {
                              "tstIndicator": ""
                          }
                      },
                      "otherPricingInfo": {
                          "attributeDetails": [
                              {
                                  "attributeType": "",
                                  "attributeDescription": ""
                              },
                              {
                                  "attributeType": "",
                                  "attributeDescription": ""
                              }
                          ]
                      },
                      "statusInformation": {
                          "firstStatusDetails": {
                              "tstFlag": ""
                          },
                          "otherStatusDetails": {
                              "tstFlag": ""
                          }
                      },
                      "fareDataInformation": {
                          "fareDataSupInformation": [
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              },
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              },
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              },
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              }
                          ],
                          "fareDataMainInformation": {
                              "fareDataQualifier": ""
                          }
                      },
                      "fareOriginDestination": {
                          "origin": "",
                          "destination": ""
                      }
                  },
                  {
                      "taxInfo": {
                          "taxDetails": {
                              "taxType": {
                                  "isoCountry": ""
                              },
                              "taxNature": "",
                              "taxQualifier": "",
                              "taxIdentification": {
                                  "taxIdentifier": ""
                              }
                          },
                          "amountDetails": {
                              "fareDataMainInformation": {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              }
                          }
                      },
                      "tktDate": [
                          {
                              "dateTime": {
                                  "day": "",
                                  "year": "",
                                  "month": ""
                              },
                              "businessSemantic": ""
                          },
                          {
                              "dateTime": {
                                  "day": "",
                                  "year": "",
                                  "month": ""
                              },
                              "businessSemantic": ""
                          }
                      ],
                      "bankerRates": {
                          "firstRateDetail": {
                              "amount": ""
                          }
                      },
                      "tstRecordId": {
                          "uniqueReference": ""
                      },
                      "paxReference": {
                          "referenceDetails": {
                              "type": "",
                              "value": ""
                          }
                      },
                      "securityInfo": {
                          "officeDetails": {
                              "originIdentification": {
                                  "agentsignin": "",
                                  "confidentialOffice": ""
                              }
                          }
                      },
                      "itineraryInfo": {
                          "fareQualifier": {
                              "fareBasisDetails": {
                                  "primaryCode": "",
                                  "fareBasisCode": "",
                                  "ticketDesignator": "",
                                  "discTktDesignator": ""
                              }
                          },
                          "segmentReference": {
                              "refDetails": {
                                  "refNumber": "",
                                  "refQualifier": ""
                              }
                          },
                          "connexInformation": {
                              "connecDetails": {
                                  "connexType": ""
                              }
                          },
                          "validityInformation": [
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              },
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              }
                          ],
                          "bagAllowanceInformation": {
                              "bagAllowanceDetails": {
                                  "baggageType": "",
                                  "baggageQuantity": ""
                              }
                          }
                      },
                      "generalTSTinfo": {
                          "fcmi": "",
                          "tstNumber": "",
                          "tstInformation": {
                              "tstIndicator": ""
                          }
                      },
                      "otherPricingInfo": {
                          "attributeDetails": [
                              {
                                  "attributeType": "",
                                  "attributeDescription": ""
                              },
                              {
                                  "attributeType": "",
                                  "attributeDescription": ""
                              }
                          ]
                      },
                      "statusInformation": {
                          "firstStatusDetails": {
                              "tstFlag": ""
                          },
                          "otherStatusDetails": {
                              "tstFlag": ""
                          }
                      },
                      "fareDataInformation": {
                          "fareDataSupInformation": [
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              },
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              },
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              },
                              {
                                  "fareAmount": "",
                                  "fareCurrency": "",
                                  "fareDataQualifier": ""
                              }
                          ],
                          "fareDataMainInformation": {
                              "fareDataQualifier": ""
                          }
                      },
                      "fareOriginDestination": {
                          "origin": "",
                          "destination": ""
                      }
                  }
              ],
              "pricingRecordData": [],
              "productPricingQuotationRecord": [
                  {
                      "pricingRecordId": {
                          "referenceType": "",
                          "uniqueReference": ""
                      },
                      "ptcDiscountCode": {
                          "discountDetails": {
                              "discountCode": "",
                              "unitQuantity": ""
                          }
                      },
                      "passengerTattoos": {
                          "passengerReference": {
                              "type": "",
                              "value": ""
                          }
                      },
                      "documentDetailsGroup": {
                          "flags": {
                              "statusDetails": {
                                  "action": "",
                                  "indicator": ""
                              }
                          },
                          "totalFare": {
                              "monetaryDetails": {
                                  "amount": "",
                                  "currency": "",
                                  "typeQualifier": ""
                              }
                          },
                          "otherDates": [
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              },
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              }
                          ],
                          "otherFares": [
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "currency": "",
                                      "typeQualifier": ""
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "typeQualifier": ""
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "currency": "",
                                      "typeQualifier": ""
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "currency": "",
                                      "typeQualifier": ""
                                  }
                              }
                          ],
                          "creationDate": {
                              "dateTime": {
                                  "day": "",
                                  "year": "",
                                  "month": ""
                              },
                              "businessSemantic": ""
                          },
                          "taxInformation": [
                              {
                                  "taxDetails": {
                                      "rate": "",
                                      "type": "",
                                      "countryCode": "",
                                      "currencyCode": ""
                                  },
                                  "taxCategory": ""
                              },
                              {
                                  "taxDetails": {
                                      "rate": "",
                                      "type": "",
                                      "countryCode": "",
                                      "currencyCode": ""
                                  },
                                  "taxCategory": ""
                              },
                              {
                                  "taxDetails": {
                                      "rate": "",
                                      "type": "",
                                      "countryCode": "",
                                      "currencyCode": ""
                                  },
                                  "taxCategory": ""
                              },
                              {
                                  "taxDetails": {
                                      "rate": "",
                                      "type": "",
                                      "countryCode": "",
                                      "currencyCode": ""
                                  },
                                  "taxCategory": ""
                              },
                              {
                                  "taxDetails": {
                                      "rate": "",
                                      "type": "",
                                      "countryCode": "",
                                      "currencyCode": ""
                                  },
                                  "taxCategory": ""
                              }
                          ],
                          "fareCalcRemarks": {
                              "freeText": "",
                              "freeTextDetails": {
                                  "source": "",
                                  "encoding": "",
                                  "textSubjectQualifier": ""
                              }
                          },
                          "issueIdentifier": {
                              "priceTariffType": "",
                              "priceTicketDetails": {
                                  "indicators": ""
                              }
                          },
                          "manualIndicator": {
                              "statusDetails": {
                                  "action": "",
                                  "indicator": ""
                              }
                          },
                          "generalIndicators": [
                              {
                                  "attributeDetails": {
                                      "attributeType": "",
                                      "attributeDescription": ""
                                  }
                              },
                              {
                                  "attributeDetails": {
                                      "attributeType": "",
                                      "attributeDescription": ""
                                  }
                              }
                          ],
                          "officeInformation": {
                              "originIdentification": {
                                  "inHouseIdentification1": "",
                                  "inHouseIdentification2": ""
                              }
                          },
                          "originDestination": {
                              "origin": "",
                              "destination": ""
                          },
                          "couponDetailsGroup": {
                              "productId": {
                                  "referenceDetails": {
                                      "type": "",
                                      "value": ""
                                  }
                              },
                              "fareQualifier": {
                                  "fareBasisDetails": {
                                      "rateTariffIndicator": "",
                                      "otherRateTariffClass": ""
                                  }
                              },
                              "validityDates": [
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "",
                                          "qualifier": ""
                                      }
                                  },
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "",
                                          "qualifier": ""
                                      }
                                  }
                              ],
                              "baggageInformation": {
                                  "baggageDetails": {
                                      "quantityCode": "",
                                      "freeAllowance": "",
                                      "unitQualifier": ""
                                  }
                              }
                          }
                      }
                  },
                  {
                      "pricingRecordId": {
                          "referenceType": "",
                          "uniqueReference": ""
                      },
                      "ptcDiscountCode": {
                          "discountDetails": {
                              "discountCode": "",
                              "unitQuantity": ""
                          }
                      },
                      "passengerTattoos": {
                          "passengerReference": {
                              "type": "",
                              "value": ""
                          }
                      },
                      "documentDetailsGroup": {
                          "flags": {
                              "statusDetails": {
                                  "action": "",
                                  "indicator": ""
                              }
                          },
                          "totalFare": {
                              "monetaryDetails": {
                                  "amount": "",
                                  "currency": "",
                                  "typeQualifier": ""
                              }
                          },
                          "otherDates": [
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              },
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              }
                          ],
                          "otherFares": [
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "currency": "",
                                      "typeQualifier": ""
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "typeQualifier": ""
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "currency": "",
                                      "typeQualifier": ""
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "currency": "",
                                      "typeQualifier": ""
                                  }
                              }
                          ],
                          "creationDate": {
                              "dateTime": {
                                  "day": "",
                                  "year": "",
                                  "month": ""
                              },
                              "businessSemantic": ""
                          },
                          "taxInformation": [
                              {
                                  "taxDetails": {
                                      "rate": "",
                                      "type": "",
                                      "countryCode": "",
                                      "currencyCode": ""
                                  },
                                  "taxCategory": ""
                              },
                              {
                                  "taxDetails": {
                                      "rate": "",
                                      "type": "",
                                      "countryCode": "",
                                      "currencyCode": ""
                                  },
                                  "taxCategory": ""
                              },
                              {
                                  "taxDetails": {
                                      "rate": "",
                                      "type": "",
                                      "countryCode": "",
                                      "currencyCode": ""
                                  },
                                  "taxCategory": ""
                              },
                              {
                                  "taxDetails": {
                                      "rate": "",
                                      "type": "",
                                      "countryCode": "",
                                      "currencyCode": ""
                                  },
                                  "taxCategory": ""
                              },
                              {
                                  "taxDetails": {
                                      "rate": "",
                                      "type": "",
                                      "countryCode": "",
                                      "currencyCode": ""
                                  },
                                  "taxCategory": ""
                              }
                          ],
                          "fareCalcRemarks": {
                              "freeText": "",
                              "freeTextDetails": {
                                  "source": "",
                                  "encoding": "",
                                  "textSubjectQualifier": ""
                              }
                          },
                          "issueIdentifier": {
                              "priceTariffType": "",
                              "priceTicketDetails": {
                                  "indicators": ""
                              }
                          },
                          "manualIndicator": {
                              "statusDetails": {
                                  "action": "",
                                  "indicator": ""
                              }
                          },
                          "generalIndicators": [
                              {
                                  "attributeDetails": {
                                      "attributeType": "",
                                      "attributeDescription": ""
                                  }
                              },
                              {
                                  "attributeDetails": {
                                      "attributeType": "",
                                      "attributeDescription": ""
                                  }
                              }
                          ],
                          "officeInformation": {
                              "originIdentification": {
                                  "inHouseIdentification1": "",
                                  "inHouseIdentification2": ""
                              }
                          },
                          "originDestination": {
                              "origin": "",
                              "destination": ""
                          },
                          "couponDetailsGroup": {
                              "productId": {
                                  "referenceDetails": {
                                      "type": "",
                                      "value": ""
                                  }
                              },
                              "fareQualifier": {
                                  "fareBasisDetails": {
                                      "rateTariffClass": "",
                                      "rateTariffIndicator": "",
                                      "otherRateTariffClass": ""
                                  }
                              },
                              "validityDates": [
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "",
                                          "qualifier": ""
                                      }
                                  },
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "",
                                          "qualifier": ""
                                      }
                                  }
                              ],
                              "baggageInformation": {
                                  "baggageDetails": {
                                      "quantityCode": "",
                                      "freeAllowance": "",
                                      "unitQualifier": ""
                                  }
                              }
                          }
                      }
                  },
                  {
                      "pricingRecordId": {
                          "referenceType": "",
                          "uniqueReference": ""
                      },
                      "ptcDiscountCode": {
                          "discountDetails": {
                              "discountCode": "",
                              "unitQuantity": ""
                          }
                      },
                      "passengerTattoos": {
                          "passengerReference": {
                              "type": "",
                              "value": ""
                          }
                      },
                      "documentDetailsGroup": {
                          "flags": {
                              "statusDetails": {
                                  "action": "",
                                  "indicator": ""
                              }
                          },
                          "totalFare": {
                              "monetaryDetails": {
                                  "amount": "",
                                  "currency": "",
                                  "typeQualifier": ""
                              }
                          },
                          "otherDates": [
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              },
                              {
                                  "dateTime": {
                                      "day": "",
                                      "year": "",
                                      "month": ""
                                  },
                                  "businessSemantic": ""
                              }
                          ],
                          "otherFares": [
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "currency": "",
                                      "typeQualifier": ""
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "typeQualifier": ""
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "currency": "",
                                      "typeQualifier": ""
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "",
                                      "currency": "",
                                      "typeQualifier": ""
                                  }
                              }
                          ],
                          "creationDate": {
                              "dateTime": {
                                  "day": "",
                                  "year": "",
                                  "month": ""
                              },
                              "businessSemantic": ""
                          },
                          "taxInformation": {
                              "taxDetails": {
                                  "rate": "",
                                  "type": "",
                                  "countryCode": "",
                                  "currencyCode": ""
                              },
                              "taxCategory": ""
                          },
                          "fareCalcRemarks": {
                              "freeText": "",
                              "freeTextDetails": {
                                  "source": "",
                                  "encoding": "",
                                  "textSubjectQualifier": ""
                              }
                          },
                          "issueIdentifier": {
                              "priceTariffType": "",
                              "priceTicketDetails": {
                                  "indicators": ""
                              }
                          },
                          "manualIndicator": {
                              "statusDetails": {
                                  "action": "",
                                  "indicator": ""
                              }
                          },
                          "generalIndicators": [
                              {
                                  "attributeDetails": {
                                      "attributeType": "",
                                      "attributeDescription": ""
                                  }
                              },
                              {
                                  "attributeDetails": {
                                      "attributeType": "",
                                      "attributeDescription": ""
                                  }
                              }
                          ],
                          "officeInformation": {
                              "originIdentification": {
                                  "inHouseIdentification1": "",
                                  "inHouseIdentification2": ""
                              }
                          },
                          "originDestination": {
                              "origin": "",
                              "destination": ""
                          },
                          "couponDetailsGroup": {
                              "productId": {
                                  "referenceDetails": {
                                      "type": "",
                                      "value": ""
                                  }
                              },
                              "fareQualifier": {
                                  "fareBasisDetails": {
                                      "rateTariffClass": "",
                                      "rateTariffIndicator": "",
                                      "otherRateTariffClass": ""
                                  }
                              },
                              "validityDates": [
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "",
                                          "qualifier": ""
                                      }
                                  },
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "",
                                          "qualifier": ""
                                      }
                                  }
                              ],
                              "baggageInformation": {
                                  "baggageDetails": {
                                      "quantityCode": "",
                                      "freeAllowance": "",
                                      "unitQualifier": ""
                                  }
                              }
                          }
                      }
                  }
              ]
          },
          "originDestinationDetails": {
              "itineraryInfo": {
                  "flightDetail": {
                      "productDetails": {
                          "weekDay": "",
                          "equipment": "",
                          "numOfStops": ""
                      },
                      "arrivalInformation": {
                          "arrivalTerminal": ""
                      },
                      "departureInformation": {
                          "departTerminal": ""
                      }
                  },
                  "inventoryData": {
                      "cabinInformation": {
                          "cabinCode": ""
                      },
                      "subClassInformation": {
                          "subclassId": ""
                      },
                      "subClassClassification": {
                          "sourceType": {
                              "sourceQualifier1": ""
                          },
                          "systemCode": "",
                          "countryCode": ""
                      }
                  },
                  "messageAction": {
                      "business": {
                          "function": ""
                      }
                  },
                  "travelProduct": {
                      "product": {
                          "arrDate": "",
                          "arrTime": "",
                          "depDate": "",
                          "depTime": "",
                          "dayChangeIndicator": ""
                      },
                      "typeDetail": {
                          "detail": [
                              "ET",
                              "I"
                          ]
                      },
                      "companyDetail": {
                          "identification": ""
                      },
                      "offpointDetail": {
                          "cityCode": ""
                      },
                      "productDetails": {
                          "classOfService": "",
                          "identification": ""
                      },
                      "boardpointDetail": {
                          "cityCode": ""
                      }
                  },
                  "relatedProduct": {
                      "status": "",
                      "quantity": ""
                  },
                  "yieldDataGroup": {
                      "tripOnD": {
                          "origin": "",
                          "destination": ""
                      },
                      "ondyield": {
                          "origin": "",
                          "destination": ""
                      },
                      "yieldInformations": {
                          "monetaryDetails": {
                              "amount": "",
                              "typeQualifier": ""
                          },
                          "otherMonetaryDetails": [
                              {
                                  "amount": "",
                                  "typeQualifier": ""
                              },
                              {
                                  "amount": "",
                                  "typeQualifier": ""
                              }
                          ]
                      }
                  },
                  "selectionDetails": {
                      "selection": {
                          "option": ""
                      }
                  },
                  "originalStatusCode": {
                      "status": [
                          "NN",
                          "NN"
                      ]
                  },
                  "pointOfSellDetails": {
                      "preferences": {
                          "userPreferences": {
                              "codedCountry": ""
                          }
                      },
                      "systemDetails": {
                          "deliveringSystem": {
                              "companyId": "",
                              "locationId": ""
                          }
                      },
                      "userIdentification": {
                          "originatorTypeCode": "",
                          "originIdentification": {
                              "originatorId": "",
                              "inHouseIdentification1": ""
                          }
                      }
                  },
                  "bookingCreationDate": {
                      "dateAndTimeDetails": {
                          "date": "",
                          "time": "",
                          "qualifier": ""
                      }
                  },
                  "itineraryReservationInfo": {
                      "reservation": {
                          "companyId": "",
                          "controlNumber": ""
                      }
                  },
                  "elementManagementItinerary": {
                      "lineNumber": "",
                      "segmentName": "",
                      "elementReference": {
                          "number": "",
                          "qualifier": ""
                      }
                  }
              },
              "originDestination": []
          }
      }
  },
  "ticketId": null,
  "createdDate": "",
  "updatedDate": "",
  "importedByUserId": "",
  "ticketedByUserId": null,
  "importedBy": {
      "id": "",
      "username": "",
      "firstName": "",
      "email": "",
      "lastName": "",
      "password": "",
      "otp": "",
      "profile": "",
      "isAdmin": true,
      "isSuperAdmin": true,
      "isActive": true,
      "phoneNumber": "",
      "type": "",
      "customSettingid": null,
      "gender": "",
      "companyName": "",
      "addressLine1": "",
      "addressLine2": "",
      "postalCode": "",
      "cityName": "",
      "countryCode": "",
      "createdDate": "",
      "updatedDate": "",
      "roles": [
          "AMADEUS_BOOK",
          "AMADEUS_SEARCH",
          "AMADEUS_TICKET",
          "AMADEUS_IMPORT"
      ],
      "title": null,
      "dob": null,
      "employeeId": null,
      "employeeDepartment": null,
      "employeeDesignation": null,
      "nationalId": null,
      "nationalIdExpiry": null,
      "nationality": null
  },
  "ticketedBy": null,
  "promoCodesId": "",
  "templated": "",
  "flightData": {
      "booked_flights": [
          {
              "airline_detail": {
                  "name": ""
              }
          }
      ]
  }
}