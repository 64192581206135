import React, { useEffect, useState } from 'react'
import { customerTemp } from '../../../data/customer/customerData'
import { Breadcrumbs, Skeleton } from '@mui/material';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Cancel, Email, Message, Phone } from '@mui/icons-material';
import BookingHistory from '../../../components/booking/BookingHistory';
import { getUser } from '../../../controllers/user/getUser';
import EditProfile from '../../../components/user/EditProfile';
import ProfilePicture from '../../../components/user/ProfilePicture';

export default function Index() {
  const [data,setData] = useState(customerTemp);
  const {id} = useParams();
  const [searchParam] = useSearchParams();
  const edit = searchParam.get('edit')
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    load();
  },[id])
  async function load() {
    setLoading(true);
    const res = await getUser(id)
    setLoading(false);
    if(res.return)
      setData(res.data);
  }
  return (
    <div className='p-10 w-full flex flex-col gap-10'>
      <Breadcrumbs separator="/" className='text-theme1'>
        <Link to="/corporates">Corporates</Link>
        <h5 className='text-primary/60'>{data.firstName} {data.lastName}</h5>
      </Breadcrumbs>
      <div className='flex gap-4 justify-between p-3'>
        <div className='flex-1 flex flex-col gap-4'>
          <div className='flex gap-4 items-center'>
            <div className='bg-primary/40 rounded-md w-20 h-20 overflow-hidden'>
              {/* {data.profile ? 
                <img src={data.profile} alt='Profile' className='w-full h-full' />
              :null} */}
              <ProfilePicture data={data} handleReturn={load} />
            </div>
            <div>
              <h5 className='capitalize'>{data.firstName} {data.lastName}</h5>
              {loading && !data.id && <Skeleton width={200} height={30} />}
            </div>
          </div>
          <div className='flex flex-wrap gap-4 items-center'>
            <Link to={"?edit=true"} className='btn2 text-xs '>Edit Profile</Link>
            {/* <button className='btn btn_err text-secondary'><Cancel className='w-[14px] h-[14px]' /></button> */}
          </div>
        </div>
        <div className='flex-1 flex flex-col gap-3'>
          <div className='flex gap-3 items-center'>
            <div className='circle text-theme1 border border-theme1 p-1'><Phone className='sqr4' /></div>
            <p>{data.phoneNumber}</p>
            {loading && !data.id && <Skeleton width={200} height={30} />}
          </div>
          <div className='flex gap-3 items-center'>
            <div className='circle text-theme1 border border-theme1 p-1'><Email className='sqr4' /></div>
            <p>{data.email}</p>
            {loading && !data.id && <Skeleton width={200} height={30} />}
          </div>
          <div className='flex gap-3 items-center'>
            <div className='circle text-theme1 border border-theme1 p-1'><Message className='sqr4' /></div>
            <p>{data.addressLine1}</p>
            <p>{data.addressLine2}</p>
            {loading && !data.id && <Skeleton width={200} height={30} />}
          </div>
        </div>
      </div>
      {edit ? <EditProfile userData={data} reload={load} />:null}
    </div>
  )
}
