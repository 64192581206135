import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard/Index';
import Navbar from './components/Navbar';
import Flights from './pages/Flights/Index';
import FlightsTicket from './pages/Flights/Ticket/Index';
import ImportPNR from './pages/Flights/ImportPNR/Index';
import Queue from './pages/Flights/Queue/Index';
import FlightDetails from './pages/Flights/FlightDetails/Index';
import FlightSeats from './pages/Flights/FlightSeats/Index';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { blue, orange } from '@mui/material/colors';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Hotels from './pages/Hotels/Index';
import HotelDetails from './pages/Hotels/Details/Index';
import Transactions from './pages/Transactions/Index';
import Packages from './pages/Packages/Index';
import Insurance from './pages/Insurance/Index';
import CreditLimit from './pages/CreditLimit/Index';
import CreditTransactions from './pages/CreditLimit/Transactions';
import CreditLog from './pages/CreditLimit/CreditLog';
import Customers from './pages/Customers/Index';
import SubAgents from './pages/SubAgents/Index';
import Corporates from './pages/Corporates/Index';
import Staff from './pages/Staff/Index';
import CreateCustomer from './pages/Customers/Create/Index';
import CreateCorporate from './pages/Corporates/Create/Index';
import CreateSubAgent from './pages/SubAgents/Create/Index';
import CreateStaff from './pages/Staff/Create/Index';
import TransactionDetails from './pages/Transactions/Details/Index';
import OffersList from './pages/Flights/FlightSearch/OffersList';
import FlightBookProcess from './pages/Flights/book/Index';
import CustomerDetails from './pages/Customers/Detail/Index';
import CorporateDetails from './pages/Corporates/Detail/Index';
import SubAgentDetails from './pages/SubAgents/Detail/Index';
import StaffDetails from './pages/Staff/Detail/Index';
import Markups from './pages/Markups/Index';
import Currency from './pages/Settings/Currency/Index';
import CreateCurrency from './pages/Settings/Currency/Create';
import EditCurrency from './pages/Settings/Currency/Edit';
import Promotion from './pages/Settings/Promotion/Index';
import Advisory from './pages/Flights/Advisory/Index';
import Addons from './pages/Flights/Addons/Index';
import CreateAddon from './pages/Flights/Addons/Create';
import EditAddon from './pages/Flights/Addons/Edit';
import Search from './pages/Search/Index';
import SearchEmbed from './pages/SearchEmbed/Index';
import HotelsList from './pages/Hotels/HotelSearch/OffersList';
import ToursList from './pages/Tours/TourSearch/OffersList';
import HotelBookProcess from './pages/Hotels/Book/Index';
import TourBookProcess from './pages/Tours/Book/Index';
import PackageDetails from './pages/Packages/Details/Index';
import ActivityLogs from './pages/Settings/Logs/Index';
import EditPoints from './pages/Settings/Points/Edit';
import Points from './pages/Settings/Points/Index';
import Notification from './pages/Settings/Notification/Index';
import Banks from './pages/Settings/Banks/Index';
import CreateBank from './pages/Settings/Banks/Create';
import EditBank from './pages/Settings/Banks/Edit';
import Queues from './pages/Settings/Queues/Index';
import CreateQueue from './pages/Settings/Queues/Create';
import EditQueue from './pages/Settings/Queues/Edit';
import Expiry from './pages/Settings/PNR/Expiry';
import ToggleProvider from './pages/Settings/Provider/ToggleProvider';
import PageNotFound from './pages/PageNotFound';
import ImportPNRDetails from './pages/Flights/FlightDetails/ImportPNRDetails';
import StaffRoles from './pages/Settings/StaffRoles/StaffRoles';
import Test from './pages/Test';
import Tester from './components/Tester';


const rootElement = document.getElementById("root");
const theme = createTheme({
  palette: {
    primary: {
      main: orange[500],
    },
    secondary: {
      main: blue[500],
    },
    primaryLight: {
      main: "#3a3"
    }
  },
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});

function App() {
  return (
    <Router>
      <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path='/test' element={<Tester />} />
            <Route path='/search/embed' element={<SearchEmbed />} />
            <Route path="/" element={<Navbar />}>
              <Route index element={<Dashboard />} />
              <Route path='search/' element={<Search />} />
              <Route path='search/flight/offers' element={<OffersList />} />
              <Route path='search/hotels' element={<HotelsList />} />
              <Route path='search/tours' element={<ToursList />} />

              <Route path='flights' element={<Flights />} />
              <Route path='flights/ticket' element={<FlightsTicket />} />
              <Route path='flights/advisory' element={<Advisory />} />
              <Route path='flights/book/:id' element={<FlightBookProcess />} />
              <Route path='flights/details/:id' element={<FlightDetails />} />
              <Route path='flights/importPNR' element={<ImportPNR />} />
              <Route path='flights/importPNR/details/:id' element={<ImportPNRDetails />} />
              <Route path='flights/queue' element={<Queue />} />
              {/* <Route path='flights/queue/create' element={<CreateQueue />} /> */}
              <Route path='flights/seats/:id' element={<FlightSeats />} />

              <Route path='hotels' element={<Hotels />} />
              <Route path='hotels/:page' element={<Hotels />} />
              <Route path='hotels/details/:id' element={<HotelDetails />} />
              <Route path='hotels/book/:id' element={<HotelBookProcess />} />
              
              <Route path='tours/book/:id' element={<TourBookProcess />} />

              <Route path='packages' element={<Packages />} />
              <Route path='packages/details/:id' element={<PackageDetails />} />

              <Route path='insurance' element={<Insurance />} />

              <Route path='credit' element={<CreditLimit />} />
              <Route path='credit/transactions' element={<CreditTransactions />} />
              <Route path='credit/log' element={<CreditLog />} />

              <Route path='transactions' element={<Transactions />} />
              <Route path='transactions/:type' element={<Transactions />} />
              <Route path='transactions/details/:id' element={<TransactionDetails />} />

              <Route path='customers' element={<Customers />} />
              <Route path='customers/create' element={<CreateCustomer />} />
              <Route path='customers/detail/:id' element={<CustomerDetails />} />

              <Route path='subagents' element={<SubAgents />} />
              <Route path='subagents/create' element={<CreateSubAgent />} />
              <Route path='subagents/detail/:id' element={<SubAgentDetails />} />

              <Route path='corporates' element={<Corporates />} />
              <Route path='corporates/create' element={<CreateCorporate />} />
              <Route path='corporates/detail/:id' element={<CorporateDetails />} />

              <Route path='staff' element={<Staff />} />
              <Route path='staff/create' element={<CreateStaff />} />
              <Route path='staff/detail/:id' element={<StaffDetails />} />

              <Route path='settings/markups' element={<Markups />} />
              <Route path='settings/promotion' element={<Promotion />} />
              <Route path='settings/points' element={<Points />} />
              <Route path='settings/points/edit/:id' element={<EditPoints />} />
              <Route path='settings/currency' element={<Currency />} />
              <Route path='settings/currency/create' element={<CreateCurrency />} />
              <Route path='settings/currency/edit/:id' element={<EditCurrency />} />
              <Route path='settings/addons' element={<Addons />} />
              <Route path='settings/addons/create' element={<CreateAddon />} />
              <Route path='settings/addons/edit/:id' element={<EditAddon />} />
              <Route path='settings/logs' element={<ActivityLogs />} />
              <Route path='settings/notification' element={<Notification />} />
              <Route path='settings/banks' element={<Banks />} />
              <Route path='settings/banks/edit/:id' element={<EditBank />} />
              <Route path='settings/banks/create' element={<CreateBank />} />
              <Route path='settings/queues' element={<Queues />} />
              <Route path='settings/queues/edit/:id' element={<EditQueue />} />
              <Route path='settings/queues/create' element={<CreateQueue />} />
              <Route path='settings/PNRExpiry' element={<Expiry />} />
              <Route path='settings/providers' element={<ToggleProvider />} />
              <Route path='settings/staffRoles' element={<StaffRoles />} />

              <Route path='t35t' element={<Test />} />
              <Route path='*' element={<PageNotFound />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </StyledEngineProvider>
      </Provider>
    </Router>
  );
}

export default App;
