import { path } from "../../config";
import fetchServer from "../fetchServer";
import { store } from "../../redux/store";

export async function verifyOTP(data,userId) {
  let result = {return: 0,msg: "Something went wrong requesting reset pin."}

  let token = store.getState().user.userData.accessToken;

  console.log('req Got: ',data)


  let headers = {
    Authorization: `Bearer ${token}`
  }
  if(userId)
    headers['user-id'] = userId

  await fetchServer({method: 'POST',url: path.api+"/users/verify-otp",data,
    headers
  })
  .then((res) => {
    console.log('serv => ',res);
    if(res.data && res.data.success)
      result = {return: 1,msg: "Successfull",data: res?.data?.data}
    else result['msg'] = res.error.message;
  })
  .catch((e) => {
    console.log("Network Error: ",e)
  })

  return result;

}