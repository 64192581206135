import mergeRecursive from "../../features/utils/mergeRecursive";

export const customerTemp = {
  "id": null,
  "username": null,
  "firstName": null,
  "email": null,
  "lastName": null,
  "otp": null,
  "profile": null,
  "isAdmin": null,
  "isSuperAdmin": null,
  "isActive": null,
  "phoneNumber": null,
  "type": null,
  "customSettingid": null,
  "gender": null,
  "companyName": null,
  "addressLine1": null,
  "addressLine2": null,
  "postalCode": null,
  "cityName": null,
  "countryCode": null,
  "createdDate": null,
  "updatedDate": null,
  "roles": null,
  "title": null,
  "dob": null,
  "employeeId": null,
  "employeeDepartment": null,
  "employeeDesignation": null,
  "nationalId": null,
  "nationalIdExpiry": null,
  "nationality": null,
  "bookingData": null,
  "bookedTrips": null,
  "customSetting": null,
  "defaultSetting": {
      "id": null,
      "for": null,
      "discount": null,
      "serviceFee": null,
      "airlineDealCode": null,
      "createdDate": null,
      "updatedDate": null,
      "expireInHours": null,
      "notifyExpireInHours": 12
  },
  "lastSearch": null,
  "apiUserData": null,
  "approvedApiRequests": null,
  "bookedForOthers": null,
  "transactions": null,
  "_count": {
      "approvedApiRequests": null,
      "bookingData": null,
      "bookedTrips": null,
      "bookedForOthers": null,
      "wallet": null,
      "confirmedTransactions": null,
      "transactions": null,
      "bookedActivities": null,
      "bookedHotels": null,
      "bookedTourproBookings": null,
      "bookedForOthersTourproBookings": null,
      "BookedHotel": null,
      "importedPnr": null,
      "ticketedImportedPnr": 0
  },
  "status": 'Active'
}

export function templateCustomer(obj) {
  let data = mergeRecursive({...obj},customerTemp);
  return {...data,
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    phone: data.phoneNumber || "",
    email: data.email || "",
    status: data?.isActive ? 'Active':'Inactive'
  };
}

export function templateCustomerReport(obj) {
  let data = mergeRecursive({...obj},customerTemp);
  let res = {
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    phone: data.phoneNumber || "",
    email: data.email || "",
    createdDate: data.updatedDate || data.createdDate,
    status: data?.isActive ? 'Active':'Inactive'
  };

  res = Object.values(res);

  return res;
}
