import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";

export async function getSingleTour(id,userId,userType) {
  var result = {return: 0,msg: 'Error',data: {}}

  let token = store.getState().user.userData.accessToken;

  // console.log(" --- on Single Booking Data ---")
  // console.log("data - ",data)
  // console.log(" --------- -----------")
  let headers = {} 
  if(token)
    headers = {Authorization: `Bearer ${token}`}

  if(userId)
    headers['user-id'] = userId;
  if(userType)
    headers['user-type'] = userType?.toUpperCase();



  await fetchServer({method: "Get",url: path.api+'/tourpro/tours/'+id,
    headers,
  })
  .then((res) => {
    console.log(" => ",res)
    if(res) {
      if(res?.data?.success) {
        result = {return: 1,msg: "Successfull",data: res.data.data};
      } else if(res?.error) result['msg'] = res?.error?.message;
    } 
  })
  .catch((err) => {
    console.log("Network Error: ",err);
  })

  return result;

}