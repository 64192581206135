import React, { memo } from 'react'
import { getCurrencySymbol } from '../../features/utils/currency';
import { useSelector } from 'react-redux';
import { def } from '../../config';
import { formatMoney } from '../../features/utils/formatMoney';


function makeNum(numString) {
  return Number(numString?.toString()?.replaceAll(',',''))
}

function PriceSummary({book,onBook,header}) {
  const {hotelBooking} = useSelector((state) => state.hotelBooking);

  // let selected = null;
  // try {
  //   selected = hotelBooking.selected;
  // } catch(ex) {}

  let total = 0;

  return (
    <div className='border rounded-md shadow p-4 min-w-[260px] flex flex-col gap-3'>
      {header}
      <h4 className='w-full text-center'>Price Summary</h4>
      <hr />
      {
        hotelBooking.selectedRooms && hotelBooking.selectedRooms.map((obj,i) => {
          total += Number(obj.price?.replaceAll(',',''))

          return (
            <div className='flex gap-2 justify-between' key={i}>
              <span>{obj.name} : </span>
              <span>{def.currency}{formatMoney(makeNum(obj.farePrice || obj.price))}</span>
            </div> 
          )
      })
      }
      <hr />
      <div className='flex gap-2 justify-between'>
        <b>Hotel total :</b>
        <b>{def.currency}{formatMoney(total)}</b>
      </div>
      {!onBook ? 
        <button onClick={book} className='btn2'>Book</button>
        : null
      }
    </div>
  )
}

export default memo(PriceSummary)