import React, { cloneElement, useCallback, useEffect, useMemo, useState } from 'react'
import HotelDetail from '../../../components/hotel/HotelDetail'
import GuestInfo from './GuestInfo';
import PriceSummary from '../../../components/hotel/PriceSummary';
import Payment from './Payment';
import Confirmation from './Confirmation';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleHotel } from '../../../controllers/hotel/getSingleHotel';
import { setHotelBooking } from '../../../redux/reducers/hotel/hotelBookingSlice';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { useParams } from 'react-router-dom';

const steps = [
  <HotelDetail />,
  <GuestInfo />,
  <Payment />,
  <Confirmation />

]
export default function Index() {
  const [step,setStep] = useState(0);
  const {hotelBooking} = useSelector(state => state.hotelBooking)
  const [data,setData] = useState((hotelBooking && hotelBooking.selected))
  const [fetched] = useState((hotelBooking && hotelBooking.fetched) || {})
  const {id} = useParams();
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  const [timeoutElem,setTimeoutElem] = useState();

  const hotelMemo = useMemo(() => hotelBooking,[hotelBooking])
  
  useEffect(() => {

  })
  
  useEffect(() => {
    load();
    async function load() {
      setLoading(true);
      // @my_fix: hotel could not be selected by id -> (id ? {code: id} : data)
      const as = hotelBooking?.as;
      const userType = hotelBooking?.userType
      const res = await getSingleHotel({...data,rooms: hotelMemo.request && hotelMemo.request.rooms},as?.id,userType);
      setLoading(false);
      if(res.return) {
        setData({...hotelMemo.selected,...res.data})
        dispatch(setHotelBooking({...hotelMemo,fetched: res.data}))
      }
    }
    setTimeout(() => {
      setTimeoutElem(<div className='py-2'><button className='btn1 p-2 text-[10px]' onClick={() => window.history.href = "/search/hotels"}>Go back</button></div>)
    },10000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch])


  const CurComp = useCallback((props) => {
    return cloneElement(props.elem || <></>,props)
  },[])

  const stepNext = () => {
    try {
      if(step === 0 && !hotelBooking.selectedRooms.length) return dispatch(setAlertMsg(['warning','No rooms selected!']))
      setStep(step => step < steps.length - 1 ? step+1 : step)
    } catch(ex) {}
  }
  const stepBack = () => {
    setStep(step => step > 0 ? step-1 : step)
  }

  return data ? (
    <div className='flex gap-8 p-10 justify-start w-full'>
      <CurComp elem={steps[step]} next={stepNext} back={stepBack} loading={loading} data={{...data,...fetched}} />
      <div className='self-start sticky top-10'>
        <PriceSummary onBook={step} book={stepNext}
          // header={<HotelDisplayMemo size={'small'} data={{...data,...fetched}} />}
        />
      </div>
    </div>
  ) : (
    <div className='w-full p-5 bg-secondary flex flex-col items-center justify-center gap-2 '>
      {timeoutElem}
      <div className='flex items-center gap-2 border-[#555]'>
        <div className='load'></div> Loading
      </div>
    </div>
  )
}
