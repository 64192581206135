import React, { memo } from 'react'
import { def } from '../../config';
import { useSelector } from 'react-redux';
import { formatMoney } from '../../features/utils/formatMoney';


function PriceSummary({book,onBook,header}) {
  let temp = [
    {name: 'tourist adult one (1) ',price: '1,000,000.00'},
    {name: 'tourist child one (1) ',price: '900,000.00'},
  ]

  const {tourBooking} = useSelector(state => state.tourBooking);

  let total = 0;

  
  return (
    <div className='border rounded-md shadow p-4 min-w-[260px] flex flex-col gap-3'>
      {header}
      <h4 className='w-full text-center'>Price Summary</h4>
      <hr />
      {
        tourBooking?.passengers?.map((obj,i) => {
          total += parseInt(obj.farePrice * obj.count)
          return obj.count ? (
            <div className='flex gap-2 justify-between' key={i}>
              <span>Tourist {obj.name} ({obj.count}) : </span>
              <span>{def.currency}{formatMoney(obj.farePrice * obj.count)}</span>
            </div> 
          ) : null
      })
      }
      <hr />
      <div className='flex gap-2 justify-between'>
        <b>Tour total :</b>
        <b>{def.currency}{formatMoney(total)}</b>
      </div>
      {!onBook ? 
        <button onClick={book} className='btn2'>Book</button>
        : null
      }
    </div>
  )
}

export default memo(PriceSummary)