import { ArticleOutlined } from '@mui/icons-material';
import { Box, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/mini/SearchInput';
import Pagination from '../../../components/mini/Pagination';
import getFlightOrders from '../../../controllers/flight/getFlightOrders';
import template from '../../../features/flights/tableTemplate';
import { Avatar } from "@mui/material";
import DotMenu from '../../../components/mini/DotMenu';
import { Link } from 'react-router-dom';
import { CancelPNRModal } from '../../../components/flight/CancelPNRModal';
import { setModal, setModalComp } from '../../../redux/reducers/modal/modalSlice';
import { useDispatch} from 'react-redux';
import { IssueTicketModal } from '../../../components/flight/IssueTicketModal';
import FlightPayment from '../../../components/payment/FlightPayment';
import { getPromoCode } from '../../../controllers/settings/promo/getPromoCode';
import addPNRToQueue from '../../../controllers/flight/addPNRToQueue';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import AddPNRToQueue from '../../../components/queue/AddPNRToQueue';
import { formatMoney } from '../../../features/utils/formatMoney';
import { def } from '../../../config';
import { cancelTicket } from '../../../controllers/flight/cancelTicket';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  // {
  //   id: 1, customer: 'Yeabsira Abebe',bookingDate: {date: '24th Oct , 2023',time: '8:29AM'},tripDetails: {airline:"Emirates",path:"LOS-DXB"},tripDate: {date: '24th Oct , 2023',time: '8:29AM'},
  //   PNR: 'GB234', amount: 123123,status: 'cancelled'
  // }
];
// [...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))



function PromoCodeFromId({id}) {
  const [val,setVal] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeaderTitle("Tickets"))
    async function load() {
      const res = await getPromoCode(id);
      if(res.return)
        setVal(res.data.code)
    }
    load()
  },[id])

  return (
    <div>{val}</div>
  )
}

const filterItems = [
  {items:[{id: 0,field: 'status', operatorValue: 'is', value: 'ticketed'}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'PENDING_PAYMENT'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'PAID_FOR'}]},
  {items:[{id: 3,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 4,field: 'status',operator: '',value:''}]},
];

function StatusCol({params,reload}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();

  function cancelPNR() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    dispatch(setModal(true))
    let id = params.id;
    dispatch(setModalComp(
      <CancelPNRModal id={id}  />
    ))
  }
  function issueTicket() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    dispatch(setModal(true))
    let id = params.id;
    dispatch(setModalComp(
      <IssueTicketModal id={id}  />
    ))

  }

  async function pay() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    dispatch(setModal(true))
    dispatch(setModalComp(
      <FlightPayment order={params} />
    ))
  }

  async function addtoQueue() {
    let data = {
      queue: 50,
      category: 0,
      recordLocator: params.row.PNR.id
    }
    const res = await addPNRToQueue(data)
    if(res.return) {
      dispatch(setAlertMsg(['success','Added To Queue.']))
    } else dispatch(setAlertMsg(['error',res.msg]))
  }

  async function handleQueue() {
    console.log('row : ',params.row)
    setAnchorEl(null);
    dispatch(setModal(true))
    dispatch(setModalComp(
      <div className=' bg-secondary rounded-2xl shadow-md p-6 relative'>
        <div className='btn_close z-10' onClick={() => dispatch(setModal(false))}>X</div>
        <AddPNRToQueue id={params.row.PNR.code || params.row.bookingId} returnData={() => dispatch(setModal(false))} />
      </div>
    ))
  }
  async function handleCancel() {
    // console.log(" ==> ",params.row)
    setAnchorEl(null)
    let airlineStockProvider = '';
    let suplier = ''
    try {
      airlineStockProvider = params.row?.flightData?.booked_flights[0].airline
      suplier = params.row?.PNR?.amadeus.split(' ')[1].toLowerCase();
    } catch(ex) {}

    let data = {
      eTicket: params.row?.ticketId,
      airlineStockProvider,
      office: suplier,
    }

    const res = await cancelTicket(data);
    if(res.return) {
      dispatch(setAlertMsg(['success','Ticket Cancelled.']))
      reload()
    } else dispatch(setAlertMsg(['error',res.msg]))

    
    // console.log('to pass: ',data)
  }


  return (
    <div className='flex justify-between w-full relative'>
      <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
        {params.value && params.value.replace("_"," ")}
      </p>
      
      <DotMenu anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true} >
        {/* <MenuItem><Link to={`/flights/details/${params.id}`}>View Detail</Link></MenuItem> */}
        <MenuItem onClick={handleQueue}>Add to Queue</MenuItem>
        {/* <MenuItem>View Ticket</MenuItem> */}
        <MenuItem onClick={handleCancel}>Cancel Ticket</MenuItem>
        {/* <MenuItem>Change Ticket</MenuItem> */}
        {/* <MenuItem onClick={cancelPNR}>Cancel PNR</MenuItem> */}
        {
          // params.value === "PENDING_PAYMENT" ? (
          //   <MenuItem onClick={pay}>Pay</MenuItem>
          // ):null
        }
        {/* <MenuItem><Link to={`/flights/details/${params.id}`}>Add Bags</Link></MenuItem> */}
        {/* <MenuItem><Link to={`/flights/details/${params.id}`}>Add Seats</Link></MenuItem> */}
      </DotMenu>
    </div>
  )
}


export default function Index() {
  const [data,setData] = useState(rows);
  const [loading,setLoading] = useState(true);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  const [type,setType] = useState("");
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData,setFetchedData] = useState(null);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  async function load() {
    const res = await getFlightOrders();
    setLoading(false);
    if(res.return) {
      // console.log("here",res.data);
      let data = res.data.map((row) => template(row))
      setFetchedData(data);
      setData(data);
    }
  }

  const columns = [
    {field: 'customer',headerName: 'Customer',flex:1,minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <Link to={`/flights/details/${params.id}`}>
  
            <div className='flex gap-4 items-center'>
              <Avatar>{params.value[0]}</Avatar>
              {params.value}
            </div>
          </Link>
        )
      }
    },
    {field: 'bookingDate',headerName: 'Booking Date',minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col'>
            {(new Date(params.value)).toDateString()}
            <small>{(new Date(params.value)).toLocaleTimeString()}</small>
          </div>
        )
      }
    },
    {field: 'tripDetails',headerName: 'Trip Details',minWidth: 170,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex flex-col'>
            {params.value.airline}
            <small>{params.value.path.origin} - {params.value.path.destination}</small>
          </div>
        )
      }
    },
    {field: 'departureDate', headerName: 'Flight Date',minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col '>
            {params.value.date}
            <small>{params.value.time}</small>
          </div>
        )
      }
    },
    // {field: 'returnDate', headerName: 'Return Date',minWidth: 130,
    //   renderCell: (params) => {
    //     if(!params.value)
    //       return null;
  
    //     return (
    //       <div className='flex flex-col '>
    //         {params.value.date}
    //         <small>{params.value.time}</small>
    //       </div>
    //     )
    //   }
    // },
    {field: 'PNR', headerName: 'PNR',
      valueGetter: (params) => {
        if(!params.value) return null;
        
        return `${params.value.code || ""} - ${params.value.amadeus || ""}`
      },
      valueFormatter: (params) => {
        if(!params.value) return null;
        
        let obj = params.value.split(" - ")
        // console.log({code: obj[1],id: obj[0]})
        return {amadeus: obj[1],code: obj[0]};
      },
      renderCell: (params) => {
        if(!params.value) return null;
        
        return (
          <Link to={`/flights/details/${params.id}`}>
            <div className='flex flex-col gap-0'>  
              <b className='text-theme1 !font-normal'>{params.formattedValue.code}</b>
              <small className='!font-normal'>{params.formattedValue.amadeus}</small>
            </div>
          </Link>
        )
      },
    },
    {field: 'amount',headerName: 'Amount',
      valueGetter: (params) => {
        if(!params.value) return null;
        
        return `${params.value.price || ""} - ${params.value.promo || ""}`
      },
      valueFormatter: (params) => {
        if(!params.value) return null;
        
        let obj = params.value.split(" - ")
        // console.log({code: obj[1],id: obj[0]})
        return {promo: obj[1],price: obj[0]};
      },
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col gap-0'>  
            {def.currency}{formatMoney(params.formattedValue.price)}
            <small><PromoCodeFromId id={params.formattedValue.promo} /></small>
          </div>
        )
      }
    },
    {field: 'status',headerName: 'Status',flex: 1,minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
        return null;
  
        return <StatusCol params={params} reload={load} />
      }
    },
  ];
  

  function filterType(val) {
    setType(val);
    setData(fetchedData);
    val = val.toLowerCase();
    if(val === "all") return true;
    
    setData(data => data.filter(obj => (
      obj.bookedBy.type.toLowerCase() === val.toLowerCase()
    )))
  }

  function handleSearch(val) {
    setData(fetchedData);

    val = val.toLowerCase();
    setData(data => data.filter(obj => (
      obj.customer.toLowerCase().includes(val) ||
      (new Date(obj.bookingDate)).toDateString().toLowerCase().includes(val) ||
      obj.PNR?.amadeus.toLowerCase().includes(val) ||
      obj.PNR?.code.toLowerCase().includes(val) ||
      obj.tripDetails.airline.toLowerCase().includes(val) ||
      obj.tripDetails.path.origin.toLowerCase().includes(val) ||
      obj.tripDetails.path.destination.toLowerCase().includes(val) ||
      (obj?.amount?.promo?.toLowerCase().includes(val)) ||
      (parseInt(obj?.amount?.price) <= val) ||
      obj.status.includes(val)
    )))
  }

  function generateReport(data,columns) {
    const csvData = data.map((row) => columns.map((col) => {
      let res = '';
      let value = row[col.field];

      // console.log(col)
      switch(col.field) {
        case 'bookingDate': 
          res = (new Date(value)).toDateString() + " " + (new Date(value)).toLocaleTimeString();
          break;
        case 'tripDetails':
          res = value.airline;
          res += " => "+value.path.origin +" - "+ value.path.destination
          break;
        case 'departureDate':
          res = `${value.date}`;
          res += value.time
          break;
        case 'PNR':
          res = value.id;
          break;
        default: return value;
      }

      return res;
      
    }));

    // console.log('csvData', csvData)

    
    // Prepend the column headers to the CSV data
    csvData.unshift(columns.map(col => col.headerName));

    const csvString = csvData.map(row => row.join(',')).join('\n');


    // Create a Blob object from the CSV data
    const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8'});

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Flight_Booking.csv';

    // Click the download link to initiate the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  return (
    <div className='w-full p-4 bg-[#f3f3f3]'>
    <div className='w-full p-4 bg-secondary'>
      <div className='py-5'>
        <TextField select value={type} onChange={(ev) => filterType(ev.target.value)} label="Select Customer,Agent or Company" size='small' className='min-w-[350px]'>
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Customer">Customer</MenuItem>
          <MenuItem value="Sub_Agent">Agent</MenuItem>
          <MenuItem value="Corporate">Company</MenuItem>
        </TextField>
      </div>
      <div className='flex gap-4 justify-between items-center my-3'>
        <Tabs value={filter.items[0].id} onChange={(ev,newVal) => setFilter(filterItems[newVal])} 
         variant='scrollable'
         scrollButtons={false}
         className='shadow-md'>
           <Tab label="Ticketed" />
          {/* 
          <Tab label={`All (${data.length})`} />
          <Tab label="Pending" />
          <Tab label="Paid" />
          <Tab label="Cancelled" />
           */}
        </Tabs>
        <SearchInput className='text-theme1' onChange={handleSearch} />
        <button className='btn2 whitespace-nowrap' onClick={() => generateReport(data,columns)}><ArticleOutlined /> Generate Report</button>
      </div>
      <Box className=''>
        <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "bookingDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
      </div>
    </div>
  )
}
