import { ArticleOutlined } from '@mui/icons-material';
import { Box, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/mini/SearchInput';
import Pagination from '../../../components/mini/Pagination';
import getFlightOrders from '../../../controllers/flight/getFlightOrders';
import template, { templateImport } from '../../../features/flights/tableTemplate';
import { Avatar } from "@mui/material";
import DotMenu from '../../../components/mini/DotMenu';
import { Link } from 'react-router-dom';
import { CancelPNRModal } from '../../../components/flight/CancelPNRModal';
import { setModal, setModalComp } from '../../../redux/reducers/modal/modalSlice';
import { useDispatch} from 'react-redux';
import { IssueTicketModal } from '../../../components/flight/IssueTicketModal';
import FlightPayment from '../../../components/payment/FlightPayment';
import { getPromoCode } from '../../../controllers/settings/promo/getPromoCode';
import importPNRReq from '../../../controllers/flight/importPNR';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import getImportedPNRs from '../../../controllers/flight/getImportedPNRs';
import moment from 'moment';
import { formatMoney } from '../../../features/utils/formatMoney';
import getQueues from '../../../controllers/flight/getQueues';
import { templateQueue } from '../../../data/flight/queue';
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import delQueue from '../../../controllers/flight/delQueue';
import ModalLocal from '../../../components/mini/ModalLocal';
import AddPNRToQueue from '../../../components/queue/AddPNRToQueue';
import getQueuesReq from '../../../controllers/settings/queues/getQueues';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  // {
  //   id: 1, customer: 'Yeabsira Abebe',bookingDate: {date: '24th Oct , 2023',time: '8:29AM'},tripDetails: {airline:"Emirates",path:"LOS-DXB"},tripDate: {date: '24th Oct , 2023',time: '8:29AM'},
  //   PNR: 'GB234', amount: 123123,status: 'cancelled'
  // }
];
// [...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))



const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operator: 'equals', operatorValue: 'is', value: 'Ticketed'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'PAID_FOR'}]},
  {items:[{id: 3,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 4,field: 'status', operatorValue: 'is', value: 'refund'}]},
];

function StatusCol({params,load}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();

  async function removeQueue() {
    let data = {
      queue: 10,
      category: 0,
      recordLocator: params.row.PNR
    }
    const res = await delQueue(data);
    if(res.return) {
      dispatch(setAlertMsg(['success','Queue Item Removed']))
      load();
      setAnchorEl(null)
    } else dispatch(setAlertMsg(['error',res.msg]))
  }

  return (
    <div className='flex justify-between w-full relative'>
      <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
        {params.value}
      </p>
      {!params.row.ticketedBy ? (
        // <button className='btn2' onClick={issueTicket}>Issue Ticket</button>

        <DotMenu anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true} >
          {/* <MenuItem><Link to={`/flights/details/${params.id}`}>View Detail</Link></MenuItem> */}
          <MenuItem onClick={removeQueue}>Remove Item</MenuItem>
        </DotMenu>
      ): null}
    </div>
  )
}


export default function Index() {
  const [data,setData] = useState(rows);
  const [loading,setLoading] = useState(true);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0});
  const [type,setType] = useState("");
  const [openModal,setOpenModal] = useState(false);
  const [queue,setQueue] = useState({queueNumber: 10,category: 0});
  const [queues,setQueues] = useState([]);

  const [PNR,setPNR] = useState('');
  const dispatch = useDispatch();
  // const [PNRData,setPNRData] = useState();
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData,setFetchedData] = useState(null);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[queue])
  useEffect(() => {
    dispatch(setHeaderTitle('Queues'))
    loadQueues();
  },[])

  async function loadQueues() {
    // setLoading(true);
    const res = await getQueuesReq();
    // setLoading(false);
    if(res.return) {
      setQueues(res.data);
    }
  }

  
  async function load() {
    let data = {
      "queue": [
          {
              queue: queue.queueNumber,
              "category": queue.category || 0
          }
      ],
    }
  
    setLoading(true);
    const res = await getQueues(data);
    setLoading(false);
    if(res.return) {
      // console.log("here",res.data);
      let items = [];
      try {
        items = res.data.queueView.item;
      } catch(ex) {}
      let data = items.map((row,i) => templateQueue({...row,id: i}))
      setFetchedData(data);
      setData(data);
    } else setData([])
  }

  const columns = [
    {field: 'officeId',headerName: 'Office',flex:1,minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex gap-4 items-center'>
            {params.value}
          </div>
        )
      }
    },
    {field: 'PNR',headerName: 'Record Locator',flex: 1,minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col'>
            {params.value}
          </div>
        )
      }
    },
    {field: 'name',headerName: 'Name',flex: 1,minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col'>
            {params.value}
          </div>
        )
      }
    },
    {field: 'departureDate', headerName: 'Departure Date',flex: 1,minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col '>
            {params.value}
            {/* {moment(params.value.date, 'DDMMYY').format('YYYY-MM-DD')} */}
            {/* <small>{moment(params.value.time, 'HHmm').format('HH:mm')}</small> */}
          </div>
        )
      }
    },
    {field: 'location', headerName: 'Location',
      renderCell: (params) => {
        if(!params.value) return null;
        
        return <StatusCol params={params} load={load} />
      },
    },

  ];
  


  function filterType(val) {
    setType(val);
    setData(fetchedData);
    val = val.toLowerCase();
    if(val === "all") return true;
    
    setData(data => data.filter(obj => (
      obj.bookedBy.type.toLowerCase() === val.toLowerCase()
    )))
  }

  function handleSearch(val) {
    setData(fetchedData);

    val = val.toLowerCase();
    setData(data => data.filter(obj => (
      obj.officeId.toLowerCase().includes(val) ||
      obj.PNR.toLowerCase().includes(val) ||
      obj.name.toLowerCase().includes(val) ||
      obj.departureDate.toLowerCase().includes(val) ||
      obj.location.toLowerCase().includes(val)
    )))
  }

  return (
    <div className='w-full p-4 bg-[#f3f3f3]'>
    <div className='w-full p-4 bg-secondary'>
      <div className='py-5'>
        <TextField select size='small' onChange={(ev) => setQueue(ev.target.value)} label='Select Queue' className='min-w-[200px]'>
          <MenuItem></MenuItem>
          {queues.map((obj,i) => (
            <MenuItem key={i} value={obj} className='flex flex-wrap gap-6 items-between'>
              <h3 className='flex-1'>{obj.queueNumber}</h3>
              {obj.title}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className='flex gap-4 justify-between items-center my-3'>
        {/* <Tabs value={filter.items[0].id} onChange={(ev,newVal) => setFilter(filterItems[newVal])} 
         variant='scrollable'
         scrollButtons={false}
         className='shadow-md'>
          <Tab label={`Imported (${data.length})`} />
          <Tab label="Ticketed" />
          <Tab label="Booked" />
          <Tab label="Cancelled" />
          <Tab label="Refund" />
        </Tabs> */}
        <SearchInput className='text-theme1' onChange={handleSearch} />
        <button className='btn2 whitespace-nowrap' onClick={() => setOpenModal(true)}><ArticleOutlined /> Add Queue</button>
        <ModalLocal open={openModal} setOpen={setOpenModal}>
          <div className=' bg-secondary rounded-2xl shadow-md p-6 relative'>
            <div className='btn_close z-10' onClick={() => (setOpenModal(false))}>X</div>
            <AddPNRToQueue returnData={() => {setOpenModal(false);load()}} />
          </div>
        </ModalLocal>
      </div>
      <Box className=''>
        <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "bookingDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
      </div>
    </div>
  )
}
