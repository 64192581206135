import { ArticleOutlined, CalendarMonth, FeedOutlined, Print } from '@mui/icons-material'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import React, { createRef, useEffect, useState } from 'react'
import FlightDisplay from '../flight/FlightDisplay'
import { offerDataTemp } from '../../data/flight/offerData'
import FlightInfoCard from '../flight/FlightInfoCard'
import { printDom } from '../../features/utils/printDom'
import { useDispatch } from 'react-redux'
import { confirmBankTransaction } from '../../controllers/payment/confirmBankTransaction'
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice'
import { setModal, setModalComp } from '../../redux/reducers/modal/modalSlice'
import { CancelPNRModal } from '../flight/CancelPNRModal'
import { IssueTicketModal } from '../flight/IssueTicketModal'
import FlightPayment from '../payment/FlightPayment'
import { paymentStatus } from '../../data/ENUM/PaymentStatus'
import DotMenu from '../mini/DotMenu'
import { Box, MenuItem, Pagination, Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'
import { Avatar } from 'antd'
import { formatMoney } from '../../features/utils/formatMoney'
import { def } from '../../config'
import SearchInput from '../mini/SearchInput'
import { getPromoCode } from '../../controllers/settings/promo/getPromoCode'
import template from '../../features/flights/tableTemplate'


// const columns = [
//   {field: 'Room Name',headerName: 'Room Name',flex: 1},
//   {field: 'BedType',headerName: 'Bed Type',flex: 1},
//   {field: 'RoomFloor',headerName: 'Room Floor',flex: 1},
//   {field: 'RoomFacility',headerName: 'Room Facility',flex: 1},
//   {field: 'BookDate',headerName: 'Book Date',flex: 1},
// ]
function PromoCodeFromId({id}) {
  const [val,setVal] = useState('');
  useEffect(() => {
    async function load() {
      const res = await getPromoCode(id);
      if(res.return)
        setVal(res.data.code)
    }
    load()
  },[id])

  return (
    <div>{val}</div>
  )
}

const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'PENDING_PAYMENT'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'PAID_FOR'}]},
  {items:[{id: 3,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 4,field: 'status', operatorValue: 'is', value: 'ticketed'}]},
];

function StatusCol({params,reload}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();

  let method;
  try {
    method = params.row.transactions[0].from
  } catch(ex) {}

  async function verifyPayment() {
    const res = await confirmBankTransaction()
    if(res.return) {
      reload();
      dispatch(setAlertMsg(['success','Payment Confirmed']))
    } else dispatch(setAlertMsg(['warning','Payment still pending']))
  }


  function cancelPNR() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    dispatch(setModal(true))
    let id = params.id;
    dispatch(setModalComp(
      <CancelPNRModal id={id}  />
    ))
  }
  function issueTicket() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    dispatch(setModal(true))
    let id = params.id;

    dispatch(setModalComp(
      <IssueTicketModal returnData={reload} userId={params.row.bookedBy && params.row.bookedBy.type !== 'CUSTOMER' && params.row.bookedBy.id} id={id}  />
    ))

  }

  async function pay() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    dispatch(setModal(true))
    dispatch(setModalComp(
      <div className='relative'>
        <div className='btn_close z-10' onClick={() => dispatch(setModal(false))}>X</div>
        <FlightPayment returnData={reload} order={params} />
      </div>
    ))
  }


  return (
    <div className='flex justify-between w-full relative'>
      <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
        {paymentStatus[params.value]}
      </p>
      
      <DotMenu anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true} >
        <MenuItem><Link to={`/flights/details/${params.id}`}>View Detail</Link></MenuItem>
        <MenuItem onClick={issueTicket}>Issue Ticket</MenuItem>
        <MenuItem onClick={cancelPNR}>Cancel PNR</MenuItem>
        {method === 'BANK' ? 
          <MenuItem onClick={verifyPayment}>Verify</MenuItem>
        :null}
        {!method ? 
          <MenuItem onClick={pay}>Pay</MenuItem>
        :null}
        <MenuItem><Link to={`/flights/details/${params.id}`}>Add Bags</Link></MenuItem>
        <MenuItem><Link to={`/flights/details/${params.id}`}>Add Seats</Link></MenuItem>
      </DotMenu>
    </div>
  )
}

export default function BookingHistory({obj}) {

  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  const [type,setType] = useState("");
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData,setFetchedData] = useState(null);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[obj])
  async function load() {
    // setData(obj)
    // const res = await getFlightOrders();
    // setLoading(false);
    // if(res.return) {
    //   // console.log("here",res.data);
    let bookedBy = {
      firstName: obj.firstName,
      lastName: obj.lastName
    }
      let data = (obj.bookedTrips && obj.bookedTrips.map((row) => template({...row,bookedBy})))
      setFetchedData(data);
      setData(data || []);
    // }
  }

  const columns = [
    {field: 'customer',headerName: 'Customer',flex:1,minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <Link to={`/flights/details/${params.id}`}>
  
            <div className='flex gap-4 items-center'>
              <Avatar>{params.value[0]}</Avatar>
              {params.value}
            </div>
          </Link>
        )
      }
    },
    {field: 'bookingDate',headerName: 'Booking date',minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col'>
            {(new Date(params.value)).toDateString()}
            <small>{(new Date(params.value)).toLocaleTimeString()}</small>
          </div>
        )
      }
    },
    {field: 'tripDetails',headerName: 'Trip details',minWidth: 170,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex flex-col'>
            {params.value.airline}
            <small>{params.value.path.origin} - {params.value.path.destination}</small>
          </div>
        )
      }
    },
    {field: 'departureDate', headerName: 'Flight date',minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col '>
            {params.value.date}
            <small>{params.value.time}</small>
          </div>
        )
      }
    },
    // {field: 'returnDate', headerName: 'Return Date',minWidth: 130,
    //   renderCell: (params) => {
    //     if(!params.value)
    //       return null;
  
    //     return (
    //       <div className='flex flex-col '>
    //         {params.value.date}
    //         <small>{params.value.time}</small>
    //       </div>
    //     )
    //   }
    // },
    {field: 'PNR', headerName: 'PNR',
      valueGetter: (params) => {
        if(!params.value) return null;
        
        return `${params.value.code || ""} - ${params.value.amadeus || ""}`
      },
      valueFormatter: (params) => {
        if(!params.value) return null;
        
        let obj = params.value.split(" - ")
        // console.log({code: obj[1],id: obj[0]})
        return {amadeus: obj[1],code: obj[0]};
      },
      renderCell: (params) => {
        if(!params.value) return null;
        
        return (
          <Link to={`/flights/details/${params.id}`}>
          <div className='flex flex-col gap-0'>  
              <b className='text-theme1 !font-normal'>{params.formattedValue.code}</b>
              <small className='!font-normal'>{params.formattedValue.amadeus}</small>
          </div>
          </Link>
        )
      },
    },
    {field: 'amount',headerName: 'Amount',
      valueGetter: (params) => {
        if(!params.value) return null;
        
        return `${params.value.price || ""} - ${params.value.promo || ""}`
      },
      valueFormatter: (params) => {
        if(!params.value) return null;
        
        let obj = params.value.split(" - ")
        // console.log({code: obj[1],id: obj[0]})
        return {promo: obj[1],price: obj[0]};
      },
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col gap-0'>  
            {def.currency}{formatMoney(params.formattedValue.price)}
            <small><PromoCodeFromId id={params.formattedValue.promo} /></small>
          </div>

        )
      }
    },
    {field: 'status',headerName: 'Status',flex: 1,minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
        return null;
  
        return <StatusCol reload={load} params={params} />
      }
    },
  ];
  

  // function filterType(val) {
  //   setType(val);
  //   setData(fetchedData);
  //   val = val.toLowerCase();
  //   if(val === "all") return true;
    
  //   setData(data => data.filter(obj => (
  //     obj.bookedBy.type.toLowerCase() === val.toLowerCase()
  //   )))
  // }

  function handleSearch(val) {
    setData(fetchedData);

    val = val.toLowerCase();
    setData(data => data && data.filter(obj => (
      obj.customer.toLowerCase().includes(val) ||
      (new Date(obj.bookingDate)).toDateString().toLowerCase().includes(val) ||
      obj.PNR.id.toLowerCase().includes(val) ||
      obj.PNR.code.toLowerCase().includes(val) ||
      obj.tripDetails.airline.toLowerCase().includes(val) ||
      obj.tripDetails.path.origin.toLowerCase().includes(val) ||
      obj.tripDetails.path.destination.toLowerCase().includes(val) ||
      (parseInt(obj.amount) <= val) ||
      obj.status.includes(val)
    )))
  }

  function generateReport(data,columns) {
    const csvData = data.map((row) => columns.map((col) => {
      let res = '';
      let value = row[col.field];

      // console.log(col)
      switch(col.field) {
        case 'bookingDate': 
          res = (new Date(value)).toDateString() + " " + (new Date(value)).toLocaleTimeString();
          break;
        case 'tripDetails':
          res = value.airline;
          res += " => "+value.path.origin +" - "+ value.path.destination
          break;
        case 'departureDate':
          res = `${value.date}`;
          res += value.time
          break;
        case 'PNR':
          res = value.id;
          break;
        default: return value;
      }

      return res;
      
    }));

    // console.log('csvData', csvData)

    
    // Prepend the column headers to the CSV data
    csvData.unshift(columns.map(col => col.headerName));

    const csvString = csvData.map(row => row.join(',')).join('\n');


    // Create a Blob object from the CSV data
    const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8'});

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Flight_Booking.csv';

    // Click the download link to initiate the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  return (
    // <div className='w-full p-4 bg-[#f3f3f3]'>
    <div className='w-full p-4 bg-secondary'>
      <h3>Booking History</h3>

      <div className='flex gap-4 justify-between items-center my-3'>
        <Tabs value={filter.items[0].id} onChange={(ev,newVal) => setFilter(filterItems[newVal])} 
         variant='scrollable'
         scrollButtons={false}
         className='shadow-md'>
          <Tab label={`All Flights (${data.length || 0})`} sx={{textTransform: 'capitalize'}}/>
          <Tab label="Pending" sx={{textTransform: 'capitalize'}}/>
          <Tab label="Paid" sx={{textTransform: 'capitalize'}}/>
          <Tab label="Cancelled" sx={{textTransform: 'capitalize'}}/>
          <Tab label="Ticketed" sx={{textTransform: 'capitalize'}}/>
        </Tabs>
        <SearchInput className='text-theme1' onChange={handleSearch} />
        <button className='btn2 whitespace-nowrap' onClick={() => generateReport(data,columns)}><ArticleOutlined /> Generate Report</button>
      </div>
      <Box className=''>
        <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "bookingDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
      </div>
    // </div>
  )

}
// export default function OBookingHistory({data}) {
//   const printDomRef = createRef();
//   // let rows = data..map((row) => templateBookedHotels(row))
//   console.log(data)
//   return data && (
//     <div className='flex flex-col gap-2'>
//       <div className='flex justify-between'>
//         <h3>Booking History</h3>
//         <div className='flex gap-2'>
//           <button className='btn2'><CalendarMonth className='sqr4' />Data Filter</button>
//           <button className='btn2'><FeedOutlined className='sqr4' />Generate Report</button>
//         </div>
//       </div>
//       <DataGrid rows={[]} columns={columns} hideFooter  />
//       <div className='flex gap-2'>
//         <div className='flex-1 flex flex-col gap-2'>
//           {data.map((obj,i) => (
//             obj.flightData && obj.flightData.booked_flights && 
//             obj.flightData.booked_flights[0] &&
//             obj.flightData.booked_flights[0].booked_segments && 
//             obj.flightData.booked_flights[0].booked_segments.map((flightData,k) => (
//               // <FlightDisplay key={k} flight={flightData} />
//               // JSON.stringify(flightData)
//               <></>
//               // <FlightDisplay key={i} flight={offerDataTemp.segments[0]} />
//             ))
//           ))}
//           <FlightDisplay flight={offerDataTemp.segments[0]} />
//           <FlightDisplay flight={offerDataTemp.segments[0]} />
//           <FlightDisplay flight={offerDataTemp.segments[0]} />
//         </div>
//         <div ref={printDomRef} className='flex-1 border bg-secondary p-3 flex flex-col gap-3'>
//           <div className='flex justify-center w-full'><Print onClick={() => printDom(printDomRef.current)} /></div>
//           <FlightInfoCard data={offerDataTemp.segments[0]} label='Depart' />
//           <FlightInfoCard data={offerDataTemp.segments[1]} label='Return' />
//         </div>
//       </div>
//     </div>
//   )
// }
