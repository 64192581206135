import { Box, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import getDisabledProviders from '../../../controllers/settings/flights/getDisabledProviders';
import { userTypes } from '../../../data/ENUM/UserType';
// import { flightProviders } from '../../../data/ENUM/FlightProviders';
import MultipleSelect from '../../../components/form/MultiSelect';
import disableFlightProvider from '../../../controllers/settings/flights/disableFlightProvider';
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { Edit } from '@mui/icons-material';
import getAllProviders from '../../../controllers/settings/flights/getAllProviders';

export default function ToggleProvider() {
  const [data,setData] = useState({forUserType: '',disabledProviders: []});
  const [list,setList] = useState([]);
  const [flightProviders,setFlightProviders] = useState([])
  const [loading,setLoading] = useState(false);
  const [providersLoading,setProvidersLoading] = useState(false);
  const [fetchLoading,setFetchLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    loadProviders();
    loadDisabled();
  },[])

  async function loadProviders() {
    setProvidersLoading(true);
    const res = await getAllProviders();
    setProvidersLoading(false);
    if(res.return) {
      setFlightProviders(res.data?.allProviders || [])
    }
  }
  async function loadDisabled() {
    setFetchLoading(true)
    const res = await getDisabledProviders();
    setFetchLoading(false)
    if(res.return) {
      console.log(res.data)
      setList(res.data)
    }
  }

  async function handleDisable() {
    setLoading(true);
    let modData = {...data};
    if(data.forUserType === 'ALL USERS')
      modData = {disabledProviders: data.disabledProviders};
    const res = await disableFlightProvider(modData);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Provider Disabled for '+data.forUserType]))
      loadDisabled();
    } else dispatch(setAlertMsg(['error',res.msg || 'Failed disabling']));
  }
  return (
    <div className='w-full p-4 bg-[#f3f3f3]'>
      <div className='flex flex-col gap-4 bg-secondary rounded-md p-4 px-8'>
        <h3>Disable Provider</h3>
        <div className='flex gap-4'>
          <TextField select label='For User Type' size='small' className='min-w-[200px]'
            value={data.forUserType}
            onChange={(ev) => setData({...data,forUserType: ev.target.value})}
          >
            <MenuItem value={'ALL USERS'}>All Users</MenuItem>
            {userTypes.map((userType,i) => (
              <MenuItem value={userType} key={i}>{userType}</MenuItem>
            ))}
          </TextField>
          <MultipleSelect select label='Select Providers' size='small' className='min-w-[200px]' 
            value={data.disabledProviders || []} loading={providersLoading}
            options={flightProviders.map(provider => ({label:provider,value: provider}))}
            onChange={(arr) => setData({...data,disabledProviders: arr.map(obj => obj.value)})}
          />
          <button className='btn2' disabled={loading} onClick={handleDisable}>
            {loading?<div className='load'></div>:null}
            {((data.disabledProviders || []).length) ? 'Disable' : 'Enable All'}
          </button>
        </div>
      </div>
      <Box className='bg-secondary px-6 rounded-md'>
        <div className='flex flex-col gap-4 my-3 p-3 py-5 border-primary'>
          <h3>Disabled Providers</h3>
          {fetchLoading ? <div className='load'></div>:null}
          {list.map((obj,i) => (
            <div key={i} className='flex flex-col gap-4'>
              <div className='flex justify-between gap-4'>
                {obj.forUserType}
                <Edit className='cursor-pointer text-primary/50 hover:text-primary' onClick={() => setData(obj)}/>
              </div>
              <div className='flex gap-4 flex-wrap px-4'>
              {obj?.disabledProviders?.map((provider,j) => (
                <span key={j} className='border rounded-md p-2'>{provider}</span>
              ))}
              </div>
              <hr />
            </div>
          ))}
        </div>
      </Box>
    </div>
  )
}
