import { Add, ArrowBack } from '@mui/icons-material'
import { TextField } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { clone } from '../../../features/utils/objClone'
import { useDispatch } from 'react-redux'
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice'
import CountriesInput from '../../../components/form/CountriesInput'
import { queueData } from '../../../data/settings/queue/bankData'
import createQueueReq from '../../../controllers/settings/queues/createQueue'


export default function CreateQueue() {
  const [data,setData] = useState(clone(queueData));
  const [loading,setLoading] = useState(false);

  const dispatch = useDispatch();

  async function handleSubmit() {
    
    if (
      data.title === "" ||
      !data.title ||
      data.queueNumber === "" ||
      !data.queueNumber
    ) return dispatch(setAlertMsg(['error','Please input all fields!']))
    

    console.log(data)
    
    setLoading(true);
    const res = await createQueueReq(data);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Queue Saved.']))
      setData(clone(queueData));
    } else dispatch(setAlertMsg(['error','Failed Saving Queue']))

  }

  return (
    <div className='w-full bg-[#f3f3f3] p-4'>
      <div className='bg-secondary p-4'>
        <Link to="/settings/queues" className='flex gap-4'>
          <ArrowBack />
          <h3>Add Queue</h3>
        </Link>
        <div className='inline-block py-4'>
          <div className='flex flex-col gap-2 w-auto'>
            <TextField size='small' label='Title'
              value={data.title} onChange={(ev) => setData(data => ({...data,title: ev.target.value}))} 
            />
            <TextField size='small' label='Queue Number' type='number'
              value={data.queueNumber} onChange={(ev) => setData(data => ({...data,queueNumber: ev.target.value}))} 
            />
            <TextField size='small' label='Category' type='number'
              value={data.category} onChange={(ev) => setData(data => ({...data,category: ev.target.value}))} 
            />
            <button className='btn2 flex gap-2' onClick={handleSubmit}>
              {loading?<div className='load'></div>:null}
              Add Queue</button>
          </div>
        </div>
      </div>
    </div>
  )
}
