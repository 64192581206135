import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { TextField } from '@mui/material';
import { createSubAgent } from '../../../controllers/user/creteSubAgent';
import { agentSignupTemp } from '../../../data/user/agentSignupData';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import AgentProfile from '../../../components/user/EditAgentProfile';
import { clone } from '../../../features/utils/objClone';
import { updateSubAgent } from '../../../controllers/user/updateSubAgent';
import PasswordInput from '../../../components/form/PasswordInput';

export default function Index() {
  const dispatch = useDispatch();
  const [data,setData] = useState(agentSignupTemp);
  const [loading,setLoading] = useState(false);


  useEffect(() => {
    dispatch(setHeaderTitle("Sub Agents"))
  },[dispatch])

  
  async function createAgent() {
    if(data.password !== data.confirmPassword)
      return dispatch(setAlertMsg(['error','Passwords dones\'nt match!']));
      
      setLoading(true);
      const res = await createSubAgent(data)
      setLoading(false);
      if(res.return) {
        dispatch(setAlertMsg(['success','Agent Creation Successfull.']))
      }
      else dispatch(setAlertMsg(['error',res.msg]))

      return res;
  }

  async function updateProfile(userId) {

    try {

      let upData = clone(data);
      upData = Object.fromEntries(
        Object.entries(upData).filter(([key, value]) => value !== null && value !== undefined)
      );

      setLoading(true);
      const res = await updateSubAgent(upData,userId);
      setLoading(false);
      if(res.return)
        return dispatch(setAlertMsg(['success','Profile updated.']));
      else return dispatch(setAlertMsg(['error',res.msg]))
    } catch(ex) {dispatch(setAlertMsg(['error','Error! (OxUPDP)']))}
    
  }

  function handleInputChange(obj) {
    setData({...data,...obj})
  }

  async function handleSubmit(ev) {
    ev.preventDefault();

    const createRes = await createAgent();
    if(createRes.return) {
      await updateProfile(createRes.data.user.id)
    }

  } 
  return (
    <form action='' onSubmit={handleSubmit} className='w-full p-4 m-2 flex flex-col gap-6'>
      <AgentProfile returnData={(subAgent) => setData({...data,...subAgent})} />
      <div className='flex gap-4'>
        <TextField size='small' label='Surname' required
          value={data.lastName}
          onChange={(ev) => handleInputChange({lastName: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <TextField size='small' label='First Name' required
          value={data.firstName}
          onChange={(ev) => handleInputChange({firstName: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <PhoneNumberInput label="User Phone" required
          value={data.phoneNumber}
          onChange={(val) => handleInputChange({phoneNumber: val})}
          />
      </div>
      <h6>Login Information</h6>
      <div className='flex gap-4'>
        <TextField size='small' label='Agency Email' required
          value={data.email}
          onChange={(ev) => handleInputChange({email: ev.target.value})}
          InputLabelProps={{
            shrink: true,
          }} />
        <PasswordInput size='small' label='Password' type='password' required
          value={data.password}
          onChange={(val) => handleInputChange({password: val})}
          InputLabelProps={{
            shrink: true,
          }} />
        <PasswordInput size='small' label='Re-enter Password' type='password' required
          value={data.confirmPassword}
          onChange={(val) => handleInputChange({confirmPassword: val})}
          InputLabelProps={{
            shrink: true,
          }} />

      </div>
      <button className='btn2 self-start'>
        {loading ? (
          <div className='load'></div>
        ) : null}
        Create SubAgent</button>


      {/* <form action='' onSubmit={createAgent} className='inline-block'>
        <div className='flex flex-col gap-6 w-auto'>
          <h2>Add a sub agent</h2>
          <TextField size='small' label='Agency Name' name='companyName'
            value={data.companyName}
            onChange={(ev) => handleInputChange({companyName: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }} />
          <div className='flex gap-4'>
            <TextField size='small' label='CAC Reg No' name='cacRegNo'
              value={data.cacRegNo}
              onChange={(ev) => handleInputChange({cacRegNo: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Agency Location' name='location'
              value={data.location}
              onChange={(ev) => handleInputChange({location: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />

          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='Agency Email' name='email'
              value={data.email}
              onChange={(ev) => handleInputChange({email: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <PhoneNumberInput label="Agency Phone"
              value={data.phoneNumber}
              onChange={(val) => handleInputChange({phoneNumber: val})}
              />

          </div>
          <h6>Contact Person</h6>
          <div className='flex gap-4'>
            <TextField size='small' label='Surname' 
              value={data.lastName}
              onChange={(ev) => handleInputChange({lastName: ev.target.value,contactSurName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='First Name' 
              value={data.firstName}
              onChange={(ev) => handleInputChange({firstName: ev.target.value,contactFirstName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='Email' 
              value={data.contactEmail}
              onChange={(ev) => handleInputChange({contactEmail: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <PhoneNumberInput label="Contact Phone"
              value={data.contactPhone}
              onChange={(val) => handleInputChange({contactPhone: val})}
              />
          </div>
          <h6>Login Information</h6>
          <div className='flex gap-4'>
            <TextField size='small' label='Agency Email' 
              value={data.email}
              onChange={(ev) => handleInputChange({email: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Password' type='password'
              value={data.password}
              onChange={(ev) => handleInputChange({password: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Re-enter Password' type='password'
              value={data.confirmPassword}
              onChange={(ev) => handleInputChange({confirmPassword: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />

          </div>
          <button className='btn2 self-start'>
            {loading ? (
              <div className='load'></div>
            ) : null}
            Create SubAgent</button>
        </div>
      </form> */}
    </form>
  )
}
