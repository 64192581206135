import React, { cloneElement, memo, useCallback, useEffect, useMemo, useState } from 'react'
import FlightOrderProgress from '../../../components/flight/FlightOrderProgress'
import FlightReview from './FlightReview'
import { ArrowBack } from '@mui/icons-material';
import PassengerInfo from './PassengerInfo';
import CustomStepper from '../../../components/mini/CustomStepper';
import { FlightOfferDetail } from '../../../components/flight/FlightOfferDetail';
import FlightServices from './FlightServices';
import Payment from './Payment'
import { useDispatch, useSelector } from 'react-redux';
import { setBookingData } from '../../../redux/reducers/flight/flightBookingSlice';
import getFlightOfferPrice from '../../../controllers/flight/getOfferPrice';
import PriceTimeout from '../../../components/flight/PriceTimeout';
import { priceTimeout } from '../../../config';
import FlightSeats from './FlightSeats';
import ModalLocal from '../../../components/mini/ModalLocal';



async function getPrice(offer) {
  const res = await getFlightOfferPrice({offer});
  return res;
}

export default function Index() {
  const dispatch = useDispatch();
  const {bookingData} = useSelector((state) => state.flightBooking);
  const data = useMemo(() => bookingData,[bookingData]);
  const status = (data.page ? data.page : 'review');
  const [loading,setLoading] = useState(true);

  const pages = {review: 0, book: 1, payment: 2};
  const step = pages.hasOwnProperty(`${status}`) ? pages[`${status}`] : 1;

  const [expTime,setExpTime] = useState(null);

  console.log('url - ', data)

  const fetchPrice = useCallback(async () => {
    let now = new Date().getTime();
    let timePass = (now - parseInt(data.time));

    if(((priceTimeout - timePass) < 0) || !data.time) {
      console.log('calling again')
      setLoading(true);
      const res =  await getPrice(data.beforePrice);
      setLoading(false);
      console.log(res)
      if(res.return) {
        console.log('returned')
        const t = new Date().getTime();
        dispatch(setBookingData(({...data,offer: res.data,time: t})))
        setExpTime(t)
      }
    }

  },[dispatch,data])

  useEffect(() => {
    let t = null;
    fetchPrice();

    return () => clearTimeout(t);
  },[data,fetchPrice])

  
  function startProcess() {
    dispatch(setBookingData({...data,step: 0,offer: data.offer,page: 'book'}))
  }
  
  const back = useCallback(() => {
    dispatch(setBookingData({...data,step: 0,offer: data.offer,page: 'review',bookingInfo: null}))
  },[data,dispatch])

  return (Object.keys(data).length === 0) ? null : (
    <div className=' w-full bg-gray-100 h-full'>
      <PriceTimeout gotTime={expTime} />
      <div className='p-5 flex items-center justify-center bg-secondary m-3'>
        <FlightOrderProgress step={step} />
      </div>
      <div className='p-4 flex gap-2'>
        {
          status === 'review' ?
          (
            <FlightReview data={data.offer} book={startProcess} />
          ) : (
            <FlightBookProcess back={back} />
          )
        }
      </div>
    </div>
  )
  
}

const PassengerInfoMemo = memo(PassengerInfo);

function FlightBookProcess({back}) {
  const dispatch = useDispatch();
  const {bookingData} = useSelector((state) => state.flightBooking);
  const data = bookingData;
  const [openDataLoss,setOpenDataLoss] = useState(false);

  const [bookStep,setBookStep] = useState(data.step ? data.step : 0);
  const stepComponents = [
    {label: 'Passenger(s) Information',elem: <PassengerInfoMemo />},
    {label: 'Add Seats',elem: <FlightSeats />},
    {label: 'Select Services',elem: <FlightServices />},
    {label: 'Payment Methods',elem: <Payment />},
  ];

  function handleDataLoss(confirmed) {
    if(confirmed)  
      if(openDataLoss?.callback)
        openDataLoss.callback();
  }


  useEffect(() => {
    function handleUnload(ev) {
      ev?.preventDefault();
      ev.returnValue = "";
        
      return "";
    }

    window.addEventListener('beforeunload', handleUnload)
    window.addEventListener('popstate', handleUnload)

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
      window.removeEventListener('popstate', handleUnload)
    }

    //eslint-disable-next-line
  },[])

  const CurComp = useCallback((props) => {
    return cloneElement(props.elem || <></>,props)
  },[])



  function nextStep(newData = data) {
    if(bookStep+1 >= stepComponents.length) return false;

    dispatch(setBookingData({...newData,step: bookStep+1}));
    setBookStep(bookStep + 1)
  }

  function stepBack() {
    if(bookStep-1 < 0) return false;
    
    dispatch(setBookingData({...data,step: bookStep-1}));
    setBookStep(bookStep - 1)
  }
  

  return (
    <div className=' flex gap-2 w-full items-start '>
      <div className='px-2 sticky top-0'>
        <ArrowBack onClick={() => setOpenDataLoss({callback: back})} className="cursor-pointer" />
      </div>
      <div className='flex flex-col flex-1 gap-5 z-10 sticky bottom-0 self-end min-h-screen '>
        <h5>{stepComponents[bookStep].label}</h5>
        <CustomStepper steps={stepComponents.length} current={bookStep} />
        <div className=' rounded-2xl'>
          <CurComp elem={stepComponents[bookStep].elem} back={bookStep > 0 ? stepBack : null} next={bookStep < stepComponents.length ? nextStep : null} />
        </div>
      </div>
      <div className='flex justify-center flex-1'>
        <FlightOfferDetail onBook obj={data.offer} />
      </div>

      <ModalLocal open={openDataLoss} setOpen={setOpenDataLoss}>
        <div className='card bg-secondary p-10 rounded-md flex flex-col gap-4'>
          <h3>You have unsaved changes, are you sure you want to leave this page?</h3>
          <div className='flex gap-6 justify-between'>
            <button className='btn1' onClick={() => setOpenDataLoss(false)}>Cancel</button>
            <button className='btn2' onClick={() => handleDataLoss(true)}>Continue</button>
          </div>
        </div>
      </ModalLocal>
    </div>
  )
}
