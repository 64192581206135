export const updateSettingData = {
  "pointEarnValueDeposit": "",
  "pointEarnValuePurchase": "",
  "pointRedemptionValue": "",
  "pointGraduationCount": "",
  "enableDoublePoints": false,
  "enableBonusPoints": false,
  "bonusPointsNGNMin": "",
  "bonusPointsEarnValue": "",
  "villageRequirementInPoints": "",
  "cityRequirementInPoints": "",
  "continentalRequirementInPoints": "",
  "globalRequirementInPoints": "",
  "highestRequirementInPoints": "",
}