import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Box, MenuItem, Tab, Tabs } from '@mui/material';
import Pagination from '../../../components/mini/Pagination';
import { setModal, setModalComp } from '../../../redux/reducers/modal/modalSlice';
import DotMenu from '../../../components/mini/DotMenu';
import { def } from '../../../config';
import { Link } from 'react-router-dom';
import getCurrencies from '../../../controllers/settings/currency/getCurrencies';
import RemoveCurrencyModal from './RemoveCurrencyModal';
import getGDSCurrency from '../../../controllers/settings/currency/getGDSCurrency';
import { Edit } from '@mui/icons-material';
import updateGDSCurrency from '../../../controllers/settings/currency/updateGDSCurrency';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';



function ActionCol({params,reload}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();


  function handleRemove() {
    setAnchorEl(null);
    dispatch(setModal(true))
    let id = params.id;
    
    dispatch(setModalComp(<RemoveCurrencyModal returnData={reload} id={id} />))

  }
  return (
    <div className='flex justify-between w-full relative'>
      <span>{params.row.status}</span>
      <DotMenu  anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true}>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
        <MenuItem>
          <Link className='w-full' to={"edit/"+params.id}>Edit</Link>
        </MenuItem>
      </DotMenu>
    </div>
  )
}


const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operatorValue: 'is', value: 'paid'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'pending'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'refund'}]},
];


export default function Index() {
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  const apiRef = useGridApiRef();

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setHeaderTitle('Currency'))

    load();
  },[dispatch])

  async function load() {
    setLoading(true);
    const res = await getCurrencies();
    setLoading(false);
    if(res.return) {
      setData(res.data)
    }
  }

  const columns = [
    {field: 'currencyName',headerName: 'Exchange Currency',flex:1,minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex gap-4 items-center'>
            {params.value}
          </div>
        )
      }
    },
    {field: 'currencyCode',headerName: 'Currency Code',flex: 1,minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col'>
            {params.value}
          </div>
        )
      }
    },
    {field: 'symbol',headerName: 'Symbol',
      renderCell: (params) => (
        params.value
      )
    },
    {field: 'exchangeRateInNaira',headerName: 'Rate',minWidth: 170,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex '>
            {/* {params.row.symbol} */}
            ₦ 
            {params.value}
          </div>
        )
      }
    },
    {field: 'action',headerName: 'Action',flex: 1,minWidth: 130,
      renderCell: (params) => {
        // if(!params.value)
        // return null;
  
        return (
          <ActionCol reload={load} params={params} />
        )
      }
    },
  ];
  
  return (
    <div className='w-full bg-[#f3f3f3] p-4'>
      <div className='w-full bg-secondary p-4 text-primary/80'>
        <div className='flex justify-end pb-4'>
          <Link to="/settings/currency/create" className='btn2'>Add Currency</Link>
        </div>
        <div className='border-b border-primary/30 mb-2'>
          <h4 className='border-b-2 inline-block border-primary'>Exchange Currencies</h4>
        </div>

        <Box className=''>
          <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
            // getRowId={(row) => row.id}
            apiRef={apiRef}
            initialState={{
              sorting: {
                sortModel: [{field: "bookingDate",sort: 'desc'}]
              },
            }}
            filterModel={filter}
            onFilterModelChange={(newFilter) => setFilter(newFilter)}
            
            // slots={{pagination: Pagination}}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}

            // onCellEditStart={params => setRowId(params.id)}
            // hideFooterPagination
            checkboxSelection
          />
          <Pagination perPage={10} total={46} apiRef={apiRef} />
        </Box>
        <GDSExchange />
      </div>
    </div>
  )
}

function GDSExchange() {
  const [data,setData] = useState([])
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();


  useEffect(() => {
    load();
  },[])

  async function load() {
    const res = await getGDSCurrency();
    if(res.return) {
      setData(res.data.data)
    }
  }
  async function updateCurrency(id,exchange_rate) {
    setLoading(true);
    const res = await updateGDSCurrency(id,{exchange_rate});
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','GDS Exchange Updated']))
    } else dispatch(setAlertMsg(['error',res.msg || 'Failed Updating GDS Exchange']))
  }


  return (
    <div className='text-primary flex flex-col gap-4 py-10'>
      <div className='border-b border-primary/30'>
        <h4 className='border-b-2 inline-block border-primary'>GDS Exchange</h4>
      </div>
      {data.map((obj) => (
        <div className='flex gap-4 items-center'>
          <b>{obj.currency_from}</b>
          <InputEditable type='text' loading={loading}
            value={obj.exchange_rate}
            handleReturn={(val) => updateCurrency(obj.id,val)}
            />
        </div>
      ))}
    </div>
  )
}

function InputEditable(props) {
  const {value,loading,handleReturn,...restProps} = props;
  const [data,setData] = useState(value || 0);
  const [disabled,setDisabled] = useState(true);
  const inputRef = useRef();

  useEffect(() => {
    if (!disabled) {
      inputRef.current.focus();
    }
  }, [disabled]);

  return (
    <div className='flex gap-4 py-4 items-center'>
      <div className='border p-4 py-2'>
        <input ref={inputRef} type='text' disabled={disabled}
          value={data}
          onChange={(ev) => setData(ev.target.value)}
          {...restProps} />
        <Edit onClick={() => setDisabled(!disabled)} fontSize='small' className='cursor-pointer' />
      </div>
      <button className={`btn2 ${disabled ? ' hidden ' : ''}`} onClick={() => handleReturn && handleReturn(data)} disabled={loading}>
        {loading?<div className='load'></div>:null}
        update</button>
    </div>
  )
}