import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { customerTemp } from '../../data/customer/customerData';
import PhoneNumberInput from '../form/PhoneNumberInput';
import { Box, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import { updatePassword } from '../../controllers/user/updatePassword';
import { updateProfile } from '../../controllers/user/updateProfile';
import { clone } from '../../features/utils/objClone';
import AgentProfile from './EditAgentProfile';
// import MultipleSelect from '../form/MultiSelect';
// import { updateRole } from '../../controllers/user/updateRole';
import CountriesInput from '../form/CountriesInput';
import RolesInput from '../form/RolesInput';
import ProfilePicture from './ProfilePicture';
import { getMarkup } from '../../controllers/settings/getMarkup';
import FlightServiceCharge from '../serviceCharge/FlightServiceCharge';
import HotelServiceCharge from '../serviceCharge/HotelServiceCharge';
import TourServiceCharge from '../serviceCharge/TourServiceCharge';
import { DataGrid } from '@mui/x-data-grid';
import { AddSharp, Delete, Edit, Remove } from '@mui/icons-material';
import ModalLocal from '../mini/ModalLocal';
import { removeMarkup } from '../../controllers/settings/removeMarkup';
import { getStaffRoles } from '../../controllers/settings/staff/getStaffRoles';
import { updateStaffRole } from '../../controllers/settings/staff/updateStaffRole';
import { updatePin } from '../../controllers/user/updatePin';
import EmailInput from '../form/EmailInput';
import { resetPinRequest } from '../../controllers/user/resetPinRequest';
import { useLocation } from 'react-router-dom';
import { resetPin } from '../../controllers/user/resetPin';
import { verifyOTP } from '../../controllers/user/verifyOTP';
import { adminResetPassword } from '../../controllers/user/adminResetPassword';
import { adminResetPin } from '../../controllers/user/adminResetPin';

export default function EditProfile({userData,reload}) {
  const dispatch = useDispatch();
  const [data,setData] = useState({...customerTemp,confirmPassword: '',newPassword: '',pin: '',newPin: '',confirmPin: '',otp: ''});
  const [loading,setLoading] = useState(false);
  const [passloading,setPassLoading] = useState(false);
  const [pinloading,setPinLoading] = useState(false);
  const [roles,setRoles] = useState([]);
  const [loadingRoles,setLoadingRoles] = useState(false);
  const {user} = useSelector(state => state.user.userData);
  const access = user?.roles?.some(role => ['MANAGE_USERS','ALL']?.includes(role)) || user?.isSuperAdmin


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)

  const [openResetPin,setOpenResetPin] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if(userData) {
      setData({...clone(userData),confirmPassword: '',newPassword: ''})
    }
    loadRoles();
    //eslint-disable-next-line
  },[userData])

  async function loadRoles() {
    setLoadingRoles(true);
    const res = await getStaffRoles();
    setLoadingRoles(false);
    if(res.return) {
      setRoles(res.data)
    }
  }


  async function handleProfileUpdate(ev) {
    ev.preventDefault();

    let {username,...upData} = clone(data);
    
    // console.log(upData);
    upData = Object.fromEntries(
      Object.entries(upData).filter(([key, value]) => value !== null && value !== undefined)
    );
      
    const {email,phoneNumber,gender,companyName,nationality,lastName,firstName,cityName,addressLine1,addressLine2} = upData;


    setLoading(true);
    const res = await updateProfile({email,phoneNumber,gender,companyName,nationality,lastName,firstName,cityName,addressLine1,addressLine2},data.id);
    setLoading(false);
    if(res.return)
      return dispatch(setAlertMsg(['success','Profile updated.']));
    else return dispatch(setAlertMsg(['error',res.msg]))

  }


  async function handlePassword(ev) {
    ev.preventDefault();

    if(data.password === "" ||
      data.newPassword === "") return dispatch(setAlertMsg(['error','Please provide Inputs!']));

    if(data.newPassword !== data.confirmPassword)
      return dispatch(setAlertMsg(['error','Passwords dones\'nt match!']));

    setPassLoading(true);
    const res = await updatePassword({password: data.password,newPassword: data.newPassword},data.id);
    setPassLoading(false);
    if(res.return)
      return dispatch(setAlertMsg(['success','Passwords updated.']));
    else return dispatch(setAlertMsg(['error',res.msg]))
      
  }

  async function handlePin(ev) {
    ev.preventDefault();

    if(data.pin === "" ||
      data.newPin === "") return dispatch(setAlertMsg(['error','Please provide Inputs!']));

    if(data.newPin !== data.confirmPin)
      return dispatch(setAlertMsg(['error','Passwords dones\'nt match!']));

    setPinLoading(true);
    const res = await updatePin({pin: data.pin,newPin: data.newPin},data.id);
    setPinLoading(false);
    if(res.return) {
      setData({...data,pin: '',newPin: '',confirmPin: ''})
      return dispatch(setAlertMsg(['success','Pin Changed.']));
    }
    else return dispatch(setAlertMsg(['error',res.msg]))
      
  }

  async function handleRoleUpdate(id) {
    const res = await updateStaffRole(data.id,id)
    if(res.return)
      return dispatch(setAlertMsg(['success','Role Updated']))
    else return dispatch(setAlertMsg(['error',res.msg]))
  }


  function handleInputChange(obj) {
    setData({...data,...obj})
  }


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [openForgetPin,setOpenForgetPin] = useState(false);
  const [otpConfirmed,setOTPConfirmed] = useState(false);
  const [forgetLoading,setForgetLoading] = useState(false);

  async function handleForgetPin() {
    setForgetLoading(true);
    const res = await resetPinRequest({email: userData?.email})
    setForgetLoading(false);
    if(res.return) {
      setOpenResetPin(true);
      setOTPConfirmed(false);
      setData({...data,pin: '',otp: ''})
      return dispatch(setAlertMsg(['success','Rest Password sent to your email.']))
    }
    else return dispatch(setAlertMsg(['error',res.msg]))
  }

  async function handleResetPin() {
    setForgetLoading(true);
    const res = await resetPin({pin: data.pin},null,otpConfirmed)
    setForgetLoading(false);
    if(res.return) {
      setOpenForgetPin(false);
      return dispatch(setAlertMsg(['success','Password reseted.']))
    } else return dispatch(setAlertMsg(['error',res.msg]))
  }
  async function handleOTP() {
    setForgetLoading(true);
    const res = await verifyOTP({email: userData?.email, otp: data.otp})
    setForgetLoading(false);
    if(res.return) {
      setOTPConfirmed(res?.data?.tempToken)
      return dispatch(setAlertMsg(['success','OTP Confirmed.']))
    } else return dispatch(setAlertMsg(['error',res.msg]))
  }

  // console.log('userData: ',userData,data)

  return (
    <div className='w-full flex flex-col gap-5'>
      <Box className='my-2'>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="General Profile" />
          {["SUB_AGENT","CORPORATE"].includes(data.type) ? 
          <Tab label="Company Profile" />
          :null}
          {data.type === 'CORPORATE' ?
            <Tab label='Service Charge' value={2} />
            :
            <Tab label="Security" value={2} />
          }
          {access ? 
            <Tab label='Reset' value={3} />
          :null}
        </Tabs>
      </Box>
      {tabValue === 0 ? 
      <form action='' onSubmit={handleProfileUpdate} className='inline-block'>
        <div className='flex flex-col gap-6 w-auto'>
          <h2>Update General Profile</h2>

          <div className='flex gap-4'>
            <TextField size='small' label='Email' name='email'
              value={data.email}
              onChange={(ev) => handleInputChange({email: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <PhoneNumberInput label="Phone"
              value={data.phoneNumber}
              onChange={(val) => handleInputChange({phoneNumber: val})}
              />
          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='Gender' name='Gender' select
              value={data.gender}
              onChange={(ev) => handleInputChange({gender: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }}>
                <MenuItem value='MALE'>Male</MenuItem>
                <MenuItem value='FEMALE'>Female</MenuItem>
            </TextField>
            <TextField size='small' label='Company Name' name='companyName'
              value={data.companyName}
              onChange={(ev) => handleInputChange({companyName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <CountriesInput size='small' label='Nationality' name='Nationality'
              value={data.nationality}
              onChange={(val) => handleInputChange({nationality: val.name || val})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div>
            {/* {data.staffRoleId} */}
            <TextField select size='small' label='Role' name='role' className='min-w-[200px]'
              value={data?.staffRoleId || ''}
              onChange={(ev) => handleRoleUpdate(ev.target.value)}
            >
              <MenuItem value=''></MenuItem>
              {roles?.map((obj,i) => (
                <MenuItem key={i} value={obj.id}>{obj.roleName}</MenuItem>
              ))}
            </TextField>
          </div>
          <h6>Contact Person</h6>
          <div className='flex gap-4'>
            <TextField size='small' label='Surname' 
              value={data.lastName}
              onChange={(ev) => handleInputChange({lastName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='First Name' 
              value={data.firstName}
              onChange={(ev) => handleInputChange({firstName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='City' name='city'
              value={data.cityName}
              onChange={(ev) => handleInputChange({cityName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />

          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='Address Line 1' name='addressLine1'
              value={data.addressLine1}
              onChange={(ev) => handleInputChange({addressLine1: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Address Line 2' name='addressLine2'
              value={data.addressLine2}
              onChange={(ev) => handleInputChange({addressLine2: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>

          <button className='btn2 self-start'>
            {loading ? (
              <div className='load'></div>
            ) : null}
            Update Profile</button>
        </div>
      </form>

      
      : tabValue === 1 ?
        ["SUB_AGENT","CORPORATE"].includes(data.type) ? <AgentProfile reload={reload} label={data.type} userData={{...userData,traacAccountCode:userData.traacAccountCode}} /> : null
        
        
      : tabValue === 2 ? // service charge 
        data.type === 'CORPORATE' ? 
          <div className='flex flex-col gap-6'>
            <ServiceCharge userData={data} />
          </div>
        : // update password
        <div className='flex flex-col gap-4 max-w-[800px]'>
          <form action='' onSubmit={handlePassword} className='flex flex-col gap-3 py-6 px-4'>
            <h6>Change Password</h6>
            <div className='flex gap-4'>
              <TextField size='small' label='Password' type='password'
                value={data.password}
                className='flex-1'
                onChange={(ev) => handleInputChange({password: ev.target.value})}
                InputLabelProps={{
                  shrink: true,
                }} />
              <TextField size='small' label='New Password' type='password'
                value={data.newPassword}
                onChange={(ev) => handleInputChange({newPassword: ev.target.value})}
                InputLabelProps={{
                  shrink: true,
                }} />
              <TextField size='small' label='Confirm New Password' type='password'
                value={data.confirmPassword}
                onChange={(ev) => handleInputChange({confirmPassword: ev.target.value})}
                InputLabelProps={{
                  shrink: true,
                }} />

            </div>
            <button className='btn2 self-start'>
              {passloading ? (
                <div className='load'></div>
              ) : null}
              Update Password</button>

          </form>
          <form action='' onSubmit={handlePin} className='flex flex-col gap-3 py-6 px-4'>
            <h6>Change Payment Pin</h6>
            <div className='flex gap-4'>
              <TextField size='small' label='Pin' type='password'
                value={data.password}
                className='flex-1'
                onChange={(ev) => handleInputChange({pin: ev.target.value})}
                InputLabelProps={{
                  shrink: true,
                }} />
              <TextField size='small' label='New Pin' type='password'
                value={data.newPin}
                onChange={(ev) => handleInputChange({newPin: ev.target.value})}
                InputLabelProps={{
                  shrink: true,
                }} />
              <TextField size='small' label='Confirm New Pin' type='password'
                value={data.confirmPin}
                onChange={(ev) => handleInputChange({confirmPin: ev.target.value})}
                InputLabelProps={{
                  shrink: true,
                }} />
            </div>
            <div className='flex gap-4 justify-between'>
              <button className='btn2'>
              {pinloading ? (
                <div className='load self-start'></div>
                ) : null}
                Update Pin</button>

              <button className='px-2' onClick={(ev) => {ev?.preventDefault();setOpenForgetPin(true)}}>Forget Pin?</button>
            </div>
          </form>
          
          <ModalLocal open={openForgetPin} setOpen={setOpenForgetPin}>
            {openResetPin ? 
              otpConfirmed ? 
                <div className='bg-secondary p-4 rounded-lg flex flex-col gap-3'>
                  <TextField size='small' label='New Pin' type='password'
                    value={data.pin}
                    className='flex-1'
                    onChange={(ev) => handleInputChange({pin: ev.target.value})}
                    InputLabelProps={{
                      shrink: true,
                    }} />
                  <div className='flex gap-2'>
                    <button className='border p-2 rounded-md' onClick={(ev) => {ev?.preventDefault(); setOpenResetPin(false)}}>Request Reset</button>
                    <button className='btn2' onClick={handleResetPin}>
                      {forgetLoading?<div className='load'></div> : null}
                      Update Pin</button>
                  </div>
                </div>
              :
              <div className='bg-secondary p-4 rounded-lg flex flex-col gap-3'>
                <TextField size='small' label='OTP' type='password'
                  value={data.otp}
                  className='flex-1'
                  onChange={(ev) => handleInputChange({otp: ev.target.value})}
                  InputLabelProps={{
                    shrink: true,
                  }} />
                <div className='flex gap-2'>
                  <button className='border p-2 rounded-md' onClick={(ev) => {ev?.preventDefault(); setOpenResetPin(false)}}>Request Reset</button>
                  <button className='btn2' onClick={handleOTP}>
                    {forgetLoading?<div className='load'></div> : null}
                    Reset Pin</button>
                </div>
              </div>
            :
              <div className='bg-secondary p-4 rounded-lg flex flex-col gap-3'>
                <EmailInput disabled value={userData?.email} />
                <div className='flex gap-2'>
                  <button className='btn2' onClick={handleForgetPin}>
                    {forgetLoading?<div className='load'></div> : null}
                    Send Reset Pin</button>
                  <button className='border p-2 rounded-md' onClick={(ev) => {ev?.preventDefault(); setOpenResetPin(true)}}>Enter OTP</button>
                </div>
              </div>
            }
          </ModalLocal>
        </div>
      : tabValue === 3 ?
        <AdminReset userData={data} />
      : null}
    </div>
  )
}

function AdminReset({userData}) {
  const {user} = useSelector(state => state.user.userData);
  const [password,setPassword] = useState('');
  const [pin,setPin] = useState('');
  const [open,setOpen] = useState(false);
  const [loading,setLoading] = useState(false);

  const dispatch = useDispatch();

  const userEmail = userData?.email;

  async function handlePasswordReset() {
    setLoading(true);
    const res = await adminResetPassword({userEmail,newPassword: password});
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Password has been reset']))
      setOpen(false)
    } else dispatch(setAlertMsg(['error',res?.msg]))

  }
  async function handlePinReset() {
    setLoading(true);
    const res = await adminResetPin({userEmail,newPin: pin});
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Pin has been reset']))
      setOpen(false)

    } else dispatch(setAlertMsg(['error',res?.msg]))

  }
  async function handleSubmit(ev,{callback}) {
    ev?.preventDefault();
    setOpen({callback})
  }

  const access = user?.roles?.some(role => ['MANAGE_USERS','ALL']?.includes(role)) || user?.isSuperAdmin
  return (
    <div className='p-4 flex flex-col gap-4'>
      <h4>Reset User Password/Pin</h4>

      <div className={`justify-between flex-wrap gap-4 ${access ? 'flex':'hidden'}`}>
        <form onSubmit={(ev) => handleSubmit(ev,{callback: handlePasswordReset})} className='flex gap-4'>
          <TextField size='small' label='New Password' value={password} onChange={(ev) => setPassword(ev.target.value)} />
          <button className='btn_err px-2 text-white rounded-md flex gap-2 items-center'>
            Reset Password</button>
        </form>
        <form onSubmit={(ev) => handleSubmit(ev,{callback: handlePinReset})} className='flex gap-4'>
          <TextField size='small' label='New Pin' value={pin} onChange={(ev) => setPin(ev.target.value)} />
          <button className='btn_err px-2 text-white rounded-md flex gap-2 items-center'>
            Reset Pin</button>
        </form>
        <ModalLocal open={open} setOpen={setOpen}>
          <div className='flex bg-secondary rounded-lg p-6 flex-col gap-4'>
            <h4>Are you sure you want to continue?</h4>
            <div className='flex gap-4'>
              <button className='btn1' onClick={() => setOpen(false)}>Cancel</button>
              <button className='text-white btn_err flex-1 flex gap-2 items-center justify-center' onClick={() => open?.callback && open?.callback()}>
                {loading?<div className='load'></div>:null}
                Continue</button>
            </div>
          </div>
        </ModalLocal>
      </div>
    </div>

  )
}

function ActionCol({params,select,remove}) {
  return (
    <div className='flex justify-evenly gap-2'>
      <Edit className='cursor-pointer text-primary/50 hover:text-primary' onClick={() => params?.id && select(params.id)} />
      <Delete className='cursor-pointer text-red-500/50 hover:text-red-500' onClick={() => params?.id && remove(params.id)} />
    </div>
  )
}

function ServiceCharge({userData}) {
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [selected,setSelected] = useState();
  const [open,setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    loadServiceCharge();
    //eslint-disable-next-line
  },[])

  async function loadServiceCharge() {
    setLoading(true);
    const res = await getMarkup(userData?.id);
    setLoading(false);
    if(res.return) {
      // let data = {};
      // res.data?.data?.map(obj => (data[obj.fareRuleType] = obj));
      if(res?.data?.fareRules)
        setData(res.data.fareRules);
    }
  }

  const columns = [
    {field: 'fareRuleType',headerName: 'Service Type',minWidth: 120,
      renderCell: (params) => (
        <div>
          {
            params.value === 'SERVICE_FEE_FLIGHT' ? 'Flight'
            :
            params.value === 'SERVICE_FEE_TOUR' ? 'Tour'
            :
            params.value === 'SERVICE_FEE_HOTEL' ? 'Hotel'
            : 
            params.value
          }
        </div>
      )
    },
    {field: 'ruleName',headerName: 'Rule Name',flex: 1},
    {field: 'markType',headerName: 'Mark-up Type',minWidth: 200},
    {field: 'markUp',headerName: 'Markup Value',minWidth: 120},
    {field: 'detail',headerName: 'Details',flex: 1,
      renderCell: (params) => (
        <div className=''>
          {params.row?.flightProvider ? 
            <p>Flight Provider: {params?.row?.flightProvider}</p>
          :null}
          {params.row?.flightType ? 
            <p>Flight Type: {params?.row?.flightType}</p>
          :null}
        </div>
      )
    },
    {field: 'action',headerName: 'Action',
      renderCell: (params) => (
        <ActionCol params={params} select={handleEdit} remove={handleRemove} />
      )
    }
  ];

  function handleEdit(id) {
    id && setSelected(data.find(obj => obj.id === id));
  }

  async function handleRemove(id) {
    const res = await removeMarkup(id);
    if(res.return) {
      dispatch(setAlertMsg(['success','Service fee removed.']))
      loadServiceCharge();
    } else dispatch(setAlertMsg(['error',res.msg]))
  }

  return (
    <div className='flex flex-col gap-6'>
      {selected && (
        <div className='flex flex-col gap-4 items-center py-5'>
          <div className='flex flex-col gap-10'>
          <h3>Update selected service</h3>
          {selected.fareRuleType === 'SERVICE_FEE_FLIGHT' ?
            <FlightServiceCharge defaultData={selected} companyData={userData} update handleReturn={loadServiceCharge}/>
            : selected.fareRuleType === 'SERVICE_FEE_HOTEL' ? 
            <HotelServiceCharge defaultData={selected} companyData={userData} update handleReturn={loadServiceCharge}/>
            : selected.fareRuleType === 'SERVICE_FEE_TOUR' ? 
            <TourServiceCharge defaultData={selected} companyData={userData} update handleReturn={loadServiceCharge} />
          :null}
          </div>
        </div>
      )}
      <div className='flex flex-col gap-2'>
        <div className='flex justify-end gap-2 '>
          <button className='btn2' onClick={() => setOpen(true)}>Add Service Fee</button>
        </div>
        <AddServiceFee open={open} setOpen={setOpen} userData={userData} reload={loadServiceCharge} />
        <DataGrid columns={columns} rows={data} loading={loading} autoHeight
          initialState={{
            sorting: {
              sortModel: [{field: "createdDate",sort: 'desc'}]
            },
          }}
          
        />
      </div>
    </div>
  )
}

function AddServiceFee({open,setOpen,userData,reload}) {
  const [type,setType] = useState('SERVICE_FEE_FLIGHT');
  const [loading,setLoading] = useState(false);
  const [initialize,setInitialize] = useState(false);
  
  function handleSubmit() {
    setLoading(true)
    setInitialize(true);
  }
  function handleReturn() {
    setLoading(false);
    reload && reload();
    setInitialize(false)
    setOpen(false);
  }
  return (
    <div>
      <ModalLocal open={open} setOpen={setOpen}>
        <div className='flex flex-col bg-secondary rounded-md p-5 pt-10 items-start gap-4 relative'>
          <div className='btn_close' onClick={() => setOpen(false)}>X</div>
          <TextField select size='small'
            value={type}
            onChange={(ev) => setType(ev.target.value)}
          >
            <MenuItem value='SERVICE_FEE_FLIGHT'>Flight</MenuItem>
            <MenuItem value='SERVICE_FEE_HOTEL'>Hotel</MenuItem>
            <MenuItem value='SERVICE_FEE_TOUR'>Tour</MenuItem>
          </TextField>
          {type === 'SERVICE_FEE_FLIGHT' ?
            <FlightServiceCharge submit={initialize} companyData={userData} handleReturn={() => handleReturn(false)} returnFalse />
            : type === 'SERVICE_FEE_HOTEL' ? 
            <HotelServiceCharge submit={initialize} companyData={userData} handleReturn={() => handleReturn(false)} returnFalse/>
            : type === 'SERVICE_FEE_TOUR' ? 
            <TourServiceCharge submit={initialize} companyData={userData} handleReturn={() => handleReturn(false)} returnFalse/>
          :null}
          <button className='btn2' onClick={handleSubmit}>
            {loading?<div className='load'></div>:null}
            Add Fee
          </button>
        </div>
      </ModalLocal>
    </div>
  )
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}