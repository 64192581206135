import React from 'react'

export default function Pagination({perPage,total,apiRef}) {
  const current = 1;

  return ""
  // return (
  //   <div className='flex justify-between items-center py-4 pag w-full'>
  //     <h6>Showing {perPage} from {total} data</h6>
  //     <div className='flex gap-3 items-center'>
  //       <button className='btn_outlined flex gap-3 '>
  //         <div className='flex'>
  //           <span className='font-bold scale-y-150 '>&lt;</span>
  //           <span className='font-bold scale-y-150 text-[#bebebe] -translate-x-1'>&lt;</span>
  //         </div>
  //         Previous
  //       </button>
        
  //       <div className='bg-gray-200 rounded-xl p-1 flex gap-2 items-center'>
  //         {
  //           [...Array(parseInt(total/perPage))].map((link,ind) => (
  //             <button key={ind} onClick={() => apiRef.current.setPage(ind+1)} className={'w-7 h-7 '+(current === ind+1 ? ' active ':'')}>{ind+1}</button>
  //           ))
  //         }
  //       </div>

  //       <button className='btn_outlined flex gap-3 '>
  //         Next          
  //         <div className='flex'>
  //           <span className='font-bold scale-y-150 text-[#bebebe] translate-x-1'>&gt;</span>
  //           <span className='font-bold scale-y-150 '>&gt;</span>
  //         </div>
  //       </button>
  //     </div>
  //   </div>
  // )
}
