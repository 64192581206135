import { useState } from "react";
import { setModal } from "../../redux/reducers/modal/modalSlice";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../redux/reducers/modal/snackBarSlice";
import { queueTicket } from "../../controllers/flight/queueTicket";

export function RequestTicketModal({id,userId,supplier,type,returnData}) {
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);
  // console.log('supplier',supplier)

  async function handleTicket() {
    setLoading(true);
    let res = {return: false};
    res = await queueTicket(id);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Ticket Request Successful.']))
    }
    else {
      dispatch(setAlertMsg(['error',res.msg || 'Ticket Request Failed!']))      
    }
    
    if(returnData)
      returnData();
    
  }
  return (
    <div className='h-full flex items-center'>
      <div className='bg-secondary p-6 pt-10 flex-col flex gap-4 justify-center relative'>
        <div className='btn_close' onClick={() => dispatch(setModal(false))}>X</div>
        <div>Are you sure you want to request ticket?</div>
        <button className='btn1 self-center flex gap-2' onClick={handleTicket}>
          {loading?(<div className="load"></div>):null}
          Yes, request ticket
        </button>
      </div>
    </div>
  )
}