import { Add, ArrowBack } from '@mui/icons-material'
import { TextField } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { clone } from '../../../features/utils/objClone'
import { useDispatch } from 'react-redux'
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice'
import { currencyReqData } from '../../../data/settings/currency/currencyData'
import createCurrencyReq from '../../../controllers/settings/currency/createCurrency'


export default function CreateCurrency() {
  const [data,setData] = useState(currencyReqData);
  const [loading,setLoading] = useState(false);

  const dispatch = useDispatch();

  async function handleSubmit() {
    
    if (
      data.currencyName === "" ||
      data.currencyCode === "" ||
      data.exchangeRateInNaira === ""
    ) return dispatch(setAlertMsg(['error','Please input all fields!']))
    
    
    setLoading(true);
    const res = await createCurrencyReq(data);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Currency Saved.']))
      setData(currencyReqData);
    } else dispatch(setAlertMsg(['error','Failed Saving Currency']))

  }

  return (
    <div className='w-full bg-[#f3f3f3] p-4'>
      <div className='bg-secondary p-4'>
        <Link to="/settings/currency" className='flex gap-4'>
          <ArrowBack />
          <h3>Add New Exchange Currency</h3>
        </Link>
        <div className='inline-block py-4'>
          <div className='flex flex-col gap-2 w-auto'>
            <TextField size='small' label='Currency Name'
              value={data.currencyName} onChange={(ev) => setData(data => ({...data,currencyName: ev.target.value}))} 
            />
            <TextField size='small' label='Currency Code'
              value={data.currencyCode} onChange={(ev) => setData(data => ({...data,currencyCode: ev.target.value}))} 
            />
            <TextField size='small' label='Currency Symbol'
              value={data.symbol} onChange={(ev) => setData(data => ({...data,symbol: ev.target.value}))} 
            />
            {/* <textarea placeholder='Description' className='min-w-[50%]'></textarea> */}
            <TextField size='small' type='number' label='Amount in Naira'
              value={data.exchangeRateInNaira} onChange={(ev) => setData(d => ({...data,exchangeRateInNaira: ev.target.value}))}
            />
            <button className='btn2 flex gap-2' onClick={handleSubmit}>
              {loading?<div className='load'></div>:null}
              Save Currency</button>
          </div>
        </div>
      </div>
    </div>
  )
}
