import { useEffect, useState } from "react";
import Collapse from "../../mini/Collapse";
import { def } from "../../../config";
import { Slider } from "@mui/material";
import { formatMoney } from "../../../features/utils/formatMoney";

export default function FilterPrice({returnData,data,min,max,clear}) {
  // let {min,max} = {}
  // let nights = 0;
  // data?.map(obj => {
  //   if(!nights)
  //     nights = obj?.nights || 0;
  //   let perNightPrice = Number(obj?.price?.replaceAll(',','') || 0) / Number(obj?.nights)
  //   if(!min || perNightPrice < min)
  //     min = perNightPrice;
  //   else if(!max || perNightPrice > max)
  //     max = perNightPrice

  //   return true;
  // })

  const init = {min: Math.min(min,max) || 0,max: Math.max(min,max) || 2000000}
  const [price,setPrice] = useState(init.min);
  const [minPrice,setMinPrice] = useState(init.min);
  const [maxPrice,setMaxPrice] = useState(init.max);

  useEffect(() => {
    setPrice(init.min);
    //eslint-disable-next-line
  },[clear])

  console.log(init)

  useEffect(() => {
    if(min)
      setMinPrice(min);
    if(max)
      setMaxPrice(max);
  },[min,max])
  console.log(min,max," ----- ",data)

  // const [price,setPrice] = useState(min ? min : 0);
  // const [minPrice,setMinPrice] = useState(min || 20000);
  // const [maxPrice,setMaxPrice] = useState(max || 2000000);

  console.log('min: ',min,max)
  useEffect(() => {
    if(min)
      setMinPrice(min);
    if(max)
      setMaxPrice(max);
  },[min,max])

  function handleChange(ev,val) {
    setPrice(val);
    let obj = {
      price: val,
      // price: nights ? parseInt(val * nights) : val,
      max: maxPrice,
      // nights: nights || 1,
      // max: nights ? parseInt(maxPrice * nights) : maxPrice
    };
    returnData(obj)
    console.log(' ---> ',obj)
  }

  console.log(price,minPrice,maxPrice)
  return (
  <Collapse show label={<h5>Price</h5>}>
    <div className="pt-2">
      <Slider valueLabelDisplay="auto"
        step={parseInt((maxPrice - minPrice) / 50)} min={parseFloat(minPrice)} max={parseFloat(maxPrice)} value={price} onChange={handleChange} color='secondary' size='small' />
      <div className='flex justify-between gap-4'>
        <span>{def.currency}{formatMoney(minPrice, data?.at(0)?.currency)}</span>
        <span>{def.currency}{formatMoney(maxPrice, data?.at(0)?.currency)}</span>
      </div>
    </div>
  </Collapse>

  )
}
