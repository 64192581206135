import React from 'react';

export default function ModalLocal({children,open,setOpen,closable}) {
  return (
    <div className={'fixed top-0 left-0 z-[100] w-full h-full bg-black/25 flex justify-center items-start '+(open ? 'block': 'hidden')} onClick={() => setOpen(false)}>
      <div className='realtive !z-[101] flex self-center' onClick={(ev) => ev.stopPropagation()}>
        {closable ? (
          <div className='bg-secondary rounded-md flex flex-col gap-3 p-5 pt-10 relative overflow-hidden !max-h-[calc(100vh-50px)] '>
            <div className='btn_close' onClick={() => setOpen(false)}>X</div>
            {children}
          </div>
        ): children}
      </div>
    </div>
  )
}
