import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Login from './Login'
import Sidebar from './Sidebar'
import Header from './Header'
import { useSelector } from 'react-redux'
import AlertMsg from './mini/SnackBar'
import Modal from './mini/Modal'
import Footer from './Footer'

export default function Navbar() {
  const {loggedIn} = useSelector((state) => state.user.userData);
  const {comp} = useSelector((state) => state.modal);

  const location = useLocation();

  useEffect(() => {
    const targetId = location.hash.substr(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  
  return loggedIn ? (
    <div className='h-screen max-w-[100vw]'>
      <AlertMsg />
      <Modal>{comp}</Modal>
      <div className='flex min-h-screen h-full overflow-auto'>
        <Sidebar />
        {/* <div className='flex justify-center w-full '> */}
        <div className='flex-1 flex flex-col w-full min-h-full overflow-auto xmax-w-[1000px]'>
          <Header />
          <div className='flex-1 flex justify-center'>
            <Outlet />
          </div>
          {/* <Footer /> */}
        </div>
        {/* </div> */}
      </div>
    </div>
  ) : (
    <div className='flex flex-col min-h-screen'>
      {/* <div className='bg-primary p-2'>
        <h2 className='text-secondary p-2'>TBS</h2>
      </div> */}
      <div className='flex-1 flex justify-center items-center '>
        <Login />
      </div>
    </div>
  )
}
