import Search from '../Search/Index'

export default function Index() {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center ">
      <div className='overflow-auto max-w-full max-h-full'>
        <Search newWindow />
      </div>
    </div>
  )
}