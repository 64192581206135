import React from 'react'
import ModalLocal from './mini/ModalLocal';
import Collapse from './mini/Collapse';
import { Cancel } from '@mui/icons-material';

export default function Tester() {
    console.log(res)
    const data = res.data;

    function getType(type) {
        if(type === 'ADT')
            return 'Adult';
        else if(type === 'CHD')
            return 'Child';
        else if(type === 'INF')
            return 'Infant';
        return type;
    }
  return (
    <div>
        <ModalLocal open={true} setOpen={() => false}>
            <div className='py-4 w-[550px] max-w-full bg-secondary rounded-md h-full max-h-screen overflow-auto flex flex-col gap-4'>
                <div className='flex justify-between items-center gap-4 relative p-4'>
                    <h5>Fare Rules</h5>
                    <Cancel />
                    {/* <div className='rounded-full w-5 h-5 bg-primary text-secondary flex items-center justify-center' onClick={() => false}>X</div> */}
                </div>
                {data.map((type,i) => (
                    <Collapse key={i}
                        className='relative'
                        labelClass='sticky top-0 bg-secondary px-4 '
                        label={
                            <div className='flex flex-col'>
                                <h5>{getType(type.PTC)}</h5>
                                <h5>
                                    {type?.detailFareRule?.Rules?.Departure?.AirportCode?.value}
                                    -
                                    {type?.detailFareRule?.Rules?.Arrival?.AirportCode?.value}
                                </h5>
                            </div>
                        }
                    >
                        <div className='px-4 '>
                            {type?.detailFareRule?.Rules?.Rule?.map((Rule,id) => (
                                <div key={id} className='flex flex-wrap gap-3 '>
                                    {Rule.Text?.map((text,i) => (
                                        <div key={i}>{text}</div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </Collapse>
                ))}
            </div>
        </ModalLocal>
    </div>
  )
}


const res = {
    "success": true,
    "data": [
      {
        "PTC": "ADT",
        "travelerReferences": [
          "T1",
          "T2"
        ],
        "originDestinationIndex": "0",
        "segmentsIndex": [
          0,
          1
        ],
        "detailFareRule": {
          "Rules": {
            "Departure": {
              "AirportCode": {
                "value": "NBO"
              },
              "Date": "2023-12-18T00:00:00.000"
            },
            "Arrival": {
              "AirportCode": {
                "value": "DXB"
              }
            },
            "FareBasisCode": {
              "Code": "QHSOPKE1"
            },
            "AirlineID": {
              "value": "EK"
            },
            "FareCode": {
              "Code": "PUBL"
            },
            "Rule": [
              {
                "FareRuleCategory": {
                  "Code": "APP"
                },
                "Text": [
                  "FOR -PKE1 TYPE FARES",
                  "EMIRATES SAVER FARES",
                  "CAPACITY LIMITATIONS",
                  "THE CARRIER SHALL LIMIT THE NUMBER OF PASSENGERS CARRIED",
                  "ON ANY ONE FLIGHT AT FARES GOVERNED BY THIS RULE AND SUCH",
                  "FARES WILL NOT NECESSARILY BE AVAILABLE ON ALL FLIGHTS.",
                  "THE NUMBER OF SEATS WHICH THE CARRIER SHALL MAKE",
                  "AVAILABLE ON A GIVEN FLIGHT WILL BE DETERMINED BY THE",
                  "CARRIERS BEST JUDGMENT",
                  "OTHER CONDITIONS",
                  "ALL FARES ARE SUBJECT TO CHANGE OR WITHDRAWAL",
                  "WITHOUT NOTICE.",
                  "-----",
                  "FARES ARE ONLY GUARANTEED IF AUTOPRICED AND",
                  "TICKETED IN GDS ON THE SAME DAY.",
                  "TARIFF-023 RULE-KET5"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "SEA"
                },
                "Text": [
                  "BETWEEN AREA 2 AND EUROPE FOR HIGH SEASON FARES",
                  "PERMITTED 19AUG THROUGH 27AUG OR 15DEC THROUGH 24DEC ON",
                  "THE FIRST INTERNATIONAL SECTOR."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "FLT"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "EI FLIGHTS 5000 THROUGH 9999",
                  "AH FLIGHTS 3900 THROUGH 3999",
                  "KC FLIGHTS 350 THROUGH 355",
                  "KC FLIGHTS 577 THROUGH 578",
                  "KC FLIGHTS 697 THROUGH 698",
                  "KC FLIGHTS 1102 THROUGH 1199",
                  "KC FLIGHTS 1217 THROUGH 1297",
                  "KC FLIGHTS 1301 THROUGH 1308",
                  "KC FLIGHTS 1350 THROUGH 1355",
                  "KC FLIGHTS 1405 THROUGH 1407",
                  "KC FLIGHTS 1647 THROUGH 1649",
                  "KC FLIGHTS 1931 THROUGH 1938",
                  "KC FLIGHT 1924",
                  "KC FLIGHT 1961",
                  "KC FLIGHT 1963",
                  "BT FLIGHTS 5000 THROUGH 7999",
                  "SB FLIGHTS 0001 THROUGH 0099",
                  "SB FLIGHTS 900 THROUGH 9999",
                  "CA FLIGHTS 1151 THROUGH 1200",
                  "CA FLIGHTS 3000 THROUGH 3999",
                  "CA FLIGHTS 4075 THROUGH 4098",
                  "CA FLIGHTS 4600 THROUGH 4990",
                  "CA FLIGHTS 5000 THROUGH 9999",
                  "NX FLIGHTS 1000 THROUGH 9999",
                  "SW FLIGHTS 2000 THROUGH 9999",
                  "PX FLIGHTS 3005 THROUGH 3006",
                  "PX FLIGHTS 3040 THROUGH 3047",
                  "HM FLIGHTS 5000 THROUGH 7899",
                  "TN FLIGHTS 1000 THROUGH 2099",
                  "TN FLIGHTS 2200 THROUGH 9999",
                  "B2 FLIGHTS 0001 THROUGH 0708",
                  "FB FLIGHTS 1000 THROUGH 1999",
                  "K6 FLIGHTS 2000 THROUGH 3999",
                  "CX FLIGHTS 1000 THROUGH 1999",
                  "CX FLIGHTS 4000 THROUGH 7999",
                  "CX FLIGHTS 9000 THROUGH 9999",
                  "KA FLIGHTS 1000 THROUGH 9999",
                  "OU FLIGHTS 5000 THROUGH 5999",
                  "OK FLIGHTS 3000 THROUGH 5999",
                  "MS FLIGHTS 8000 THROUGH 9999",
                  "ET FLIGHTS 0001 THROUGH 0019",
                  "ET FLIGHTS 1000 THROUGH 1899",
                  "ET FLIGHTS 4000 THROUGH 4699",
                  "BR FLIGHTS 2001 THROUGH 3999",
                  "IE FLIGHTS 0704 THROUGH 0709",
                  "IE FLIGHTS 0712 THROUGH 0713",
                  "HA FLIGHTS 1900 THROUGH 9999",
                  "FJ FLIGHTS 500 THROUGH 599",
                  "FJ FLIGHTS 5000 THROUGH 6999.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LO FLIGHTS 4000 THROUGH 9999",
                  "LG FLIGHTS 1000 THROUGH 1999",
                  "ME FLIGHTS 1000 THROUGH 9999",
                  "AI FLIGHTS 3000 THROUGH 9999",
                  "PW FLIGHTS 2000 THROUGH 6999",
                  "BI FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 6100 THROUGH 6199",
                  "SK FLIGHTS 6400 THROUGH 6999",
                  "SK FLIGHTS 8000 THROUGH 9999",
                  "VN FLIGHTS 2000 THROUGH 4999",
                  "VN FLIGHTS 6000 THROUGH 6999",
                  "VS FLIGHTS 1000 THROUGH 9999",
                  "W2 FLIGHTS 0001 THROUGH 6199",
                  "W2 FLIGHTS 7250 THROUGH 9999",
                  "8M FLIGHTS 4000 THROUGH 4999",
                  "8M FLIGHTS 5000 THROUGH 5999",
                  "8M FLIGHTS 6000 THROUGH 6999",
                  "8M FLIGHTS 7000 THROUGH 7999",
                  "8M FLIGHTS 9500 THROUGH 9599",
                  "A3 FLIGHTS 1000 THROUGH 7099",
                  "A3 FLIGHTS 8000 THROUGH 9999",
                  "AR FLIGHTS 001 THROUGH 1099",
                  "AR FLIGHTS 1950 THROUGH 2999",
                  "AR FLIGHTS 7000 THROUGH 7999",
                  "LX FLIGHTS 3000 THROUGH 4999",
                  "LX FLIGHTS 7400 THROUGH 7499",
                  "NH FLIGHTS 2210 THROUGH 3200",
                  "NH FLIGHTS 3610 THROUGH 4840",
                  "NH FLIGHTS 5001 THROUGH 9999",
                  "UX FLIGHTS 2000 THROUGH 2999",
                  "UX FLIGHTS 3000 THROUGH 3999",
                  "4Z FLIGHTS 8000 THROUGH 8999",
                  "RQ FLIGHTS 1000 THROUGH 9999",
                  "CZ FLIGHTS 000 THROUGH 299",
                  "CZ FLIGHTS 501 THROUGH 599",
                  "CZ FLIGHTS 701 THROUGH 799",
                  "CZ FLIGHTS 2000 THROUGH 2899",
                  "CZ FLIGHTS 2900 THROUGH 2999",
                  "CZ FLIGHTS 1001 THROUGH 1999",
                  "CZ FLIGHTS 4001 THROUGH 4999",
                  "CZ FLIGHTS 7001 THROUGH 7999",
                  "CZ FLIGHTS 9000 THROUGH 9999",
                  "PC FLIGHTS 7500 THROUGH 7999",
                  "QV FLIGHTS 4000 THROUGH 4999",
                  "OD FLIGHTS 9000 THROUGH 9999",
                  "LA FLIGHTS 1510 THROUGH 1949",
                  "LA FLIGHTS 4800 THROUGH 4910.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 8300 THROUGH 8999",
                  "KQ FLIGHTS 1000 THROUGH 9999",
                  "P0 FLIGHTS 8000 THROUGH 8999",
                  "5Z FLIGHTS 8000 THROUGH 8999",
                  "RJ FLIGHTS 9000 THROUGH 9999",
                  "RJ FLIGHTS 6000 THROUGH 7999",
                  "RJ FLIGHTS 2000 THROUGH 4999",
                  "WY FLIGHTS 5000 THROUGH 7000",
                  "AD FLIGHTS 9900 THROUGH 9999",
                  "AD FLIGHTS 9400 THROUGH 9599",
                  "AD FLIGHTS 6000 THROUGH 7999",
                  "AD FLIGHTS 2000 THROUGH 2099",
                  "PK FLIGHTS 1860 THROUGH 1895",
                  "KP FLIGHTS 1000 THROUGH 9999",
                  "IB FLIGHTS 7000 THROUGH 7999",
                  "IB FLIGHTS 5000 THROUGH 5999",
                  "IB FLIGHTS 4000 THROUGH 4999",
                  "IB FLIGHTS 1027 THROUGH 2699",
                  "IB FLIGHTS 0980 THROUGH 0999",
                  "IB FLIGHTS 0658 THROUGH 0687",
                  "IB FLIGHTS 0050 THROUGH 0399",
                  "AC FLIGHTS 9000 THROUGH 9999",
                  "AC FLIGHTS 2500 THROUGH 6999",
                  "QF FLIGHTS 300 THROUGH 349",
                  "EK FLIGHTS 7434 THROUGH 7437",
                  "EK FLIGHTS 3470 THROUGH 3471",
                  "EK FLIGHTS 3482 THROUGH 3483",
                  "ET FLIGHTS 0060 THROUGH 0099",
                  "WB FLIGHTS 1000 THROUGH 9999",
                  "AV FLIGHTS 6660 THROUGH 6999",
                  "AV FLIGHTS 6000 THROUGH 6639",
                  "AV FLIGHTS 4300 THROUGH 4799",
                  "AV FLIGHTS 2000 THROUGH 2699.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 806 THROUGH 807",
                  "LA FLIGHTS 5041 THROUGH 7204",
                  "LA FLIGHTS 7405 THROUGH 7599",
                  "LA FLIGHTS 7228 THROUGH 7777",
                  "LA FLIGHTS 4966 THROUGH 5020",
                  "UL FLIGHTS 001 THROUGH 099",
                  "UL FLIGHTS 2000 THROUGH 4999",
                  "UL FLIGHTS 5000 THROUGH 5099",
                  "UL FLIGHTS 6000 THROUGH 6099",
                  "UL FLIGHTS 7000 THROUGH 7099",
                  "UL FLIGHTS 8000 THROUGH 8099",
                  "UL FLIGHTS 9000 THROUGH 9999",
                  "PK FLIGHTS 4000 THROUGH 5999",
                  "PK FLIGHTS 1888 THROUGH 1889",
                  "3U FLIGHTS 7000 THROUGH 7999",
                  "3U FLIGHTS 4000 THROUGH 5999",
                  "3U FLIGHTS 1000 THROUGH 2999",
                  "DE FLIGHTS 3000 THROUGH 9999",
                  "QF FLIGHTS 386 THROUGH 399",
                  "AV FLIGHTS 2851 THROUGH 2999",
                  "SY FLIGHTS 2000 THROUGH 9999",
                  "AY FLIGHTS 2501 THROUGH 7000",
                  "AY FLIGHTS 7501 THROUGH 9999",
                  "SA FLIGHTS 7702 THROUGH 7949",
                  "SA FLIGHTS 7000 THROUGH 7699",
                  "AZ FLIGHTS 7000 THROUGH 7999",
                  "AZ FLIGHTS 4100 THROUGH 5999",
                  "AZ FLIGHTS 2400 THROUGH 3999",
                  "WF FLIGHTS 020 THROUGH 029",
                  "WF FLIGHT 144",
                  "WF FLIGHTS 155 THROUGH 156",
                  "WF FLIGHT 175",
                  "WF FLIGHT 178",
                  "PR FLIGHTS 3004 THROUGH 3999.",
                  "AND",
                  "IF THE FARE COMPONENT INCLUDES TRAVEL WITHIN AREA 2",
                  "THEN THAT TRAVEL MUST BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "ANY 9B FLIGHT OPERATED BY 9B",
                  "ANY LM FLIGHT OPERATED BY LM",
                  "ANY QS FLIGHT OPERATED BY QS",
                  "ANY EI FLIGHT OPERATED BY EI",
                  "ANY BT FLIGHT OPERATED BY BT",
                  "ANY EK FLIGHT OPERATED BY BT",
                  "ANY B2 FLIGHT OPERATED BY B2",
                  "ANY FB FLIGHT OPERATED BY FB",
                  "ANY OU FLIGHT OPERATED BY OU",
                  "ANY OK FLIGHT OPERATED BY OK",
                  "ANY LO FLIGHT OPERATED BY LO",
                  "ANY LG FLIGHT OPERATED BY LG",
                  "ANY SK FLIGHT OPERATED BY SK",
                  "ANY WF FLIGHT OPERATED BY WF",
                  "ANY A3 FLIGHT OPERATED BY A3",
                  "ANY GQ FLIGHT OPERATED BY GQ",
                  "ANY A3 FLIGHT OPERATED BY OA",
                  "ANY UX FLIGHT OPERATED BY UX",
                  "ANY FI FLIGHT OPERATED BY FI",
                  "ANY PC FLIGHT OPERATED BY PC",
                  "ANY W2 FLIGHT OPERATED BY W2",
                  "ANY W2 FLIGHT OPERATED BY 2A",
                  "ANY AH FLIGHT OPERATED BY AH",
                  "ANY UU FLIGHT OPERATED BY UU",
                  "ANY AT FLIGHT OPERATED BY AT",
                  "ANY TU FLIGHT OPERATED BY TU",
                  "ANY LH FLIGHT OPERATED BY EN",
                  "ANY LH FLIGHT OPERATED BY CL",
                  "ANY LH FLIGHT OPERATED BY 2A",
                  "ANY LH FLIGHT OPERATED BY LH",
                  "ANY IB FLIGHT OPERATED BY I2",
                  "ANY IB FLIGHT OPERATED BY YW",
                  "ANY IB FLIGHT OPERATED BY IB",
                  "ANY EK FLIGHT OPERATED BY 7T",
                  "ANY 7T FLIGHT OPERATED BY 7T",
                  "ANY 2C FLIGHT OPERATED BY 2C",
                  "ANY EK FLIGHT OPERATED BY 2C",
                  "ANY TP FLIGHT OPERATED BY TP",
                  "ANY EK FLIGHT OPERATED BY TP",
                  "ANY KM FLIGHT OPERATED BY KM",
                  "ANY EK FLIGHT OPERATED BY S7",
                  "ANY S7 FLIGHT OPERATED BY S7",
                  "ANY VY FLIGHT OPERATED BY VY",
                  "ANY DX FLIGHT OPERATED BY DX",
                  "ANY LX FLIGHT OPERATED BY LX",
                  "ANY BP FLIGHT OPERATED BY BP",
                  "ANY 2J FLIGHT OPERATED BY 2J",
                  "ANY EK FLIGHT OPERATED BY EK",
                  "ANY FZ FLIGHT OPERATED BY FZ",
                  "ANY EK FLIGHT OPERATED BY FZ.",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "EI FLIGHTS 5000 THROUGH 9999",
                  "AH FLIGHTS 3900 THROUGH 3999",
                  "KC FLIGHTS 350 THROUGH 355",
                  "KC FLIGHTS 577 THROUGH 578",
                  "KC FLIGHTS 697 THROUGH 698",
                  "KC FLIGHTS 1102 THROUGH 1199",
                  "KC FLIGHTS 1217 THROUGH 1297",
                  "KC FLIGHTS 1301 THROUGH 1308",
                  "KC FLIGHTS 1350 THROUGH 1355",
                  "KC FLIGHTS 1405 THROUGH 1407",
                  "KC FLIGHTS 1647 THROUGH 1649",
                  "KC FLIGHTS 1931 THROUGH 1938",
                  "KC FLIGHT 1924",
                  "KC FLIGHT 1961",
                  "KC FLIGHT 1963",
                  "BT FLIGHTS 5000 THROUGH 7999",
                  "SB FLIGHTS 0001 THROUGH 0099",
                  "SB FLIGHTS 900 THROUGH 9999",
                  "CA FLIGHTS 1151 THROUGH 1200",
                  "CA FLIGHTS 3000 THROUGH 3999",
                  "CA FLIGHTS 4075 THROUGH 4098",
                  "CA FLIGHTS 4600 THROUGH 4990",
                  "CA FLIGHTS 5000 THROUGH 9999",
                  "NX FLIGHTS 1000 THROUGH 9999",
                  "SW FLIGHTS 2000 THROUGH 9999",
                  "PX FLIGHTS 3005 THROUGH 3006",
                  "PX FLIGHTS 3040 THROUGH 3047",
                  "HM FLIGHTS 5000 THROUGH 7899",
                  "TN FLIGHTS 1000 THROUGH 2099",
                  "TN FLIGHTS 2200 THROUGH 9999",
                  "B2 FLIGHTS 0001 THROUGH 0708",
                  "FB FLIGHTS 1000 THROUGH 1999",
                  "K6 FLIGHTS 2000 THROUGH 3999",
                  "CX FLIGHTS 1000 THROUGH 1999",
                  "CX FLIGHTS 4000 THROUGH 7999",
                  "CX FLIGHTS 9000 THROUGH 9999",
                  "KA FLIGHTS 1000 THROUGH 9999",
                  "OU FLIGHTS 5000 THROUGH 5999",
                  "OK FLIGHTS 3000 THROUGH 5999",
                  "MS FLIGHTS 8000 THROUGH 9999",
                  "ET FLIGHTS 0001 THROUGH 0019",
                  "ET FLIGHTS 1000 THROUGH 1899",
                  "ET FLIGHTS 4000 THROUGH 4699",
                  "BR FLIGHTS 2001 THROUGH 3999",
                  "IE FLIGHTS 0704 THROUGH 0709",
                  "IE FLIGHTS 0712 THROUGH 0713",
                  "HA FLIGHTS 1900 THROUGH 9999",
                  "FJ FLIGHTS 500 THROUGH 599",
                  "FJ FLIGHTS 5000 THROUGH 6999.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LO FLIGHTS 4000 THROUGH 9999",
                  "LG FLIGHTS 1000 THROUGH 1999",
                  "ME FLIGHTS 1000 THROUGH 9999",
                  "AI FLIGHTS 3000 THROUGH 9999",
                  "PW FLIGHTS 2000 THROUGH 6999",
                  "BI FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 6100 THROUGH 6199",
                  "SK FLIGHTS 6400 THROUGH 6999",
                  "SK FLIGHTS 8000 THROUGH 9999",
                  "VN FLIGHTS 2000 THROUGH 4999",
                  "VN FLIGHTS 6000 THROUGH 6999",
                  "VS FLIGHTS 1000 THROUGH 9999",
                  "W2 FLIGHTS 0001 THROUGH 6199",
                  "W2 FLIGHTS 7250 THROUGH 9999",
                  "8M FLIGHTS 4000 THROUGH 4999",
                  "8M FLIGHTS 5000 THROUGH 5999",
                  "8M FLIGHTS 6000 THROUGH 6999",
                  "8M FLIGHTS 7000 THROUGH 7999",
                  "8M FLIGHTS 9500 THROUGH 9599",
                  "A3 FLIGHTS 1000 THROUGH 7099",
                  "A3 FLIGHTS 8000 THROUGH 9999",
                  "AR FLIGHTS 001 THROUGH 1099",
                  "AR FLIGHTS 1950 THROUGH 2999",
                  "AR FLIGHTS 7000 THROUGH 7999",
                  "LX FLIGHTS 3000 THROUGH 4999",
                  "LX FLIGHTS 7400 THROUGH 7499",
                  "NH FLIGHTS 2210 THROUGH 3200",
                  "NH FLIGHTS 3610 THROUGH 4840",
                  "NH FLIGHTS 5001 THROUGH 9999",
                  "UX FLIGHTS 2000 THROUGH 2999",
                  "UX FLIGHTS 3000 THROUGH 3999",
                  "4Z FLIGHTS 8000 THROUGH 8999",
                  "RQ FLIGHTS 1000 THROUGH 9999",
                  "CZ FLIGHTS 000 THROUGH 299",
                  "CZ FLIGHTS 501 THROUGH 599",
                  "CZ FLIGHTS 701 THROUGH 799",
                  "CZ FLIGHTS 2000 THROUGH 2899",
                  "CZ FLIGHTS 2900 THROUGH 2999",
                  "CZ FLIGHTS 1001 THROUGH 1999",
                  "CZ FLIGHTS 4001 THROUGH 4999",
                  "CZ FLIGHTS 7001 THROUGH 7999",
                  "CZ FLIGHTS 9000 THROUGH 9999",
                  "PC FLIGHTS 7500 THROUGH 7999",
                  "QV FLIGHTS 4000 THROUGH 4999",
                  "OD FLIGHTS 9000 THROUGH 9999",
                  "LA FLIGHTS 1510 THROUGH 1949",
                  "LA FLIGHTS 4800 THROUGH 4910.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 8300 THROUGH 8999",
                  "KQ FLIGHTS 1000 THROUGH 9999",
                  "P0 FLIGHTS 8000 THROUGH 8999",
                  "5Z FLIGHTS 8000 THROUGH 8999",
                  "RJ FLIGHTS 9000 THROUGH 9999",
                  "RJ FLIGHTS 6000 THROUGH 7999",
                  "RJ FLIGHTS 2000 THROUGH 4999",
                  "WY FLIGHTS 5000 THROUGH 7000",
                  "AD FLIGHTS 9900 THROUGH 9999",
                  "AD FLIGHTS 9400 THROUGH 9599",
                  "AD FLIGHTS 6000 THROUGH 7999",
                  "AD FLIGHTS 2000 THROUGH 2099",
                  "PK FLIGHTS 1860 THROUGH 1895",
                  "KP FLIGHTS 1000 THROUGH 9999",
                  "IB FLIGHTS 7000 THROUGH 7999",
                  "IB FLIGHTS 5000 THROUGH 5999",
                  "IB FLIGHTS 4000 THROUGH 4999",
                  "IB FLIGHTS 1027 THROUGH 2699",
                  "IB FLIGHTS 0980 THROUGH 0999",
                  "IB FLIGHTS 0658 THROUGH 0687",
                  "IB FLIGHTS 0050 THROUGH 0399",
                  "AC FLIGHTS 9000 THROUGH 9999",
                  "AC FLIGHTS 2500 THROUGH 6999",
                  "QF FLIGHTS 300 THROUGH 349",
                  "EK FLIGHTS 7434 THROUGH 7437",
                  "EK FLIGHTS 3470 THROUGH 3471",
                  "EK FLIGHTS 3482 THROUGH 3483",
                  "ET FLIGHTS 0060 THROUGH 0099",
                  "WB FLIGHTS 1000 THROUGH 9999",
                  "AV FLIGHTS 6660 THROUGH 6999",
                  "AV FLIGHTS 6000 THROUGH 6639",
                  "AV FLIGHTS 4300 THROUGH 4799",
                  "AV FLIGHTS 2000 THROUGH 2699.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 806 THROUGH 807",
                  "LA FLIGHTS 5041 THROUGH 7204",
                  "LA FLIGHTS 7405 THROUGH 7599",
                  "LA FLIGHTS 7228 THROUGH 7777",
                  "LA FLIGHTS 4966 THROUGH 5020",
                  "UL FLIGHTS 001 THROUGH 099",
                  "UL FLIGHTS 2000 THROUGH 4999",
                  "UL FLIGHTS 5000 THROUGH 5099",
                  "UL FLIGHTS 6000 THROUGH 6099",
                  "UL FLIGHTS 7000 THROUGH 7099",
                  "UL FLIGHTS 8000 THROUGH 8099",
                  "UL FLIGHTS 9000 THROUGH 9999",
                  "PK FLIGHTS 4000 THROUGH 5999",
                  "PK FLIGHTS 1888 THROUGH 1889",
                  "3U FLIGHTS 7000 THROUGH 7999",
                  "3U FLIGHTS 4000 THROUGH 5999",
                  "3U FLIGHTS 1000 THROUGH 2999",
                  "DE FLIGHTS 3000 THROUGH 9999",
                  "QF FLIGHTS 386 THROUGH 399",
                  "AV FLIGHTS 2851 THROUGH 2999",
                  "SY FLIGHTS 2000 THROUGH 9999",
                  "AY FLIGHTS 2501 THROUGH 7000",
                  "AY FLIGHTS 7501 THROUGH 9999",
                  "SA FLIGHTS 7702 THROUGH 7949",
                  "SA FLIGHTS 7000 THROUGH 7699",
                  "AZ FLIGHTS 7000 THROUGH 7999",
                  "AZ FLIGHTS 4100 THROUGH 5999",
                  "AZ FLIGHTS 2400 THROUGH 3999",
                  "WF FLIGHTS 020 THROUGH 029",
                  "WF FLIGHT 144",
                  "WF FLIGHTS 155 THROUGH 156",
                  "WF FLIGHT 175",
                  "WF FLIGHT 178",
                  "PR FLIGHTS 3004 THROUGH 3999.",
                  "AND",
                  "IF THE FARE COMPONENT INCLUDES TRAVEL WITHIN AREA 2",
                  "THEN THAT TRAVEL MUST BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "ANY P0 FLIGHT OPERATED BY P0",
                  "ANY FA FLIGHT OPERATED BY FA",
                  "ANY HM FLIGHT OPERATED BY HM",
                  "ANY ET FLIGHT OPERATED BY ET",
                  "ANY TM FLIGHT OPERATED BY TM",
                  "ANY PW FLIGHT OPERATED BY PW",
                  "ANY KP FLIGHT OPERATED BY KP",
                  "ANY UR FLIGHT OPERATED BY UR",
                  "ANY HF FLIGHT OPERATED BY HF",
                  "ANY AW FLIGHT OPERATED BY AW",
                  "ANY ET FLIGHT OPERATED BY 3W",
                  "ANY WB FLIGHT OPERATED BY WB",
                  "ANY KQ FLIGHT OPERATED BY KQ",
                  "ANY 5Z FLIGHT OPERATED BY 5Z",
                  "ANY SA FLIGHT OPERATED BY SA",
                  "ANY MK FLIGHT OPERATED BY MK",
                  "ANY EK FLIGHT OPERATED BY MK",
                  "ANY 4Z FLIGHT OPERATED BY 4Z",
                  "ANY TC FLIGHT OPERATED BY TC",
                  "ANY EK FLIGHT OPERATED BY 4Z",
                  "ANY SS FLIGHT OPERATED BY SS",
                  "ANY EK FLIGHT OPERATED BY EK",
                  "ANY FZ FLIGHT OPERATED BY FZ",
                  "ANY EK FLIGHT OPERATED BY FZ",
                  "ANY FN FLIGHT OPERATED BY FN",
                  "ANY L6 FLIGHT OPERATED BY L6",
                  "ANY SK FLIGHT OPERATED BY SK",
                  "ANY AY FLIGHT OPERATED BY AY",
                  "ANY AZ FLIGHT OPERATED BY AZ",
                  "ANY A3 FLIGHT OPERATED BY A3",
                  "ANY EK FLIGHT OPERATED BY A3",
                  "ANY EK FLIGHT OPERATED BY AT",
                  "ANY EK FLIGHT OPERATED BY 9B",
                  "ANY EK FLIGHT OPERATED BY OA",
                  "ANY DE FLIGHT OPERATED BY DE."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "RES"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "RESERVATIONS ARE REQUIRED FOR ALL SECTORS.",
                  "WAITLIST NOT PERMITTED.",
                  "NOTE -",
                  "EK RESERVES THE RIGHT TO CANCEL ANY PNR WHERE",
                  "ITINERARIES DO NOT COMPLY WITH RESERVATION",
                  "BOOKING CODE /RBD/.",
                  "---",
                  "IF A TICKET IS USED OUT OF SEQUENCE EK RESERVES",
                  "THE RIGHT TO CANCEL ANY PNR OR INVALIDATE THE",
                  "REMAINING FLIGHT COUPONS.",
                  "---",
                  "IF A PASSENGER FAILS TO TRAVEL ON A SECTOR BOOKED",
                  "EK RESERVES THE RIGHT TO CANCEL REMAINING EK",
                  "SECTORS."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "MIN"
                },
                "Text": [
                  "NONE FOR ONE WAY FARES"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "MAX"
                },
                "Text": [
                  "NONE FOR ONE WAY FARES"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "STP"
                },
                "Text": [
                  "BETWEEN AREA 2 AND EUROPE FOR QHSOPKE1 TYPE FARES",
                  "UNLIMITED STOPOVERS PERMITTED IN EACH DIRECTION",
                  "LIMITED TO 2 FREE AND UNLIMITED AT USD 100.00 EACH.",
                  "1 FREE IN DXB IN EACH DIRECTION",
                  "UNLIMITED IN EACH DIRECTION AT USD 100.00 EACH.",
                  "A STOPOVER MAY NOT EXCEED 21 DAYS.",
                  "NOTE -",
                  "CHILD/INFANT DISCOUNT DOES NOT APPLY."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "TRF"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "UNLIMITED TRANSFERS PERMITTED ON THE PRICING UNIT",
                  "FARE BREAK AND EMBEDDED SURFACE SECTORS NOT PERMITTED ON",
                  "THE FARE COMPONENT.",
                  "NOTE -",
                  "AS PER SPECIFIED PUBLISHED ROUTING ATTACHED.",
                  "SURFACE SECTORS NOT PERMITTED."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "CMB"
                },
                "Text": [
                  "FOR ONE WAY FARES",
                  "SINGLE/DOUBLE OPEN JAWS/ROUND TRIPS/CIRCLE TRIPS NOT",
                  "PERMITTED.",
                  "APPLICABLE ADD-ON CONSTRUCTION IS ADDRESSED IN",
                  "MISCELLANEOUS PROVISIONS - CATEGORY 23.",
                  "END-ON-END",
                  "END-ON-END COMBINATIONS NOT PERMITTED. VALIDATE ALL FARE",
                  "COMPONENTS. SIDE TRIPS PERMITTED.",
                  "PROVIDED -",
                  "COMBINATIONS ARE FOR CARRIER EK/FZ/B6",
                  "COMBINATIONS ARE FOR ANY CARRIER FROM/TO DEPARTURE FROM",
                  "JOURNEY ORIGIN IN AREA 1",
                  "COMBINATIONS ARE FOR ANY CARRIER FROM/TO ISRAEL",
                  "OR FOR ANY CARRIER FROM/TO SUDAN",
                  "OR FOR ANY CARRIER FROM/TO DEPARTURE FROM JOURNEY",
                  "ORIGIN IN EUROPE",
                  "OR FOR ANY CARRIER FROM/TO DEPARTURE FROM JOURNEY",
                  "ORIGIN IN MIDDLE EAST",
                  "OR FOR ANY CARRIER FROM/TO DEPARTURE FROM JOURNEY",
                  "ORIGIN IN AREA 3",
                  "END-ON-END",
                  "END-ON-END COMBINATIONS PERMITTED. VALIDATE ALL FARE",
                  "COMPONENTS. TRAVEL MUST BE VIA THE POINT OF COMBINATION.",
                  "SIDE TRIPS PERMITTED."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "SUR"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "OUTBOUND -",
                  "A SURCHARGE OF USD 50.00 PER FARE COMPONENT WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM 15DEC",
                  "THROUGH 24DEC.",
                  "AND - A SURCHARGE OF USD 30.00 PER FARE COMPONENT WILL",
                  "BE ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM",
                  "19AUG THROUGH 27AUG.",
                  "INBOUND -",
                  "A SURCHARGE OF USD 50.00 PER FARE COMPONENT WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM 02JAN",
                  "THROUGH 08JAN.",
                  "AND - A SURCHARGE OF USD 30.00 PER FARE COMPONENT WILL",
                  "BE ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM",
                  "14JUL THROUGH 30JUL.",
                  "UNLESS OTHERWISE SPECIFIED",
                  "THE PROVISIONS BELOW APPLY ONLY AS FOLLOWS -",
                  "SALE IS RESTRICTED TO SPECIFIC AGENTS.",
                  "TICKETS MAY ONLY BE SOLD IN BANGLADESH.",
                  "A SURCHARGE OF 9 PERCENT OF THE FARE PER TICKET WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL. APPLIES PER",
                  "ADULT. CHILD/INFANT DISCOUNTS APPLY.",
                  "THE PROVISIONS BELOW APPLY ONLY AS FOLLOWS -",
                  "TICKETS MAY ONLY BE SOLD IN BANGLADESH.",
                  "A SURCHARGE OF 9 PERCENT OF THE FARE PER TICKET WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "SUR"
                }
              },
              {
                "FareRuleCategory": {
                  "Code": "TKT"
                },
                "Text": [
                  "FROM/TO KENYA FOR -KE1 TYPE FARES",
                  "TICKETS MUST BE ISSUED ON EK AND MAY NOT BE SOLD IN SUDAN/",
                  "VENEZUELA/MALAWI/IRAN,ISLAMIC REPUBLIC OF/ETHIOPIA/ANGOLA/",
                  "NIGERIA/CAMEROON/GABON/CHAD/CONGO (BRAZZAVILLE)/ARGENTINA/",
                  "EGYPT/BANGLADESH/PAKISTAN",
                  "IF THE FARE COMPONENT IS ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "ANY EK FLIGHT OPERATED BY EK",
                  "ANY EK FLIGHT OPERATED BY FZ",
                  "ANY FZ FLIGHT OPERATED BY FZ",
                  "TICKETS MUST BE ISSUED ON EK OR HR AND MAY NOT BE SOLD",
                  "IN SUDAN/VENEZUELA/MALAWI/IRAN,ISLAMIC REPUBLIC OF/",
                  "ETHIOPIA/ANGOLA/NIGERIA/CAMEROON/GABON/CHAD/CONGO",
                  "(BRAZZAVILLE)/ARGENTINA/EGYPT/BANGLADESH/PAKISTAN",
                  "OR - TICKETS MUST BE ISSUED ON EK OR FZ AND MAY NOT BE",
                  "SOLD IN SUDAN/VENEZUELA/MALAWI/IRAN,ISLAMIC",
                  "REPUBLIC OF/ETHIOPIA/ANGOLA/NIGERIA/CAMEROON/GABON/",
                  "CHAD/CONGO (BRAZZAVILLE)/ARGENTINA/EGYPT/",
                  "BANGLADESH/PAKISTAN"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "PEN"
                },
                "Text": [
                  "FROM/TO KENYA FOR QHSOPKE1 TYPE ALL ECONOMY CABIN FARE TYPES FARES",
                  "CHANGES",
                  "ANY TIME",
                  "CHARGE USD 75.00.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "CHARGE USD 150.00 FOR NO-SHOW.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "CANCELLATIONS",
                  "BEFORE DEPARTURE",
                  "CHARGE USD 200.00 FOR CANCEL/REFUND.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "CHARGE USD 400.00 FOR NO-SHOW.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "AFTER DEPARTURE",
                  "TICKET IS NON-REFUNDABLE IN CASE OF CANCEL/REFUND.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "TICKET IS NON-REFUNDABLE IN CASE OF NO-SHOW.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "NOTE -",
                  "--------------------------------------------------",
                  "IF THE DATE REQUIRED IS BEYOND THE BOOKING RANGE",
                  "I.E.AFTER 11MONTHS THE REQUIRED FLIGHT/DATE SHOULD",
                  "BE SPECIFIED IN THE PNR FOR A FREE DATE CHANGE AND",
                  "AMENDED WITHIN ONE MONTH OF DEPARTURE. IF NOT",
                  "APPLICABLE CHANGE FEE APPLIES.",
                  "--------------------------------------------------",
                  "UNLESS OTHERWISE SPECIFIED",
                  "NOTE -",
                  "CHANGES",
                  "--------------------------------------------------",
                  "... A CHANGE IS A DATE/FLIGHT/ROUTING/BOOKING",
                  "CODE CHANGE.",
                  "... CHANGE FEE APPLIES PER PASSENGER PER",
                  "TRANSACTION.",
                  "... CHANGE FEE DOES NOT APPLY TO INFANT NOT",
                  "OCCUPYING A SEAT.",
                  "... CHD/INF WITH A SEAT DISCOUNT DOES NOT APPLY",
                  "ON THE CHANGE/REROUTING FEES.",
                  "... CHANGE IS PERMITTED WITHIN TICKET VALIDITY OF",
                  "ORIGINAL TICKET.",
                  "... CHANGES ONLY PERMITTED TO FARE OF EQUIVALENT",
                  "OR HIGHER VALUE.",
                  "--------------------------------------------------",
                  "WAIVERS",
                  "1.WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "A COPY OF VALID DEATH CERTIFICATE ISSUED BY A",
                  "COMPETENT MEDICAL AUTHORITY IS REQUIRED.",
                  "FAMILY MEMBERS AS DEFINED IN EK CONDITIONS OF",
                  "CARRIAGE OR PASSENGER AIRLINE TARIFF RULE BOOK.",
                  "2.NO WAIVER APPLICABLE FOR ILLNESS OF PASSENGER",
                  "OR FAMILY MEMBER.",
                  "3.CONTACT EK OFFICE FOR WAIVERS DEFINED ABOVE.",
                  "--------------------------------------------------",
                  "CHANGES AGAINST NO SHOW",
                  "- A NO-SHOW FOR A FLIGHT IS CONSIDERED WHEN A",
                  "PASSENGER FAILS TO REPORT TO THE AIRPORT AS",
                  "BOOKED ONE HOUR BEFORE DEPARTURE OF THE",
                  "SCHEDULED FLIGHT.",
                  "- FAILURE TO UTILISE TICKET AS BOOKED ON ANY",
                  "SEGMENT OF THE ITINERARY WILL RESULT IN ALL",
                  "SUBSEQUENT SEGMENTS OF THE ITINERARY BEING",
                  "CANCELLED. IN SUCH CASES NO-SHOW FEE WILL APPLY.",
                  "- IN CASE OF NO-SHOW ONLY ONE FEE IS TO BE CHARGED",
                  "I.E. EITHER THE NO-SHOW FEE OR THE CHANGE FEE",
                  "WHICHEVER IS HIGHER AND NOT BOTH.",
                  "--------------------------------------------------",
                  "UPGRADES - APPLICABLE ONLY IF CHANGES ARE",
                  "PERMITTED.",
                  "1.UPGRADES TO HIGHER FARE IN THE SAME CABIN.",
                  "RECALCULATE THE FARE FROM THE POINT OF ORIGIN",
                  "PROVIDED THE FARE RULE CONDITIONS OF THE",
                  "HIGHER FARE ARE MET.",
                  "COLLECT THE FARE DIFFERENCE AND CHANGE FEE",
                  "APPLIES PER PASSENGER PER TRANSACTION.",
                  "IF THE UPGRADED TICKET IS SUBSEQUENTLY",
                  "CANCELLED THE ORIGINAL CHARGE WILL APPLY.",
                  "2.UPGRADES TO HIGHER FARE IN A HIGHER CABIN.",
                  "RECALCULATE THE FARE FROM THE POINT OF ORIGIN",
                  "PROVIDED THE FARE RULE CONDITIONS OF THE HIGHER",
                  "FARE ARE MET.",
                  "COLLECT THE FARE DIFFERENCE. CHANGE FEE IS",
                  "WAIVED FOR UPGRADE TO HIGHER CABIN.",
                  "IF THE UPGRADED TICKET IS SUBSEQUENTLY",
                  "CANCELLED THE ORIGINAL CHARGE WILL APPLY.",
                  "--------------------------------------------------",
                  "VOLUNTARY DOWNGRADE - NO REFUNDS IN CASE OF",
                  "VOLUNTARY DOWNGRADE.",
                  "--------------------------------------------------",
                  "PENALTY FEE APPLICATION",
                  "1.ANY TIME WHEN THIS FARE IS COMBINED WITH",
                  "ANOTHER FARE AND ONLY ONE FARE COMPONENT IS",
                  "CHANGED THE PENALTY CONDITIONS OF THE CHANGED",
                  "FARE COMPONENT WILL APPLY.",
                  "2.ANY TIME WHEN MORE THAN ONE FARE COMPONENT IS",
                  "BEING CHANGED THE HIGHEST PENALTY OF ALL",
                  "CHANGED FARE COMPONENTS WILL APPLY.",
                  "--------------------------------------------------",
                  "REPRICING CONDITIONS",
                  "ONE FREE DATE CHANGE FOR INBOUND JOURNEY",
                  "PERMITTED IF OUTSIDE SYSTEM RANGE AT TIME OF",
                  "TICKETING APPLICABLE ONLY IF REISSUED BEFORE",
                  "DEPARTURE.  FARE DIFFERENCE MAY APPLY DEPENDING",
                  "ON AVAILABILITY AT TIME OF CHANGE - REFER",
                  "CAPACITY LIMITATIONS.  NO REISSUE FEE APPLIES IF",
                  "DATE CHANGE AND FARE DIFFERENCE COMPLETED IN SAME",
                  "TRANSACTION.",
                  "A.BEFORE DEPARTURE / FULLY UNUTILISED TICKETS",
                  "IN THE EVENT OF VOLUNTARY CHANGES TO ANY",
                  "FLIGHT/DATE ON THE ITINERARY TICKET HAS TO BE",
                  "REISSUED TO FARE OF EQUIVALENT OR HIGHER",
                  "VALUE AND COLLECT ANY FARE DIFFERENCE AS AN",
                  "ADC. THE FARES FOR THE PASSENGER JOURNEY",
                  "SHALL BE RECALCULATED FROM THE POINT OF",
                  "ORIGIN BASEDON THE DATE OF REISSUE.CHANGE",
                  "FEES IF ANY TO BE COLLECTED AS PER THE",
                  "ORIGINAL FARE PAID AND SHOWN ON TICKET AS AN",
                  "OD TAX PLUS ANY ADDITIONAL TAXES.",
                  "B.AFTER DEPARTURE / PARTIALLY UTILISED TICKETS",
                  "AFTER COMMENCEMENT OF THE FIRST SECTOR OF THE",
                  "JOURNEY OR THE JOURNEY PERFORMED TILL THE",
                  "TURNAROUND / FARE BREAK POINT.",
                  "IN THE EVENT OF VOLUNTARY CHANGES AFTER",
                  "COMMENCEMENT OF TRAVEL THE FARES FOR THE",
                  "PASSENGER JOURNEY SHALL BE RECALCULATED FROM",
                  "THE POINT OF ORIGIN IN ACCORDANCE WITH THE",
                  "FARES IN EFFECT ON THE DATE OF ISSUE OF THE",
                  "TICKET USED FOR THE OUTBOUND FLIGHT AND",
                  "COLLECT ANY FARE DIFFERENCE",
                  "AS AN ADC PLUS THE APPLICABLE CHANGE FEE FOR",
                  "THE TICKETED FARE AS OD TAX PLUS ANY",
                  "ADDITIONAL TAXES ON THE NEW TICKET. NEW",
                  "TICKET TO BE RE-ISSUED TO FARE OF EQUIVALENT",
                  "OR HIGHER VALUE.",
                  "--------------------------------------------------",
                  "CANCELLATION / REFUNDS",
                  "1.CANCELLATION /REFUND FEES ARE NOT COMMISSIONABLE",
                  "2.CANCELLATION FEE DOES NOT APPLY TO INFANT NOT",
                  "OCCUPYING A SEAT.",
                  "3.THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILIZED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE",
                  "4.THE DISTRIBUTION SURCHARGE YR REFUND WILL BE",
                  "MADE TO THE  ORIGINAL FORM OF PAYMENT",
                  "--------------------------------------------------",
                  "WAIVERS",
                  "1.WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "A COPY OF VALID DEATH CERTIFICATE ISSUED BY A",
                  "COMPETENT MEDICAL AUTHORITY IS REQUIRED.",
                  "FAMILY MEMBERS AS DEFINED IN EK CONDITIONS OF",
                  "CARRIAGE OR PASSENGER AIRLINE TARIFF RULE",
                  "BOOK.",
                  "2.NO WAIVER APPLICABLE FOR ILLNESS OF PASSENGER",
                  "OR FAMILY MEMBER.",
                  "3.CONTACT EK LOCAL OFFICE FOR WAIVERS DEFINED",
                  "ABOVE.",
                  "--------------------------------------------------",
                  "CANCELLATION / REFUNDS AGAINST NO SHOW.",
                  "... A NO-SHOW FOR A FLIGHT IS CONSIDERED WHEN A",
                  "PASSENGER FAILS TO REPORT AT THE AIRPORT AS",
                  "BOOKED ONE HOUR BEFORE DEPARTURE OF THE",
                  "SCHEDULED FLIGHT.",
                  "... FAILURE TO UTILISED TICKET AS BOOKED ON ANY",
                  "SEGMENT OF THE ITINERARY WILL RESULT IN ALL",
                  "SUBSEQUENT SEGMENTS OF THE ITINERARY BEING",
                  "CANCELLED. IN SUCH CASES ONLY NO-SHOW FEE",
                  "WILL APPLY AND NOT BOTH.",
                  "... NO SHOW  FEE IS NON COMMISSIONABLE.",
                  "--------------------------------------------------",
                  "CANCELLATION / REFUNDS AGAINST UPGRADES -",
                  "IF THE UPGRADED TICKET IS SUBSEQUENTLY CANCELLED",
                  "THE ORIGINAL CHARGE WILL APPLY.",
                  "IN ADDITION TO THE ABOVE -",
                  "- REFUND AGAINST UPGRADES WHERE ORIGINAL FARE IS",
                  "NON-REFUNDABLE AND NEW FARE IS REFUNDABLE ONLY",
                  "THE ADC AMOUNT AND REFUNDABLE TAXES CAN BE",
                  "REIMBURSED.",
                  "- REFUND AGAINST UPGRADES WHERE ORIGINAL FARE IS",
                  "REFUNDABLE AND NEW FARE IS NON-REFUNDABLE",
                  "ORIGINAL FARE PLUS CARRIER IMPOSED FEE AND",
                  "REFUNDABLE TAXES CAN BE REFUNDED",
                  "--------------------------------------------------",
                  "OUT OF SEQUENCE TICKETS -",
                  "ANYTIME TICKETS IS UTILIZED OUT OF SEQUENCE NO",
                  "REFUND WILL BE GIVEN AND/OR CARRIER IMPOSED",
                  "SURCHARGE - YQ/YR.",
                  "--------------------------------------------------",
                  "A.WHEN OUTBOUND AND INBOUND FARES ARE REFUNDABLE.",
                  "WHEN COMBINING FARES THAT HAVE CANCELLATION",
                  "FEES THE HIGHEST CANCELLATION FEE OF EACH",
                  "CANCELLED PRICING UNIT APPLIES.",
                  "A1.BEFORE DEPARTURE / FULLY UNUTILISED TICKETS",
                  "- DEDUCT THE APPLICABLE HIGHEST CANCELLATION FEE",
                  "FROM THE TOTAL OF THE BASE FARE AND CARRIER-",
                  "IMPOSED SURCHARGE YQ/YR.",
                  "- THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILISED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE.",
                  "- REFUND THE RESIDUAL AMOUNT ALONG WITH THE",
                  "REFUNDABLE GOVERNMENT TAXES",
                  "- CARRIER IMPOSED SERVICE FEE 6A THROUGH 6Z ARE",
                  "NOT REFUNDABLE.",
                  "A2.AFTER DEPARTURE / PARTIALLY UTILISED TICKETS -",
                  "AFTER COMMENCEMENT OF THE FIRST SECTOR OF THE",
                  "JOURNEY.",
                  "RETURN JOURNEY",
                  "- DEDUCT THE OW FARE OF EQUAL OR HIGHER AMOUNT",
                  "THAN THE FARE PAID FOR THE COMPONENT FOR WHICH",
                  "JOURNEY HAS BEEN PERFORMED IN THE SAME OR NEXT",
                  "HIGHER RBD",
                  "- CHARGE THE APPLICABLE CANCELLATION FEE AND THE",
                  "CARRIER-IMPOSED SURCHARGE - YQ FOR THE COMPONENT",
                  "FOR WHICH JOURNEY HAS BEEN PERFORMED. DEDUCT NON-",
                  "REFUNDABLE TAXES.",
                  "- REFUND UNUTILISED GOVERNMENT TAXES FOR THE",
                  "UNUSED PORTION/SECTOR OF THE JOURNEY.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED",
                  "SURCHARGES YQ IF THE UTILISED OW COMPONENT FARE",
                  "IS GREATER THAN THE TICKETED FARE.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ IF JOURNEY PERFORMED BEYOND THE",
                  "TURNAROUND/FARE BREAK POINT.",
                  "- CARRIER-IMPOSED SURCHARGE YR AND SERVICE FEE 6A",
                  "THROUGH 6Z IS NOT REFUNDABLE.",
                  "ONE-WAY JOURNEY",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ/YR AND SERVICE FEE 6A THROUGH 6Z AFTER",
                  "COMMENCEMENT OF THE FIRST SECTOR OF THE JOURNEY",
                  "- REFUND UNUTILISED GOVERNMENT TAXES FOR THE",
                  "UNUSED PORTION/SECTOR OF THE JOURNEY.",
                  "--------------------------------------------------",
                  "B.WHEN OUTBOUND AND INBOUND FARES ARE NON -",
                  "REFUNDABLE.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ AND SERVICE FEE 6A THROUGH 6Z.",
                  "- DEDUCT NON-REFUNDABLE TAXES.",
                  "- THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILISED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE.",
                  "--------------------------------------------------",
                  "C.COMBINATION OF REFUNDABLE AND NON-REFUNDABLE",
                  "FARES.",
                  "1.BEFORE DEPARTURE/FULLY UNUTILISED TICKETS.",
                  "- DEDUCT THE NON-REFUNDABLE FARE PAID HALF RT AND",
                  "THE CANCELLATION FEE OF THE REFUNDABLE FARE.",
                  "- DEDUCT YQ OF NON-REFUNDABLE COMPONENT.",
                  "- DEDUCT NON-REFUNDABLE TAXES",
                  "- SERVICE FEE 6A THROUGH 6Z ARE NON-REFUNDABLE.",
                  "- THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILISED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE.",
                  "2.AFTER DEPARTURE / PARTIALLY UTILISED TICKETS -",
                  "AFTER COMMENCEMENT OF THE FIRST SECTOR OF THE",
                  "JOURNEY.",
                  "2.1 IF OUTBOUND FARE COMPONENT IS NON-",
                  "REFUNDABLE.",
                  "- DEDUCT THE OW CARRIER-IMPOSED FEES YQ AND FARE",
                  "OF EQUAL OR HIGHER AMOUNT THAN THE FARE PAID FOR",
                  "THE COMPONENT FOR WHICH JOURNEY HAS BEEN",
                  "PERFORMED IN THE SAME OR NEXT HIGHER RBD",
                  "- DEDUCT THE CANCELLATION FEE OF THE REFUNDABLE",
                  "FARE.",
                  "- DEDUCT NON-REFUNDABLE TAXES",
                  "- CARRIER IMPOSED FEES - YR AND SERVICE FEE 6A",
                  "THROUGH 6Z ARE NON-REFUNDABLE.",
                  "- NO REFUND OF FARE AND CARRIER IMPOSED FEES",
                  "YQ IF THE UTILISED OW FARE IS GREATER THAN THE",
                  "TICKETED FARE.",
                  "2.2 IF INBOUND FARE COMPONENT IS NON-REFUNDABLE.",
                  "- NO REFUND OF THE FARE AND CARRIER IMPOSED",
                  "FEES YQ /YR AND SERVICE FEE 6A THROUGH 6Z.",
                  "- DEDUCT NON-REFUNDABLE TAXES.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ/YR AND SERVICE FEE 6A THROUGH 6Z IF JOURNEY",
                  "PERFORMED BEYOND THE TURNAROUND/FARE BREAK POINT.",
                  "--------------------------------------------------"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "PEN"
                }
              },
              {
                "FareRuleCategory": {
                  "Code": "HIP"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "THE HIGHER INTERMEDIATE POINT RULE DOES NOT APPLY FOR",
                  "CONNECTIONS.",
                  "AND - THE HIGHER INTERMEDIATE POINT RULE DOES NOT APPLY",
                  "FOR STOPOVERS.",
                  "NOTE -",
                  "SURCHARGE AND EXCESS MILEAGE CAN BE IGNORED.",
                  "HIGHER INTERMEDIATE POINT RULE DOES NOT APPLY FOR",
                  "STOPOVERS/CONNECTIONS.",
                  "--------------------------------------------------",
                  "THESE FARES SHOULD NOT BE COMPARED/CHECKED WITH",
                  "THE FOLLOWING-",
                  "- HIGH IMMEDIATE FARE       -HIF-",
                  "- CIRCLE TRIP MINIMUM CHECK -CTM-"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "END"
                },
                "Text": [
                  "FOR QHSOPKE1 TYPE FARES",
                  "THE ORIGINAL AND THE REISSUED TICKET MUST BE ANNOTATED -",
                  "NON-END/SAVER - AND - REWARD UPGDS ALLOWED - IN THE",
                  "ENDORSEMENT BOX."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "CHD"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED   NOTE - GENERAL RULE DOES NOT",
                  "APPLY",
                  "ACCOMPANIED CHILD 2-11 - CHARGE 75 PERCENT OF THE FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS CH.",
                  "MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME COMPARTMENT",
                  "BY ADULT",
                  "OR - INFANT UNDER 2 WITHOUT A SEAT - CHARGE 10 PERCENT OF",
                  "THE FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS IN.",
                  "MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME",
                  "COMPARTMENT BY ADULT.",
                  "NOTE -",
                  "WHEN INFANT REACHES 2 YEARS OF AGE ON/BEFORE",
                  "DEPARTURE FROM POINT OF TURNAROUND A SEAT",
                  "MUST BE BOOKED ON THE RETURN LEG AND THE",
                  "APPLICABLE CHILD FARE CHARGED ON HALF ROUNDTRIP",
                  "BASIS WITH OUTBOUND INFANT FARE.THE EK INFORMATION",
                  "PAGE TITLED INFANTS IN THE VARIOUS GDS REFERENCE",
                  "PAGES REFLECTS THIS INFORMATION IN DETAIL.",
                  "OR - INFANT UNDER 2 WITH A SEAT - CHARGE 75 PERCENT OF THE",
                  "FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS CH.",
                  "MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME",
                  "COMPARTMENT BY ADULT",
                  "OR - UNACCOMPANIED CHILD 5-11 - CHARGE 100 PERCENT OF THE",
                  "FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS CH."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "TCO"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "TOUR CONDUCTOR - NO DISCOUNT."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "AGT"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "AGENT - NO DISCOUNT."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "DSC"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "NOTE -",
                  "NO OTHER DISCOUNTS PERMITTED"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "VOL"
                },
                "Text": [
                  "FROM/TO KENYA",
                  "IN THE EVENT OF CHANGES TO TICKETED FLIGHTS",
                  "BEFORE DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 150.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK T- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK L- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Q- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK K- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK U- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK B- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK M- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK X- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK R- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Y- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK E- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK W- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK H- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK O- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK I- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK C- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK J- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK A- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK F- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS.",
                  "OR -",
                  "BEFORE DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 75.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK T- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK L- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Q- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK K- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK U- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK B- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK M- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK X- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK R- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Y- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK E- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK W- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK H- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK O- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK I- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK C- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK J- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK A- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK F- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS.",
                  "OR -",
                  "AFTER DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 150.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK T- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK L- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Q- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK K- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK U- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK B- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK M- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK X- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK R- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Y- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK E- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK W- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK H- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK O- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK I- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK C- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK J- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK A- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK F- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS.",
                  "OR -",
                  "AFTER DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 75.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK T- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK L- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Q- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK K- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK U- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK B- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK M- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK X- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK R- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Y- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK E- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK W- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK H- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK O- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK I- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK C- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK J- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK A- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK F- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "REF"
                },
                "Text": [
                  "FROM/TO KENYA",
                  "APPLIES IN THE CASE OF DEATH OF PASSENGER OR FAMILY",
                  "MEMBER.",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "APPLIES IN THE CASE OF DEATH OF PASSENGER OR FAMILY",
                  "MEMBER.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 400.00 PER PRICING UNIT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER PRICING UNIT COLLECT HIGHEST. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 400.00 PER FARE COMPONENT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER FARE COMPONENT COLLECT EACH. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 200.00 PER PRICING UNIT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER PRICING UNIT COLLECT HIGHEST. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 200.00 PER FARE COMPONENT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER FARE COMPONENT COLLECT EACH. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR INFANT",
                  "WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE ANYTIME AFTER ORIGINALLY SCHEDULED FLIGHT",
                  "OF FIRST UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER FARE",
                  "COMPONENT COLLECT EACH. IF MIX OF PER FARE COMPONENT AND",
                  "PER PRICING UNIT CALCULATE EACH AS PER PRICING UNIT AND",
                  "COLLECT HIGHEST. NO CHARGE FOR INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE ANYTIME AFTER ORIGINALLY SCHEDULED FLIGHT",
                  "OF FIRST UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR INFANT",
                  "WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "APPLIES FOR FARE COMPONENTS THAT ARE FULLY FLOWN. REFUND",
                  "MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR INFANT",
                  "WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE BEFORE ORIGINALLY SCHEDULED FLIGHT OF FIRST",
                  "UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER FARE",
                  "COMPONENT COLLECT EACH. IF MIX OF PER FARE COMPONENT AND",
                  "PER PRICING UNIT CALCULATE EACH AS PER PRICING UNIT AND",
                  "COLLECT HIGHEST. NO CHARGE FOR INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "APPLIES FOR FARE COMPONENTS THAT ARE FULLY FLOWN. REFUND",
                  "MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER FARE",
                  "COMPONENT COLLECT EACH. IF MIX OF PER FARE COMPONENT AND",
                  "PER PRICING UNIT CALCULATE EACH AS PER PRICING UNIT AND",
                  "COLLECT HIGHEST. NO CHARGE FOR INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE BEFORE ORIGINALLY SCHEDULED FLIGHT OF FIRST",
                  "UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD."
                ]
              }
            ]
          }
        }
      },
      {
        "PTC": "CHD",
        "travelerReferences": [
          "T3"
        ],
        "originDestinationIndex": "0",
        "segmentsIndex": [
          0,
          1
        ],
        "detailFareRule": {
          "Rules": {
            "Departure": {
              "AirportCode": {
                "value": "NBO"
              },
              "Date": "2023-12-18T00:00:00.000"
            },
            "Arrival": {
              "AirportCode": {
                "value": "DXB"
              }
            },
            "FareBasisCode": {
              "Code": "QHSOPKE1CH"
            },
            "AirlineID": {
              "value": "EK"
            },
            "FareCode": {
              "Code": "PUBL"
            },
            "Rule": [
              {
                "FareRuleCategory": {
                  "Code": "APP"
                },
                "Text": [
                  "FOR -PKE1 TYPE FARES",
                  "EMIRATES SAVER FARES",
                  "CAPACITY LIMITATIONS",
                  "THE CARRIER SHALL LIMIT THE NUMBER OF PASSENGERS CARRIED",
                  "ON ANY ONE FLIGHT AT FARES GOVERNED BY THIS RULE AND SUCH",
                  "FARES WILL NOT NECESSARILY BE AVAILABLE ON ALL FLIGHTS.",
                  "THE NUMBER OF SEATS WHICH THE CARRIER SHALL MAKE",
                  "AVAILABLE ON A GIVEN FLIGHT WILL BE DETERMINED BY THE",
                  "CARRIERS BEST JUDGMENT",
                  "OTHER CONDITIONS",
                  "ALL FARES ARE SUBJECT TO CHANGE OR WITHDRAWAL",
                  "WITHOUT NOTICE.",
                  "-----",
                  "FARES ARE ONLY GUARANTEED IF AUTOPRICED AND",
                  "TICKETED IN GDS ON THE SAME DAY.",
                  "TARIFF-023 RULE-KET5"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "SEA"
                },
                "Text": [
                  "BETWEEN AREA 2 AND EUROPE FOR HIGH SEASON FARES",
                  "PERMITTED 19AUG THROUGH 27AUG OR 15DEC THROUGH 24DEC ON",
                  "THE FIRST INTERNATIONAL SECTOR."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "FLT"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "EI FLIGHTS 5000 THROUGH 9999",
                  "AH FLIGHTS 3900 THROUGH 3999",
                  "KC FLIGHTS 350 THROUGH 355",
                  "KC FLIGHTS 577 THROUGH 578",
                  "KC FLIGHTS 697 THROUGH 698",
                  "KC FLIGHTS 1102 THROUGH 1199",
                  "KC FLIGHTS 1217 THROUGH 1297",
                  "KC FLIGHTS 1301 THROUGH 1308",
                  "KC FLIGHTS 1350 THROUGH 1355",
                  "KC FLIGHTS 1405 THROUGH 1407",
                  "KC FLIGHTS 1647 THROUGH 1649",
                  "KC FLIGHTS 1931 THROUGH 1938",
                  "KC FLIGHT 1924",
                  "KC FLIGHT 1961",
                  "KC FLIGHT 1963",
                  "BT FLIGHTS 5000 THROUGH 7999",
                  "SB FLIGHTS 0001 THROUGH 0099",
                  "SB FLIGHTS 900 THROUGH 9999",
                  "CA FLIGHTS 1151 THROUGH 1200",
                  "CA FLIGHTS 3000 THROUGH 3999",
                  "CA FLIGHTS 4075 THROUGH 4098",
                  "CA FLIGHTS 4600 THROUGH 4990",
                  "CA FLIGHTS 5000 THROUGH 9999",
                  "NX FLIGHTS 1000 THROUGH 9999",
                  "SW FLIGHTS 2000 THROUGH 9999",
                  "PX FLIGHTS 3005 THROUGH 3006",
                  "PX FLIGHTS 3040 THROUGH 3047",
                  "HM FLIGHTS 5000 THROUGH 7899",
                  "TN FLIGHTS 1000 THROUGH 2099",
                  "TN FLIGHTS 2200 THROUGH 9999",
                  "B2 FLIGHTS 0001 THROUGH 0708",
                  "FB FLIGHTS 1000 THROUGH 1999",
                  "K6 FLIGHTS 2000 THROUGH 3999",
                  "CX FLIGHTS 1000 THROUGH 1999",
                  "CX FLIGHTS 4000 THROUGH 7999",
                  "CX FLIGHTS 9000 THROUGH 9999",
                  "KA FLIGHTS 1000 THROUGH 9999",
                  "OU FLIGHTS 5000 THROUGH 5999",
                  "OK FLIGHTS 3000 THROUGH 5999",
                  "MS FLIGHTS 8000 THROUGH 9999",
                  "ET FLIGHTS 0001 THROUGH 0019",
                  "ET FLIGHTS 1000 THROUGH 1899",
                  "ET FLIGHTS 4000 THROUGH 4699",
                  "BR FLIGHTS 2001 THROUGH 3999",
                  "IE FLIGHTS 0704 THROUGH 0709",
                  "IE FLIGHTS 0712 THROUGH 0713",
                  "HA FLIGHTS 1900 THROUGH 9999",
                  "FJ FLIGHTS 500 THROUGH 599",
                  "FJ FLIGHTS 5000 THROUGH 6999.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LO FLIGHTS 4000 THROUGH 9999",
                  "LG FLIGHTS 1000 THROUGH 1999",
                  "ME FLIGHTS 1000 THROUGH 9999",
                  "AI FLIGHTS 3000 THROUGH 9999",
                  "PW FLIGHTS 2000 THROUGH 6999",
                  "BI FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 6100 THROUGH 6199",
                  "SK FLIGHTS 6400 THROUGH 6999",
                  "SK FLIGHTS 8000 THROUGH 9999",
                  "VN FLIGHTS 2000 THROUGH 4999",
                  "VN FLIGHTS 6000 THROUGH 6999",
                  "VS FLIGHTS 1000 THROUGH 9999",
                  "W2 FLIGHTS 0001 THROUGH 6199",
                  "W2 FLIGHTS 7250 THROUGH 9999",
                  "8M FLIGHTS 4000 THROUGH 4999",
                  "8M FLIGHTS 5000 THROUGH 5999",
                  "8M FLIGHTS 6000 THROUGH 6999",
                  "8M FLIGHTS 7000 THROUGH 7999",
                  "8M FLIGHTS 9500 THROUGH 9599",
                  "A3 FLIGHTS 1000 THROUGH 7099",
                  "A3 FLIGHTS 8000 THROUGH 9999",
                  "AR FLIGHTS 001 THROUGH 1099",
                  "AR FLIGHTS 1950 THROUGH 2999",
                  "AR FLIGHTS 7000 THROUGH 7999",
                  "LX FLIGHTS 3000 THROUGH 4999",
                  "LX FLIGHTS 7400 THROUGH 7499",
                  "NH FLIGHTS 2210 THROUGH 3200",
                  "NH FLIGHTS 3610 THROUGH 4840",
                  "NH FLIGHTS 5001 THROUGH 9999",
                  "UX FLIGHTS 2000 THROUGH 2999",
                  "UX FLIGHTS 3000 THROUGH 3999",
                  "4Z FLIGHTS 8000 THROUGH 8999",
                  "RQ FLIGHTS 1000 THROUGH 9999",
                  "CZ FLIGHTS 000 THROUGH 299",
                  "CZ FLIGHTS 501 THROUGH 599",
                  "CZ FLIGHTS 701 THROUGH 799",
                  "CZ FLIGHTS 2000 THROUGH 2899",
                  "CZ FLIGHTS 2900 THROUGH 2999",
                  "CZ FLIGHTS 1001 THROUGH 1999",
                  "CZ FLIGHTS 4001 THROUGH 4999",
                  "CZ FLIGHTS 7001 THROUGH 7999",
                  "CZ FLIGHTS 9000 THROUGH 9999",
                  "PC FLIGHTS 7500 THROUGH 7999",
                  "QV FLIGHTS 4000 THROUGH 4999",
                  "OD FLIGHTS 9000 THROUGH 9999",
                  "LA FLIGHTS 1510 THROUGH 1949",
                  "LA FLIGHTS 4800 THROUGH 4910.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 8300 THROUGH 8999",
                  "KQ FLIGHTS 1000 THROUGH 9999",
                  "P0 FLIGHTS 8000 THROUGH 8999",
                  "5Z FLIGHTS 8000 THROUGH 8999",
                  "RJ FLIGHTS 9000 THROUGH 9999",
                  "RJ FLIGHTS 6000 THROUGH 7999",
                  "RJ FLIGHTS 2000 THROUGH 4999",
                  "WY FLIGHTS 5000 THROUGH 7000",
                  "AD FLIGHTS 9900 THROUGH 9999",
                  "AD FLIGHTS 9400 THROUGH 9599",
                  "AD FLIGHTS 6000 THROUGH 7999",
                  "AD FLIGHTS 2000 THROUGH 2099",
                  "PK FLIGHTS 1860 THROUGH 1895",
                  "KP FLIGHTS 1000 THROUGH 9999",
                  "IB FLIGHTS 7000 THROUGH 7999",
                  "IB FLIGHTS 5000 THROUGH 5999",
                  "IB FLIGHTS 4000 THROUGH 4999",
                  "IB FLIGHTS 1027 THROUGH 2699",
                  "IB FLIGHTS 0980 THROUGH 0999",
                  "IB FLIGHTS 0658 THROUGH 0687",
                  "IB FLIGHTS 0050 THROUGH 0399",
                  "AC FLIGHTS 9000 THROUGH 9999",
                  "AC FLIGHTS 2500 THROUGH 6999",
                  "QF FLIGHTS 300 THROUGH 349",
                  "EK FLIGHTS 7434 THROUGH 7437",
                  "EK FLIGHTS 3470 THROUGH 3471",
                  "EK FLIGHTS 3482 THROUGH 3483",
                  "ET FLIGHTS 0060 THROUGH 0099",
                  "WB FLIGHTS 1000 THROUGH 9999",
                  "AV FLIGHTS 6660 THROUGH 6999",
                  "AV FLIGHTS 6000 THROUGH 6639",
                  "AV FLIGHTS 4300 THROUGH 4799",
                  "AV FLIGHTS 2000 THROUGH 2699.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 806 THROUGH 807",
                  "LA FLIGHTS 5041 THROUGH 7204",
                  "LA FLIGHTS 7405 THROUGH 7599",
                  "LA FLIGHTS 7228 THROUGH 7777",
                  "LA FLIGHTS 4966 THROUGH 5020",
                  "UL FLIGHTS 001 THROUGH 099",
                  "UL FLIGHTS 2000 THROUGH 4999",
                  "UL FLIGHTS 5000 THROUGH 5099",
                  "UL FLIGHTS 6000 THROUGH 6099",
                  "UL FLIGHTS 7000 THROUGH 7099",
                  "UL FLIGHTS 8000 THROUGH 8099",
                  "UL FLIGHTS 9000 THROUGH 9999",
                  "PK FLIGHTS 4000 THROUGH 5999",
                  "PK FLIGHTS 1888 THROUGH 1889",
                  "3U FLIGHTS 7000 THROUGH 7999",
                  "3U FLIGHTS 4000 THROUGH 5999",
                  "3U FLIGHTS 1000 THROUGH 2999",
                  "DE FLIGHTS 3000 THROUGH 9999",
                  "QF FLIGHTS 386 THROUGH 399",
                  "AV FLIGHTS 2851 THROUGH 2999",
                  "SY FLIGHTS 2000 THROUGH 9999",
                  "AY FLIGHTS 2501 THROUGH 7000",
                  "AY FLIGHTS 7501 THROUGH 9999",
                  "SA FLIGHTS 7702 THROUGH 7949",
                  "SA FLIGHTS 7000 THROUGH 7699",
                  "AZ FLIGHTS 7000 THROUGH 7999",
                  "AZ FLIGHTS 4100 THROUGH 5999",
                  "AZ FLIGHTS 2400 THROUGH 3999",
                  "WF FLIGHTS 020 THROUGH 029",
                  "WF FLIGHT 144",
                  "WF FLIGHTS 155 THROUGH 156",
                  "WF FLIGHT 175",
                  "WF FLIGHT 178",
                  "PR FLIGHTS 3004 THROUGH 3999.",
                  "AND",
                  "IF THE FARE COMPONENT INCLUDES TRAVEL WITHIN AREA 2",
                  "THEN THAT TRAVEL MUST BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "ANY 9B FLIGHT OPERATED BY 9B",
                  "ANY LM FLIGHT OPERATED BY LM",
                  "ANY QS FLIGHT OPERATED BY QS",
                  "ANY EI FLIGHT OPERATED BY EI",
                  "ANY BT FLIGHT OPERATED BY BT",
                  "ANY EK FLIGHT OPERATED BY BT",
                  "ANY B2 FLIGHT OPERATED BY B2",
                  "ANY FB FLIGHT OPERATED BY FB",
                  "ANY OU FLIGHT OPERATED BY OU",
                  "ANY OK FLIGHT OPERATED BY OK",
                  "ANY LO FLIGHT OPERATED BY LO",
                  "ANY LG FLIGHT OPERATED BY LG",
                  "ANY SK FLIGHT OPERATED BY SK",
                  "ANY WF FLIGHT OPERATED BY WF",
                  "ANY A3 FLIGHT OPERATED BY A3",
                  "ANY GQ FLIGHT OPERATED BY GQ",
                  "ANY A3 FLIGHT OPERATED BY OA",
                  "ANY UX FLIGHT OPERATED BY UX",
                  "ANY FI FLIGHT OPERATED BY FI",
                  "ANY PC FLIGHT OPERATED BY PC",
                  "ANY W2 FLIGHT OPERATED BY W2",
                  "ANY W2 FLIGHT OPERATED BY 2A",
                  "ANY AH FLIGHT OPERATED BY AH",
                  "ANY UU FLIGHT OPERATED BY UU",
                  "ANY AT FLIGHT OPERATED BY AT",
                  "ANY TU FLIGHT OPERATED BY TU",
                  "ANY LH FLIGHT OPERATED BY EN",
                  "ANY LH FLIGHT OPERATED BY CL",
                  "ANY LH FLIGHT OPERATED BY 2A",
                  "ANY LH FLIGHT OPERATED BY LH",
                  "ANY IB FLIGHT OPERATED BY I2",
                  "ANY IB FLIGHT OPERATED BY YW",
                  "ANY IB FLIGHT OPERATED BY IB",
                  "ANY EK FLIGHT OPERATED BY 7T",
                  "ANY 7T FLIGHT OPERATED BY 7T",
                  "ANY 2C FLIGHT OPERATED BY 2C",
                  "ANY EK FLIGHT OPERATED BY 2C",
                  "ANY TP FLIGHT OPERATED BY TP",
                  "ANY EK FLIGHT OPERATED BY TP",
                  "ANY KM FLIGHT OPERATED BY KM",
                  "ANY EK FLIGHT OPERATED BY S7",
                  "ANY S7 FLIGHT OPERATED BY S7",
                  "ANY VY FLIGHT OPERATED BY VY",
                  "ANY DX FLIGHT OPERATED BY DX",
                  "ANY LX FLIGHT OPERATED BY LX",
                  "ANY BP FLIGHT OPERATED BY BP",
                  "ANY 2J FLIGHT OPERATED BY 2J",
                  "ANY EK FLIGHT OPERATED BY EK",
                  "ANY FZ FLIGHT OPERATED BY FZ",
                  "ANY EK FLIGHT OPERATED BY FZ.",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "EI FLIGHTS 5000 THROUGH 9999",
                  "AH FLIGHTS 3900 THROUGH 3999",
                  "KC FLIGHTS 350 THROUGH 355",
                  "KC FLIGHTS 577 THROUGH 578",
                  "KC FLIGHTS 697 THROUGH 698",
                  "KC FLIGHTS 1102 THROUGH 1199",
                  "KC FLIGHTS 1217 THROUGH 1297",
                  "KC FLIGHTS 1301 THROUGH 1308",
                  "KC FLIGHTS 1350 THROUGH 1355",
                  "KC FLIGHTS 1405 THROUGH 1407",
                  "KC FLIGHTS 1647 THROUGH 1649",
                  "KC FLIGHTS 1931 THROUGH 1938",
                  "KC FLIGHT 1924",
                  "KC FLIGHT 1961",
                  "KC FLIGHT 1963",
                  "BT FLIGHTS 5000 THROUGH 7999",
                  "SB FLIGHTS 0001 THROUGH 0099",
                  "SB FLIGHTS 900 THROUGH 9999",
                  "CA FLIGHTS 1151 THROUGH 1200",
                  "CA FLIGHTS 3000 THROUGH 3999",
                  "CA FLIGHTS 4075 THROUGH 4098",
                  "CA FLIGHTS 4600 THROUGH 4990",
                  "CA FLIGHTS 5000 THROUGH 9999",
                  "NX FLIGHTS 1000 THROUGH 9999",
                  "SW FLIGHTS 2000 THROUGH 9999",
                  "PX FLIGHTS 3005 THROUGH 3006",
                  "PX FLIGHTS 3040 THROUGH 3047",
                  "HM FLIGHTS 5000 THROUGH 7899",
                  "TN FLIGHTS 1000 THROUGH 2099",
                  "TN FLIGHTS 2200 THROUGH 9999",
                  "B2 FLIGHTS 0001 THROUGH 0708",
                  "FB FLIGHTS 1000 THROUGH 1999",
                  "K6 FLIGHTS 2000 THROUGH 3999",
                  "CX FLIGHTS 1000 THROUGH 1999",
                  "CX FLIGHTS 4000 THROUGH 7999",
                  "CX FLIGHTS 9000 THROUGH 9999",
                  "KA FLIGHTS 1000 THROUGH 9999",
                  "OU FLIGHTS 5000 THROUGH 5999",
                  "OK FLIGHTS 3000 THROUGH 5999",
                  "MS FLIGHTS 8000 THROUGH 9999",
                  "ET FLIGHTS 0001 THROUGH 0019",
                  "ET FLIGHTS 1000 THROUGH 1899",
                  "ET FLIGHTS 4000 THROUGH 4699",
                  "BR FLIGHTS 2001 THROUGH 3999",
                  "IE FLIGHTS 0704 THROUGH 0709",
                  "IE FLIGHTS 0712 THROUGH 0713",
                  "HA FLIGHTS 1900 THROUGH 9999",
                  "FJ FLIGHTS 500 THROUGH 599",
                  "FJ FLIGHTS 5000 THROUGH 6999.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LO FLIGHTS 4000 THROUGH 9999",
                  "LG FLIGHTS 1000 THROUGH 1999",
                  "ME FLIGHTS 1000 THROUGH 9999",
                  "AI FLIGHTS 3000 THROUGH 9999",
                  "PW FLIGHTS 2000 THROUGH 6999",
                  "BI FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 6100 THROUGH 6199",
                  "SK FLIGHTS 6400 THROUGH 6999",
                  "SK FLIGHTS 8000 THROUGH 9999",
                  "VN FLIGHTS 2000 THROUGH 4999",
                  "VN FLIGHTS 6000 THROUGH 6999",
                  "VS FLIGHTS 1000 THROUGH 9999",
                  "W2 FLIGHTS 0001 THROUGH 6199",
                  "W2 FLIGHTS 7250 THROUGH 9999",
                  "8M FLIGHTS 4000 THROUGH 4999",
                  "8M FLIGHTS 5000 THROUGH 5999",
                  "8M FLIGHTS 6000 THROUGH 6999",
                  "8M FLIGHTS 7000 THROUGH 7999",
                  "8M FLIGHTS 9500 THROUGH 9599",
                  "A3 FLIGHTS 1000 THROUGH 7099",
                  "A3 FLIGHTS 8000 THROUGH 9999",
                  "AR FLIGHTS 001 THROUGH 1099",
                  "AR FLIGHTS 1950 THROUGH 2999",
                  "AR FLIGHTS 7000 THROUGH 7999",
                  "LX FLIGHTS 3000 THROUGH 4999",
                  "LX FLIGHTS 7400 THROUGH 7499",
                  "NH FLIGHTS 2210 THROUGH 3200",
                  "NH FLIGHTS 3610 THROUGH 4840",
                  "NH FLIGHTS 5001 THROUGH 9999",
                  "UX FLIGHTS 2000 THROUGH 2999",
                  "UX FLIGHTS 3000 THROUGH 3999",
                  "4Z FLIGHTS 8000 THROUGH 8999",
                  "RQ FLIGHTS 1000 THROUGH 9999",
                  "CZ FLIGHTS 000 THROUGH 299",
                  "CZ FLIGHTS 501 THROUGH 599",
                  "CZ FLIGHTS 701 THROUGH 799",
                  "CZ FLIGHTS 2000 THROUGH 2899",
                  "CZ FLIGHTS 2900 THROUGH 2999",
                  "CZ FLIGHTS 1001 THROUGH 1999",
                  "CZ FLIGHTS 4001 THROUGH 4999",
                  "CZ FLIGHTS 7001 THROUGH 7999",
                  "CZ FLIGHTS 9000 THROUGH 9999",
                  "PC FLIGHTS 7500 THROUGH 7999",
                  "QV FLIGHTS 4000 THROUGH 4999",
                  "OD FLIGHTS 9000 THROUGH 9999",
                  "LA FLIGHTS 1510 THROUGH 1949",
                  "LA FLIGHTS 4800 THROUGH 4910.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 8300 THROUGH 8999",
                  "KQ FLIGHTS 1000 THROUGH 9999",
                  "P0 FLIGHTS 8000 THROUGH 8999",
                  "5Z FLIGHTS 8000 THROUGH 8999",
                  "RJ FLIGHTS 9000 THROUGH 9999",
                  "RJ FLIGHTS 6000 THROUGH 7999",
                  "RJ FLIGHTS 2000 THROUGH 4999",
                  "WY FLIGHTS 5000 THROUGH 7000",
                  "AD FLIGHTS 9900 THROUGH 9999",
                  "AD FLIGHTS 9400 THROUGH 9599",
                  "AD FLIGHTS 6000 THROUGH 7999",
                  "AD FLIGHTS 2000 THROUGH 2099",
                  "PK FLIGHTS 1860 THROUGH 1895",
                  "KP FLIGHTS 1000 THROUGH 9999",
                  "IB FLIGHTS 7000 THROUGH 7999",
                  "IB FLIGHTS 5000 THROUGH 5999",
                  "IB FLIGHTS 4000 THROUGH 4999",
                  "IB FLIGHTS 1027 THROUGH 2699",
                  "IB FLIGHTS 0980 THROUGH 0999",
                  "IB FLIGHTS 0658 THROUGH 0687",
                  "IB FLIGHTS 0050 THROUGH 0399",
                  "AC FLIGHTS 9000 THROUGH 9999",
                  "AC FLIGHTS 2500 THROUGH 6999",
                  "QF FLIGHTS 300 THROUGH 349",
                  "EK FLIGHTS 7434 THROUGH 7437",
                  "EK FLIGHTS 3470 THROUGH 3471",
                  "EK FLIGHTS 3482 THROUGH 3483",
                  "ET FLIGHTS 0060 THROUGH 0099",
                  "WB FLIGHTS 1000 THROUGH 9999",
                  "AV FLIGHTS 6660 THROUGH 6999",
                  "AV FLIGHTS 6000 THROUGH 6639",
                  "AV FLIGHTS 4300 THROUGH 4799",
                  "AV FLIGHTS 2000 THROUGH 2699.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 806 THROUGH 807",
                  "LA FLIGHTS 5041 THROUGH 7204",
                  "LA FLIGHTS 7405 THROUGH 7599",
                  "LA FLIGHTS 7228 THROUGH 7777",
                  "LA FLIGHTS 4966 THROUGH 5020",
                  "UL FLIGHTS 001 THROUGH 099",
                  "UL FLIGHTS 2000 THROUGH 4999",
                  "UL FLIGHTS 5000 THROUGH 5099",
                  "UL FLIGHTS 6000 THROUGH 6099",
                  "UL FLIGHTS 7000 THROUGH 7099",
                  "UL FLIGHTS 8000 THROUGH 8099",
                  "UL FLIGHTS 9000 THROUGH 9999",
                  "PK FLIGHTS 4000 THROUGH 5999",
                  "PK FLIGHTS 1888 THROUGH 1889",
                  "3U FLIGHTS 7000 THROUGH 7999",
                  "3U FLIGHTS 4000 THROUGH 5999",
                  "3U FLIGHTS 1000 THROUGH 2999",
                  "DE FLIGHTS 3000 THROUGH 9999",
                  "QF FLIGHTS 386 THROUGH 399",
                  "AV FLIGHTS 2851 THROUGH 2999",
                  "SY FLIGHTS 2000 THROUGH 9999",
                  "AY FLIGHTS 2501 THROUGH 7000",
                  "AY FLIGHTS 7501 THROUGH 9999",
                  "SA FLIGHTS 7702 THROUGH 7949",
                  "SA FLIGHTS 7000 THROUGH 7699",
                  "AZ FLIGHTS 7000 THROUGH 7999",
                  "AZ FLIGHTS 4100 THROUGH 5999",
                  "AZ FLIGHTS 2400 THROUGH 3999",
                  "WF FLIGHTS 020 THROUGH 029",
                  "WF FLIGHT 144",
                  "WF FLIGHTS 155 THROUGH 156",
                  "WF FLIGHT 175",
                  "WF FLIGHT 178",
                  "PR FLIGHTS 3004 THROUGH 3999.",
                  "AND",
                  "IF THE FARE COMPONENT INCLUDES TRAVEL WITHIN AREA 2",
                  "THEN THAT TRAVEL MUST BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "ANY P0 FLIGHT OPERATED BY P0",
                  "ANY FA FLIGHT OPERATED BY FA",
                  "ANY HM FLIGHT OPERATED BY HM",
                  "ANY ET FLIGHT OPERATED BY ET",
                  "ANY TM FLIGHT OPERATED BY TM",
                  "ANY PW FLIGHT OPERATED BY PW",
                  "ANY KP FLIGHT OPERATED BY KP",
                  "ANY UR FLIGHT OPERATED BY UR",
                  "ANY HF FLIGHT OPERATED BY HF",
                  "ANY AW FLIGHT OPERATED BY AW",
                  "ANY ET FLIGHT OPERATED BY 3W",
                  "ANY WB FLIGHT OPERATED BY WB",
                  "ANY KQ FLIGHT OPERATED BY KQ",
                  "ANY 5Z FLIGHT OPERATED BY 5Z",
                  "ANY SA FLIGHT OPERATED BY SA",
                  "ANY MK FLIGHT OPERATED BY MK",
                  "ANY EK FLIGHT OPERATED BY MK",
                  "ANY 4Z FLIGHT OPERATED BY 4Z",
                  "ANY TC FLIGHT OPERATED BY TC",
                  "ANY EK FLIGHT OPERATED BY 4Z",
                  "ANY SS FLIGHT OPERATED BY SS",
                  "ANY EK FLIGHT OPERATED BY EK",
                  "ANY FZ FLIGHT OPERATED BY FZ",
                  "ANY EK FLIGHT OPERATED BY FZ",
                  "ANY FN FLIGHT OPERATED BY FN",
                  "ANY L6 FLIGHT OPERATED BY L6",
                  "ANY SK FLIGHT OPERATED BY SK",
                  "ANY AY FLIGHT OPERATED BY AY",
                  "ANY AZ FLIGHT OPERATED BY AZ",
                  "ANY A3 FLIGHT OPERATED BY A3",
                  "ANY EK FLIGHT OPERATED BY A3",
                  "ANY EK FLIGHT OPERATED BY AT",
                  "ANY EK FLIGHT OPERATED BY 9B",
                  "ANY EK FLIGHT OPERATED BY OA",
                  "ANY DE FLIGHT OPERATED BY DE."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "RES"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "RESERVATIONS ARE REQUIRED FOR ALL SECTORS.",
                  "WAITLIST NOT PERMITTED.",
                  "NOTE -",
                  "EK RESERVES THE RIGHT TO CANCEL ANY PNR WHERE",
                  "ITINERARIES DO NOT COMPLY WITH RESERVATION",
                  "BOOKING CODE /RBD/.",
                  "---",
                  "IF A TICKET IS USED OUT OF SEQUENCE EK RESERVES",
                  "THE RIGHT TO CANCEL ANY PNR OR INVALIDATE THE",
                  "REMAINING FLIGHT COUPONS.",
                  "---",
                  "IF A PASSENGER FAILS TO TRAVEL ON A SECTOR BOOKED",
                  "EK RESERVES THE RIGHT TO CANCEL REMAINING EK",
                  "SECTORS."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "MIN"
                },
                "Text": [
                  "NONE FOR ONE WAY FARES"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "MAX"
                },
                "Text": [
                  "NONE FOR ONE WAY FARES"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "STP"
                },
                "Text": [
                  "BETWEEN AREA 2 AND EUROPE FOR QHSOPKE1 TYPE FARES",
                  "UNLIMITED STOPOVERS PERMITTED IN EACH DIRECTION",
                  "LIMITED TO 2 FREE AND UNLIMITED AT USD 100.00 EACH.",
                  "1 FREE IN DXB IN EACH DIRECTION",
                  "UNLIMITED IN EACH DIRECTION AT USD 100.00 EACH.",
                  "A STOPOVER MAY NOT EXCEED 21 DAYS.",
                  "NOTE -",
                  "CHILD/INFANT DISCOUNT DOES NOT APPLY."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "TRF"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "UNLIMITED TRANSFERS PERMITTED ON THE PRICING UNIT",
                  "FARE BREAK AND EMBEDDED SURFACE SECTORS NOT PERMITTED ON",
                  "THE FARE COMPONENT.",
                  "NOTE -",
                  "AS PER SPECIFIED PUBLISHED ROUTING ATTACHED.",
                  "SURFACE SECTORS NOT PERMITTED."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "CMB"
                },
                "Text": [
                  "FOR ONE WAY FARES",
                  "SINGLE/DOUBLE OPEN JAWS/ROUND TRIPS/CIRCLE TRIPS NOT",
                  "PERMITTED.",
                  "APPLICABLE ADD-ON CONSTRUCTION IS ADDRESSED IN",
                  "MISCELLANEOUS PROVISIONS - CATEGORY 23.",
                  "END-ON-END",
                  "END-ON-END COMBINATIONS NOT PERMITTED. VALIDATE ALL FARE",
                  "COMPONENTS. SIDE TRIPS PERMITTED.",
                  "PROVIDED -",
                  "COMBINATIONS ARE FOR CARRIER EK/FZ/B6",
                  "COMBINATIONS ARE FOR ANY CARRIER FROM/TO DEPARTURE FROM",
                  "JOURNEY ORIGIN IN AREA 1",
                  "COMBINATIONS ARE FOR ANY CARRIER FROM/TO ISRAEL",
                  "OR FOR ANY CARRIER FROM/TO SUDAN",
                  "OR FOR ANY CARRIER FROM/TO DEPARTURE FROM JOURNEY",
                  "ORIGIN IN EUROPE",
                  "OR FOR ANY CARRIER FROM/TO DEPARTURE FROM JOURNEY",
                  "ORIGIN IN MIDDLE EAST",
                  "OR FOR ANY CARRIER FROM/TO DEPARTURE FROM JOURNEY",
                  "ORIGIN IN AREA 3",
                  "END-ON-END",
                  "END-ON-END COMBINATIONS PERMITTED. VALIDATE ALL FARE",
                  "COMPONENTS. TRAVEL MUST BE VIA THE POINT OF COMBINATION.",
                  "SIDE TRIPS PERMITTED."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "SUR"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "OUTBOUND -",
                  "A SURCHARGE OF USD 50.00 PER FARE COMPONENT WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM 15DEC",
                  "THROUGH 24DEC.",
                  "AND - A SURCHARGE OF USD 30.00 PER FARE COMPONENT WILL",
                  "BE ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM",
                  "19AUG THROUGH 27AUG.",
                  "INBOUND -",
                  "A SURCHARGE OF USD 50.00 PER FARE COMPONENT WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM 02JAN",
                  "THROUGH 08JAN.",
                  "AND - A SURCHARGE OF USD 30.00 PER FARE COMPONENT WILL",
                  "BE ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM",
                  "14JUL THROUGH 30JUL.",
                  "UNLESS OTHERWISE SPECIFIED",
                  "THE PROVISIONS BELOW APPLY ONLY AS FOLLOWS -",
                  "SALE IS RESTRICTED TO SPECIFIC AGENTS.",
                  "TICKETS MAY ONLY BE SOLD IN BANGLADESH.",
                  "A SURCHARGE OF 9 PERCENT OF THE FARE PER TICKET WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL. APPLIES PER",
                  "ADULT. CHILD/INFANT DISCOUNTS APPLY.",
                  "THE PROVISIONS BELOW APPLY ONLY AS FOLLOWS -",
                  "TICKETS MAY ONLY BE SOLD IN BANGLADESH.",
                  "A SURCHARGE OF 9 PERCENT OF THE FARE PER TICKET WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "SUR"
                }
              },
              {
                "FareRuleCategory": {
                  "Code": "TKT"
                },
                "Text": [
                  "FROM/TO KENYA FOR -KE1 TYPE FARES",
                  "TICKETS MUST BE ISSUED ON EK AND MAY NOT BE SOLD IN SUDAN/",
                  "VENEZUELA/MALAWI/IRAN,ISLAMIC REPUBLIC OF/ETHIOPIA/ANGOLA/",
                  "NIGERIA/CAMEROON/GABON/CHAD/CONGO (BRAZZAVILLE)/ARGENTINA/",
                  "EGYPT/BANGLADESH/PAKISTAN",
                  "IF THE FARE COMPONENT IS ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "ANY EK FLIGHT OPERATED BY EK",
                  "ANY EK FLIGHT OPERATED BY FZ",
                  "ANY FZ FLIGHT OPERATED BY FZ",
                  "TICKETS MUST BE ISSUED ON EK OR HR AND MAY NOT BE SOLD",
                  "IN SUDAN/VENEZUELA/MALAWI/IRAN,ISLAMIC REPUBLIC OF/",
                  "ETHIOPIA/ANGOLA/NIGERIA/CAMEROON/GABON/CHAD/CONGO",
                  "(BRAZZAVILLE)/ARGENTINA/EGYPT/BANGLADESH/PAKISTAN",
                  "OR - TICKETS MUST BE ISSUED ON EK OR FZ AND MAY NOT BE",
                  "SOLD IN SUDAN/VENEZUELA/MALAWI/IRAN,ISLAMIC",
                  "REPUBLIC OF/ETHIOPIA/ANGOLA/NIGERIA/CAMEROON/GABON/",
                  "CHAD/CONGO (BRAZZAVILLE)/ARGENTINA/EGYPT/",
                  "BANGLADESH/PAKISTAN"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "PEN"
                },
                "Text": [
                  "FROM/TO KENYA FOR QHSOPKE1 TYPE ALL ECONOMY CABIN FARE TYPES FARES",
                  "CHANGES",
                  "ANY TIME",
                  "CHARGE USD 75.00.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "CHARGE USD 150.00 FOR NO-SHOW.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "CANCELLATIONS",
                  "BEFORE DEPARTURE",
                  "CHARGE USD 200.00 FOR CANCEL/REFUND.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "CHARGE USD 400.00 FOR NO-SHOW.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "AFTER DEPARTURE",
                  "TICKET IS NON-REFUNDABLE IN CASE OF CANCEL/REFUND.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "TICKET IS NON-REFUNDABLE IN CASE OF NO-SHOW.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "NOTE -",
                  "--------------------------------------------------",
                  "IF THE DATE REQUIRED IS BEYOND THE BOOKING RANGE",
                  "I.E.AFTER 11MONTHS THE REQUIRED FLIGHT/DATE SHOULD",
                  "BE SPECIFIED IN THE PNR FOR A FREE DATE CHANGE AND",
                  "AMENDED WITHIN ONE MONTH OF DEPARTURE. IF NOT",
                  "APPLICABLE CHANGE FEE APPLIES.",
                  "--------------------------------------------------",
                  "UNLESS OTHERWISE SPECIFIED",
                  "NOTE -",
                  "CHANGES",
                  "--------------------------------------------------",
                  "... A CHANGE IS A DATE/FLIGHT/ROUTING/BOOKING",
                  "CODE CHANGE.",
                  "... CHANGE FEE APPLIES PER PASSENGER PER",
                  "TRANSACTION.",
                  "... CHANGE FEE DOES NOT APPLY TO INFANT NOT",
                  "OCCUPYING A SEAT.",
                  "... CHD/INF WITH A SEAT DISCOUNT DOES NOT APPLY",
                  "ON THE CHANGE/REROUTING FEES.",
                  "... CHANGE IS PERMITTED WITHIN TICKET VALIDITY OF",
                  "ORIGINAL TICKET.",
                  "... CHANGES ONLY PERMITTED TO FARE OF EQUIVALENT",
                  "OR HIGHER VALUE.",
                  "--------------------------------------------------",
                  "WAIVERS",
                  "1.WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "A COPY OF VALID DEATH CERTIFICATE ISSUED BY A",
                  "COMPETENT MEDICAL AUTHORITY IS REQUIRED.",
                  "FAMILY MEMBERS AS DEFINED IN EK CONDITIONS OF",
                  "CARRIAGE OR PASSENGER AIRLINE TARIFF RULE BOOK.",
                  "2.NO WAIVER APPLICABLE FOR ILLNESS OF PASSENGER",
                  "OR FAMILY MEMBER.",
                  "3.CONTACT EK OFFICE FOR WAIVERS DEFINED ABOVE.",
                  "--------------------------------------------------",
                  "CHANGES AGAINST NO SHOW",
                  "- A NO-SHOW FOR A FLIGHT IS CONSIDERED WHEN A",
                  "PASSENGER FAILS TO REPORT TO THE AIRPORT AS",
                  "BOOKED ONE HOUR BEFORE DEPARTURE OF THE",
                  "SCHEDULED FLIGHT.",
                  "- FAILURE TO UTILISE TICKET AS BOOKED ON ANY",
                  "SEGMENT OF THE ITINERARY WILL RESULT IN ALL",
                  "SUBSEQUENT SEGMENTS OF THE ITINERARY BEING",
                  "CANCELLED. IN SUCH CASES NO-SHOW FEE WILL APPLY.",
                  "- IN CASE OF NO-SHOW ONLY ONE FEE IS TO BE CHARGED",
                  "I.E. EITHER THE NO-SHOW FEE OR THE CHANGE FEE",
                  "WHICHEVER IS HIGHER AND NOT BOTH.",
                  "--------------------------------------------------",
                  "UPGRADES - APPLICABLE ONLY IF CHANGES ARE",
                  "PERMITTED.",
                  "1.UPGRADES TO HIGHER FARE IN THE SAME CABIN.",
                  "RECALCULATE THE FARE FROM THE POINT OF ORIGIN",
                  "PROVIDED THE FARE RULE CONDITIONS OF THE",
                  "HIGHER FARE ARE MET.",
                  "COLLECT THE FARE DIFFERENCE AND CHANGE FEE",
                  "APPLIES PER PASSENGER PER TRANSACTION.",
                  "IF THE UPGRADED TICKET IS SUBSEQUENTLY",
                  "CANCELLED THE ORIGINAL CHARGE WILL APPLY.",
                  "2.UPGRADES TO HIGHER FARE IN A HIGHER CABIN.",
                  "RECALCULATE THE FARE FROM THE POINT OF ORIGIN",
                  "PROVIDED THE FARE RULE CONDITIONS OF THE HIGHER",
                  "FARE ARE MET.",
                  "COLLECT THE FARE DIFFERENCE. CHANGE FEE IS",
                  "WAIVED FOR UPGRADE TO HIGHER CABIN.",
                  "IF THE UPGRADED TICKET IS SUBSEQUENTLY",
                  "CANCELLED THE ORIGINAL CHARGE WILL APPLY.",
                  "--------------------------------------------------",
                  "VOLUNTARY DOWNGRADE - NO REFUNDS IN CASE OF",
                  "VOLUNTARY DOWNGRADE.",
                  "--------------------------------------------------",
                  "PENALTY FEE APPLICATION",
                  "1.ANY TIME WHEN THIS FARE IS COMBINED WITH",
                  "ANOTHER FARE AND ONLY ONE FARE COMPONENT IS",
                  "CHANGED THE PENALTY CONDITIONS OF THE CHANGED",
                  "FARE COMPONENT WILL APPLY.",
                  "2.ANY TIME WHEN MORE THAN ONE FARE COMPONENT IS",
                  "BEING CHANGED THE HIGHEST PENALTY OF ALL",
                  "CHANGED FARE COMPONENTS WILL APPLY.",
                  "--------------------------------------------------",
                  "REPRICING CONDITIONS",
                  "ONE FREE DATE CHANGE FOR INBOUND JOURNEY",
                  "PERMITTED IF OUTSIDE SYSTEM RANGE AT TIME OF",
                  "TICKETING APPLICABLE ONLY IF REISSUED BEFORE",
                  "DEPARTURE.  FARE DIFFERENCE MAY APPLY DEPENDING",
                  "ON AVAILABILITY AT TIME OF CHANGE - REFER",
                  "CAPACITY LIMITATIONS.  NO REISSUE FEE APPLIES IF",
                  "DATE CHANGE AND FARE DIFFERENCE COMPLETED IN SAME",
                  "TRANSACTION.",
                  "A.BEFORE DEPARTURE / FULLY UNUTILISED TICKETS",
                  "IN THE EVENT OF VOLUNTARY CHANGES TO ANY",
                  "FLIGHT/DATE ON THE ITINERARY TICKET HAS TO BE",
                  "REISSUED TO FARE OF EQUIVALENT OR HIGHER",
                  "VALUE AND COLLECT ANY FARE DIFFERENCE AS AN",
                  "ADC. THE FARES FOR THE PASSENGER JOURNEY",
                  "SHALL BE RECALCULATED FROM THE POINT OF",
                  "ORIGIN BASEDON THE DATE OF REISSUE.CHANGE",
                  "FEES IF ANY TO BE COLLECTED AS PER THE",
                  "ORIGINAL FARE PAID AND SHOWN ON TICKET AS AN",
                  "OD TAX PLUS ANY ADDITIONAL TAXES.",
                  "B.AFTER DEPARTURE / PARTIALLY UTILISED TICKETS",
                  "AFTER COMMENCEMENT OF THE FIRST SECTOR OF THE",
                  "JOURNEY OR THE JOURNEY PERFORMED TILL THE",
                  "TURNAROUND / FARE BREAK POINT.",
                  "IN THE EVENT OF VOLUNTARY CHANGES AFTER",
                  "COMMENCEMENT OF TRAVEL THE FARES FOR THE",
                  "PASSENGER JOURNEY SHALL BE RECALCULATED FROM",
                  "THE POINT OF ORIGIN IN ACCORDANCE WITH THE",
                  "FARES IN EFFECT ON THE DATE OF ISSUE OF THE",
                  "TICKET USED FOR THE OUTBOUND FLIGHT AND",
                  "COLLECT ANY FARE DIFFERENCE",
                  "AS AN ADC PLUS THE APPLICABLE CHANGE FEE FOR",
                  "THE TICKETED FARE AS OD TAX PLUS ANY",
                  "ADDITIONAL TAXES ON THE NEW TICKET. NEW",
                  "TICKET TO BE RE-ISSUED TO FARE OF EQUIVALENT",
                  "OR HIGHER VALUE.",
                  "--------------------------------------------------",
                  "CANCELLATION / REFUNDS",
                  "1.CANCELLATION /REFUND FEES ARE NOT COMMISSIONABLE",
                  "2.CANCELLATION FEE DOES NOT APPLY TO INFANT NOT",
                  "OCCUPYING A SEAT.",
                  "3.THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILIZED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE",
                  "4.THE DISTRIBUTION SURCHARGE YR REFUND WILL BE",
                  "MADE TO THE  ORIGINAL FORM OF PAYMENT",
                  "--------------------------------------------------",
                  "WAIVERS",
                  "1.WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "A COPY OF VALID DEATH CERTIFICATE ISSUED BY A",
                  "COMPETENT MEDICAL AUTHORITY IS REQUIRED.",
                  "FAMILY MEMBERS AS DEFINED IN EK CONDITIONS OF",
                  "CARRIAGE OR PASSENGER AIRLINE TARIFF RULE",
                  "BOOK.",
                  "2.NO WAIVER APPLICABLE FOR ILLNESS OF PASSENGER",
                  "OR FAMILY MEMBER.",
                  "3.CONTACT EK LOCAL OFFICE FOR WAIVERS DEFINED",
                  "ABOVE.",
                  "--------------------------------------------------",
                  "CANCELLATION / REFUNDS AGAINST NO SHOW.",
                  "... A NO-SHOW FOR A FLIGHT IS CONSIDERED WHEN A",
                  "PASSENGER FAILS TO REPORT AT THE AIRPORT AS",
                  "BOOKED ONE HOUR BEFORE DEPARTURE OF THE",
                  "SCHEDULED FLIGHT.",
                  "... FAILURE TO UTILISED TICKET AS BOOKED ON ANY",
                  "SEGMENT OF THE ITINERARY WILL RESULT IN ALL",
                  "SUBSEQUENT SEGMENTS OF THE ITINERARY BEING",
                  "CANCELLED. IN SUCH CASES ONLY NO-SHOW FEE",
                  "WILL APPLY AND NOT BOTH.",
                  "... NO SHOW  FEE IS NON COMMISSIONABLE.",
                  "--------------------------------------------------",
                  "CANCELLATION / REFUNDS AGAINST UPGRADES -",
                  "IF THE UPGRADED TICKET IS SUBSEQUENTLY CANCELLED",
                  "THE ORIGINAL CHARGE WILL APPLY.",
                  "IN ADDITION TO THE ABOVE -",
                  "- REFUND AGAINST UPGRADES WHERE ORIGINAL FARE IS",
                  "NON-REFUNDABLE AND NEW FARE IS REFUNDABLE ONLY",
                  "THE ADC AMOUNT AND REFUNDABLE TAXES CAN BE",
                  "REIMBURSED.",
                  "- REFUND AGAINST UPGRADES WHERE ORIGINAL FARE IS",
                  "REFUNDABLE AND NEW FARE IS NON-REFUNDABLE",
                  "ORIGINAL FARE PLUS CARRIER IMPOSED FEE AND",
                  "REFUNDABLE TAXES CAN BE REFUNDED",
                  "--------------------------------------------------",
                  "OUT OF SEQUENCE TICKETS -",
                  "ANYTIME TICKETS IS UTILIZED OUT OF SEQUENCE NO",
                  "REFUND WILL BE GIVEN AND/OR CARRIER IMPOSED",
                  "SURCHARGE - YQ/YR.",
                  "--------------------------------------------------",
                  "A.WHEN OUTBOUND AND INBOUND FARES ARE REFUNDABLE.",
                  "WHEN COMBINING FARES THAT HAVE CANCELLATION",
                  "FEES THE HIGHEST CANCELLATION FEE OF EACH",
                  "CANCELLED PRICING UNIT APPLIES.",
                  "A1.BEFORE DEPARTURE / FULLY UNUTILISED TICKETS",
                  "- DEDUCT THE APPLICABLE HIGHEST CANCELLATION FEE",
                  "FROM THE TOTAL OF THE BASE FARE AND CARRIER-",
                  "IMPOSED SURCHARGE YQ/YR.",
                  "- THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILISED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE.",
                  "- REFUND THE RESIDUAL AMOUNT ALONG WITH THE",
                  "REFUNDABLE GOVERNMENT TAXES",
                  "- CARRIER IMPOSED SERVICE FEE 6A THROUGH 6Z ARE",
                  "NOT REFUNDABLE.",
                  "A2.AFTER DEPARTURE / PARTIALLY UTILISED TICKETS -",
                  "AFTER COMMENCEMENT OF THE FIRST SECTOR OF THE",
                  "JOURNEY.",
                  "RETURN JOURNEY",
                  "- DEDUCT THE OW FARE OF EQUAL OR HIGHER AMOUNT",
                  "THAN THE FARE PAID FOR THE COMPONENT FOR WHICH",
                  "JOURNEY HAS BEEN PERFORMED IN THE SAME OR NEXT",
                  "HIGHER RBD",
                  "- CHARGE THE APPLICABLE CANCELLATION FEE AND THE",
                  "CARRIER-IMPOSED SURCHARGE - YQ FOR THE COMPONENT",
                  "FOR WHICH JOURNEY HAS BEEN PERFORMED. DEDUCT NON-",
                  "REFUNDABLE TAXES.",
                  "- REFUND UNUTILISED GOVERNMENT TAXES FOR THE",
                  "UNUSED PORTION/SECTOR OF THE JOURNEY.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED",
                  "SURCHARGES YQ IF THE UTILISED OW COMPONENT FARE",
                  "IS GREATER THAN THE TICKETED FARE.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ IF JOURNEY PERFORMED BEYOND THE",
                  "TURNAROUND/FARE BREAK POINT.",
                  "- CARRIER-IMPOSED SURCHARGE YR AND SERVICE FEE 6A",
                  "THROUGH 6Z IS NOT REFUNDABLE.",
                  "ONE-WAY JOURNEY",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ/YR AND SERVICE FEE 6A THROUGH 6Z AFTER",
                  "COMMENCEMENT OF THE FIRST SECTOR OF THE JOURNEY",
                  "- REFUND UNUTILISED GOVERNMENT TAXES FOR THE",
                  "UNUSED PORTION/SECTOR OF THE JOURNEY.",
                  "--------------------------------------------------",
                  "B.WHEN OUTBOUND AND INBOUND FARES ARE NON -",
                  "REFUNDABLE.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ AND SERVICE FEE 6A THROUGH 6Z.",
                  "- DEDUCT NON-REFUNDABLE TAXES.",
                  "- THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILISED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE.",
                  "--------------------------------------------------",
                  "C.COMBINATION OF REFUNDABLE AND NON-REFUNDABLE",
                  "FARES.",
                  "1.BEFORE DEPARTURE/FULLY UNUTILISED TICKETS.",
                  "- DEDUCT THE NON-REFUNDABLE FARE PAID HALF RT AND",
                  "THE CANCELLATION FEE OF THE REFUNDABLE FARE.",
                  "- DEDUCT YQ OF NON-REFUNDABLE COMPONENT.",
                  "- DEDUCT NON-REFUNDABLE TAXES",
                  "- SERVICE FEE 6A THROUGH 6Z ARE NON-REFUNDABLE.",
                  "- THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILISED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE.",
                  "2.AFTER DEPARTURE / PARTIALLY UTILISED TICKETS -",
                  "AFTER COMMENCEMENT OF THE FIRST SECTOR OF THE",
                  "JOURNEY.",
                  "2.1 IF OUTBOUND FARE COMPONENT IS NON-",
                  "REFUNDABLE.",
                  "- DEDUCT THE OW CARRIER-IMPOSED FEES YQ AND FARE",
                  "OF EQUAL OR HIGHER AMOUNT THAN THE FARE PAID FOR",
                  "THE COMPONENT FOR WHICH JOURNEY HAS BEEN",
                  "PERFORMED IN THE SAME OR NEXT HIGHER RBD",
                  "- DEDUCT THE CANCELLATION FEE OF THE REFUNDABLE",
                  "FARE.",
                  "- DEDUCT NON-REFUNDABLE TAXES",
                  "- CARRIER IMPOSED FEES - YR AND SERVICE FEE 6A",
                  "THROUGH 6Z ARE NON-REFUNDABLE.",
                  "- NO REFUND OF FARE AND CARRIER IMPOSED FEES",
                  "YQ IF THE UTILISED OW FARE IS GREATER THAN THE",
                  "TICKETED FARE.",
                  "2.2 IF INBOUND FARE COMPONENT IS NON-REFUNDABLE.",
                  "- NO REFUND OF THE FARE AND CARRIER IMPOSED",
                  "FEES YQ /YR AND SERVICE FEE 6A THROUGH 6Z.",
                  "- DEDUCT NON-REFUNDABLE TAXES.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ/YR AND SERVICE FEE 6A THROUGH 6Z IF JOURNEY",
                  "PERFORMED BEYOND THE TURNAROUND/FARE BREAK POINT.",
                  "--------------------------------------------------"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "PEN"
                }
              },
              {
                "FareRuleCategory": {
                  "Code": "HIP"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "THE HIGHER INTERMEDIATE POINT RULE DOES NOT APPLY FOR",
                  "CONNECTIONS.",
                  "AND - THE HIGHER INTERMEDIATE POINT RULE DOES NOT APPLY",
                  "FOR STOPOVERS.",
                  "NOTE -",
                  "SURCHARGE AND EXCESS MILEAGE CAN BE IGNORED.",
                  "HIGHER INTERMEDIATE POINT RULE DOES NOT APPLY FOR",
                  "STOPOVERS/CONNECTIONS.",
                  "--------------------------------------------------",
                  "THESE FARES SHOULD NOT BE COMPARED/CHECKED WITH",
                  "THE FOLLOWING-",
                  "- HIGH IMMEDIATE FARE       -HIF-",
                  "- CIRCLE TRIP MINIMUM CHECK -CTM-"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "END"
                },
                "Text": [
                  "FOR QHSOPKE1 TYPE FARES",
                  "THE ORIGINAL AND THE REISSUED TICKET MUST BE ANNOTATED -",
                  "NON-END/SAVER - AND - REWARD UPGDS ALLOWED - IN THE",
                  "ENDORSEMENT BOX."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "CHD"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED   NOTE - GENERAL RULE DOES NOT",
                  "APPLY",
                  "ACCOMPANIED CHILD 2-11 - CHARGE 75 PERCENT OF THE FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS CH.",
                  "MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME COMPARTMENT",
                  "BY ADULT",
                  "OR - INFANT UNDER 2 WITHOUT A SEAT - CHARGE 10 PERCENT OF",
                  "THE FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS IN.",
                  "MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME",
                  "COMPARTMENT BY ADULT.",
                  "NOTE -",
                  "WHEN INFANT REACHES 2 YEARS OF AGE ON/BEFORE",
                  "DEPARTURE FROM POINT OF TURNAROUND A SEAT",
                  "MUST BE BOOKED ON THE RETURN LEG AND THE",
                  "APPLICABLE CHILD FARE CHARGED ON HALF ROUNDTRIP",
                  "BASIS WITH OUTBOUND INFANT FARE.THE EK INFORMATION",
                  "PAGE TITLED INFANTS IN THE VARIOUS GDS REFERENCE",
                  "PAGES REFLECTS THIS INFORMATION IN DETAIL.",
                  "OR - INFANT UNDER 2 WITH A SEAT - CHARGE 75 PERCENT OF THE",
                  "FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS CH.",
                  "MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME",
                  "COMPARTMENT BY ADULT",
                  "OR - UNACCOMPANIED CHILD 5-11 - CHARGE 100 PERCENT OF THE",
                  "FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS CH."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "TCO"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "TOUR CONDUCTOR - NO DISCOUNT."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "AGT"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "AGENT - NO DISCOUNT."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "DSC"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "NOTE -",
                  "NO OTHER DISCOUNTS PERMITTED"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "VOL"
                },
                "Text": [
                  "FROM/TO KENYA",
                  "IN THE EVENT OF CHANGES TO TICKETED FLIGHTS",
                  "BEFORE DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 150.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK T- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK L- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Q- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK K- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK U- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK B- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK M- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK X- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK R- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Y- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK E- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK W- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK H- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK O- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK I- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK C- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK J- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK A- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK F- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS.",
                  "OR -",
                  "BEFORE DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 75.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK T- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK L- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Q- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK K- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK U- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK B- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK M- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK X- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK R- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Y- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK E- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK W- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK H- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK O- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK I- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK C- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK J- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK A- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK F- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS.",
                  "OR -",
                  "AFTER DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 150.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK T- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK L- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Q- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK K- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK U- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK B- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK M- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK X- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK R- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Y- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK E- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK W- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK H- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK O- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK I- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK C- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK J- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK A- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK F- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS.",
                  "OR -",
                  "AFTER DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 75.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK T- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK L- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Q- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK K- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK U- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK B- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK M- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK X- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK R- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Y- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK E- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK W- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK H- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK O- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK I- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK C- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK J- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK A- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK F- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "REF"
                },
                "Text": [
                  "FROM/TO KENYA",
                  "APPLIES IN THE CASE OF DEATH OF PASSENGER OR FAMILY",
                  "MEMBER.",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "APPLIES IN THE CASE OF DEATH OF PASSENGER OR FAMILY",
                  "MEMBER.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 400.00 PER PRICING UNIT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER PRICING UNIT COLLECT HIGHEST. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 400.00 PER FARE COMPONENT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER FARE COMPONENT COLLECT EACH. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 200.00 PER PRICING UNIT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER PRICING UNIT COLLECT HIGHEST. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 200.00 PER FARE COMPONENT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER FARE COMPONENT COLLECT EACH. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR INFANT",
                  "WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE ANYTIME AFTER ORIGINALLY SCHEDULED FLIGHT",
                  "OF FIRST UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER FARE",
                  "COMPONENT COLLECT EACH. IF MIX OF PER FARE COMPONENT AND",
                  "PER PRICING UNIT CALCULATE EACH AS PER PRICING UNIT AND",
                  "COLLECT HIGHEST. NO CHARGE FOR INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE ANYTIME AFTER ORIGINALLY SCHEDULED FLIGHT",
                  "OF FIRST UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR INFANT",
                  "WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "APPLIES FOR FARE COMPONENTS THAT ARE FULLY FLOWN. REFUND",
                  "MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR INFANT",
                  "WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE BEFORE ORIGINALLY SCHEDULED FLIGHT OF FIRST",
                  "UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER FARE",
                  "COMPONENT COLLECT EACH. IF MIX OF PER FARE COMPONENT AND",
                  "PER PRICING UNIT CALCULATE EACH AS PER PRICING UNIT AND",
                  "COLLECT HIGHEST. NO CHARGE FOR INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "APPLIES FOR FARE COMPONENTS THAT ARE FULLY FLOWN. REFUND",
                  "MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER FARE",
                  "COMPONENT COLLECT EACH. IF MIX OF PER FARE COMPONENT AND",
                  "PER PRICING UNIT CALCULATE EACH AS PER PRICING UNIT AND",
                  "COLLECT HIGHEST. NO CHARGE FOR INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE BEFORE ORIGINALLY SCHEDULED FLIGHT OF FIRST",
                  "UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD."
                ]
              }
            ]
          }
        }
      },
      {
        "PTC": "INF",
        "travelerReferences": [
          "T1.1"
        ],
        "originDestinationIndex": "0",
        "segmentsIndex": [
          0,
          1
        ],
        "detailFareRule": {
          "Rules": {
            "Departure": {
              "AirportCode": {
                "value": "NBO"
              },
              "Date": "2023-12-18T00:00:00.000"
            },
            "Arrival": {
              "AirportCode": {
                "value": "DXB"
              }
            },
            "FareBasisCode": {
              "Code": "QHSOPKE1IN"
            },
            "AirlineID": {
              "value": "EK"
            },
            "FareCode": {
              "Code": "PUBL"
            },
            "Rule": [
              {
                "FareRuleCategory": {
                  "Code": "APP"
                },
                "Text": [
                  "FOR -PKE1 TYPE FARES",
                  "EMIRATES SAVER FARES",
                  "CAPACITY LIMITATIONS",
                  "THE CARRIER SHALL LIMIT THE NUMBER OF PASSENGERS CARRIED",
                  "ON ANY ONE FLIGHT AT FARES GOVERNED BY THIS RULE AND SUCH",
                  "FARES WILL NOT NECESSARILY BE AVAILABLE ON ALL FLIGHTS.",
                  "THE NUMBER OF SEATS WHICH THE CARRIER SHALL MAKE",
                  "AVAILABLE ON A GIVEN FLIGHT WILL BE DETERMINED BY THE",
                  "CARRIERS BEST JUDGMENT",
                  "OTHER CONDITIONS",
                  "ALL FARES ARE SUBJECT TO CHANGE OR WITHDRAWAL",
                  "WITHOUT NOTICE.",
                  "-----",
                  "FARES ARE ONLY GUARANTEED IF AUTOPRICED AND",
                  "TICKETED IN GDS ON THE SAME DAY.",
                  "TARIFF-023 RULE-KET5"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "SEA"
                },
                "Text": [
                  "BETWEEN AREA 2 AND EUROPE FOR HIGH SEASON FARES",
                  "PERMITTED 19AUG THROUGH 27AUG OR 15DEC THROUGH 24DEC ON",
                  "THE FIRST INTERNATIONAL SECTOR."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "FLT"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "EI FLIGHTS 5000 THROUGH 9999",
                  "AH FLIGHTS 3900 THROUGH 3999",
                  "KC FLIGHTS 350 THROUGH 355",
                  "KC FLIGHTS 577 THROUGH 578",
                  "KC FLIGHTS 697 THROUGH 698",
                  "KC FLIGHTS 1102 THROUGH 1199",
                  "KC FLIGHTS 1217 THROUGH 1297",
                  "KC FLIGHTS 1301 THROUGH 1308",
                  "KC FLIGHTS 1350 THROUGH 1355",
                  "KC FLIGHTS 1405 THROUGH 1407",
                  "KC FLIGHTS 1647 THROUGH 1649",
                  "KC FLIGHTS 1931 THROUGH 1938",
                  "KC FLIGHT 1924",
                  "KC FLIGHT 1961",
                  "KC FLIGHT 1963",
                  "BT FLIGHTS 5000 THROUGH 7999",
                  "SB FLIGHTS 0001 THROUGH 0099",
                  "SB FLIGHTS 900 THROUGH 9999",
                  "CA FLIGHTS 1151 THROUGH 1200",
                  "CA FLIGHTS 3000 THROUGH 3999",
                  "CA FLIGHTS 4075 THROUGH 4098",
                  "CA FLIGHTS 4600 THROUGH 4990",
                  "CA FLIGHTS 5000 THROUGH 9999",
                  "NX FLIGHTS 1000 THROUGH 9999",
                  "SW FLIGHTS 2000 THROUGH 9999",
                  "PX FLIGHTS 3005 THROUGH 3006",
                  "PX FLIGHTS 3040 THROUGH 3047",
                  "HM FLIGHTS 5000 THROUGH 7899",
                  "TN FLIGHTS 1000 THROUGH 2099",
                  "TN FLIGHTS 2200 THROUGH 9999",
                  "B2 FLIGHTS 0001 THROUGH 0708",
                  "FB FLIGHTS 1000 THROUGH 1999",
                  "K6 FLIGHTS 2000 THROUGH 3999",
                  "CX FLIGHTS 1000 THROUGH 1999",
                  "CX FLIGHTS 4000 THROUGH 7999",
                  "CX FLIGHTS 9000 THROUGH 9999",
                  "KA FLIGHTS 1000 THROUGH 9999",
                  "OU FLIGHTS 5000 THROUGH 5999",
                  "OK FLIGHTS 3000 THROUGH 5999",
                  "MS FLIGHTS 8000 THROUGH 9999",
                  "ET FLIGHTS 0001 THROUGH 0019",
                  "ET FLIGHTS 1000 THROUGH 1899",
                  "ET FLIGHTS 4000 THROUGH 4699",
                  "BR FLIGHTS 2001 THROUGH 3999",
                  "IE FLIGHTS 0704 THROUGH 0709",
                  "IE FLIGHTS 0712 THROUGH 0713",
                  "HA FLIGHTS 1900 THROUGH 9999",
                  "FJ FLIGHTS 500 THROUGH 599",
                  "FJ FLIGHTS 5000 THROUGH 6999.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LO FLIGHTS 4000 THROUGH 9999",
                  "LG FLIGHTS 1000 THROUGH 1999",
                  "ME FLIGHTS 1000 THROUGH 9999",
                  "AI FLIGHTS 3000 THROUGH 9999",
                  "PW FLIGHTS 2000 THROUGH 6999",
                  "BI FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 6100 THROUGH 6199",
                  "SK FLIGHTS 6400 THROUGH 6999",
                  "SK FLIGHTS 8000 THROUGH 9999",
                  "VN FLIGHTS 2000 THROUGH 4999",
                  "VN FLIGHTS 6000 THROUGH 6999",
                  "VS FLIGHTS 1000 THROUGH 9999",
                  "W2 FLIGHTS 0001 THROUGH 6199",
                  "W2 FLIGHTS 7250 THROUGH 9999",
                  "8M FLIGHTS 4000 THROUGH 4999",
                  "8M FLIGHTS 5000 THROUGH 5999",
                  "8M FLIGHTS 6000 THROUGH 6999",
                  "8M FLIGHTS 7000 THROUGH 7999",
                  "8M FLIGHTS 9500 THROUGH 9599",
                  "A3 FLIGHTS 1000 THROUGH 7099",
                  "A3 FLIGHTS 8000 THROUGH 9999",
                  "AR FLIGHTS 001 THROUGH 1099",
                  "AR FLIGHTS 1950 THROUGH 2999",
                  "AR FLIGHTS 7000 THROUGH 7999",
                  "LX FLIGHTS 3000 THROUGH 4999",
                  "LX FLIGHTS 7400 THROUGH 7499",
                  "NH FLIGHTS 2210 THROUGH 3200",
                  "NH FLIGHTS 3610 THROUGH 4840",
                  "NH FLIGHTS 5001 THROUGH 9999",
                  "UX FLIGHTS 2000 THROUGH 2999",
                  "UX FLIGHTS 3000 THROUGH 3999",
                  "4Z FLIGHTS 8000 THROUGH 8999",
                  "RQ FLIGHTS 1000 THROUGH 9999",
                  "CZ FLIGHTS 000 THROUGH 299",
                  "CZ FLIGHTS 501 THROUGH 599",
                  "CZ FLIGHTS 701 THROUGH 799",
                  "CZ FLIGHTS 2000 THROUGH 2899",
                  "CZ FLIGHTS 2900 THROUGH 2999",
                  "CZ FLIGHTS 1001 THROUGH 1999",
                  "CZ FLIGHTS 4001 THROUGH 4999",
                  "CZ FLIGHTS 7001 THROUGH 7999",
                  "CZ FLIGHTS 9000 THROUGH 9999",
                  "PC FLIGHTS 7500 THROUGH 7999",
                  "QV FLIGHTS 4000 THROUGH 4999",
                  "OD FLIGHTS 9000 THROUGH 9999",
                  "LA FLIGHTS 1510 THROUGH 1949",
                  "LA FLIGHTS 4800 THROUGH 4910.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 8300 THROUGH 8999",
                  "KQ FLIGHTS 1000 THROUGH 9999",
                  "P0 FLIGHTS 8000 THROUGH 8999",
                  "5Z FLIGHTS 8000 THROUGH 8999",
                  "RJ FLIGHTS 9000 THROUGH 9999",
                  "RJ FLIGHTS 6000 THROUGH 7999",
                  "RJ FLIGHTS 2000 THROUGH 4999",
                  "WY FLIGHTS 5000 THROUGH 7000",
                  "AD FLIGHTS 9900 THROUGH 9999",
                  "AD FLIGHTS 9400 THROUGH 9599",
                  "AD FLIGHTS 6000 THROUGH 7999",
                  "AD FLIGHTS 2000 THROUGH 2099",
                  "PK FLIGHTS 1860 THROUGH 1895",
                  "KP FLIGHTS 1000 THROUGH 9999",
                  "IB FLIGHTS 7000 THROUGH 7999",
                  "IB FLIGHTS 5000 THROUGH 5999",
                  "IB FLIGHTS 4000 THROUGH 4999",
                  "IB FLIGHTS 1027 THROUGH 2699",
                  "IB FLIGHTS 0980 THROUGH 0999",
                  "IB FLIGHTS 0658 THROUGH 0687",
                  "IB FLIGHTS 0050 THROUGH 0399",
                  "AC FLIGHTS 9000 THROUGH 9999",
                  "AC FLIGHTS 2500 THROUGH 6999",
                  "QF FLIGHTS 300 THROUGH 349",
                  "EK FLIGHTS 7434 THROUGH 7437",
                  "EK FLIGHTS 3470 THROUGH 3471",
                  "EK FLIGHTS 3482 THROUGH 3483",
                  "ET FLIGHTS 0060 THROUGH 0099",
                  "WB FLIGHTS 1000 THROUGH 9999",
                  "AV FLIGHTS 6660 THROUGH 6999",
                  "AV FLIGHTS 6000 THROUGH 6639",
                  "AV FLIGHTS 4300 THROUGH 4799",
                  "AV FLIGHTS 2000 THROUGH 2699.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 806 THROUGH 807",
                  "LA FLIGHTS 5041 THROUGH 7204",
                  "LA FLIGHTS 7405 THROUGH 7599",
                  "LA FLIGHTS 7228 THROUGH 7777",
                  "LA FLIGHTS 4966 THROUGH 5020",
                  "UL FLIGHTS 001 THROUGH 099",
                  "UL FLIGHTS 2000 THROUGH 4999",
                  "UL FLIGHTS 5000 THROUGH 5099",
                  "UL FLIGHTS 6000 THROUGH 6099",
                  "UL FLIGHTS 7000 THROUGH 7099",
                  "UL FLIGHTS 8000 THROUGH 8099",
                  "UL FLIGHTS 9000 THROUGH 9999",
                  "PK FLIGHTS 4000 THROUGH 5999",
                  "PK FLIGHTS 1888 THROUGH 1889",
                  "3U FLIGHTS 7000 THROUGH 7999",
                  "3U FLIGHTS 4000 THROUGH 5999",
                  "3U FLIGHTS 1000 THROUGH 2999",
                  "DE FLIGHTS 3000 THROUGH 9999",
                  "QF FLIGHTS 386 THROUGH 399",
                  "AV FLIGHTS 2851 THROUGH 2999",
                  "SY FLIGHTS 2000 THROUGH 9999",
                  "AY FLIGHTS 2501 THROUGH 7000",
                  "AY FLIGHTS 7501 THROUGH 9999",
                  "SA FLIGHTS 7702 THROUGH 7949",
                  "SA FLIGHTS 7000 THROUGH 7699",
                  "AZ FLIGHTS 7000 THROUGH 7999",
                  "AZ FLIGHTS 4100 THROUGH 5999",
                  "AZ FLIGHTS 2400 THROUGH 3999",
                  "WF FLIGHTS 020 THROUGH 029",
                  "WF FLIGHT 144",
                  "WF FLIGHTS 155 THROUGH 156",
                  "WF FLIGHT 175",
                  "WF FLIGHT 178",
                  "PR FLIGHTS 3004 THROUGH 3999.",
                  "AND",
                  "IF THE FARE COMPONENT INCLUDES TRAVEL WITHIN AREA 2",
                  "THEN THAT TRAVEL MUST BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "ANY 9B FLIGHT OPERATED BY 9B",
                  "ANY LM FLIGHT OPERATED BY LM",
                  "ANY QS FLIGHT OPERATED BY QS",
                  "ANY EI FLIGHT OPERATED BY EI",
                  "ANY BT FLIGHT OPERATED BY BT",
                  "ANY EK FLIGHT OPERATED BY BT",
                  "ANY B2 FLIGHT OPERATED BY B2",
                  "ANY FB FLIGHT OPERATED BY FB",
                  "ANY OU FLIGHT OPERATED BY OU",
                  "ANY OK FLIGHT OPERATED BY OK",
                  "ANY LO FLIGHT OPERATED BY LO",
                  "ANY LG FLIGHT OPERATED BY LG",
                  "ANY SK FLIGHT OPERATED BY SK",
                  "ANY WF FLIGHT OPERATED BY WF",
                  "ANY A3 FLIGHT OPERATED BY A3",
                  "ANY GQ FLIGHT OPERATED BY GQ",
                  "ANY A3 FLIGHT OPERATED BY OA",
                  "ANY UX FLIGHT OPERATED BY UX",
                  "ANY FI FLIGHT OPERATED BY FI",
                  "ANY PC FLIGHT OPERATED BY PC",
                  "ANY W2 FLIGHT OPERATED BY W2",
                  "ANY W2 FLIGHT OPERATED BY 2A",
                  "ANY AH FLIGHT OPERATED BY AH",
                  "ANY UU FLIGHT OPERATED BY UU",
                  "ANY AT FLIGHT OPERATED BY AT",
                  "ANY TU FLIGHT OPERATED BY TU",
                  "ANY LH FLIGHT OPERATED BY EN",
                  "ANY LH FLIGHT OPERATED BY CL",
                  "ANY LH FLIGHT OPERATED BY 2A",
                  "ANY LH FLIGHT OPERATED BY LH",
                  "ANY IB FLIGHT OPERATED BY I2",
                  "ANY IB FLIGHT OPERATED BY YW",
                  "ANY IB FLIGHT OPERATED BY IB",
                  "ANY EK FLIGHT OPERATED BY 7T",
                  "ANY 7T FLIGHT OPERATED BY 7T",
                  "ANY 2C FLIGHT OPERATED BY 2C",
                  "ANY EK FLIGHT OPERATED BY 2C",
                  "ANY TP FLIGHT OPERATED BY TP",
                  "ANY EK FLIGHT OPERATED BY TP",
                  "ANY KM FLIGHT OPERATED BY KM",
                  "ANY EK FLIGHT OPERATED BY S7",
                  "ANY S7 FLIGHT OPERATED BY S7",
                  "ANY VY FLIGHT OPERATED BY VY",
                  "ANY DX FLIGHT OPERATED BY DX",
                  "ANY LX FLIGHT OPERATED BY LX",
                  "ANY BP FLIGHT OPERATED BY BP",
                  "ANY 2J FLIGHT OPERATED BY 2J",
                  "ANY EK FLIGHT OPERATED BY EK",
                  "ANY FZ FLIGHT OPERATED BY FZ",
                  "ANY EK FLIGHT OPERATED BY FZ.",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "EI FLIGHTS 5000 THROUGH 9999",
                  "AH FLIGHTS 3900 THROUGH 3999",
                  "KC FLIGHTS 350 THROUGH 355",
                  "KC FLIGHTS 577 THROUGH 578",
                  "KC FLIGHTS 697 THROUGH 698",
                  "KC FLIGHTS 1102 THROUGH 1199",
                  "KC FLIGHTS 1217 THROUGH 1297",
                  "KC FLIGHTS 1301 THROUGH 1308",
                  "KC FLIGHTS 1350 THROUGH 1355",
                  "KC FLIGHTS 1405 THROUGH 1407",
                  "KC FLIGHTS 1647 THROUGH 1649",
                  "KC FLIGHTS 1931 THROUGH 1938",
                  "KC FLIGHT 1924",
                  "KC FLIGHT 1961",
                  "KC FLIGHT 1963",
                  "BT FLIGHTS 5000 THROUGH 7999",
                  "SB FLIGHTS 0001 THROUGH 0099",
                  "SB FLIGHTS 900 THROUGH 9999",
                  "CA FLIGHTS 1151 THROUGH 1200",
                  "CA FLIGHTS 3000 THROUGH 3999",
                  "CA FLIGHTS 4075 THROUGH 4098",
                  "CA FLIGHTS 4600 THROUGH 4990",
                  "CA FLIGHTS 5000 THROUGH 9999",
                  "NX FLIGHTS 1000 THROUGH 9999",
                  "SW FLIGHTS 2000 THROUGH 9999",
                  "PX FLIGHTS 3005 THROUGH 3006",
                  "PX FLIGHTS 3040 THROUGH 3047",
                  "HM FLIGHTS 5000 THROUGH 7899",
                  "TN FLIGHTS 1000 THROUGH 2099",
                  "TN FLIGHTS 2200 THROUGH 9999",
                  "B2 FLIGHTS 0001 THROUGH 0708",
                  "FB FLIGHTS 1000 THROUGH 1999",
                  "K6 FLIGHTS 2000 THROUGH 3999",
                  "CX FLIGHTS 1000 THROUGH 1999",
                  "CX FLIGHTS 4000 THROUGH 7999",
                  "CX FLIGHTS 9000 THROUGH 9999",
                  "KA FLIGHTS 1000 THROUGH 9999",
                  "OU FLIGHTS 5000 THROUGH 5999",
                  "OK FLIGHTS 3000 THROUGH 5999",
                  "MS FLIGHTS 8000 THROUGH 9999",
                  "ET FLIGHTS 0001 THROUGH 0019",
                  "ET FLIGHTS 1000 THROUGH 1899",
                  "ET FLIGHTS 4000 THROUGH 4699",
                  "BR FLIGHTS 2001 THROUGH 3999",
                  "IE FLIGHTS 0704 THROUGH 0709",
                  "IE FLIGHTS 0712 THROUGH 0713",
                  "HA FLIGHTS 1900 THROUGH 9999",
                  "FJ FLIGHTS 500 THROUGH 599",
                  "FJ FLIGHTS 5000 THROUGH 6999.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LO FLIGHTS 4000 THROUGH 9999",
                  "LG FLIGHTS 1000 THROUGH 1999",
                  "ME FLIGHTS 1000 THROUGH 9999",
                  "AI FLIGHTS 3000 THROUGH 9999",
                  "PW FLIGHTS 2000 THROUGH 6999",
                  "BI FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 3000 THROUGH 3999",
                  "SK FLIGHTS 6100 THROUGH 6199",
                  "SK FLIGHTS 6400 THROUGH 6999",
                  "SK FLIGHTS 8000 THROUGH 9999",
                  "VN FLIGHTS 2000 THROUGH 4999",
                  "VN FLIGHTS 6000 THROUGH 6999",
                  "VS FLIGHTS 1000 THROUGH 9999",
                  "W2 FLIGHTS 0001 THROUGH 6199",
                  "W2 FLIGHTS 7250 THROUGH 9999",
                  "8M FLIGHTS 4000 THROUGH 4999",
                  "8M FLIGHTS 5000 THROUGH 5999",
                  "8M FLIGHTS 6000 THROUGH 6999",
                  "8M FLIGHTS 7000 THROUGH 7999",
                  "8M FLIGHTS 9500 THROUGH 9599",
                  "A3 FLIGHTS 1000 THROUGH 7099",
                  "A3 FLIGHTS 8000 THROUGH 9999",
                  "AR FLIGHTS 001 THROUGH 1099",
                  "AR FLIGHTS 1950 THROUGH 2999",
                  "AR FLIGHTS 7000 THROUGH 7999",
                  "LX FLIGHTS 3000 THROUGH 4999",
                  "LX FLIGHTS 7400 THROUGH 7499",
                  "NH FLIGHTS 2210 THROUGH 3200",
                  "NH FLIGHTS 3610 THROUGH 4840",
                  "NH FLIGHTS 5001 THROUGH 9999",
                  "UX FLIGHTS 2000 THROUGH 2999",
                  "UX FLIGHTS 3000 THROUGH 3999",
                  "4Z FLIGHTS 8000 THROUGH 8999",
                  "RQ FLIGHTS 1000 THROUGH 9999",
                  "CZ FLIGHTS 000 THROUGH 299",
                  "CZ FLIGHTS 501 THROUGH 599",
                  "CZ FLIGHTS 701 THROUGH 799",
                  "CZ FLIGHTS 2000 THROUGH 2899",
                  "CZ FLIGHTS 2900 THROUGH 2999",
                  "CZ FLIGHTS 1001 THROUGH 1999",
                  "CZ FLIGHTS 4001 THROUGH 4999",
                  "CZ FLIGHTS 7001 THROUGH 7999",
                  "CZ FLIGHTS 9000 THROUGH 9999",
                  "PC FLIGHTS 7500 THROUGH 7999",
                  "QV FLIGHTS 4000 THROUGH 4999",
                  "OD FLIGHTS 9000 THROUGH 9999",
                  "LA FLIGHTS 1510 THROUGH 1949",
                  "LA FLIGHTS 4800 THROUGH 4910.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 8300 THROUGH 8999",
                  "KQ FLIGHTS 1000 THROUGH 9999",
                  "P0 FLIGHTS 8000 THROUGH 8999",
                  "5Z FLIGHTS 8000 THROUGH 8999",
                  "RJ FLIGHTS 9000 THROUGH 9999",
                  "RJ FLIGHTS 6000 THROUGH 7999",
                  "RJ FLIGHTS 2000 THROUGH 4999",
                  "WY FLIGHTS 5000 THROUGH 7000",
                  "AD FLIGHTS 9900 THROUGH 9999",
                  "AD FLIGHTS 9400 THROUGH 9599",
                  "AD FLIGHTS 6000 THROUGH 7999",
                  "AD FLIGHTS 2000 THROUGH 2099",
                  "PK FLIGHTS 1860 THROUGH 1895",
                  "KP FLIGHTS 1000 THROUGH 9999",
                  "IB FLIGHTS 7000 THROUGH 7999",
                  "IB FLIGHTS 5000 THROUGH 5999",
                  "IB FLIGHTS 4000 THROUGH 4999",
                  "IB FLIGHTS 1027 THROUGH 2699",
                  "IB FLIGHTS 0980 THROUGH 0999",
                  "IB FLIGHTS 0658 THROUGH 0687",
                  "IB FLIGHTS 0050 THROUGH 0399",
                  "AC FLIGHTS 9000 THROUGH 9999",
                  "AC FLIGHTS 2500 THROUGH 6999",
                  "QF FLIGHTS 300 THROUGH 349",
                  "EK FLIGHTS 7434 THROUGH 7437",
                  "EK FLIGHTS 3470 THROUGH 3471",
                  "EK FLIGHTS 3482 THROUGH 3483",
                  "ET FLIGHTS 0060 THROUGH 0099",
                  "WB FLIGHTS 1000 THROUGH 9999",
                  "AV FLIGHTS 6660 THROUGH 6999",
                  "AV FLIGHTS 6000 THROUGH 6639",
                  "AV FLIGHTS 4300 THROUGH 4799",
                  "AV FLIGHTS 2000 THROUGH 2699.",
                  "AND",
                  "THE FARE COMPONENT MUST NOT BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "LA FLIGHTS 806 THROUGH 807",
                  "LA FLIGHTS 5041 THROUGH 7204",
                  "LA FLIGHTS 7405 THROUGH 7599",
                  "LA FLIGHTS 7228 THROUGH 7777",
                  "LA FLIGHTS 4966 THROUGH 5020",
                  "UL FLIGHTS 001 THROUGH 099",
                  "UL FLIGHTS 2000 THROUGH 4999",
                  "UL FLIGHTS 5000 THROUGH 5099",
                  "UL FLIGHTS 6000 THROUGH 6099",
                  "UL FLIGHTS 7000 THROUGH 7099",
                  "UL FLIGHTS 8000 THROUGH 8099",
                  "UL FLIGHTS 9000 THROUGH 9999",
                  "PK FLIGHTS 4000 THROUGH 5999",
                  "PK FLIGHTS 1888 THROUGH 1889",
                  "3U FLIGHTS 7000 THROUGH 7999",
                  "3U FLIGHTS 4000 THROUGH 5999",
                  "3U FLIGHTS 1000 THROUGH 2999",
                  "DE FLIGHTS 3000 THROUGH 9999",
                  "QF FLIGHTS 386 THROUGH 399",
                  "AV FLIGHTS 2851 THROUGH 2999",
                  "SY FLIGHTS 2000 THROUGH 9999",
                  "AY FLIGHTS 2501 THROUGH 7000",
                  "AY FLIGHTS 7501 THROUGH 9999",
                  "SA FLIGHTS 7702 THROUGH 7949",
                  "SA FLIGHTS 7000 THROUGH 7699",
                  "AZ FLIGHTS 7000 THROUGH 7999",
                  "AZ FLIGHTS 4100 THROUGH 5999",
                  "AZ FLIGHTS 2400 THROUGH 3999",
                  "WF FLIGHTS 020 THROUGH 029",
                  "WF FLIGHT 144",
                  "WF FLIGHTS 155 THROUGH 156",
                  "WF FLIGHT 175",
                  "WF FLIGHT 178",
                  "PR FLIGHTS 3004 THROUGH 3999.",
                  "AND",
                  "IF THE FARE COMPONENT INCLUDES TRAVEL WITHIN AREA 2",
                  "THEN THAT TRAVEL MUST BE ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "ANY P0 FLIGHT OPERATED BY P0",
                  "ANY FA FLIGHT OPERATED BY FA",
                  "ANY HM FLIGHT OPERATED BY HM",
                  "ANY ET FLIGHT OPERATED BY ET",
                  "ANY TM FLIGHT OPERATED BY TM",
                  "ANY PW FLIGHT OPERATED BY PW",
                  "ANY KP FLIGHT OPERATED BY KP",
                  "ANY UR FLIGHT OPERATED BY UR",
                  "ANY HF FLIGHT OPERATED BY HF",
                  "ANY AW FLIGHT OPERATED BY AW",
                  "ANY ET FLIGHT OPERATED BY 3W",
                  "ANY WB FLIGHT OPERATED BY WB",
                  "ANY KQ FLIGHT OPERATED BY KQ",
                  "ANY 5Z FLIGHT OPERATED BY 5Z",
                  "ANY SA FLIGHT OPERATED BY SA",
                  "ANY MK FLIGHT OPERATED BY MK",
                  "ANY EK FLIGHT OPERATED BY MK",
                  "ANY 4Z FLIGHT OPERATED BY 4Z",
                  "ANY TC FLIGHT OPERATED BY TC",
                  "ANY EK FLIGHT OPERATED BY 4Z",
                  "ANY SS FLIGHT OPERATED BY SS",
                  "ANY EK FLIGHT OPERATED BY EK",
                  "ANY FZ FLIGHT OPERATED BY FZ",
                  "ANY EK FLIGHT OPERATED BY FZ",
                  "ANY FN FLIGHT OPERATED BY FN",
                  "ANY L6 FLIGHT OPERATED BY L6",
                  "ANY SK FLIGHT OPERATED BY SK",
                  "ANY AY FLIGHT OPERATED BY AY",
                  "ANY AZ FLIGHT OPERATED BY AZ",
                  "ANY A3 FLIGHT OPERATED BY A3",
                  "ANY EK FLIGHT OPERATED BY A3",
                  "ANY EK FLIGHT OPERATED BY AT",
                  "ANY EK FLIGHT OPERATED BY 9B",
                  "ANY EK FLIGHT OPERATED BY OA",
                  "ANY DE FLIGHT OPERATED BY DE."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "RES"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "RESERVATIONS ARE REQUIRED FOR ALL SECTORS.",
                  "WAITLIST NOT PERMITTED.",
                  "NOTE -",
                  "EK RESERVES THE RIGHT TO CANCEL ANY PNR WHERE",
                  "ITINERARIES DO NOT COMPLY WITH RESERVATION",
                  "BOOKING CODE /RBD/.",
                  "---",
                  "IF A TICKET IS USED OUT OF SEQUENCE EK RESERVES",
                  "THE RIGHT TO CANCEL ANY PNR OR INVALIDATE THE",
                  "REMAINING FLIGHT COUPONS.",
                  "---",
                  "IF A PASSENGER FAILS TO TRAVEL ON A SECTOR BOOKED",
                  "EK RESERVES THE RIGHT TO CANCEL REMAINING EK",
                  "SECTORS."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "MIN"
                },
                "Text": [
                  "NONE FOR ONE WAY FARES"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "MAX"
                },
                "Text": [
                  "NONE FOR ONE WAY FARES"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "STP"
                },
                "Text": [
                  "BETWEEN AREA 2 AND EUROPE FOR QHSOPKE1 TYPE FARES",
                  "UNLIMITED STOPOVERS PERMITTED IN EACH DIRECTION",
                  "LIMITED TO 2 FREE AND UNLIMITED AT USD 100.00 EACH.",
                  "1 FREE IN DXB IN EACH DIRECTION",
                  "UNLIMITED IN EACH DIRECTION AT USD 100.00 EACH.",
                  "A STOPOVER MAY NOT EXCEED 21 DAYS.",
                  "NOTE -",
                  "CHILD/INFANT DISCOUNT DOES NOT APPLY."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "TRF"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "UNLIMITED TRANSFERS PERMITTED ON THE PRICING UNIT",
                  "FARE BREAK AND EMBEDDED SURFACE SECTORS NOT PERMITTED ON",
                  "THE FARE COMPONENT.",
                  "NOTE -",
                  "AS PER SPECIFIED PUBLISHED ROUTING ATTACHED.",
                  "SURFACE SECTORS NOT PERMITTED."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "CMB"
                },
                "Text": [
                  "FOR ONE WAY FARES",
                  "SINGLE/DOUBLE OPEN JAWS/ROUND TRIPS/CIRCLE TRIPS NOT",
                  "PERMITTED.",
                  "APPLICABLE ADD-ON CONSTRUCTION IS ADDRESSED IN",
                  "MISCELLANEOUS PROVISIONS - CATEGORY 23.",
                  "END-ON-END",
                  "END-ON-END COMBINATIONS NOT PERMITTED. VALIDATE ALL FARE",
                  "COMPONENTS. SIDE TRIPS PERMITTED.",
                  "PROVIDED -",
                  "COMBINATIONS ARE FOR CARRIER EK/FZ/B6",
                  "COMBINATIONS ARE FOR ANY CARRIER FROM/TO DEPARTURE FROM",
                  "JOURNEY ORIGIN IN AREA 1",
                  "COMBINATIONS ARE FOR ANY CARRIER FROM/TO ISRAEL",
                  "OR FOR ANY CARRIER FROM/TO SUDAN",
                  "OR FOR ANY CARRIER FROM/TO DEPARTURE FROM JOURNEY",
                  "ORIGIN IN EUROPE",
                  "OR FOR ANY CARRIER FROM/TO DEPARTURE FROM JOURNEY",
                  "ORIGIN IN MIDDLE EAST",
                  "OR FOR ANY CARRIER FROM/TO DEPARTURE FROM JOURNEY",
                  "ORIGIN IN AREA 3",
                  "END-ON-END",
                  "END-ON-END COMBINATIONS PERMITTED. VALIDATE ALL FARE",
                  "COMPONENTS. TRAVEL MUST BE VIA THE POINT OF COMBINATION.",
                  "SIDE TRIPS PERMITTED."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "SUR"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "OUTBOUND -",
                  "A SURCHARGE OF USD 50.00 PER FARE COMPONENT WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM 15DEC",
                  "THROUGH 24DEC.",
                  "AND - A SURCHARGE OF USD 30.00 PER FARE COMPONENT WILL",
                  "BE ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM",
                  "19AUG THROUGH 27AUG.",
                  "INBOUND -",
                  "A SURCHARGE OF USD 50.00 PER FARE COMPONENT WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM 02JAN",
                  "THROUGH 08JAN.",
                  "AND - A SURCHARGE OF USD 30.00 PER FARE COMPONENT WILL",
                  "BE ADDED TO THE APPLICABLE FARE FOR TRAVEL FROM",
                  "14JUL THROUGH 30JUL.",
                  "UNLESS OTHERWISE SPECIFIED",
                  "THE PROVISIONS BELOW APPLY ONLY AS FOLLOWS -",
                  "SALE IS RESTRICTED TO SPECIFIC AGENTS.",
                  "TICKETS MAY ONLY BE SOLD IN BANGLADESH.",
                  "A SURCHARGE OF 9 PERCENT OF THE FARE PER TICKET WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL. APPLIES PER",
                  "ADULT. CHILD/INFANT DISCOUNTS APPLY.",
                  "THE PROVISIONS BELOW APPLY ONLY AS FOLLOWS -",
                  "TICKETS MAY ONLY BE SOLD IN BANGLADESH.",
                  "A SURCHARGE OF 9 PERCENT OF THE FARE PER TICKET WILL BE",
                  "ADDED TO THE APPLICABLE FARE FOR TRAVEL."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "SUR"
                }
              },
              {
                "FareRuleCategory": {
                  "Code": "TKT"
                },
                "Text": [
                  "FROM/TO KENYA FOR -KE1 TYPE FARES",
                  "TICKETS MUST BE ISSUED ON EK AND MAY NOT BE SOLD IN SUDAN/",
                  "VENEZUELA/MALAWI/IRAN,ISLAMIC REPUBLIC OF/ETHIOPIA/ANGOLA/",
                  "NIGERIA/CAMEROON/GABON/CHAD/CONGO (BRAZZAVILLE)/ARGENTINA/",
                  "EGYPT/BANGLADESH/PAKISTAN",
                  "IF THE FARE COMPONENT IS ON",
                  "ONE OR MORE OF THE FOLLOWING",
                  "ANY EK FLIGHT OPERATED BY EK",
                  "ANY EK FLIGHT OPERATED BY FZ",
                  "ANY FZ FLIGHT OPERATED BY FZ",
                  "TICKETS MUST BE ISSUED ON EK OR HR AND MAY NOT BE SOLD",
                  "IN SUDAN/VENEZUELA/MALAWI/IRAN,ISLAMIC REPUBLIC OF/",
                  "ETHIOPIA/ANGOLA/NIGERIA/CAMEROON/GABON/CHAD/CONGO",
                  "(BRAZZAVILLE)/ARGENTINA/EGYPT/BANGLADESH/PAKISTAN",
                  "OR - TICKETS MUST BE ISSUED ON EK OR FZ AND MAY NOT BE",
                  "SOLD IN SUDAN/VENEZUELA/MALAWI/IRAN,ISLAMIC",
                  "REPUBLIC OF/ETHIOPIA/ANGOLA/NIGERIA/CAMEROON/GABON/",
                  "CHAD/CONGO (BRAZZAVILLE)/ARGENTINA/EGYPT/",
                  "BANGLADESH/PAKISTAN"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "PEN"
                },
                "Text": [
                  "FROM/TO KENYA FOR QHSOPKE1 TYPE ALL ECONOMY CABIN FARE TYPES FARES",
                  "CHANGES",
                  "ANY TIME",
                  "CHARGE USD 75.00.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "CHARGE USD 150.00 FOR NO-SHOW.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "CANCELLATIONS",
                  "BEFORE DEPARTURE",
                  "CHARGE USD 200.00 FOR CANCEL/REFUND.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "CHARGE USD 400.00 FOR NO-SHOW.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "AFTER DEPARTURE",
                  "TICKET IS NON-REFUNDABLE IN CASE OF CANCEL/REFUND.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "TICKET IS NON-REFUNDABLE IN CASE OF NO-SHOW.",
                  "WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "NOTE -",
                  "--------------------------------------------------",
                  "IF THE DATE REQUIRED IS BEYOND THE BOOKING RANGE",
                  "I.E.AFTER 11MONTHS THE REQUIRED FLIGHT/DATE SHOULD",
                  "BE SPECIFIED IN THE PNR FOR A FREE DATE CHANGE AND",
                  "AMENDED WITHIN ONE MONTH OF DEPARTURE. IF NOT",
                  "APPLICABLE CHANGE FEE APPLIES.",
                  "--------------------------------------------------",
                  "UNLESS OTHERWISE SPECIFIED",
                  "NOTE -",
                  "CHANGES",
                  "--------------------------------------------------",
                  "... A CHANGE IS A DATE/FLIGHT/ROUTING/BOOKING",
                  "CODE CHANGE.",
                  "... CHANGE FEE APPLIES PER PASSENGER PER",
                  "TRANSACTION.",
                  "... CHANGE FEE DOES NOT APPLY TO INFANT NOT",
                  "OCCUPYING A SEAT.",
                  "... CHD/INF WITH A SEAT DISCOUNT DOES NOT APPLY",
                  "ON THE CHANGE/REROUTING FEES.",
                  "... CHANGE IS PERMITTED WITHIN TICKET VALIDITY OF",
                  "ORIGINAL TICKET.",
                  "... CHANGES ONLY PERMITTED TO FARE OF EQUIVALENT",
                  "OR HIGHER VALUE.",
                  "--------------------------------------------------",
                  "WAIVERS",
                  "1.WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "A COPY OF VALID DEATH CERTIFICATE ISSUED BY A",
                  "COMPETENT MEDICAL AUTHORITY IS REQUIRED.",
                  "FAMILY MEMBERS AS DEFINED IN EK CONDITIONS OF",
                  "CARRIAGE OR PASSENGER AIRLINE TARIFF RULE BOOK.",
                  "2.NO WAIVER APPLICABLE FOR ILLNESS OF PASSENGER",
                  "OR FAMILY MEMBER.",
                  "3.CONTACT EK OFFICE FOR WAIVERS DEFINED ABOVE.",
                  "--------------------------------------------------",
                  "CHANGES AGAINST NO SHOW",
                  "- A NO-SHOW FOR A FLIGHT IS CONSIDERED WHEN A",
                  "PASSENGER FAILS TO REPORT TO THE AIRPORT AS",
                  "BOOKED ONE HOUR BEFORE DEPARTURE OF THE",
                  "SCHEDULED FLIGHT.",
                  "- FAILURE TO UTILISE TICKET AS BOOKED ON ANY",
                  "SEGMENT OF THE ITINERARY WILL RESULT IN ALL",
                  "SUBSEQUENT SEGMENTS OF THE ITINERARY BEING",
                  "CANCELLED. IN SUCH CASES NO-SHOW FEE WILL APPLY.",
                  "- IN CASE OF NO-SHOW ONLY ONE FEE IS TO BE CHARGED",
                  "I.E. EITHER THE NO-SHOW FEE OR THE CHANGE FEE",
                  "WHICHEVER IS HIGHER AND NOT BOTH.",
                  "--------------------------------------------------",
                  "UPGRADES - APPLICABLE ONLY IF CHANGES ARE",
                  "PERMITTED.",
                  "1.UPGRADES TO HIGHER FARE IN THE SAME CABIN.",
                  "RECALCULATE THE FARE FROM THE POINT OF ORIGIN",
                  "PROVIDED THE FARE RULE CONDITIONS OF THE",
                  "HIGHER FARE ARE MET.",
                  "COLLECT THE FARE DIFFERENCE AND CHANGE FEE",
                  "APPLIES PER PASSENGER PER TRANSACTION.",
                  "IF THE UPGRADED TICKET IS SUBSEQUENTLY",
                  "CANCELLED THE ORIGINAL CHARGE WILL APPLY.",
                  "2.UPGRADES TO HIGHER FARE IN A HIGHER CABIN.",
                  "RECALCULATE THE FARE FROM THE POINT OF ORIGIN",
                  "PROVIDED THE FARE RULE CONDITIONS OF THE HIGHER",
                  "FARE ARE MET.",
                  "COLLECT THE FARE DIFFERENCE. CHANGE FEE IS",
                  "WAIVED FOR UPGRADE TO HIGHER CABIN.",
                  "IF THE UPGRADED TICKET IS SUBSEQUENTLY",
                  "CANCELLED THE ORIGINAL CHARGE WILL APPLY.",
                  "--------------------------------------------------",
                  "VOLUNTARY DOWNGRADE - NO REFUNDS IN CASE OF",
                  "VOLUNTARY DOWNGRADE.",
                  "--------------------------------------------------",
                  "PENALTY FEE APPLICATION",
                  "1.ANY TIME WHEN THIS FARE IS COMBINED WITH",
                  "ANOTHER FARE AND ONLY ONE FARE COMPONENT IS",
                  "CHANGED THE PENALTY CONDITIONS OF THE CHANGED",
                  "FARE COMPONENT WILL APPLY.",
                  "2.ANY TIME WHEN MORE THAN ONE FARE COMPONENT IS",
                  "BEING CHANGED THE HIGHEST PENALTY OF ALL",
                  "CHANGED FARE COMPONENTS WILL APPLY.",
                  "--------------------------------------------------",
                  "REPRICING CONDITIONS",
                  "ONE FREE DATE CHANGE FOR INBOUND JOURNEY",
                  "PERMITTED IF OUTSIDE SYSTEM RANGE AT TIME OF",
                  "TICKETING APPLICABLE ONLY IF REISSUED BEFORE",
                  "DEPARTURE.  FARE DIFFERENCE MAY APPLY DEPENDING",
                  "ON AVAILABILITY AT TIME OF CHANGE - REFER",
                  "CAPACITY LIMITATIONS.  NO REISSUE FEE APPLIES IF",
                  "DATE CHANGE AND FARE DIFFERENCE COMPLETED IN SAME",
                  "TRANSACTION.",
                  "A.BEFORE DEPARTURE / FULLY UNUTILISED TICKETS",
                  "IN THE EVENT OF VOLUNTARY CHANGES TO ANY",
                  "FLIGHT/DATE ON THE ITINERARY TICKET HAS TO BE",
                  "REISSUED TO FARE OF EQUIVALENT OR HIGHER",
                  "VALUE AND COLLECT ANY FARE DIFFERENCE AS AN",
                  "ADC. THE FARES FOR THE PASSENGER JOURNEY",
                  "SHALL BE RECALCULATED FROM THE POINT OF",
                  "ORIGIN BASEDON THE DATE OF REISSUE.CHANGE",
                  "FEES IF ANY TO BE COLLECTED AS PER THE",
                  "ORIGINAL FARE PAID AND SHOWN ON TICKET AS AN",
                  "OD TAX PLUS ANY ADDITIONAL TAXES.",
                  "B.AFTER DEPARTURE / PARTIALLY UTILISED TICKETS",
                  "AFTER COMMENCEMENT OF THE FIRST SECTOR OF THE",
                  "JOURNEY OR THE JOURNEY PERFORMED TILL THE",
                  "TURNAROUND / FARE BREAK POINT.",
                  "IN THE EVENT OF VOLUNTARY CHANGES AFTER",
                  "COMMENCEMENT OF TRAVEL THE FARES FOR THE",
                  "PASSENGER JOURNEY SHALL BE RECALCULATED FROM",
                  "THE POINT OF ORIGIN IN ACCORDANCE WITH THE",
                  "FARES IN EFFECT ON THE DATE OF ISSUE OF THE",
                  "TICKET USED FOR THE OUTBOUND FLIGHT AND",
                  "COLLECT ANY FARE DIFFERENCE",
                  "AS AN ADC PLUS THE APPLICABLE CHANGE FEE FOR",
                  "THE TICKETED FARE AS OD TAX PLUS ANY",
                  "ADDITIONAL TAXES ON THE NEW TICKET. NEW",
                  "TICKET TO BE RE-ISSUED TO FARE OF EQUIVALENT",
                  "OR HIGHER VALUE.",
                  "--------------------------------------------------",
                  "CANCELLATION / REFUNDS",
                  "1.CANCELLATION /REFUND FEES ARE NOT COMMISSIONABLE",
                  "2.CANCELLATION FEE DOES NOT APPLY TO INFANT NOT",
                  "OCCUPYING A SEAT.",
                  "3.THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILIZED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE",
                  "4.THE DISTRIBUTION SURCHARGE YR REFUND WILL BE",
                  "MADE TO THE  ORIGINAL FORM OF PAYMENT",
                  "--------------------------------------------------",
                  "WAIVERS",
                  "1.WAIVED FOR DEATH OF PASSENGER OR FAMILY MEMBER.",
                  "A COPY OF VALID DEATH CERTIFICATE ISSUED BY A",
                  "COMPETENT MEDICAL AUTHORITY IS REQUIRED.",
                  "FAMILY MEMBERS AS DEFINED IN EK CONDITIONS OF",
                  "CARRIAGE OR PASSENGER AIRLINE TARIFF RULE",
                  "BOOK.",
                  "2.NO WAIVER APPLICABLE FOR ILLNESS OF PASSENGER",
                  "OR FAMILY MEMBER.",
                  "3.CONTACT EK LOCAL OFFICE FOR WAIVERS DEFINED",
                  "ABOVE.",
                  "--------------------------------------------------",
                  "CANCELLATION / REFUNDS AGAINST NO SHOW.",
                  "... A NO-SHOW FOR A FLIGHT IS CONSIDERED WHEN A",
                  "PASSENGER FAILS TO REPORT AT THE AIRPORT AS",
                  "BOOKED ONE HOUR BEFORE DEPARTURE OF THE",
                  "SCHEDULED FLIGHT.",
                  "... FAILURE TO UTILISED TICKET AS BOOKED ON ANY",
                  "SEGMENT OF THE ITINERARY WILL RESULT IN ALL",
                  "SUBSEQUENT SEGMENTS OF THE ITINERARY BEING",
                  "CANCELLED. IN SUCH CASES ONLY NO-SHOW FEE",
                  "WILL APPLY AND NOT BOTH.",
                  "... NO SHOW  FEE IS NON COMMISSIONABLE.",
                  "--------------------------------------------------",
                  "CANCELLATION / REFUNDS AGAINST UPGRADES -",
                  "IF THE UPGRADED TICKET IS SUBSEQUENTLY CANCELLED",
                  "THE ORIGINAL CHARGE WILL APPLY.",
                  "IN ADDITION TO THE ABOVE -",
                  "- REFUND AGAINST UPGRADES WHERE ORIGINAL FARE IS",
                  "NON-REFUNDABLE AND NEW FARE IS REFUNDABLE ONLY",
                  "THE ADC AMOUNT AND REFUNDABLE TAXES CAN BE",
                  "REIMBURSED.",
                  "- REFUND AGAINST UPGRADES WHERE ORIGINAL FARE IS",
                  "REFUNDABLE AND NEW FARE IS NON-REFUNDABLE",
                  "ORIGINAL FARE PLUS CARRIER IMPOSED FEE AND",
                  "REFUNDABLE TAXES CAN BE REFUNDED",
                  "--------------------------------------------------",
                  "OUT OF SEQUENCE TICKETS -",
                  "ANYTIME TICKETS IS UTILIZED OUT OF SEQUENCE NO",
                  "REFUND WILL BE GIVEN AND/OR CARRIER IMPOSED",
                  "SURCHARGE - YQ/YR.",
                  "--------------------------------------------------",
                  "A.WHEN OUTBOUND AND INBOUND FARES ARE REFUNDABLE.",
                  "WHEN COMBINING FARES THAT HAVE CANCELLATION",
                  "FEES THE HIGHEST CANCELLATION FEE OF EACH",
                  "CANCELLED PRICING UNIT APPLIES.",
                  "A1.BEFORE DEPARTURE / FULLY UNUTILISED TICKETS",
                  "- DEDUCT THE APPLICABLE HIGHEST CANCELLATION FEE",
                  "FROM THE TOTAL OF THE BASE FARE AND CARRIER-",
                  "IMPOSED SURCHARGE YQ/YR.",
                  "- THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILISED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE.",
                  "- REFUND THE RESIDUAL AMOUNT ALONG WITH THE",
                  "REFUNDABLE GOVERNMENT TAXES",
                  "- CARRIER IMPOSED SERVICE FEE 6A THROUGH 6Z ARE",
                  "NOT REFUNDABLE.",
                  "A2.AFTER DEPARTURE / PARTIALLY UTILISED TICKETS -",
                  "AFTER COMMENCEMENT OF THE FIRST SECTOR OF THE",
                  "JOURNEY.",
                  "RETURN JOURNEY",
                  "- DEDUCT THE OW FARE OF EQUAL OR HIGHER AMOUNT",
                  "THAN THE FARE PAID FOR THE COMPONENT FOR WHICH",
                  "JOURNEY HAS BEEN PERFORMED IN THE SAME OR NEXT",
                  "HIGHER RBD",
                  "- CHARGE THE APPLICABLE CANCELLATION FEE AND THE",
                  "CARRIER-IMPOSED SURCHARGE - YQ FOR THE COMPONENT",
                  "FOR WHICH JOURNEY HAS BEEN PERFORMED. DEDUCT NON-",
                  "REFUNDABLE TAXES.",
                  "- REFUND UNUTILISED GOVERNMENT TAXES FOR THE",
                  "UNUSED PORTION/SECTOR OF THE JOURNEY.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED",
                  "SURCHARGES YQ IF THE UTILISED OW COMPONENT FARE",
                  "IS GREATER THAN THE TICKETED FARE.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ IF JOURNEY PERFORMED BEYOND THE",
                  "TURNAROUND/FARE BREAK POINT.",
                  "- CARRIER-IMPOSED SURCHARGE YR AND SERVICE FEE 6A",
                  "THROUGH 6Z IS NOT REFUNDABLE.",
                  "ONE-WAY JOURNEY",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ/YR AND SERVICE FEE 6A THROUGH 6Z AFTER",
                  "COMMENCEMENT OF THE FIRST SECTOR OF THE JOURNEY",
                  "- REFUND UNUTILISED GOVERNMENT TAXES FOR THE",
                  "UNUSED PORTION/SECTOR OF THE JOURNEY.",
                  "--------------------------------------------------",
                  "B.WHEN OUTBOUND AND INBOUND FARES ARE NON -",
                  "REFUNDABLE.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ AND SERVICE FEE 6A THROUGH 6Z.",
                  "- DEDUCT NON-REFUNDABLE TAXES.",
                  "- THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILISED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE.",
                  "--------------------------------------------------",
                  "C.COMBINATION OF REFUNDABLE AND NON-REFUNDABLE",
                  "FARES.",
                  "1.BEFORE DEPARTURE/FULLY UNUTILISED TICKETS.",
                  "- DEDUCT THE NON-REFUNDABLE FARE PAID HALF RT AND",
                  "THE CANCELLATION FEE OF THE REFUNDABLE FARE.",
                  "- DEDUCT YQ OF NON-REFUNDABLE COMPONENT.",
                  "- DEDUCT NON-REFUNDABLE TAXES",
                  "- SERVICE FEE 6A THROUGH 6Z ARE NON-REFUNDABLE.",
                  "- THE DISTRIBUTION SURCHARGE YR WILL ONLY BE",
                  "REFUNDABLE FOR FULLY UNUTILISED TICKETS. IN ALL",
                  "OTHER SCENARIOS THE SURCHARGE IS NON-REFUNDABLE.",
                  "2.AFTER DEPARTURE / PARTIALLY UTILISED TICKETS -",
                  "AFTER COMMENCEMENT OF THE FIRST SECTOR OF THE",
                  "JOURNEY.",
                  "2.1 IF OUTBOUND FARE COMPONENT IS NON-",
                  "REFUNDABLE.",
                  "- DEDUCT THE OW CARRIER-IMPOSED FEES YQ AND FARE",
                  "OF EQUAL OR HIGHER AMOUNT THAN THE FARE PAID FOR",
                  "THE COMPONENT FOR WHICH JOURNEY HAS BEEN",
                  "PERFORMED IN THE SAME OR NEXT HIGHER RBD",
                  "- DEDUCT THE CANCELLATION FEE OF THE REFUNDABLE",
                  "FARE.",
                  "- DEDUCT NON-REFUNDABLE TAXES",
                  "- CARRIER IMPOSED FEES - YR AND SERVICE FEE 6A",
                  "THROUGH 6Z ARE NON-REFUNDABLE.",
                  "- NO REFUND OF FARE AND CARRIER IMPOSED FEES",
                  "YQ IF THE UTILISED OW FARE IS GREATER THAN THE",
                  "TICKETED FARE.",
                  "2.2 IF INBOUND FARE COMPONENT IS NON-REFUNDABLE.",
                  "- NO REFUND OF THE FARE AND CARRIER IMPOSED",
                  "FEES YQ /YR AND SERVICE FEE 6A THROUGH 6Z.",
                  "- DEDUCT NON-REFUNDABLE TAXES.",
                  "- NO REFUND OF FARE AND CARRIER-IMPOSED SURCHARGE",
                  "YQ/YR AND SERVICE FEE 6A THROUGH 6Z IF JOURNEY",
                  "PERFORMED BEYOND THE TURNAROUND/FARE BREAK POINT.",
                  "--------------------------------------------------"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "PEN"
                }
              },
              {
                "FareRuleCategory": {
                  "Code": "HIP"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "THE HIGHER INTERMEDIATE POINT RULE DOES NOT APPLY FOR",
                  "CONNECTIONS.",
                  "AND - THE HIGHER INTERMEDIATE POINT RULE DOES NOT APPLY",
                  "FOR STOPOVERS.",
                  "NOTE -",
                  "SURCHARGE AND EXCESS MILEAGE CAN BE IGNORED.",
                  "HIGHER INTERMEDIATE POINT RULE DOES NOT APPLY FOR",
                  "STOPOVERS/CONNECTIONS.",
                  "--------------------------------------------------",
                  "THESE FARES SHOULD NOT BE COMPARED/CHECKED WITH",
                  "THE FOLLOWING-",
                  "- HIGH IMMEDIATE FARE       -HIF-",
                  "- CIRCLE TRIP MINIMUM CHECK -CTM-"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "END"
                },
                "Text": [
                  "FOR QHSOPKE1 TYPE FARES",
                  "THE ORIGINAL AND THE REISSUED TICKET MUST BE ANNOTATED -",
                  "NON-END/SAVER - AND - REWARD UPGDS ALLOWED - IN THE",
                  "ENDORSEMENT BOX."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "CHD"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED   NOTE - GENERAL RULE DOES NOT",
                  "APPLY",
                  "ACCOMPANIED CHILD 2-11 - CHARGE 75 PERCENT OF THE FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS CH.",
                  "MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME COMPARTMENT",
                  "BY ADULT",
                  "OR - INFANT UNDER 2 WITHOUT A SEAT - CHARGE 10 PERCENT OF",
                  "THE FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS IN.",
                  "MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME",
                  "COMPARTMENT BY ADULT.",
                  "NOTE -",
                  "WHEN INFANT REACHES 2 YEARS OF AGE ON/BEFORE",
                  "DEPARTURE FROM POINT OF TURNAROUND A SEAT",
                  "MUST BE BOOKED ON THE RETURN LEG AND THE",
                  "APPLICABLE CHILD FARE CHARGED ON HALF ROUNDTRIP",
                  "BASIS WITH OUTBOUND INFANT FARE.THE EK INFORMATION",
                  "PAGE TITLED INFANTS IN THE VARIOUS GDS REFERENCE",
                  "PAGES REFLECTS THIS INFORMATION IN DETAIL.",
                  "OR - INFANT UNDER 2 WITH A SEAT - CHARGE 75 PERCENT OF THE",
                  "FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS CH.",
                  "MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME",
                  "COMPARTMENT BY ADULT",
                  "OR - UNACCOMPANIED CHILD 5-11 - CHARGE 100 PERCENT OF THE",
                  "FARE.",
                  "TICKETING CODE - BASE FARE CODE PLUS CH."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "TCO"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "TOUR CONDUCTOR - NO DISCOUNT."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "AGT"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "AGENT - NO DISCOUNT."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "DSC"
                },
                "Text": [
                  "UNLESS OTHERWISE SPECIFIED",
                  "NOTE -",
                  "NO OTHER DISCOUNTS PERMITTED"
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "VOL"
                },
                "Text": [
                  "FROM/TO KENYA",
                  "IN THE EVENT OF CHANGES TO TICKETED FLIGHTS",
                  "BEFORE DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 150.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK T- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK L- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Q- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK K- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK U- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK B- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK M- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK X- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK R- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Y- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK E- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK W- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK H- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK O- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK I- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK C- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK J- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK A- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "3. EK F- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS.",
                  "OR -",
                  "BEFORE DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 75.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK T- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK L- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Q- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK K- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK U- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK B- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK M- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK X- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK R- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK Y- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK E- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK W- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK H- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK O- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK I- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK C- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK J- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK A- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT TODAY",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "3. EK F- FARE FAMILY IS USED",
                  "4. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "5. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "6. ADV RES IS MEASURED FROM REISSUE DATE TO DEPARTURE",
                  "OF PRICING UNIT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS.",
                  "OR -",
                  "AFTER DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 150.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK T- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK L- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Q- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK K- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK U- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK B- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK M- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK X- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK R- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Y- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK E- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK W- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK H- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK O- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK I- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK C- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK J- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK A- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS AFTER ORIGINAL SCHEDULED FLIGHT",
                  "4. EK F- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS.",
                  "OR -",
                  "AFTER DEPARTURE OF JOURNEY - APPLIES WITHIN TKT VALIDITY",
                  "CERTAIN DOMESTIC REISSUE PROVISIONS MAY BE OVERRIDDEN BY",
                  "THOSE OF EK INTERNATIONAL FARES",
                  "CHARGE USD 75.00 FOR REISSUE OR HIGHEST FEE OF ALL",
                  "CHANGED FARE COMPONENTS - DISCOUNTS APPLY - NO FEE FOR",
                  "INFANTS W/O SEAT AND",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK T- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK L- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Q- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK K- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK U- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK B- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK M- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK X- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK R- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK Y- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK E- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK W- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK H- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK O- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK I- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK C- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK J- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK A- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "OR -",
                  "REPRICE USING FARES IN EFFECT WHEN TKT WAS ISSUED",
                  "PROVIDED ALL OF THE FOLLOWING CONDITIONS ARE MET-",
                  "1. WHEN NO INTL COUPONS REMAIN - ALL NEW TRAVEL MUST",
                  "BE DOMESTIC",
                  "2. FULLY FLOWN FARE NOT REPRICED TO FURTHER POINT",
                  "3. CHANGE IS BEFORE ORIGINAL SCHEDULED FLIGHT",
                  "4. EK F- FARE FAMILY IS USED",
                  "5. PUBLIC FARES ARE USED IF TICKETED FARE IS IN",
                  "PUBLIC TARIFF. PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF",
                  "6. NEW TKT HAS EQUAL OR HIGHER VALUE THAN PREVIOUS",
                  "TKT",
                  "7. ALL RULE AND BOOKING CODE PROVISIONS ARE MET",
                  "8. ADV RES IS MEASURED FROM ORIGINAL TKT DATE TO",
                  "DEPARTURE OF FARE COMPONENT",
                  "WHEN CHANGE RESULTS IN LOWER FARE REFUND RESIDUAL THEN",
                  "ADD-COLLECT - REFUND VIA ORIGINAL FORM OF PAYMENT",
                  "ENDORSEMENT BOX- HIGHER NON-REF AMT AND NEW ENDORSEMENTS."
                ]
              },
              {
                "FareRuleCategory": {
                  "Code": "REF"
                },
                "Text": [
                  "FROM/TO KENYA",
                  "APPLIES IN THE CASE OF DEATH OF PASSENGER OR FAMILY",
                  "MEMBER.",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "APPLIES IN THE CASE OF DEATH OF PASSENGER OR FAMILY",
                  "MEMBER.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 400.00 PER PRICING UNIT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER PRICING UNIT COLLECT HIGHEST. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 400.00 PER FARE COMPONENT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER FARE COMPONENT COLLECT EACH. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 200.00 PER PRICING UNIT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER PRICING UNIT COLLECT HIGHEST. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "CHARGE USD 200.00 PER FARE COMPONENT. IF ALL PENALTIES IN",
                  "PRICING UNIT ARE PER FARE COMPONENT COLLECT EACH. IF MIX",
                  "OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH",
                  "AS PER PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR",
                  "INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR INFANT",
                  "WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE ANYTIME AFTER ORIGINALLY SCHEDULED FLIGHT",
                  "OF FIRST UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "OR -",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY",
                  "SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER FARE",
                  "COMPONENT COLLECT EACH. IF MIX OF PER FARE COMPONENT AND",
                  "PER PRICING UNIT CALCULATE EACH AS PER PRICING UNIT AND",
                  "COLLECT HIGHEST. NO CHARGE FOR INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE ANYTIME AFTER ORIGINALLY SCHEDULED FLIGHT",
                  "OF FIRST UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR INFANT",
                  "WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "APPLIES FOR FARE COMPONENTS THAT ARE FULLY FLOWN. REFUND",
                  "MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER",
                  "PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE",
                  "COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER",
                  "PRICING UNIT AND COLLECT HIGHEST. NO CHARGE FOR INFANT",
                  "WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE BEFORE ORIGINALLY SCHEDULED FLIGHT OF FIRST",
                  "UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD.",
                  "APPLIES FOR FARE COMPONENTS THAT ARE PARTIALLY FLOWN.",
                  "REFUND MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED",
                  "FLIGHT OF FIRST UNUSED TICKET COUPON.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER FARE",
                  "COMPONENT COLLECT EACH. IF MIX OF PER FARE COMPONENT AND",
                  "PER PRICING UNIT CALCULATE EACH AS PER PRICING UNIT AND",
                  "COLLECT HIGHEST. NO CHARGE FOR INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "APPLIES FOR FARE COMPONENTS THAT ARE FULLY FLOWN. REFUND",
                  "MUST BE REQUESTED AFTER DEPARTURE OF JOURNEY.",
                  "NO CHARGE. IF ALL PENALTIES IN PRICING UNIT ARE PER FARE",
                  "COMPONENT COLLECT EACH. IF MIX OF PER FARE COMPONENT AND",
                  "PER PRICING UNIT CALCULATE EACH AS PER PRICING UNIT AND",
                  "COLLECT HIGHEST. NO CHARGE FOR INFANT WITHOUT SEAT.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY",
                  "VALIDATING CARRIER MAY REFUND TICKET.",
                  "REPRICE FLOWN PORTION USING FARES IN EFFECT ON TICKET",
                  "ISSUANCE DATE. FOR FULLY FLOWN FARE COMPONENTS FARE BREAK",
                  "POINTS MAY NOT BE CHANGED. FOR PARTIALLY FLOWN FARE",
                  "COMPONENTS ONLY DESTINATION FARE BREAK POINTS MAY BE",
                  "CHANGED. REPRICE USING NORMAL/SPECIAL ONE WAY/ROUND TRIP",
                  "FARES/ANY RULE/FARE CLASS/EQUAL OR HIGHER RBD. PUBLIC",
                  "FARES OR QUALIFIED PRIVATE FARES ARE USED IF TICKETED",
                  "FARE IS IN PRIVATE TARIFF. NEW FARE FOR FULLY FLOWN FARE",
                  "COMPONENTS MUST BE EQUAL TO OR HIGHER THAN TICKETED FARE.",
                  "OR -",
                  "FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS",
                  "NONREFUNDABLE BEFORE ORIGINALLY SCHEDULED FLIGHT OF FIRST",
                  "UNUSED TICKET COUPON.",
                  "IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT",
                  "CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.",
                  "REFUND REQUEST MUST BE LESS THAN ONE YEAR AFTER TICKET",
                  "ISSUANCE.",
                  "REPRICE USING EQUAL OR HIGHER RBD."
                ]
              }
            ]
          }
        }
      }
    ]
  }
