import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { MenuItem, TextField } from '@mui/material';
import { customerTemp } from '../../../data/customer/customerData';
import { signup } from '../../../controllers/user/signup';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import CountriesInput from '../../../components/form/CountriesInput';
import moment from 'moment';
import PasswordInput from '../../../components/form/PasswordInput';
import EmailInput from '../../../components/form/EmailInput';
import RolesInput from '../../../components/form/RolesInput';

export default function Index() {
  const [data,setData] = useState({...customerTemp,title: '',confirmPass: ''});
  const [user,setUser] = useState({});
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHeaderTitle("Customers"))
  },[dispatch])

  async function createCustomer(ev) {
    // let {email,password,confirmPass,firstName} = data
    // console.log(' data -- ',{email,password,confirmPass,firstName})
    ev.preventDefault();
    if(data.email === "" || data.password === "" || data.confirmPass === "" || data.firstName === "")
      return dispatch(setAlertMsg(['error','Please Input required fields!']))
    else if(data.confirmPass !== data.password)
      return dispatch(setAlertMsg(['error','Passwords Does not match!']));

    setLoading(true);
    const res = await signup(data);
    if(res.return) {
      try {
        setUser({id: res.data.id});
      } catch(ex) {}
    }
    setLoading(false);
    
    if(res.return) {
      dispatch(setAlertMsg(['success','Customer Creation Successful.']))
      setData({...customerTemp,title: '',confirmPass: ''})
    }
    else dispatch(setAlertMsg(['error',res.msg]));
  }
  
  return (
    <div className='w-full p-4 m-2'>
      <form action='' onSubmit={createCustomer} className='inline-block'>
        <div className='flex flex-col gap-6 w-auto'>
          <h2>Create a Customer</h2>
          <TextField size='small' label='Title' select
            className='self-start min-w-[100px]'
            value={data.title || ''}
            onChange={(ev) => setData({...data,title: ev.target.value})}
            InputLabelProps={{
              shrink: true,
            }}>
              <MenuItem value='Mr'>Mr</MenuItem>
              <MenuItem value='Ms'>Ms</MenuItem>
              <MenuItem value='Mrs'>Mrs</MenuItem>
          </TextField>
          <div className='flex gap-4'>
            <TextField size='small' label='Surname' required 
              value={data.lastName || ''}
              onChange={(ev) => setData({...data,lastName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='First Name' required
              value={data.firstName || ''}
              onChange={(ev) => setData({...data,firstName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <EmailInput size='small' label='Email' required
              value={data.email || ''}
              onChange={(val) => setData({...data,email: val})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex gap-4'>
            <PhoneNumberInput size='small' label='Phone' required
              value={data.phoneNumber || ''}
              onChange={(val) => setData({...data,phoneNumber: val})}
              InputLabelProps={{
                shrink: true,
              }} />
            {/* <TextField size='small' label='Birth Date' type='date'
              value={moment(data.dob || '' || "").format("yyyy-MM-DD")}
              onChange={(ev) => setData({...data,dob: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} /> */}
          </div>
          
          <div className='flex gap-4'>
            <PasswordInput size='small' label='Password' required
              value={data.password || ''}
              onChange={(val) => setData({...data,password: val})}
              InputLabelProps={{
                shrink: true,
              }} />
            <PasswordInput size='small' label='Re-enter Password' required
              value={data.confirmPass || ''}
              onChange={(val) => setData({...data,confirmPass: val})}
              />

          </div>

          {/* <h5>Employment Details</h5>
          <div className='flex gap-4'>
            <TextField size='small' label='Employee Id' 
              value={data.employeeId}
              onChange={(ev) => setData({...data,employeeId: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Department' 
              value={data.employeeDepartment}
              onChange={(ev) => setData({...data,employeeDepartment: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Designation' 
              value={data.employeeDesignation}
              onChange={(ev) => setData({...data,employeeDesignation: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div> */}
          {/* <div className='flex gap-4'>
            <TextField size='small' label='National Id' 
              value={data.nationalId}
              onChange={(ev) => setData({...data,nationalId: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='National Id Expiry Date' type='date'
              value={moment(data.nationalIdExpiry || "").format("YYYY-MM-DD")}
              onChange={(ev) => setData({...data,nationalIdExpiry: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />

          </div> */}

          <h5>Address Details</h5>
          <div className='flex gap-4'>
            {/* <TextField size='small' label='Nationality' 
              value={data.nationality}
              onChange={(ev) => setData({...data,nationality: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} /> */}
            <CountriesInput size='small' label='Country' 
              value={data.countryCode}
              onChange={(val) => setData({...data,countryCode: val.iata2 || val})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='City' 
              value={data.cityName}
              onChange={(ev) => setData({...data,cityName: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <div className='flex gap-4'>
            <TextField size='small' label='Address One' 
              value={data.addressLine1}
              onChange={(ev) => setData({...data,addressLine1: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
            <TextField size='small' label='Address Two' 
              value={data.addressLine2}
              onChange={(ev) => setData({...data,addressLine2: ev.target.value})}
              InputLabelProps={{
                shrink: true,
              }} />
          </div>
          <RolesInput id={user.id} />
          <button className='btn2 self-start flex items-center gap-2'>
            {loading? <div className='load'></div>:null}
            Create Customer</button>
        </div>
      </form>
    </div>
  )

  // return (
  //   <div className=' p-4 m-2 w-full'>
  //     <form action='' onSubmit={createCustomer} className='inline-block'>
  //       <div className='flex flex-col gap-6 w-auto'>
  //         <h2>Create a customer</h2>
  //         <div className='flex gap-4'>
  //           <TextField size='small' label='Title' select className='w-[100px]'
  //             value={data.title}
  //             onChange={(ev) => setData({...data,title: ev.target.value})}
  //             InputLabelProps={{
  //               shrink: true,
  //             }}>
  //             <MenuItem value='Mr'>Mr</MenuItem>
  //             <MenuItem value='Ms'>Ms</MenuItem>
  //             <MenuItem value='Mrs'>Mrs</MenuItem>
  //           </TextField>
  //           <TextField size='small' label='Surname'
  //             value={data.lastName}
  //             onChange={(ev) => setData({...data,lastName: ev.target.value})}
            
  //             InputLabelProps={{
  //               shrink: true,
  //             }} />
  //           <TextField size='small' label='First Name'
  //             value={data.firstName}
  //             onChange={(ev) => setData({...data,firstName: ev.target.value})}
            
  //             InputLabelProps={{
  //               shrink: true,
  //             }} />

  //         </div>
  //         <div className='flex gap-4'>
  //           <TextField size='small' label='Email'
  //             value={data.email}
  //             onChange={(ev) => setData({...data,email: ev.target.value})}
            
  //             InputLabelProps={{
  //               shrink: true,
  //             }} />
  //           <PhoneNumberInput size='small' label='Phone'
  //             value={data.phoneNumber}
  //             onChange={(value) => setData({...data,phoneNumber: value})}
                        
  //             InputLabelProps={{
  //               shrink: true,
  //             }} />

  //         </div>
  //         <div className='flex gap-4'>
  //           <TextField size='small' label='Password' type='password'
  //             value={data.password}
  //             onChange={(ev) => setData({...data,password: ev.target.value})}
              
  //             InputLabelProps={{
  //               shrink: true,
  //             }} />
  //           <TextField size='small' label='Re-enter Password' type='password'
  //             value={data.confirmPass}
  //             onChange={(ev) => setData({...data,confirmPass: ev.target.value})}

  //             InputLabelProps={{
  //               shrink: true,
  //             }} />

  //         </div>
  //         <button className='btn2 self-start flex gap-2'>
  //           {loading ? (<div className='load'></div>) : null}
  //           Create Customer</button>
  //       </div>
  //     </form>
  //   </div>
  // )
}
