import { path } from "../../../config";
import { store } from "../../../redux/store";
import fetchServer from "../../fetchServer";

export async function applyPromoCodeTour(id,data) {
  var response = {return: 0,msg: "Error",data: null}

  let token = store.getState().user.userData.accessToken;
  console.log('got : ',data)

  await fetchServer({method: 'POST',url: path.api+"/promo-codes/apply-tour/"+id,data,
    headers: {
      Authorization: `Bearer ${token}`
    } 
  })
  .then((res) => {
    console.log(" -- ",res)
    if(res && res.data) {
      if(res?.data?.success)
        response = {return: 1,msg: "Successfull",data: res.data.data}
      else if(res?.error) response['msg'] = res?.error?.message;
    } else if(res.error)
      response['msg'] = res.error.message
  })
  .catch(e => console.log("Network Error: ",e))
  
  return response;
}