import React from 'react'
import { getCurrencySymbol } from '../../../features/utils/currency';
import { def } from '../../../config';
import { formatMoney } from '../../../features/utils/formatMoney';

export default function PriceSummary({data}) {
  let passengers = [];
  try {
    passengers = data.flightData.booked_travellers
  } catch(ex) {}

  return data && (
    <div className='flex flex-col gap-5'>
      <h5>PriceSummary</h5>
      <div className='flex flex-col px-4'>
        {
          passengers?.map((passenger,i) => (
            <div key={i}>
              <div className='flex gap-4 justify-between -ml-1'>
                <b>Traveler {i+1}</b>
                <b>{def.currency}{formatMoney(passenger.total_amount)}</b> 
              </div>
              <div className='flex justify-between'>
               <div>Fare Amount</div> 
               <div>{def.currency}{formatMoney(passenger.fare_amount)}</div> 
              </div>
              <div className='flex justify-between'>
               <div>Tax Amount</div> 
               <div>{def.currency}{formatMoney(passenger.tax_amount)}</div> 
              </div>
            </div>
          ))
        }
        {data?.insuranceAPIData && data?.insuranceAPIData?.insuranceApiData.map(data => (
          <div className='flex gap-4 py-2 justify-between -ml-1'>
            <b>Insurance</b>
            <b>{def.currency}{formatMoney(data.fareAmount)}</b>
          </div>

        ))}
        {data?.selectedUpgrade ? (
          <div className='flex gap-4 py-2 justify-between -ml-1'>
            <b>Flight upgrade</b>
            <b>{def.currency}{formatMoney(data?.selectedUpgrade?.price)}</b>
          </div>
        ):null}
        {data?.appliedPromoCode ? (
          <div className='flex gap-4 py-2 justify-between -ml-1'>
            <b>{data?.appliedPromoCode?.code} Promotion</b>
            <b>  {data?.appliedPromoCode?.discountPercentage}% &nbsp; -{def.currency}{formatMoney(data?.appliedPromoCode?.discountAmount)}</b>
          </div>
        ):null}
      </div>
      <div className='flex justify-between px-3'>
        <h4>Trip Total : </h4>
        <h4>{def.currency}{formatMoney(data?.editedtotalPrice)}</h4>
      </div>
    </div>
  )
}
