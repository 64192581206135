import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PlaneSeat from '../../../components/flight/PlaneSeat';
import { bookFlight } from '../../../controllers/flight/bookFlight';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import Collapse from '../../../components/mini/Collapse';
import { Cancel, Flight } from '@mui/icons-material';
import { clone } from '../../../features/utils/objClone';
import { setBookingData } from '../../../redux/reducers/flight/flightBookingSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import ModalLocal from '../../../components/mini/ModalLocal';
import { TextField, Typography } from '@mui/material';
import BookingFailed from '../../../components/booking/BookingFailed';
import MultipleSelect from '../../../components/form/MultiSelect';
import getSupplierOffice from '../../../features/flights/getSupplierOffice';

export default function FlightSeats({back,next}) {
  const {bookingData} = useSelector((state) => state.flightBooking);
  const [loading,setLoading] = useState(false);
  const [selectedSeats,setSelectedSeats] = useState([]);
  const [loadSeats,setLoadSeats] = useState(false);
  const [confirmModal,setConfirmModal] = useState(false);
  const [searchParam] = useSearchParams();
  const [bookingFailed,setBookingFailed] = useState(false); 
  const [cdr,setcdr] = useState([]);
  const {id} = useParams();
  const dispatch = useDispatch();

  let referralCode = searchParam.get('referralCode');


  let flights = useMemo(() => [],[]);
  try {
    flights = bookingData.offer.segments.map(flightData => {
      const {
        departureDate,
        departureLocation,
        marketingCarrier,
        arrivalLocation,
        flightNumber,
        flights
      } = flightData;
      return ({departure: departureLocation,departureDate,arrival: arrivalLocation,airline: marketingCarrier,flightNumber,flights})
    })
  } catch(ex) {}

  // const memoizedFlights = useMemo(() => flights, [flights]);

  const office = bookingData?.offer?.office || getSupplierOffice(bookingData?.offer?.supplier)


  async function book(data,bookingInfo) {
    if(data.orderData || (!(data.bookingInfo || bookingInfo) && !bookingData?.cwt)) return false;

    let info = bookingInfo || data.bookingInfo || [];

    console.log('booking order')
    let bookReqData = {offer: data.offer,travelerIds: [...(info?.map(d => d.id))]};
    if(referralCode && referralCode !== 'null')
      bookReqData['referralCode'] = referralCode
    if(cdr && cdr?.length)
      bookReqData['CDR'] = cdr;
    if(selectedSeats && selectedSeats?.length)
      bookReqData['selectedSeats'] = selectedSeats.flat(1);
    if(!data.as && data.contact) {
      bookReqData['contactEmail'] = data?.contact?.email;
      bookReqData['contactPhoneNumber'] = data?.contact?.phoneNumber;
    }
    let currency = JSON.parse(window.localStorage.getItem('currencyObj')||'{}');
    if(currency?.id)
      bookReqData['prefferedCurrencyId'] = currency.id;

    if(data?.offer?.office || data?.offer?.supplier)
      bookReqData['office'] = office

    bookReqData['baseAmount'] = data?.offer?.baseAmount;
    bookReqData['totalAmount'] = data?.offer?.totalAmount;
    bookReqData['currency'] = data?.offer?.currency;
    bookReqData['taxAmount'] = data?.offer?.taxAmount;

    if(bookingData?.cwt) {
      bookReqData['bookingType'] = 'CWT';
      bookReqData['cwtProfile'] = bookingData?.cwtObj;
      bookReqData['cwtTravelers'] = bookingData?.cwtTravelers;
      bookReqData['cwtCompanyName'] = bookingData?.cwt
      bookReqData['travelerIds'] = undefined
    }

    let userType = undefined;
    if(bookingData?.userType)
      userType = bookingData?.userType

     
    setLoading(true);
    const res = await bookFlight(bookReqData,info?.at(0)?.userId,userType);
    setLoading(false);

    if(res.return) {
      // dispatch(setBookingData({...data,orderData: res.data.orderData}))
      // dispatch(setBookingData({...data,testSucc: true}))
      dispatch(setAlertMsg(['success','Flight Booked.']))
      next({...data,...res.data});
    } else {
      setBookingFailed(true)
      dispatch(setAlertMsg(['error','Booking Failed!']));
    }

    return res.return;
  }

  async function handleSubmit() {
    setConfirmModal(false);
    if(bookingData.orderData) return next && next();

    book(bookingData)
  }
  function confirmSubmit() {
    setConfirmModal(true)
  }

  function handleSelectedSeats(seats,i) {
    let temp = clone(selectedSeats);
    temp[i] = seats

    console.log('seats: ',seats)
    // let seatsPrice = (clone(bookingData.seats) || [])
    dispatch(setBookingData({...bookingData,seats: temp.flat()}))
    setSelectedSeats(temp)
  }

  // console.log(flights)
  console.log(selectedSeats)
  return (
    <div className=' p-2 flex flex-col gap-4'>
      {/* <PriceTimeout /> */}

      <ModalLocal open={confirmModal} setOpen={setConfirmModal}>
        <div className='bg-secondary rounded-2xl p-6 relative flex flex-col gap-4'>
          <Cancel className='self-end cursor-pointer absolute top-0 right-0 m-2' onClick={() => setConfirmModal(false)} />
          <div className='flex flex-col gap-6 py-5'>
            <Typography variant='h2' className='text-primary/90'>Are you sure you want to confirm this booking?</Typography>
            <button className='btn2 self-end' onClick={handleSubmit}>Yes. Confirm</button>
          </div>
        </div>
      </ModalLocal>
      <BookingFailed open={bookingFailed} setOpen={setBookingFailed} link={id?`/search/flight/offers?q=${id}`:'/search/'} />
      <div className='flex flex-col gap-3 p-6 bg-secondary'>
        <h5>Choose your seat (Optional)</h5>
        <small>Add a seat for to your reservation or skip.</small>
      </div>
      {flights.map(flightData => 
        flightData.flights.map((flight,i) =>
        <div key={i} className='border flex gap-4 items-center p-6 bg-secondary'>
          <div>
            <img alt='airline' src={flight.carrierIcon} className='w-[50px] max-h-[30px] !object-contain' />  
          </div>
          <div className='flex gap-3 items-center flex-1'>
            <h5>
              {flight.departureLocation}
            </h5>
            <div className='flex'>
              <div className='mr' /><Flight className='rotate-90 scale-75 ' />
            </div>
            <h5>
              {flight.arrivalLocation}
            </h5>
          </div>
          <div>
            <Collapse returnData={(val) => setLoadSeats(val)} label={(<button className='btn bg-primary/50 text-secondary self-end'>Select Seat</button>)} show={false} dropdown={null} >
              {/* {memoizedFlights.map((flightData,i) => ( */}
                <PlaneSeat flightData={loadSeats && {...flightData,i,office}} returnData={(seats) => handleSelectedSeats(seats,i)} />
              {/* ))} */}
            </Collapse>
          </div>
        </div>
      ))}

      {bookingData?.as?.type === 'CORPORATE' && bookingData?.as?.apiUserData?.CDR ? 
        <div className='bg-secondary rounded-md p-6'>
          <MultipleSelect size='small' label='Corporate CDR' required
            format={false}
            options={bookingData?.as?.apiUserData?.CDR}
            value={cdr}
            onChange={(val) => setcdr(val)}
            InputLabelProps={{
              shrink: true,
            }} />

        </div>
      :null}

      <div className='p-3 mt-6 flex gap-6 justify-between text-primary/80'>
        <button className='p-2 font-bold' onClick={confirmSubmit} disabled={loading}>
          {loading ? 'Booking...' : 'Skip and Book'}
        </button>

        {/* <div>
          {back ? (
            <button className='p-2 ' onClick={back}><ArrowBack /> Back</button>
            ): null}
        </div> */}
        {selectedSeats.flat().length ? 
          <button className='btn2 min-w-[150px]' disabled={loading} onClick={confirmSubmit}>{loading ? "Please Wait..." : "Continue"}</button>
          :
          <button className='btn btn_nofocus cursor-not-allowed min-w-[150px] flex justify-center'>Continue</button>
        }
      </div>
    </div>
  )
}
