import React, { useEffect, useState } from 'react'
import { formatMoney } from '../features/utils/formatMoney';

export default function Test() {
    const [open,setOpen] = useState(false);
    
  return (
    <div>{formatMoney('1,322,000','USD')}</div>
  )
}
