import React, { memo, useCallback, useEffect, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import getFlightOrders from '../../controllers/flight/getFlightOrders';
import moment from 'moment';
import { def } from '../../config';
import { formatMoney } from '../../features/utils/formatMoney';
import { formatNumber } from '../../features/utils/formatNumber';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 3,
      grid: {
        display: false
      },
      // ticks: {
      //   min: 0,
      //   max: 10000000,
      //   stepSize: 600000
      // }
    },
    x: {
      grid: {
        display: false
      },
      ticks: {
        maxTicksLimit: 5,
      }
    }
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
    }
  },
}

function Insights({returnData,filter}) {
  const [datas,setDatas] = useState([
    // {date: '2023-03-09T12:00:00-06:30',orders: 0,editedtotalPrice: 0},
    // {date: '2023-03-15T12:00:00-06:30',orders: 0,editedtotalPrice: 0},
    // {date: '2023-03-21T12:00:00-06:30',orders: 0,editedtotalPrice: 0},
    // {date: '2023-03-27T12:00:00-06:30',orders: 0,editedtotalPrice: 0},
    // {date: '2023-04-02T12:00:00-06:30',orders: 0,editedtotalPrice: 0},
    // {date: '2023-04-05T12:00:00-06:30',orders: 0,editedtotalPrice: 0},
  ])
  const {key,setKey,filter:filters} = filter;

  const handleReturnData = useCallback((data) => {
    if(returnData)
      returnData(data);
  }, [returnData]);

  
  
  let orders = [];
  
  datas.forEach(data => {
    const dateExists = orders.find(newData => moment(newData.date).format('YYYY-MM-DD') === moment(data.date).format('YYYY-MM-DD'));
    if (dateExists) {
      dateExists.orders++;
    } else {
      orders.push({ date: data.date, orders: 1 });
    }
  });

  // console.log(' -> -> ',orders)
  
  
  const startDate = '2023-03-09T12:00:00-06:30';
  const endDate = '2023-04-05T12:00:00-06:30';
  
  const dates = [];
  let currentDate = moment(startDate);
  while (currentDate <= moment(endDate)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.clone().add(6, 'days');
  }
  
  
  let days = 30;
  try {
    days = Object.values(filters).find(obj => obj.index === parseInt(key)).days
  } catch(ex) {}

  const today = new Date();
  const filteredDays = new Date(today.getFullYear(), today.getMonth(), today.getDate() - days);
  // const last30Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);

  let filteredOrders = datas;
  let ordersFilter = orders;

  if(days !== 'All') {
    filteredOrders = datas;

    filteredOrders = datas.filter(order => {
      const orderDate = new Date(order.date);
      return orderDate >= filteredDays && orderDate <= today;
    });
  
    ordersFilter = orders.filter(order => {
      const orderDate = new Date(order.date);
      return orderDate >= filteredDays && orderDate <= today;
    })
    
  }

  const totalGross = (([...filteredOrders].reduce((prev,cur) => ({...cur,total: (prev.total) + (cur.editedtotalPrice)}),{total: 0,editedtotalPrice: 0})).total)
  const totalOrder = (([...ordersFilter].reduce((prev,cur) => ({...cur,total: (prev.total) + (cur.orders)}  ),{total: 0,orders: 0})).total)
  
  const data = {
    // labels: datas.map((d) => moment(d.date).format('MM DD')),
    labels: filteredOrders.map((d) => new Date(d.date).toLocaleString('default',{day: 'numeric',month: 'short'})),
    datasets: [
      {
        label: 'Total ',
        data: filteredOrders.map((d) => d.editedtotalPrice),
        borderColor: '#1362FC',
        barThickness: 4,
        borderWidth: 2,
        pointRadius: 1,
        tension: 0.4,
        backgroundColor: '#1362FCaa',
      }
    ]
  }

  const binSize = 7;
  const bins = Array(Math.ceil(data.datasets[0].data.length / binSize)).fill().map((_, i) => i * binSize);
  const binnedData = bins.map(bin => {
    const dataSlice = data.datasets[0].data.slice(bin, bin + binSize);
    const average = dataSlice.reduce((sum, value) => sum + value, 0) / dataSlice.length;
    return average;
  });

  console.log('bins: ',bins)
  const binnedLabels = bins.map(bin => data.labels[bin]);
  const binnedChartData = {
    labels: binnedLabels,
    datasets: [
      {
        label: 'Flight Orders',
        data: binnedData,
        fill: false,
        // borderColor: 'rgb(75, 192, 192)',
        borderColor: '#1362FC',
        barThickness: 4,
        borderWidth: 2,
        pointRadius: 0,
        tension: 0.5
      }
    ]
  };

  // console.log(filteredOrders)
  // console.log(orders)

  const orderCountData = {
    labels: ordersFilter.map((d) => new Date(d.date).toLocaleString('default',{day: 'numeric',month: 'short'})),
    datasets: [
      {
        label: 'Orders ',
        data: ordersFilter.map((d) => d.orders),
        borderColor: '#1362FC',
        barThickness: 4,
        borderWidth: 2,
        pointRadius: 0,
        backgroundColor: '#1362FCaa',
      }
    ]
  }
  
  
  useEffect(() => {
    async function load() {
      const res = await getFlightOrders();
      if(res.return) {
        // console.log('got',res.data);
        
        let data = res.data.map(d => {
          
          return ({...d,orders: 0,date: d.createdDate})
        }).sort((p,c) => new Date(p.date) - new Date(c.date));

        handleReturnData(data);
          
        setDatas(data)

        // console.log('made: ',data)
      }
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <div className='p-4 bg-secondary'>
      <div className='flex justify-between'>
        <h4>Insights</h4>
        {/* <p className='text-primary/50'>Last 30 days</p> */}
      </div>
      <div className='flex py-2 gap-5 overflow-auto snap-x snap-mandatory'>
        <div className='border border-gray-300 p-3 rounded-md snap-start'>
          <div className='py-4'>
            <h6>Orders Created</h6>
            <h4>{totalOrder}</h4>
          </div>
          <Bar options={options} data={orderCountData} />
        </div>
        <div className='border border-gray-300 p-3 rounded-md snap-start'>
          <div className='py-4'>
            <h6>Gross Total</h6>
            <h4>{def.currency}{formatNumber(totalGross.toFixed(2))}</h4>
          </div>
          <Line options={options} data={binnedChartData} />
        </div>
      </div>
    </div>
  )
}

export default (Insights);