import React, { useEffect, useState } from 'react'
import addPNRToQueue from '../../controllers/flight/addPNRToQueue'
import { useDispatch } from 'react-redux'
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import getQueuesReq from '../../controllers/settings/queues/getQueues';
import { MenuItem, TextField } from '@mui/material';

export default function AddPNRToQueue({id,returnData}) {
  const [queue,setQueue] = useState();
  const [queues,setQueues] = useState([]);
  const [loading,setLoading] = useState(false);
  const [loadingSave,setLoadingSave] = useState(false);
  const [PNR,setPNR] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if(id)
      setPNR(id)
    loadQueues();
  },[id])
  async function loadQueues() {
    setLoading(true);
    const res = await getQueuesReq();
    setLoading(false);
    if(res.return) {
      setQueues(res.data);
    }
  }
  async function addToQueue() {
    let data = {
      queue: queue.queueNumber,
      category: queue.category,
      recordLocator: PNR
    }

    // console.log(data);
    setLoadingSave(true);
    const res = await addPNRToQueue(data)
    setLoadingSave(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Added To Queue.']))
      if(returnData)
        returnData();
    } else dispatch(setAlertMsg(['error',res.msg]))
  }

  return (
    <div className='flex flex-col items-stretch gap-4'>
      <div className='w-full flex items-center justify-center'>
        {loading?<div className='load'></div>:null}
      </div>
      {/* {!id ?  */}
        <TextField size='small' label='PNR (Record Locator)' disabled={id}
          value={PNR}
          onChange={(ev) => setPNR(ev.target.value)} />
      {/* :null} */}
      <TextField select size='small' onChange={(ev) => setQueue(ev.target.value)} label='Select Queue' className='min-w-[200px]'>
        <MenuItem></MenuItem>
        {queues.map((obj,i) => (
          <MenuItem key={i} value={obj} className='flex flex-wrap gap-6 items-between'>
            <h3 className='flex-1'>{obj.queueNumber}</h3>
            {obj.title}
          </MenuItem>
        ))}
      </TextField>
      <button className='btn2 flex items-center gap-2 w-full' onClick={addToQueue}>
        {loadingSave?<div className='load'></div>:null}
        Add
      </button>
    </div>
  )
}
