import React, { useEffect, useState } from 'react'
import { addMarkup } from '../../controllers/settings/addMarkups';
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import { MenuItem, TextField } from '@mui/material';
import { FLIGHT_TYPES } from '../../data/ENUM/FlightTypes';
import { updateFareRule } from '../../controllers/settings/updateFareRule';
import { addMultipleMarkups } from '../../controllers/settings/addMultipleMarkups';

export default function FlightServiceCharge({defaultData,submit,handleReturn,update,companyData,returnFalse,resetFields}) {
  let company = companyData?.apiUserData?.companyName || ''

  const initState = defaultData || {
    flightProvider: '',
    markType: '',
    markUp: '',
    flightType: '',
    fareRuleType: 'SERVICE_FEE_FLIGHT',
    for: 'CORPORATE',
    ruleName: 'Service fee for Corporate '+company,
  }
  const [data,setData] = useState(initState);
  const [loading,setLoading] = useState(false);
  const [flightTypes] = useState(update ? 1 : 3);
  const [arrayData,setArrayData] = useState([data,data,data])
  const dispatch = useDispatch();

  useEffect(() => {
    if(submit) {
      handleSubmit();
    }
    //eslint-disable-next-line
  },[submit])

  useEffect(() => {
    if(resetFields) {
      setData(initState)
      setArrayData([initState,initState,initState])
    }
    //eslint-disable-next-line
  },[resetFields])

  // useEffect(() => {
  //   console.log(data)
  //   if(data.flightType)
  //     setFlightTypes(n => n+1 > 3 ? 3 : n+1);
  //   //eslint-disable-next-line
  // },[data])

  function handleSubmit() {
    if(!update || !defaultData)
      handleFlightServiceFee();
    else handleFlightServiceFeeUpdate();
  }

  function handleArray(i,data) {
    let temp = [...arrayData];
    temp[i] = {...temp[i],...data,flightType: FLIGHT_TYPES[i]};
    setArrayData(temp);
    console.log(temp);
  }

  async function handleFlightServiceFee() {
    console.log('adding flight service fee')
    let res = {return: false,msg: 'Route not found!'}
    if(!update)
      res = await addMultipleMarkups(companyData?.id,{rules: arrayData})
    else 
      res = await addMarkup(companyData?.id,{
        flightProvider: data.flightProvider,
        markType: data.markType,
        flightType: data.flightType,
        markUp: parseInt(data.markUp),
        fareRuleType: 'SERVICE_FEE_FLIGHT',
        for: 'CORPORATE',
        ruleName: 'Service fee for Corporate '+company,
      });

    if(res.return) {
      if(handleReturn) handleReturn();

      if(update)
        dispatch(setAlertMsg(['success','Flight service charge updated.']))
    }
    else
      dispatch(setAlertMsg(['error','Failed setting flight service fee! Please set from settings.']))

    if(!res.return && returnFalse && handleReturn) handleReturn(); 
  }
  async function handleFlightServiceFeeUpdate(id) {
    setLoading(true);
    const res = await updateFareRule(defaultData?.id,{
      // fareRuleType: 'SERVICE_FEE_FLIGHT',
      // for: 'CORPORATE',
      markType: data.markType,
      markUp: parseInt(data.markUp),
      flightProvider: data.flightProvider,
      ruleName: 'Service fee for Corporate '+company,
      flightType: data.flightType,
    });
    setLoading(false);

    if(res.return) {
      if(handleReturn) handleReturn();
  
      if(update)
        dispatch(setAlertMsg(['success','Flight service charge updated.']))
    }
    else
      dispatch(setAlertMsg(['error','Failed updating flight service fee!']))

    if(!res.return && returnFalse && handleReturn) handleReturn(); 

  }
  return (
    <div className='flex flex-col gap-4'>
      {[...Array.from(Array(flightTypes))].map((val,i) => (
        <div key={i} className=' flex items-stretch gap-4'>
          <TextField select 
            value={update ? data.flightProvider : arrayData[i].flightProvider} 
            onChange={(ev) => {
              setData({...data,flightProvider: ev.target.value})
              handleArray(i,{flightProvider: ev.target.value})
            }} 
            label='Flight Provider' size='small' className='min-w-[150px]'>
            <MenuItem value='AMADEUS'>Amadeus</MenuItem>
            <MenuItem value='AMADEUS_DXB'>Amadeus DXB</MenuItem>
            <MenuItem value='TRAVEL_PORT'>Travelport</MenuItem>
            <MenuItem value='ALL'>ALL</MenuItem>
          </TextField>
          <TextField select 
            value={update ? data.markType : arrayData[i].markType} 
            onChange={(ev) => {
              setData({...data,markType: ev.target.value})
              handleArray(i,{markType: ev.target.value})
            }} 
            label='Markup Type' size='small' className='min-w-[150px]'>
            <MenuItem value='PERCENTAGE'>Percentage</MenuItem>
            <MenuItem value="VALUE">Value</MenuItem>
          </TextField>
          <TextField size='small' value={update ? data.markUp : arrayData[i].markUp} onChange={(ev) => {
            setData({...data,markUp: ev.target.value})
            handleArray(i,{markUp: ev.target.value})
          }  } label={"Markup Value"} />
          <TextField select size='small' 
            value={update ? data.flightType : FLIGHT_TYPES[i]} 
            disabled={!update}
            // defaultValue={'INTERNATIONAL'}
            onChange={(ev) => {
              setData({...data,flightType: ev.target.value})
              handleArray(i,{flightType: ev.target.value})
            }} 
            label='Flight Type' className='min-w-[150px]'>
            {FLIGHT_TYPES.map((val,i) => (
              <MenuItem value={val} key={i} className='capitalize'>{val.toLowerCase()}</MenuItem>
              ))}
          </TextField>
        </div>
      ))}
      {update ? (

        <button className='btn2 flex gap-2 self-start border-primary' 
          onClick={handleSubmit}
          disabled={loading}>
          {loading ? <div className='load'></div> : null}
          {!defaultData ? 'Add Fee' : 'Update Fee'}
        </button>
      ):null}

    </div>

  )
}
