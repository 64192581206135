export const currencyReqData = {
  "currencyName": "",
  "currencyCode": "",
  "exchangeRateInNaira": "",
  "symbol": ""
}

export const currencyData = {
  "id": null,
  "currencyName": null,
  "currencyCode": null,
  "exchangeRateInNaira": null,
  "symbol": null,
  "status": null,
  "updatedDate": null,
  "createdDate": null,
}