import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import React, { useState } from 'react'
import { login } from '../controllers/user/login';
import { useDispatch } from 'react-redux';
import { setUserData } from '../redux/reducers/user/userSlice';


export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [keepLog,setKeepLog] = useState(false);
  const [msg,setMsg] = useState("");
  const [msgtype,setMsgType] = useState(0);
  const [valid,setValid] = useState({email:null,password:null});
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  async function handleLogin(ev) {
    ev.preventDefault();
    
    if(!(valid.email === null || valid.password === null)) {
      setMsg("Invalid Login")
      return false
    };
    
    setLoading(true);

    const res = await login({email,password,keepMeLoggedIn: keepLog})
    setMsg(res.msg);
    setMsgType(res.return)
    if(res.return) {
      dispatch(setUserData({...res.data,loggedIn: true}))
    }

    setLoading(false);
  }

  function handleEmail(ev) {
    if(ev.target.value === "")
      setValid((obj) => ({...obj,email: null}))
    else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(ev.target.value))
      setValid((obj) => ({...obj,email:'Invalid Email'}))
    else setValid((obj) => ({...obj,email: null}))

    setEmail(ev.target.value)
  }

  function handlePassword(ev) {
    if(ev.target.value === "")
      setValid((obj) => ({...obj,password: null}))
    else if(!/^[A-Za-z0-9._%+!@#$%^&*()]$/i.test(ev.target.value))
      setValid((obj) => ({...obj,password:'Invalid Password'}))
    else setValid((obj) => ({...obj,password: null}))

    setPassword(ev.target.value)
  }

  return (
    <form onSubmit={handleLogin} className='flex flex-col  gap-4 min-w-[400px]'>
      <div className='flex justify-center'>
        <img src={require('../assets/icons/btm-logo-transparent.png')} alt='Logo' />
      </div>
      <div className='flex flex-col gap-4 items-center'>
        {/* <h3>Login</h3> */}
        <p>Login to access your Global account</p>
      </div>
      <h4 className={msgtype?'text-green-500':'text-red-500'}>{msg}</h4>
      <div className='flex flex-col gap-4'>
        <TextField label="Email" value={email} required onChange={handleEmail}
          error={valid.email?true:false}
          helperText={valid.email}
          variant='outlined' />
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            value={password}
            required
            onChange={handlePassword}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <label className='flex gap-4 cursor-pointer'>
          <input type='checkbox' checked={keepLog} onChange={() => setKeepLog(!keepLog)} />
          Remember me
        </label>
      </div>
      <div className='py-4'>
        <input type='submit' className='btn1 w-full' value={!loading?'Login':'Please Wait...'} disabled={loading} />
      </div>
    </form>
  )
}
