import axios from "axios";
import { path } from "../../config";

export async function login(data) {
  let result = {return: 0,msg: "Invalid Login"}
  await axios.post(path.api+"/users/login",data)
  .then((res) => {
    if(res && !res?.error)
      result = {return: 1,msg: "Successfull",data: res.data}
    else if(res?.error) result['msg'] = res?.error?.message;
  })
  .catch((e) => null)

  return result;

}