import { Add, ArrowBack } from '@mui/icons-material'
import { TextField } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { clone } from '../../../features/utils/objClone'
import { useDispatch } from 'react-redux'
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice'
import createCurrencyReq from '../../../controllers/settings/currency/createCurrency'
import { bankData } from '../../../data/settings/bank/bankData'
import CountriesInput from '../../../components/form/CountriesInput'
import createBankReq from '../../../controllers/settings/banks/createBank'


export default function CreateCurrency() {
  const [data,setData] = useState(clone(bankData));
  const [loading,setLoading] = useState(false);

  const dispatch = useDispatch();

  async function handleSubmit() {
    
    if (
      data.accountHolder === "" ||
      !data.accountHolder ||
      data.accountNumber === "" ||
      !data.accountNumber ||
      data.bankCode === "" ||
      !data.bankCode ||
      data.country === "" ||
      !data.country ||
      data.bankName === "" ||
      !data.bankName
    ) return dispatch(setAlertMsg(['error','Please input all fields!']))
    

    console.log(data)
    
    setLoading(true);
    const res = await createBankReq(data);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Bank Saved.']))
      setData(clone(bankData));
    } else dispatch(setAlertMsg(['error','Failed Saving Bank']))

  }

  return (
    <div className='w-full bg-[#f3f3f3] p-4'>
      <div className='bg-secondary p-4'>
        <Link to="/settings/banks" className='flex gap-4'>
          <ArrowBack />
          <h3>Add Bank</h3>
        </Link>
        <div className='inline-block py-4'>
          <div className='flex flex-col gap-2 w-auto'>
            <TextField size='small' label='Bank Name'
              value={data.bankName} onChange={(ev) => setData(data => ({...data,bankName: ev.target.value}))} 
            />
            <TextField size='small' label='Bank Code'
              value={data.bankCode} onChange={(ev) => setData(data => ({...data,bankCode: ev.target.value}))} 
            />
            <TextField size='small' label='Account Holder'
              value={data.accountHolder} onChange={(ev) => setData(data => ({...data,accountHolder: ev.target.value}))} 
            />
            {/* <textarea placeholder='Description' className='min-w-[50%]'></textarea> */}
            <TextField size='small' label='Account Number'
              value={data.accountNumber} onChange={(ev) => setData(d => ({...data,accountNumber: ev.target.value}))}
            />
            <CountriesInput label={'Country'}
              value={data.country} onChange={(val) => setData(d => ({...data,country: val.name || val}))}
            />
            <button className='btn2 flex gap-2' onClick={handleSubmit}>
              {loading?<div className='load'></div>:null}
              Save Bank</button>
          </div>
        </div>
      </div>
    </div>
  )
}
