import { ArrowBack, ShareOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { walletPay } from '../../../controllers/payment/walletPay';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useParams } from 'react-router-dom';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { setBookingData } from '../../../redux/reducers/flight/flightBookingSlice';
import PromoCode from '../../../components/payment/PromoCode';
import ModalLocal from '../../../components/mini/ModalLocal';
import { MenuItem, TextField } from '@mui/material';
import { ListBanksReq } from '../../../controllers/payment/listBanks';
import { bankPay } from '../../../controllers/payment/bankPay';
import FlightPayment from '../../../components/payment/FlightPayment';
import PaymentMethod from '../../../components/payment/PaymentMethod';
import moment from 'moment';
import { encrypt } from '../../../features/utils/crypto';
import { def, path } from '../../../config';


export default function Payment({back,next}) {
  const {bookingData} = useSelector((state) => state.flightBooking);
  const [loading,setLoading] = useState(false);
  const [res,setRes] = useState({success: false,msg: ''})
  const [meth,setMeth] = useState('wallet');
  const [listBanks,setListBanks] = useState(false);
  const [banks,setBanks] = useState([]);
  const [banksLoading,setBanksLoading] = useState(false);
  const [selectedBank,setSelectedBank] = useState();
  const dispatch = useDispatch();
  const {id} = useParams()

  useEffect(() => {
    if(bookingData && bookingData.page !== 'payment')
      dispatch(setBookingData({...bookingData,page: 'payment'}));
  },[bookingData,dispatch])

  console.log(next);

  // useEffect(() => {
  //   if(meth === 'bank') {
  //     setListBanks(true);
  //   }
  // },[meth])

  async function loadBanksList() {
    let params = {
      limit: 10,
      skip: 0,
    }
    setListBanks(true);
    setBanksLoading(true);
    const res = await ListBanksReq((new URLSearchParams(params)).toString())
    setBanksLoading(false);
    if(res.return) {
      setBanks(res.data);
    }
  }

  async function handleSubmit() {
    if(!bookingData.orderData) return false;

    
    
    if(meth === 'wallet') {
      setLoading(true);
      const res = await walletPay(bookingData.orderData.id,bookingData.as && bookingData.as.id);
      if(res.return) {
        setRes({success: true})
        dispatch(setAlertMsg(['success','Payment Successfull']))
        next();
      } else dispatch(setAlertMsg(['error','Failed on payment']));
      setLoading(false);
    } else if(meth === 'bank') {
      setLoading(true);
      const res = await bankPay(bookingData.orderData.id);
      if(res.return) {
        setRes({success: true})
        dispatch(setAlertMsg(['success','Payment Successfull']))
        next();
      } else dispatch(setAlertMsg(['error','Failed on payment']));
      setLoading(false);
    }
  }

  function handleReturn(val) {
    if(val) {
      next();
      setRes({success: true})
    }
  }

  let hotelSearch = {
    // "destination": "DXB",
    cityCode: bookingData?.offer?.segments[0]?.flights[0]?.arrivalLocation,
    // "start_date": "2023-12-01",
    "start_date": bookingData?.offer?.segments[0]?.flights[0]?.departureDate,
    "end_date": moment(bookingData?.offer?.segments[0]?.flights[0]?.arrivalDate).add(1,'day').format("YYYY-MM-DD"),
    "rooms": [
        {
            "adults": bookingData?.offer?.passengers?.adult?.total || 1,
            "children": bookingData?.offer?.passengers?.children?.total || 0,
            "infants": bookingData?.offer?.passengers?.infants?.total || 0,
            "units": 1
        }
    ],
    "currencyOverride": def.currency
  }
  function handeHotelSearch() {
    let enc = encrypt(JSON.stringify(hotelSearch));
    
    window.open(`/search/hotels?q=${enc}`,'_blank')

  }
  function handleShare() {
    let val = `${path.site}/search/flight/offers?q=${id}`;
    
    const tempInput = document.createElement('input');
    tempInput.value = val;
    document.body.appendChild(tempInput);

    // Select the text in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Execute the copy command
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    dispatch(setAlertMsg(['success','Copied']))
  }

  return res.success ? (
    <div className='flex flex-col gap-3 items-center justify-center'>
      <h2 className='text-green-500'>Booking Complete</h2>
      <p>You have successfully completed your booking.</p>
      <div className='flex gap-2 flex-wrap justify-evenly w-full py-5'>
        <button onClick={handeHotelSearch} className='flex-1 btn1 text-center rounded-lg' >Find a hotel</button>
        <Link className='flex-1 btn2 text-center' to="/search#Flight">Book another flight</Link>
        <button className='btn2' onClick={handleShare}><ShareOutlined /></button>
      </div>
    </div>
    ):(
    <div className='bg-secondary p-4'>
      <PaymentMethod packageName='flight' order={bookingData.orderData} userData={bookingData.orderData?.bookingUsers[0]} returnData={handleReturn} />
      <PromoCode id={bookingData.orderData && bookingData.orderData.id} type="flight" returnData={() => setLoading(false)} />
      {/* <FlightPayment order={bookingData.orderData} returnData={handleReturn} /> */}
    </div>
  )
    

  // return res.success ? (
  //   <div className='flex flex-col gap-3'>
  //     {/* <h2>Successfull</h2> */}
  //     <Link className='btn1 self-start' to="/search#Flight">Order Other Flight</Link>
  //   </div>
  // ):(
  //   <form onSubmit={(ev) => ev.preventDefault()} className='bg-secondary p-5 flex flex-col gap-4'>
  //     <h5>Payment method</h5>
  //     <p>Choose your payment method</p>
  //     <div className='flex justify-stretch gap-4 py-4'>
  //       {/* <label className='flex gap-2'>
  //         <input type='radio' name='paymeth' />
  //         Credit / Debit Card
  //       </label> */}
  //       <ModalLocal open={listBanks} setOpen={setListBanks}>
  //         <div className=' bg-secondary p-5 flex flex-col gap-5'>
  //           {banksLoading?(
  //             <div className='w-full flex items-center border-primary justify-center p-2'>
  //               <div className='load'></div>
  //             </div>
  //           ):null}
  //           {selectedBank ? (
  //             <div className='flex flex-col'>
  //               <div className='flex justify-between gap-2'>
  //                 Account No : <span> {selectedBank.accountNumber}</span>
  //               </div>
  //               <div className='flex justify-between gap-2'>
  //                 Country : <span> {selectedBank.country}</span>
  //               </div>
  //             </div>
  //           ):null}
  //           <TextField label="Select Banks" value={selectedBank && selectedBank.bankName} select size='small' className='min-w-[250px]'>
  //             <MenuItem></MenuItem>
  //             {banks.map((obj,i) => (
  //               <MenuItem value={obj.bankName} onClick={() => setSelectedBank(obj)}>{obj.bankName}</MenuItem>
  //             ))}
  //           </TextField>
  //           <button className='btn2' onClick={handleSubmit}>{loading ? "Please Wait..." : "Verify"}</button>

  //           <button className='btn1' onClick={() => setRes({success: true,msg: 'Verify Payment Later'})}>Verify Later</button>
  //         </div>
  //       </ModalLocal>

  //       <label className='flex gap-2'>
  //         <input type='radio' name='paymeth' checked={meth === 'bank'} onChange={(ev) => setMeth(ev.target.value)} onClick={() => loadBanksList()} value="bank" />
  //         Bank Transfer
  //       </label>
  //       {(bookingData.as ? (
  //         <label className='flex gap-2'>
  //           <input type='radio' name='paymeth' checked={meth === 'wallet'} onChange={(ev) => setMeth(ev.target.value)} value="wallet" />
  //           Wallet Balance
  //         </label>
  //       ) : null)}
  //     </div>
  //     <PromoCode id={bookingData && bookingData.orderData.id} />
  //     <div className='p-3 flex gap-6 justify-between text-primary/80'>
  //       {back ? (
  //         <button className='p-2 ' onClick={back}><ArrowBack /> Back</button>
  //       ): null}
  //       <button className='btn2' onClick={handleSubmit}>{loading ? "Please Wait..." : "Make Payment"}</button>
  //     </div>

  //   </form>
  // )
}


