import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'

export default function EmailInput(props) {
  const {value,onChange} = props;
  const [valid,setValid] = useState({email:null,password:null});
  const [email,setEmail] = useState(value || "");

  
  useEffect(() => {
    setEmail(value);
  },[value])


  function handleEmail(ev) {
    if(ev.target.value === "")
      setValid((obj) => ({...obj,email: null}))
    else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(ev.target.value))
      setValid((obj) => ({...obj,email:'Invalid Email'}))
    else setValid((obj) => ({...obj,email: null}))

    setEmail(ev.target.value)
    // console.log('email: ',ev.target.value)
    if(onChange)
      onChange(ev.target.value)

  }

  return (
    <TextField label="Email" required 
      size='small'
      {...props}
      value={email} 
      onChange={handleEmail}
      error={valid.email?true:false}
      helperText={valid.email}
      variant='outlined' />
  )
}
