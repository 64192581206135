import React, { useEffect, useState } from 'react'
import { NotificationsOutlined } from '@mui/icons-material'
import { Avatar } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setHeaderTitle } from '../redux/reducers/titleSlice'
import CurrencyManagment from './CurrencyManagment'
import { getNotifications } from '../controllers/settings/notification/getNotifications'


export default function Header() {
  const location = useLocation();
  const {user} = useSelector((state) => state.user.userData);

  let pathname = location.pathname.split("/");
  pathname = (pathname[1]);

  const {title} = useSelector((state) => state.title)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeaderTitle(pathname))
  },[pathname,dispatch])

  let userType = user.type;
  if(user.isAdmin)
    userType = 'Admin';
  if(user.isSuperAdmin)
    userType = 'Super Admin'

  return (
    <div className='p-3 bg-secondary'>
      <div className='flex justify-between items-center'>
        <div className='px-10'>
          <h3 className='capitalize'>{title || "Dashboard"}</h3>
        </div>
        <div className='flex-1 flex justify-center'>
          {/* <div className='self-center w-1/2 relative text-[#1362FC]'>
            <SearchInput />
          </div> */}
        </div>
        <div>
          <div className='flex items-center gap-4 relative'>
            <div>
              <CurrencyManagment />
            </div>
            <Notification />
            <div className='px-2 flex gap-2 items-center'>
              <div>
                <Avatar className='uppercase'>{user.firstName[0]}</Avatar>
              </div>
              <div className='flex flex-col  justify-between'>
                <h5 className='capitalize'>{user.firstName} {user.lastName}</h5>
                <small>{userType}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


function Notification() {
  const [data,setData] = useState([]);
  const [open,setOpen] = useState(false);

  useEffect(() => {
    load();
  },[])
  async function load() {
    const res = await getNotifications();
    if(res.return) {
      let data = res.data;
      setData(data);
    }
  }
  return (
    <div>
      <div className='rounded-full w-10 h-10 flex justify-center items-center shadow-md  cursor-pointer hover:shadow-primary/30'
       onClick={() => setOpen(true)}
      >
        <NotificationsOutlined className='text-theme1' />
      </div>
      <div className={`absolute top-full z-10 p-2 px-4 mt-3 min-w-[230px] right-0  flex flex-col gap-2 bg-secondary w-full shadow-xl ${open ? '' : 'hidden'}`}>
        <div className='flex gap-4 flex-wrap justify-between w-full'>
          <div className='flex flex-col gap-2 w-full'>
            <h5>Notification</h5>
            <hr />
            <div className='flex flex-col gap-2 max-h-[calc(100vh-200px)] overflow-hidden overflow-y-auto'>
              {data.map((obj,i) => (
                <div className='flex flex-col gap-1 text-xs max-w-[400px] border-b'>
                  <b className='capitalize'>{obj.title}</b>
                  <p>{obj.body}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={`fixed top-0 left-0 w-full h-full z-0 ${open ? '' : 'hidden'}`} onClick={() => setOpen(false)}></div>
    </div>
  )
}