import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';


// const options = [
//   { label: 'Option 1', value: 'Option 1' },
//   { label: 'Option 2', value: 'Option 2' },
//   { label: 'Option 3', value: 'Option 3' },
//   { label: 'Option 4', value: 'Option 4' },
//   { label: 'Option 5', value: 'Option 5' },
// ];

const MultipleSelect = ({options=[],label,className,value,onChange,loading: gotLoading,freeSolo,format=true}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading,setLoading] = useState(gotLoading || false);

  console.log(" ---- ",selectedOptions)

  useEffect(() => {
    if(value) {
      let temped = value;
      if(!Array.isArray(value))
        temped = [temped]
      try {
        if(!temped.label && !freeSolo && format)
        temped = value.map(val => ({value: val,label: val.replace('_',' ')}))
      } catch(ex) {}

      setSelectedOptions(temped);
    }
  },[value])

  useEffect(() => {
    setLoading(gotLoading)
  },[gotLoading])


  const handleChange = (event, value) => {
    // console.log('selected: ',value)
    setSelectedOptions(value);
    if(onChange)
      onChange(value);
  };

  let moreProps = {}
  if(freeSolo)
    moreProps.freeSolo = true;

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.label || option}
      value={selectedOptions}
      loading={loading}
      onChange={handleChange}
      className={className}
      size='small'
      {...moreProps}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          // label={label}
          placeholder={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default MultipleSelect;