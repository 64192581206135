import { MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import getCurrencies from '../../controllers/settings/currency/getCurrencies';
import { def } from '../../config';
import { getCurrencySymbol } from '../../features/utils/currency';

export default function CurrencyInput({value,onChange,className}) {
  const [currency,setCurrency] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    load();
  },[])

  useEffect(() => {
    setCurrency(value)
  },[value])

  async function load() {
    const res = await getCurrencies()
    if(res.return)
      setOptions(res.data)
  }

  function handleChange(val) {
    setCurrency(val)
    if(onChange)
      onChange(val)
  }
  return (
    <TextField size='small' label='Currency' name='currency' select
    className={className}
    value={currency}
    onChange={(ev) => handleChange(ev.target.value)}
    InputLabelProps={{
      shrink: true,
    }}>
      <MenuItem value={'NGN'}>
        <h5 className='flex gap-1 justify-between w-full'>
          <span>NGN</span>
          {getCurrencySymbol('NGN')}
        </h5>
      </MenuItem>
      {options.map(val => (
        <MenuItem value={val.currencyCode}>
          <h5 className='capitalize flex gap-1 justify-between w-full items-start'>
            {val.currencyCode}
            <span>{val.symbol}</span>
          </h5>
          {/* <small>
            {val.currencyName}
          </small> */}
        </MenuItem>
      ))}
    </TextField>

  )
}
