import mergeRecursive from "../../features/utils/mergeRecursive";

export const InsuranceTemp = {
  "id": "996a1eb5-da2e-4e6e-b7cd-d8460d47f151",
  "bookingId": "LTUPXN",
  "status": "EXPIRED",
  "expiryDate": null,
  "ticketId": null,
  "editedBasePrice": null,
  "editedtotalPrice": 2920210,
  "flightUpgradePrice": 0,
  "flightServicePrice": 0,
  "userId": "ac9a0b00-19cc-4ddf-9d25-b48bf5e19d1c",
  "createdDate": "2023-04-28T18:13:44.739Z",
  "updatedDate": "2023-04-28T18:40:00.809Z",
  "flightUpgradeId": null,
  "flightServiceId": null,
  "flightData": {
    "id": 187,
    "status": "Confirmed",
    "user_id": 223,
    "currency": "NGN",
    "fare_type": "RP",
    "created_at": "2023-04-28 18:13:43",
    "tax_amount": "498,610.00",
    "updated_at": "2023-04-28 18:13:43",
    "fare_amount": "2,415,300.00",
    "total_amount": "2,913,910.00",
    "booked_contact": {
      "id": 198,
      "email": "admin@admin.com",
      "booking_id": 187,
      "created_at": "2023-04-28 18:13:43",
      "updated_at": "2023-04-28 18:13:43",
      "booking_type": "booking",
      "mobile_number": "251-922211221"
    },
    "booked_flights": [
      {
        "id": 262,
        "cabin": "Economy",
        "class": "Y",
        "origin": {
          "date": "2023-05-20",
          "time": "09:00",
          "location": "LOS",
          "terminal": "I"
        },
        "airline": "VS",
        "baggage": null,
        "duration": "06H 30M",
        "equipment": "351",
        "booking_id": 187,
        "created_at": "2023-04-28 18:13:43",
        "updated_at": "2023-04-28 18:13:43",
        "destination": {
          "date": "2023-05-20",
          "time": "15:30",
          "location": "LHR",
          "terminal": "3"
        },
        "flight_number": "412",
        "airline_detail": {
          "iata": "VS",
          "name": "Virgin Atlantic Airways"
        },
        "booked_segments": [
          {
            "id": 348,
            "cabin": "Economy",
            "class": "Y",
            "origin": {
              "date": "2023-05-20",
              "time": "09:00",
              "location": "LOS",
              "terminal": "I"
            },
            "airline": "VS",
            "baggage": null,
            "duration": "06H 30M",
            "equipment": "351",
            "created_at": "2023-04-28 18:13:43",
            "updated_at": "2023-04-28 18:13:43",
            "destination": {
              "date": "2023-05-20",
              "time": "15:30",
              "location": "LHR",
              "terminal": "3"
            },
            "flight_number": "412",
            "stop_duration": "00H 00M",
            "airline_detail": {
              "iata": "VS",
              "name": "Virgin Atlantic Airways"
            },
            "number_of_stops": 0,
            "booked_flight_id": 262,
            "origin_airport_detail": {
              "city": "Lagos",
              "iata": "LOS",
              "country": "Nigeria"
            },
            "destination_airport_detail": {
              "city": "London",
              "iata": "LHR",
              "country": "United Kingdom"
            }
          }
        ],
        "number_of_stops": 0,
        "origin_airport_detail": {
          "city": "Lagos",
          "iata": "LOS",
          "country": "Nigeria"
        },
        "destination_airport_detail": {
          "city": "London",
          "iata": "LHR",
          "country": "United Kingdom"
        }
      },
      {
        "id": 263,
        "cabin": "Economy",
        "class": "Y",
        "origin": {
          "date": "2023-05-27",
          "time": "22:30",
          "location": "LHR",
          "terminal": "3"
        },
        "airline": "VS",
        "baggage": null,
        "duration": "06H 35M",
        "equipment": "351",
        "booking_id": 187,
        "created_at": "2023-04-28 18:13:43",
        "updated_at": "2023-04-28 18:13:43",
        "destination": {
          "date": "2023-05-28",
          "time": "05:05",
          "location": "LOS",
          "terminal": "I"
        },
        "flight_number": "411",
        "airline_detail": {
          "iata": "VS",
          "name": "Virgin Atlantic Airways"
        },
        "booked_segments": [
          {
            "id": 349,
            "cabin": "Economy",
            "class": "Y",
            "origin": {
              "date": "2023-05-27",
              "time": "22:30",
              "location": "LHR",
              "terminal": "3"
            },
            "airline": "VS",
            "baggage": null,
            "duration": "06H 35M",
            "equipment": "351",
            "created_at": "2023-04-28 18:13:43",
            "updated_at": "2023-04-28 18:13:43",
            "destination": {
              "date": "2023-05-28",
              "time": "05:05",
              "location": "LOS",
              "terminal": "I"
            },
            "flight_number": "411",
            "stop_duration": "00H 00M",
            "airline_detail": {
              "iata": "VS",
              "name": "Virgin Atlantic Airways"
            },
            "number_of_stops": 0,
            "booked_flight_id": 263,
            "origin_airport_detail": {
              "city": "London",
              "iata": "LHR",
              "country": "United Kingdom"
            },
            "destination_airport_detail": {
              "city": "Lagos",
              "iata": "LOS",
              "country": "Nigeria"
            }
          }
        ],
        "number_of_stops": 0,
        "origin_airport_detail": {
          "city": "London",
          "iata": "LHR",
          "country": "United Kingdom"
        },
        "destination_airport_detail": {
          "city": "Lagos",
          "iata": "LOS",
          "country": "Nigeria"
        }
      }
    ],
    "control_number": "LTUPXN",
    "booked_travellers": [
      {
        "id": 179,
        "type": "Adult",
        "title": "Mr",
        "gender": "MALE",
        "currency": "NGN",
        "last_name": "Terefe",
        "booking_id": 187,
        "created_at": "2023-04-28 18:13:43",
        "first_name": "Yared",
        "tax_amount": "498610",
        "updated_at": "2023-04-28 18:13:43",
        "fare_amount": "2415300",
        "total_amount": "2913910",
        "date_of_birth": "1998-10-09"
      }
    ],
    "booked_payment_method": {
      "id": 203,
      "type": "credit",
      "detail": {
        "freeText": "*********",
        "creditCardExpiry": "****",
        "creditCardHolder": "******",
        "creditCardNumber": "**********",
        "creditCardCvcCode": "***"
      },
      "booking_id": 187,
      "created_at": "2023-04-28 18:13:43",
      "updated_at": "2023-04-28 18:13:43",
      "booking_type": "booking"
    }
  },
  "insuranceAPIData": {
    "wef": "2022-11-14T00:00:00",
    "wet": "2023-11-28T00:00:00",
    "hash": null,
    "name": "Yared Terefe",
    "certNo": null,
    "excess": 200,
    "smsTel": null,
    "userId": null,
    "isQuote": false,
    "medical": "Medical Condition",
    "noKaddr": "Victoria Island",
    "nokName": "segun",
    "premium": 120000,
    "titleId": null,
    "branchId": "15439e44-98cf-e711-83b7-44850015c0e7",
    "clientId": "808d1cc6-f0e5-ed11-8e89-6045bdebf4db",
    "currency": null,
    "genderId": null,
    "isActive": false,
    "lastName": null,
    "pryEmail": null,
    "countryId": "cb14a378-98e4-e711-83bb-44850015c0e4",
    "firstName": null,
    "groupType": null,
    "is9Mobile": false,
    "productId": "cb00e3f3-9feb-e711-a2be-005056a02281",
    "transDate": "2023-04-28T18:19:08.3264334+00:00",
    "commission": 0,
    "fareAmount": 6300,
    "preMedical": false,
    "sumAssured": 200000,
    "commAllowed": false,
    "dateOfBirth": "0001-01-01T00:00:00",
    "embassycode": null,
    "passportUrl": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/...",
    "travelParty": null,
    "grossPremium": 120000,
    "referralCode": null,
    "physicalAddrs": null,
    "ravePublicKey": "FLWPUBK_TEST-d7fec501b9be2af81e11abd94c814e07-X",
    "travelPurpose": null,
    "intermediaryId": null,
    "passportNumber": "0000000",
    "paymentChannel": null,
    "transactionRef": "TRA-9005B4",
    "wemaBankMacKey": "7E410E2C6FAEB61C7A128026CB0160E4581F90194B72E609EDB46F8D4FFB1637",
    "noKrelationship": "Brother",
    "beneficiaryPhone": null,
    "clientCategoryId": null,
    "partnerReference": null,
    "interswitchMacKey": "E187B1191265B18338B5DEBAF9F38FEC37B170FF582D4666DAB1F098304D5EE7F3BE15540461FE92F1D40332FDBBA34579034EE2AC78B1A1B8D9A321974025C4",
    "paystackPublicKey": "pk_test_7c81d65b68c9e5723b28f0e52bd30d267a374303",
    "paystackSubaccount": "",
    "wemaBankPaymentUrl": "https://apps.wemabank.com/GateWay",
    "beneficiaryFullname": null,
    "interswitchProductId": "6204",
    "is9MobileTransaction": false,
    "agentShortDescription": null,
    "interswitchPaymentUrl": "https://sandbox.interswitchng.com/webpay/pay",
    "beneficiaryDateOfBirth": null,
    "interswitchProductItemId": "103"
  },
  "selectedSeats": null,
  "promoCodesId": null,
  "bookedByAdminId": null,
  "transactions": []
}


export function templateInsurance(obj) {
  let data = mergeRecursive({...obj},InsuranceTemp);

  let fullName = "";
  let destination = "";
  try {
    fullName = data.flightData.booked_travellers[0].first_name + " " + data.flightData.booked_travellers[0].last_name;
    destination = data.flightData.booked_flights[0].destination_airport_detail;
  } catch(ex) {}
  return {...data,
    customer: fullName,
    bookingDate: data.updatedDate,
    packageName: data.flightData ? 'Flight' : '------',
    destination,
    bookingId: data.bookingId,
    amount: data.insuranceAPIData.fareAmount,
    status: data.flightData.status
  };
}
