import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import RolesInput from "../../../components/form/RolesInput";
import { addStaffRole } from "../../../controllers/settings/staff/addRole";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../../redux/reducers/modal/snackBarSlice";
import { updateRoleStaff } from "../../../controllers/settings/staff/updateRole";

export default function StaffRoleForm({callback,update}) {
    const [data,setData] = useState({
        roleName: update?.roleName || '',
		allowedRoles: update?.allowedRoles || []
	})
    const [loading,setLoading] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(update?.id) {
            let {roleName,allowedRoles} = update;
            setData({roleName,allowedRoles})
        } else setData({roleName: '',allowedRoles: []})
    },[update])

	async function handleSubmit(ev) {
		ev.preventDefault();

        setLoading(true);
        let res = {return: 0,msg: 'Something went wrong! please contact support 0XSRF'};
        if(update)
            res = await updateRoleStaff(update.id,data)
        else
            res = await addStaffRole(data) 
        setLoading(false);
        if(res.return)
            dispatch(setAlertMsg(['success','Successfull']))
        else dispatch(setAlertMsg(['error',res?.msg]))

        callback && callback(res)
	}
	return (
		<form onSubmit={handleSubmit} className='flex flex-col gap-4'>

			<TextField label='Role Name' required size='small'
				value={data.roleName}
				onChange={(ev) => setData({...data,roleName: ev.target.value})}
			/>
            <div>
			    <RolesInput value={data?.allowedRoles} callback={(allowedRoles) => setData({...data,allowedRoles})} />
            </div>
			<button className="btn1 flex items-center justify-center gap-2" type='submit' disabled={loading} >
                {loading?<div className="load"></div>:null}
                {update?'Update':'Add'}
            </button>
		</form>
	)
}