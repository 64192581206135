import React, { useEffect, useState } from 'react'
import ModalLocal from '../../components/mini/ModalLocal'
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import FetchCWTPassengers from '../../components/form/FetchCWTPassengers';
import { cwtCompaniesData, cwtCompanyData, cwtTravellersData } from '../../data/flight/cwt';
import { getCWT } from '../../controllers/flight/cwt/getCWT';
import axios from 'axios';

let cancelToken = null;
export default function CWTForm({office,company:defCompany,callback,variant}) {
    const [open,setOpen] = useState(true);
    const [q,setQ] = useState('')
    const [companyList,setCompanyList] = useState([])
    const [company,setCompany] = useState()
    const [data,setData] = useState({company: '',passenger: null})
    const [companiesLoad,setCompaniesLoad] = useState(false);
    const [companyLoad,setCompanyLoad] = useState(false);

    useEffect(() => {
        loadCompanies();
        //eslint-disable-next-line
    },[q,office])
    useEffect(() => {
        setCompanyList([])
        setCompany(defCompany || undefined)
        setQ('')
    },[office,defCompany])

    async function loadCompanies() {
        if(cancelToken)
            cancelToken?.cancel('Request canceld for another search')

        cancelToken = axios.CancelToken.source()

        setCompaniesLoad(true);
        let params = {
            office,
            mode: 'name',
            profile: 'company',
            value: ''
        }
        const res = await getCWT((new URLSearchParams(params))?.toString(),cancelToken)
        // const res = await new Promise((resolve) => setTimeout(() => {
        //     resolve({return: true,data: cwtCompaniesData?.AMA_ProfileReadRS?.Profiles?.ProfileInfo})
        // },1000))
        // console.log(res.data)
        setCompaniesLoad(false);
        if(res.return) {
            let profileInfo = res?.data?.AMA_ProfileReadRS?.Profiles?.ProfileInfo;
            if(!Array.isArray(profileInfo))
                profileInfo = [profileInfo]
            profileInfo = (profileInfo || [])?.filter(obj => obj?.Profile?.CompanyInfo?.CompanyName?.toLowerCase()?.includes(q?.toLowerCase()))
            setCompanyList(profileInfo);
            // console.log('here: ',res.data)
        }
    }

    async function loadCompany(value) {
        if(!value) return false;

        setCompanyLoad(true);
        let params = {
            office,
            mode: 'name',
            profile: 'company',
            value
        }
        const res = await getCWT(new URLSearchParams(params)?.toString())
        // const res = await new Promise((resolve) => setTimeout(() => {
        //     resolve({return: true,data: cwtCompanyData?.AMA_ProfileReadRS?.Profiles?.ProfileInfo})
        // },2000))
        setCompanyLoad(false);
        if(res.return) {
            // let profiles = (res.data || [])?.filter(obj => obj?.Profile?.CompanyInfo?.CompanyName?.toLowerCase()?.includes(q?.toLowerCase()))
            const data = res.data?.AMA_ProfileReadRS?.Profiles?.ProfileInfo;
            setCompany(data);
            callback && callback(data);
        }
    }

    function handlePassengerSelect(passenger) {
        setData({...data,passenger})
        callback && callback(passenger)
    }
    
    // console.log(defCompany,office)
  return (
    <div>
        {/* <TextField label='CWT Profile' size='small'
            value={data?.passenger?.Profile.Customer.PersonName.GivenName || ''} onFocus={() => setOpen(true)} /> */}
        {/* <ModalLocal open={open} setOpen={setOpen}> */}
            {/* <div className='card bg-secondary p-10 rounded-md flex flex-col gap-4'>
                <h5>CWT Profiles</h5> */}
                {variant === 'passenger' ? 
                    <FetchPassengers companyName={company?.Profile?.CompanyInfo?.CompanyName} callback={(passenger) => handlePassengerSelect(passenger)} office={office}  />
                :
                    <Autocomplete size='small' options={companyList} noOptionsText={q ? 'No Company found' : 'Search for companies'} loading={companiesLoad} className='min-w-[300px]'
                        getOptionLabel={(opt) => typeof opt === 'string' ? opt : opt?.Profile?.CompanyInfo?.CompanyName}
                        value={q} onInputChange={(_,val) => setQ(val)}
                        onChange={(_,company) => loadCompany(company?.Profile?.CompanyInfo?.CompanyName)}
                        renderInput={(params) => (
                            <TextField size='small' {...params} label='Search/Select Company' />
                        )}
                        renderOption={(props,opt) => (
                            <div {...props} key={opt.id}>
                                {opt?.Profile.CompanyInfo.CompanyName}
                            </div>
                        )}
                    />
                }
                {/* {companyLoad ? <div className='flex justify-center p-10 py-5'><CircularProgress className='!w-5 !h-5' /></div>
                :
                company ? 
                    <FetchPassengers companyName={company?.Profile?.CompanyInfo?.CompanyName} callback={(passenger) => setData({...data,passenger})} office={office} onChange={handlePassengerSelect} />
                :null} */}
                {/* <div>
                    <button className='btn2' onClick={() => setOpen(false)}>Done</button>
                </div> */}
            {/* </div> */}
        {/* </ModalLocal> */}
    </div>
  )
}

function FetchPassengers({companyName,callback,office}) {
    const [q,setQ] = useState('');
    const [loading,setLoading] = useState(false);
    const [list,setList] = useState([]);
    const [selectedPassenger,setSelectedPassenger] = useState();

    useEffect(() => {
        if(selectedPassenger)
            callback && callback(selectedPassenger)
        // console.log(selectedPassenger)

        //eslint-disable-next-line
    },[selectedPassenger])

    useEffect(() => {
        if(q)
            loadPassengers(q);

        //eslint-disable-next-line
    },[q])


    async function loadPassengers(value) {
        if(cancelToken)
            cancelToken.cancel('Canceled for another search')

        cancelToken = axios.CancelToken.source()
        
        setLoading(true);
        let params = {
            office,
            mode: 'name',
            profile: 'traveler',
            value
        }
        const res = await getCWT(new URLSearchParams(params)?.toString(),cancelToken)

        // const res = await new Promise((resolve) => setTimeout(() => {
        //     resolve({return: true,data: cwtTravellersData?.AMA_ProfileReadRS?.Profiles?.ProfileInfo})
        // },1000))
        setLoading(false);
        if(res.return) {
            let profiles = (res.data?.AMA_ProfileReadRS?.Profiles?.ProfileInfo || [])?.filter(obj => {
                let name = obj?.Profile?.Customer?.PersonName;
                name = name?.GivenName +' '+name?.Surname;

                return obj?.Profile?.Customer?.RelatedCompany?.CompanyName === companyName &&
                    name?.toLowerCase()?.includes(value?.toLowerCase())
            })
            setList(profiles);
            console.log('here: ',profiles,companyName)
        }

    }
    
    return (
        <Autocomplete size='small' options={list} noOptionsText={q ? 'No Passenger found' : 'Search for Travellers'} 
            loading={loading} className='min-w-[300px]'
            getOptionLabel={(opt) => typeof opt === 'string' ? opt : opt?.Profile?.Customer?.PersonName?.GivenName + ' ' + opt?.Profile?.Customer?.PersonName?.Surname}
            value={q} onInputChange={(_,val) => setQ(val)}
            onChange={(_,passenger) => setSelectedPassenger(passenger)}
            renderInput={(params) => (
                <TextField size='small' {...params} label='Search/Select Travellers' />
            )}
            renderOption={(props,opt) => (
                <div {...props} key={opt.id}>
                    {opt?.Profile?.Customer?.PersonName?.GivenName + ' ' + opt?.Profile?.Customer?.PersonName?.Surname}
                </div>
            )}
        />

    )
}
