import { path } from "../../config";
import { store } from "../../redux/store";
import fetchServer from "../fetchServer";

export async function getCustomers(type="CUSTOMER") {
  var response = {return: 0,msg: "Error",data: null}

  let token = store.getState().user.userData.accessToken;

  // console.log("getCustomer Type : ",type);
  await fetchServer({method: 'GET',url: path.api+`/users/?type=${type}`,
    headers: {
      Authorization: `Bearer ${token}`
    } 
  })
  .then((res) => {
    console.log(" -- ",res)
    if(res && res?.data?.success) {
      response = {return: 1,msg: "Successfull",data: res.data.data}
    } else if(res?.error) response['msg'] = res?.error?.message;
  })
  .catch(e => console.log("Network Error: ",e))
  
  return response;
}