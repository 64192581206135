import { PersonRounded } from '@mui/icons-material'
import moment from 'moment';
import React from 'react'

export default function Passengers({data}) {
  const types = {};
  data?.map(passenger => types[passenger.boardingUserType] = (types[passenger.boardingUserType] || 0) + 1)

  return (
    <div className='flex flex-col gap-3 '>
      <div>
        <div className='flex justify-between items-center'>
          <h5>Passengers</h5>
          <div className='flex gap-2 flex-wrap'>
            {Object.entries(types || {})?.map(([key,val],i) => (
              <button key={i} className='btn2'><PersonRounded /> {key} {val}</button>
              ))}
          </div>
        </div>
      </div>
      <hr />
      <table className='w-full mx-2'>
        <thead>
          <tr>
            <td>Name</td>
            <td className='flex-1'>Date of Birth</td>
            <td>Gender</td>
          </tr>
        </thead>
        <tbody>
          {data?.map((passenger,i) => (
            <tr key={i}>
              <td>{passenger.title} {passenger.firstName} {passenger?.middleName} {passenger.lastName}</td>
              <td>{moment(passenger.birthDate).format("MMM DD YYYY")}</td>
              <td>{passenger.gender}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
