import { Breadcrumbs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import FlightInfo from './FlightInfo';
import Passengers from './Passengers';
import FlightDetails from './FlightDetails';
import PriceSummary from './PriceSummary';
import getFlightOrder from '../../../controllers/flight/getFlightOrder';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../../redux/reducers/titleSlice';
import { flightDataTemp, templateDetails } from '../../../data/flight/detail';


let temp = flightDataTemp

export default function Index() {
  const [data,setData] = useState(temp);
  const {id} = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setHeaderTitle("Flight Details"));
    load(id);
  },[id,dispatch])
  
  async function load(id) {
    const res = await getFlightOrder(id);
    if(res.return) {
      let data = templateDetails(res.data)
      console.log("detail -> ",data)
      setData(data);
    }
  }

  
  return (
    <div className='w-full p-4 m-2'>
      <Breadcrumbs separator=">">
        <Link to="/flights">Orders</Link>
        <span >{data.bookingId}</span>
      </Breadcrumbs>
      <h3 className='!text-[#444]'>{data.bookingId}</h3>


      <div className='flex gap-4 flex-wrap'>
        <div className='flex-1 flex flex-col gap-10 pb-10 mb-10'>
          <div className='mt-8'>
            <FlightDetails data={data} />
          </div>
          <div className=''>
            <Passengers data={data.bookingUsers} />
          </div>
          <div className=''>
            <Seats data={data} />
          </div>
          <div className=''>
            <FlightInfo data={data.flightData.booked_flights} />
          </div>
          <div className=''>
            <PriceSummary data={data} />
          </div>
        </div>
        <div>
          <MinDetail data={data} />
        </div>
      </div>
    </div>
  )
}

function Seats({data}) {
  return (
    <div className='flex flex-col gap-2 mb-3'>
      <h5>Flight Seats</h5>
      <div className='flex gap-4'>
        {data.selectedSeats.length ? data.selectedSeats.map((seat) => (
          <label className='p-2 border border-primary/30 rounded-md'>{seat.seatNumber}</label>
        )) : '----'}
      </div>
    </div>
  )
}
function MinDetail({data}) {
  return (
    <div>
      <h5 className='px-4'>Flight Details</h5>
      <hr />
      <div className='px-4 pt-5 flex flex-col gap-4'>
        <div>
          <div>Status</div>
          {
            data.status === 'PAID_FOR' ||
            data.status === 'Confirmed' ? 
              <span className='text-xs p-2 inline-block rounded-md !border-[#45BF43] border !bg-[#58ffb038]'>Confirmed</span>
              : (data.status === 'PENDING_PAYMENT') ? 
                <span className='text-xs p-2 inline-block rounded-md !border-[#ffea2b] bg-[#fff3b1] border'>{data.status}</span>
                :
                <span className='text-xs p-2 inline-block rounded-md !border-[#ff8282] bg-[#feafaf] border'>{data.status}</span>

          }
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex justify-between gap-10'>
            <div>Ariline</div>
            <div>Issuing Date</div>
          </div>
          <div className='flex  justify-between gap-10'>
            <b className='flex gap-1'>
              <img alt="turkish" src={(data.flightData.booked_flights[0].booked_segments[0].carrierIcon)} width={20} height={20} />
              {data.flightData.booked_flights[0].airline_detail.name}
            </b>
            <b>
              {(new Date(`${data.flightData.booked_flights[0].created_at}`).toDateString())}
            </b>
          </div>
        </div>
        <div>
          <div>Order Id</div>
          <b>{data.flightData.booked_flights[0].booking_id}</b>
        </div>
        <HideShow header={<span>Ticket Number</span>}>
          <div>
            <div className='target'>{data.ticketId}</div>
          </div>
        </HideShow>
      </div>
    </div>
  )
}


function HideShow(props) {
  const [show,setShow] = useState(false);
  return (
    <div className='flex flex-col '>
      {props.header}
      {show ? 
        <div>
          {props.children}
        </div>
      :null}
      <span onClick={() => setShow(!show)}>{show?'hide':'show'}</span>
    </div>
  )
}