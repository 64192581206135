import { Box, Button, MenuItem, TextField } from '@mui/material'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import Pagination from '../../../components/mini/Pagination';
import { getStaffRoles } from '../../../controllers/settings/staff/getStaffRoles';
import DotMenu from '../../../components/mini/DotMenu';
import { Icon } from '@iconify/react';
import StaffRoleForm from './StaffRoleForm';
import ModalLocal from '../../../components/mini/ModalLocal';
import { deleteStaffRole } from '../../../controllers/settings/staff/deleteRole';
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';


function ActionCol({params,reload,edit}) {
	const [anchorEl,setAnchorEl] = useState();
	const dispatch = useDispatch();

	async function handleDelete() {
		setAnchorEl(null);
		const res = await deleteStaffRole(params.id)
		if(res.return) {
			reload && reload();
			dispatch(setAlertMsg(['success','Role Removed']))
		}
		else dispatch(setAlertMsg(['error',res?.msg]))
	}

	function handleEdit() {
		setAnchorEl(null);
		edit && edit(params.row)
	}

	return (
    <div className='flex justify-between w-full relative'>
      <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
        {params.value}
      </p>
      <DotMenu anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true} >
				<MenuItem className='flex gap-2 items-center ' onClick={handleEdit}>
					<Icon icon='tabler:edit' /> Edit
				</MenuItem>
        <MenuItem className='flex gap-2 items-center bg-red-500 text-white' onClick={handleDelete}>
					<Icon icon='material-symbols:delete' /> Delete
				</MenuItem>
      </DotMenu>

    </div>

	)
}

const filterItems = [
	{ items: [{ id: 0, field: 'status', operator: '', value: '' }] },
	{ items: [{ id: 1, field: 'status', operatorValue: 'is', value: 'active' }] },
	{ items: [{ id: 2, field: 'status', operatorValue: 'is', value: 'inactive' }] },
];

export default function StaffRoles() {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false);
	const apiRef = useGridApiRef();
	const [paginationModel, setPaginationModel] = useState({ pageSize: 25, page: 0 })

	const [open,setOpen] = useState(false);

	const [filter, setFilter] = useState(filterItems[0]);

	useEffect(() => {
		load();
	}, [])

	async function load() {
		setLoading(true);
		const res = await getStaffRoles();
		setLoading(false);
		if (res.return)
			setData(res.data)
	}

	const columns = [
		{ field: 'roleName', headerName: 'Role Name', flex: 1 },
		{
			field: 'allowedRoles', headerName: 'Allowed Roles', flex: 1,
			renderCell: (params) => {
				if (!params.value)
					return null;

				return (
					<div className=' py-2 h-auto overflow-hidden overflow-y-auto max-h-full'>
						{params.value?.map((val, i) => (
							<div className='' key={i}>{val}</div>
						))}
					</div>
				)
			}
		},
		{field: 'action', headerName: 'Actions', flex: 1,
			renderCell: (params) => (
				<ActionCol params={params} edit={(row) => setOpen(row)} reload={load} />
			)
		}
	]

	function handleAdd(res) {
		if(res.return)
			load();
		
		setOpen(false);
	}

	return (
    <div className='w-full p-4 bg-[#f3f3f3]'>
			<div className='bg-secondary p-4 flex flex-col gap-4'>
				<button className='btn1 self-end' onClick={() => setOpen(true)}>Add New Role</button>
				<ModalLocal open={open} setOpen={setOpen}>
					<div className='bg-secondary p-4 mx-10 rounded-lg'>
						<StaffRoleForm callback={(res) => handleAdd(res)} update={open?.id && open} />
					</div>
				</ModalLocal>
				<Box className=''>
					<DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
						// getRowId={(row) => row.id}
						apiRef={apiRef}
						initialState={{
							sorting: {
								sortModel: [{ field: "bookingDate", sort: 'desc' }]
							},
						}}
						filterModel={filter}
						onFilterModelChange={(newFilter) => setFilter(newFilter)}

						// slots={{pagination: Pagination}}
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}

						// onCellEditStart={params => setRowId(params.id)}
						// hideFooterPagination
						checkboxSelection
					/>
					<Pagination perPage={10} total={46} apiRef={apiRef} />
				</Box>
			</div>
		</div>
	)
}

