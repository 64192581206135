import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { createNotification } from '../../../controllers/settings/notification/createNotification';
import { updateNotification } from '../../../controllers/settings/notification/updateNotification';


let init = {title: null,body: ''};
export default function CreateNotification({editData,returnData}) {
  const [data,setData] = useState(init);
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(editData) {
      const {body,title} = editData;
      setData({body,title})
    }
  },[editData])

  console.log(editData)
  console.log(data)
  async function handleSubmit(ev) {
    ev.preventDefault();

    setLoading(true);
    let res;
    if(editData)
      res = await updateNotification(editData.id,data);
    else
      res = await createNotification(data);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Successful.']))
      setData(init)
      if(returnData)
        returnData();
    } else dispatch(setAlertMsg(['error',res.msg]))
  }

  return (
    <form onSubmit={handleSubmit} className='flex flex-col gap-2 p-4'>
      <TextField size="small" label={'Title'}
        value={data.title}
        onChange={(ev) => setData({...data,title: ev.target.value})}
      />
      <textarea placeholder='Body' className='border p-3 resize-y min-h-[150px]' 
        value={data.body}
        onChange={(ev) => setData({...data,body: ev.target.value})}      
      ></textarea>
      <button className='btn2 self-start flex gap-2 items-center'>
        {loading?<div className='load'></div>:null}
        {editData ? 'Update': 'Create'}
      </button>
    </form>
  )
}
