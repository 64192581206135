import mergeRecursive from "../../features/utils/mergeRecursive";

export const agentProfileTemp = {
    "companyName": null,
    "companyDescription": null,
    "remark": null,
    "cacRegNo": null,
    "location": null,
    "contactSurName": null,
    "contactFirstName": null,
    "contactEmail": null,
    "contactPhone": null,
    "contactPhone2": null,
    "receivePromotion": null,
    "referalDescription": null,
    "referalUsername": null,
    "referalRelationShip": null,
    "referalAccountNumber": null,
    "earnPointsTogether": null,
    "facebookHandle": null,
    "twitterHandle": null,
    "instagramHandle": null,
    "linkedInHandle": null,
    "snapchatHandle": null,
    "contactTitle": null,
    "salesManager": null,
    "country": null,
    "location2": null,
    "currency": null,
    "paymentType": null,
    "startDate": null,
    "endDate":null
}

export function templateAgentProfile(obj) {
  let data = mergeRecursive({...obj},agentProfileTemp);
  return {...data};
}
