export const fareRuleTypesData = [
'SERVICE_FEE_FLIGHT',
'SERVICE_FEE_HOTEL',
'SERVICE_FEE_TOUR',
'SERVICE_FEE_VIATOR',
'VIATOR_BOOKING',
"ADULT",
"CHILD",
"FAMILY",
"ONEWAY",
"RETURN",
"AIRLINE",
"DEPARTURE_AIRPORT",
"ARRIVAL_AIRPORT",
"TRANSIT_AIRPORT",
"WAIT_TIME",
"CABIN",
"TICKET_CLASS",
"ARRIVAL_TIME",
"BAGGAGE",
"INSURANCE",
"TOUR_PRO_FLIGHT",
"TOUR_PRO_HOTEL",
"TOUR_PRO_TOUR",
"TOUR_PRO_PLACE",
"TOUR_PRO_EVENT",
"TOUR_PRO_ACTIVITY",
'HOTEL_ROOM',
]

export function mapFareType(val) {
  let obj = {
    'SERVICE_FEE_FLIGHT': 'Flight Service Fee',
    'SERVICE_FEE_HOTEL': 'Hotel Service Fee',
    'SERVICE_FEE_TOUR': 'Tour Service Fee',
    'SERVICE_FEE_VIATOR': 'Viator Service Fee',
  }

  let newval = val;
  try {
    newval = obj[val.toUpperCase()] || val;
  } catch(ex) {}

  return newval;
}