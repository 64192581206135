import React, { memo, useEffect, useState } from 'react'
import getActivityLogs from '../../controllers/dashboard/getActivityLogs';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CircularProgress, TextField } from '@mui/material';
import { Icon } from '@iconify/react';


var orgiData = null;
function ActivityLogs({limit}) {
  const [logs,setLogs] = useState([])
  const [q,setQ] = useState('');
  const [defLimit,setDefLimit] = useState(limit||50);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    load();
    //eslint-disable-next-line
  },[defLimit])

  useEffect(() => {
    if(!q) {
      if(orgiData)
      setLogs(orgiData)
    }
    else
      setLogs((orgiData || logs)?.filter(obj => obj?.logText?.toLowerCase()?.includes(q?.toLowerCase()) || moment(obj.createdDate).format("DD/MM/YY hh:mm")?.includes(q)))
    //eslint-disable-next-line
  },[q,orgiData])

  async function load() {
    let params = {
      skip: 0,
      limit: defLimit,
    }
    setLoading(true);
    const res = await getActivityLogs((new URLSearchParams(params)).toString())
    setLoading(false);
    if(res.return) {
      orgiData = res.data
      setLogs(res.data)
    }
  }
  
  return (
    <div className='w-full'>
      <div className='bg-secondary p-4 flex flex-col gap-6'>
        <div className='flex justify-between items-center gap-4'>
          <h3>Activity Logs</h3>
          <div>
            <TextField size='small' 
              value={q} onChange={(ev) => setQ(ev.target.value)} 
              InputProps={{
                endAdornment: <Icon icon='fluent:search-12-filled' />
              }}
            />
          </div>
          {limit ? 
            <Link to="/settings/logs">View More</Link>
          :null}
        </div>
        <div className='border p-3'>
        <div className=' overflow-hidden overflow-y-auto flex flex-col gap-2'>
          {logs.map((obj,i) => (
            <div className='flex flex-col'>
              <div className='flex gap-3 items-center'>
                <small className='font-bold font-mono'>{moment(obj.createdDate).format("DD/MM/YY hh:mm")}</small>
                <p>{obj.logText}</p>
              </div>
              <div className='flex gap-2 min-h-[20px] items-end'>
                {obj.userId ? (
                  <Link className='bg-theme1/20 px-2 text-[10px] ' to={`/customers/detail/${obj.userId}`} >User</Link>
                ):null}
                {obj.targetTour ? (
                  <Link className='bg-theme1/20 px-2 text-[10px] ' to={`/packages/details/${obj.targetTour.id}`} >Tour Detail</Link>
                ):null}
                {obj.targetFlight ? (
                  <Link className='bg-theme1/20 px-2 text-[10px] ' to={`/flights/details/${obj.targetFlight.id}`} >Flight Detail</Link>
                ):null}
                {obj.targetHotel ? (
                  <Link className='bg-theme1/20 px-2 text-[10px] ' to={`/hotels/details/${obj.targetHotel.id}`} >Hotel Detail</Link>
                ):null}
                {obj?.requestHeaderInfo ? 
                  <div className='bg-theme1/20 px-2 text-[10px] group relative cursor-pointer'>
                    Device Info
                    <div className='absolute z-10 top-full left-0 border min-w-[200px] max-w-full shadow-md bg-secondary p-2 group-hover:flex flex-col gap-2 hidden'>
                      <span>{obj?.requestHeaderInfo?.desktopOrMobile}</span>
                      <span> 
                        {obj?.requestHeaderInfo?.browser}
                      </span>
                      <span> 
                        {obj?.requestHeaderInfo?.ip}
                      </span>
                      <span>
                        {obj?.requestHeaderInfo?.os}
                      </span>
                      <span>User agent: &nbsp;
                        {obj?.requestHeaderInfo?.userAgent}
                      </span>
                    </div>
                  </div>
                :null}
              </div>
              <hr />
            </div>
          ))}
          </div>
          <div className='sticky bottom-0 flex justify-center'>
            {logs?.length ? 
              <button className='p-2 bg-secondary px-4 border shadow-md flex gap-2 items-center'
                onClick={() => setDefLimit(defLimit * 2)}
              >
                {loading?
                  <CircularProgress className='!w-3 !h-3' />
                :
                  <Icon icon={'formkit:arrowdown'} />
                }
                Load More
              </button>
            :null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(ActivityLogs)