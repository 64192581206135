import mergeRecursive from "../../features/utils/mergeRecursive";

export const transactionTemp = {
  "id": "",
  "from": "",
  "to": "",
  "paymentReference": "",
  "transactionHash": null,
  "confirmedById": null,
  "bookedTripId": "",
  "transactionStatus": "",
  "fromWalletId": null,
  "toWalletId": null,
  "createdDate": "",
  "updatedDate": "",
  "userId": "",
  "amount": "",
  "bookedActivityId": null,
  "bookedHotelId": null,
  "confirmedBy": null,
  "user": {
      "id": "",
      "username": null,
      "firstName": "",
      "email": "",
      "lastName": "",
      "password": "",
      "otp": null,
      "profile": null,
      "isAdmin": false,
      "phoneNumber": "",
      "type": "",
      "customSettingid": null,
      "gender": "",
      "companyName": "",
      "addressLine1": "",
      "addressLine2": null,
      "postalCode": "",
      "cityName": "",
      "countryCode": "",
      "createdDate": "",
      "updatedDate": ""
  },
  "bookedTrip": {
    "id": "",
    "bookingId": "",
    "status": "",
    "expiryDate": "",
    "ticketId": null,
    "editedBasePrice": "",
    "editedtotalPrice": "",
    "flightUpgradePrice": "",
    "flightServicePrice": "",
    "userId": "",
    "createdDate": "",
    "updatedDate": "",
    "flightUpgradeId": "",
    "flightServiceId": null,
    "flightData": {
        "id": "",
        "status": "",
        "user_id": "",
        "currency": "",
        "fare_type": "",
        "created_at": "",
        "tax_amount": "",
        "updated_at": "",
        "fare_amount": "",
        "total_amount": "",
        "booked_contact": {
            "id": "",
            "email": "",
            "booking_id": "",
            "created_at": "",
            "updated_at": "",
            "booking_type": "",
            "mobile_number": ""
        },
        "booked_flights": [
            {
                "id": "",
                "cabin": "",
                "class": "",
                "origin": {
                    "date": "",
                    "name": "",
                    "time": "",
                    "location": "",
                    "terminal": ""
                },
                "airline": "",
                "baggage": null,
                "duration": "",
                "equipment": "",
                "booking_id": "",
                "created_at": "",
                "updated_at": "",
                "destination": {
                    "date": "",
                    "name": "",
                    "time": "",
                    "location": "",
                    "terminal": ""
                },
                "flight_number": "",
                "airline_detail": {
                    "iata": "",
                    "name": ""
                },
                "booked_segments": [
                    {
                        "id": "",
                        "cabin": "",
                        "class": "",
                        "origin": {
                            "date": "",
                            "time": "",
                            "location": "",
                            "terminal": "",
                            "AirportName": "",
                            "CountryCode": ""
                        },
                        "airline": "",
                        "baggage": null,
                        "duration": "",
                        "equipment": "",
                        "created_at": "",
                        "updated_at": "",
                        "carrierIcon": "",
                        "destination": {
                            "date": "",
                            "time": "",
                            "location": "",
                            "terminal": "",
                            "AirportName": "",
                            "CountryCode": ""
                        },
                        "flight_number": "",
                        "stop_duration": "",
                        "airline_detail": {
                            "iata": "",
                            "name": ""
                        },
                        "carrierFullName": "",
                        "number_of_stops": "",
                        "booked_flight_id": "",
                        "origin_airport_detail": {
                            "city": "",
                            "iata": "",
                            "country": ""
                        },
                        "destination_airport_detail": {
                            "city": "",
                            "iata": "",
                            "country": ""
                        }
                    }
                ],
                "number_of_stops": "",
                "origin_airport_detail": {
                    "city": "",
                    "iata": "",
                    "country": ""
                },
                "destination_airport_detail": {
                    "city": "",
                    "iata": "",
                    "country": ""
                }
            },
            {
                "id": "",
                "cabin": "",
                "class": "",
                "origin": {
                    "date": "",
                    "name": "",
                    "time": "",
                    "location": "",
                    "terminal": ""
                },
                "airline": "",
                "baggage": null,
                "duration": "",
                "equipment": "",
                "booking_id": "",
                "created_at": "",
                "updated_at": "",
                "destination": {
                    "date": "",
                    "name": "",
                    "time": "",
                    "location": "",
                    "terminal": ""
                },
                "flight_number": "",
                "airline_detail": {
                    "iata": "",
                    "name": ""
                },
                "booked_segments": [
                    {
                        "id": "",
                        "cabin": "",
                        "class": "",
                        "origin": {
                            "date": "",
                            "time": "",
                            "location": "",
                            "terminal": "",
                            "AirportName": "",
                            "CountryCode": ""
                        },
                        "airline": "",
                        "baggage": null,
                        "duration": "",
                        "equipment": "",
                        "created_at": "",
                        "updated_at": "",
                        "carrierIcon": "",
                        "destination": {
                            "date": "",
                            "time": "",
                            "location": "",
                            "terminal": "",
                            "AirportName": "",
                            "CountryCode": ""
                        },
                        "flight_number": "",
                        "stop_duration": "",
                        "airline_detail": {
                            "iata": "",
                            "name": ""
                        },
                        "carrierFullName": "",
                        "number_of_stops": "",
                        "booked_flight_id": "",
                        "origin_airport_detail": {
                            "city": "",
                            "iata": "",
                            "country": ""
                        },
                        "destination_airport_detail": {
                            "city": "",
                            "iata": "",
                            "country": ""
                        }
                    }
                ],
                "number_of_stops": "",
                "origin_airport_detail": {
                    "city": "",
                    "iata": "",
                    "country": ""
                },
                "destination_airport_detail": {
                    "city": "",
                    "iata": "",
                    "country": ""
                }
            }
        ],
        "control_number": "",
        "booked_travellers": [
            {
                "id": "",
                "type": "",
                "title": "",
                "gender": "",
                "currency": "",
                "last_name": "",
                "booking_id": "",
                "created_at": "",
                "first_name": "",
                "tax_amount": "",
                "updated_at": "",
                "fare_amount": "",
                "total_amount": "",
                "date_of_birth": ""
            }
        ],
        "booked_payment_method": {
            "id": "",
            "type": "",
            "detail": {
                "freeText": "",
                "creditCardExpiry": "",
                "creditCardHolder": "",
                "creditCardNumber": "",
                "creditCardCvcCode": ""
            },
            "booking_id": "",
            "created_at": "",
            "updated_at": "",
            "booking_type": ""
        }
    },
    "insuranceAPIData": null,
    "selectedSeats": null,
    "promoCodesId": null
},

}

export function templateTransactions(obj) {
  let data = mergeRecursive({...obj},transactionTemp);
  let productName = 'Booking';
  try {
    productName = ({"booking":"Booking","hotel_booking": "Hotel","package":"Package",
        "tourpro_booking": 'Tour',
        "flight":"Flight"})[data.to.toLowerCase()];
    if(!productName) productName = data.to;
  } catch(ex) {}

  return {...data,
    customer: data.user.firstName+ " "+data.user.lastName,
    pnr: data?.bookedTrip?.bookingId,
    date: data.createdDate,
    product: productName,
    paymentMethod: data.from,
    status: data.transactionStatus
  };
}

export function templateTransactionsReport(obj) {
    let data = mergeRecursive({...obj},transactionTemp);
    let productName = 'Booking';
    try {
        productName = ({"booking":"Booking","hotel_booking": "Hotel","package":"Package",
            "tourpro_booking": 'Tour',
            "flight":"Flight"})[data.to.toLowerCase()];
        if(!productName) productName = data.to;
    } catch(ex) {}
    
    let res = {
      customer: data.user.firstName+ " "+data.user.lastName,
      date: data.createdDate,
      product: productName,
      paymentMethod: data.from,
      amount: data.amount,
      status: data.transactionStatus
    };

    res = Object.values(res);

    return res;
  }
  