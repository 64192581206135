import { ArrowBack } from '@mui/icons-material';
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { updateSettingData } from '../../../data/settings/updateSettingData';
import { updateSettings } from '../../../controllers/settings/updateSettings';
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import { getSettings } from '../../../controllers/settings/getSettings';

export default function EditPoints() {
  const [data,setData] = useState({...updateSettingData});
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();
  const {id} = useParams();


  useEffect(() => {
    load();
  },[])

  async function load() {
    let q = {
      for: "SUB_AGENT"
    }
    const res = await getSettings(new URLSearchParams(q));
    if(res.return) {
      // let data = res.data.map(d => d.fareRules.map(obj => ({for: d['for'] || (d['customUser'].firstName+" "+d['customUser'].lastName),...obj}) )).flat(1);
      // console.log('flatted', data)
      if(res.data.length)
        setData(res.data[0]);
    }

  }

  console.log(data)
  
  
  async function handleSubmit(ev) {
    ev.preventDefault();
    const temped = Object.entries(data).reduce((acc,[key,val]) => {
      if(val !== null)
       acc[key] = val
      
      return acc
    },{})

    setLoading(true);
    let res = await updateSettings(data.id,temped);
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Settings Updated.']))
      // setData({...updateSettingData});
    } else dispatch(setAlertMsg(['error',res.msg]))

    // console.log(temped)
  }
  return (
    <form onSubmit={handleSubmit} className='w-full bg-secondary p-4'>
      <div className='p-4'>
        {/* <Link to="/settings/points" className='flex gap-4'>
          <ArrowBack />
          <h3>Edit Points</h3>
        </Link> */}
      </div>
      <div className='p-4 flex flex-wrap gap-4'>
        <div className='flex gap-4 w-full py-2'>
          <label>
            <input type='checkbox'
              checked={data.enableBonusPoints}
              onChange={(ev) => setData({...data,enableBonusPoints: ev.target.checked})}
            /> Enable bonus points
          </label>
          <label>
            <input type='checkbox'
              checked={data.enableDoublePoints}
              onChange={(ev) => setData({...data,enableDoublePoints: ev.target.checked})}
            /> Enable double points
          </label>
        </div>
        <TextField size='small' label='Deposite Point' type='number' className=''
          value={data.pointEarnValueDeposit}
          onChange={(ev) => setData({...data,pointEarnValueDeposit: ev.target.value})} 
        />
        <TextField size='small' label='Purchase Point' type='number' className=''
          value={data.pointEarnValuePurchase}
          onChange={(ev) => setData({...data,pointEarnValuePurchase: ev.target.value})} 
        />
        <TextField size='small' label='Redemption Point' type='number' className=''
          value={data.pointRedemptionValue}
          onChange={(ev) => setData({...data,pointRedemptionValue: ev.target.value})} 
        />
        <TextField size='small' label='Graduation Count' type='number' className=''
          value={data.pointGraduationCount}
          onChange={(ev) => setData({...data,pointGraduationCount: ev.target.value})} 
        />
        <TextField size='small' label='Bonus Point Min' type='number' className=''
          value={data.bonusPointsNGNMin}
          onChange={(ev) => setData({...data,bonusPointsNGNMin: ev.target.value})} 
        />
        <TextField size='small' label='Bonus Point Earn Value' type='number' className=''
          value={data.bonusPointsEarnValue}
          onChange={(ev) => setData({...data,bonusPointsEarnValue: ev.target.value})} 
        />
        <TextField size='small' label='Village Requirement Point' type='number' className=''
          value={data.villageRequirementInPoints}
          onChange={(ev) => setData({...data,villageRequirementInPoints: ev.target.value})} 
        />
        <TextField size='small' label='City Requirment Point' type='number' className=''
          value={data.cityRequirementInPoints}
          onChange={(ev) => setData({...data,cityRequirementInPoints: ev.target.value})} 
        />
        <TextField size='small' label='Continental Requirment Point' type='number' className=''
          value={data.continentalRequirementInPoints}
          onChange={(ev) => setData({...data,continentalRequirementInPoints: ev.target.value})} 
        />
        <TextField size='small' label='Global Requirment Point' type='number' className=''
          value={data.globalRequirementInPoints}
          onChange={(ev) => setData({...data,globalRequirementInPoints: ev.target.value})} 
        />
        <TextField size='small' label='Highest Requirment Point' type='number' className=''
          value={data.highestRequirementInPoints}
          onChange={(ev) => setData({...data,highestRequirementInPoints: ev.target.value})} 
        />
        <div className='w-full py-4'>
          <button className='btn2 flex items-center gap-2'>
            {loading?<div className='load'></div>:null}
            Update Setting</button>
        </div>
      </div>
    </form>
  )
}
