import { ChildCareOutlined, ChildFriendlyOutlined, Person } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import IncDec from "../../mini/IncDec";
import { def } from "../../../config";
import { formatMoney } from "../../../features/utils/formatMoney";

export default function FilterTravelPassengers({data={},totalMax,returnData,q,oneLine,value}) {
  const init = [
    {
      name: 'adults',
      label: <div className='flex flex-wrap gap-2'><b> Adult</b> <small className='whitespace-nowrap'>(Over 11)</small> </div>,
      icon: <Person className='sqr4' />,
      value: parseInt(q && q.adults) || 0
    },
    {
      name: 'children',
      label: <div className='flex flex-wrap gap-2'><b> Child</b> <small className='whitespace-nowrap'>(2 - 11)</small></div>,
      icon: <ChildCareOutlined className='sqr4' />,
      value: parseInt(q && q.children) || 0
    },
    {
      name: 'infants',
      label: <div className='flex flex-wrap gap-2'><b> Infant</b> <small className='whitespace-nowrap'>(Under 2)</small></div>,
      icon: <ChildFriendlyOutlined className='sqr4' />,
      value: parseInt(q && q.infants) || 0
    },
  ]
  const [passenger,setPassenger] = useState(init)

  console.log('inside passenger')

  const handleReturnData = useCallback((passenger) => {
    if(returnData)
      returnData(passenger);
    console.log('returned')
  },[returnData])

  useEffect(() => {
    if(value) {
      console.log('setted : ',value)
      setPassenger(value);
    }
  },[])

  useEffect(() => {
    if(passenger)
      handleReturnData(passenger)
  },[passenger])

  function handleChange(val,i) {
    let temp = [...passenger];
    temp[i].value = val;
    setPassenger(temp);
  }

  const totalPassengers = passenger?.reduce((acc,cur) => acc + cur?.value,0);
  const totalAdults = passenger?.find(obj => obj?.name === 'adults')?.value;
  const totalInfants = passenger?.find(obj => obj?.name === 'infants')?.value;

  return (
    <div className={`flex ${oneLine ? ' gap-6 grow items-center max-w-full overflow-x-auto ' : ' flex-col gap-3 '} `}>
      <h4 className='font-bold'>Travelers</h4>
      <div className="flex flex-col gap-1">
        {totalMax && (totalPassengers >= totalMax) ? 
          <small className="text-orange-400">Maximum number of travelers reached</small>
        :null}
        {totalInfants > totalAdults ? 
          <small className="text-red-400">Infants should not exceed adults</small>
        :null}
      </div>
      {passenger.map((obj,i) => (
        <div className='flex flex-col'>
          <div key={i} className={`flex gap-2 flex-nowrap items-center ${oneLine ? ' flex-1 px-4 border-r border-primary/20 ' : ''}`}>
            {obj.icon}
            <div className='flex-1 flex justify-between gap-4 '>
              <div className='flex flex-col items-start justify-center' >
                {obj.label}
                <div className=''>
                  {obj.farePrice ? def.currency:null}{obj?.farePrice ? formatMoney(obj?.farePrice) : null}
                </div>
              </div>
              <IncDec min={0} max={totalMax ? (totalPassengers >= totalMax ? 0 : totalMax) : data['number_of_'+(obj.name)]} value={obj.value} returnData={(val) => handleChange(val,i)} />
            </div>
          </div>
        </div>      
      ))
      }

    </div>
  )
}
