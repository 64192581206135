import React, { useEffect, useState } from 'react';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { TextField } from '@mui/material';

const libraries = ['places'];
const mapLoaderObj = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries
}


export default function CitySearch({value,label,onChange,...restProps}) {
  const [searchResult,setSearchResult] = useState();

  const {isLoaded} = useJsApiLoader(mapLoaderObj)

  const handlePlaceSelect = (place) => {
    console.log('Selected place:', place);
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng();

    onChange && onChange({lat,lng,...place})
    // Handle the selected place data as per your requirement
  };

  function handlePlaceChange() {
    if(!searchResult) return false;

    const place = searchResult?.getPlace();
    handlePlaceSelect(place);
  }

  return isLoaded ? (
      <Autocomplete
        onLoad={(autocomplete) => setSearchResult(autocomplete)}
        onPlaceChanged={handlePlaceChange}
      >
        <InputComponent label={label} value={value} {...restProps} />
      </Autocomplete>
  ) : <InputComponent {...restProps} />
};

const InputComponent = ({label,value,...restProps}) => {
  // const [value,setValue] = useState(defValue||'');

  // useEffect(() => {
  //   if(defValue)
  //   setValue(defValue)
  // },[defValue])
  let props = {...restProps}
  if(value) {
    props = {InputLabelProps:{
      shrink: true
    },
    ...restProps}
  }
  return (
    <TextField size='small' placeholder={value}
      label={label||'Search cities'}
      {...props}  
    />
  )
}