import React, { useEffect, useState } from 'react'
import { addMarkup } from '../../controllers/settings/addMarkups';
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import { MenuItem, TextField } from '@mui/material';
import { updateFareRule } from '../../controllers/settings/updateFareRule';


export default function TourServiceCharge({defaultData,submit,handleReturn,update,companyData,returnFalse,resetFields}) {
  let company = companyData?.apiUserData?.companyName || ''

  const initState = defaultData || {
    flightProvider: '',
    markType: '',
    markUp: '',
    flightType: '',
  }
  const [data,setData] = useState(initState);
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(submit) {
      handleSubmit();
    }
    //eslint-disable-next-line
  },[submit])

  useEffect(() => {
    if(resetFields)
      setData(initState)
    //eslint-disable-next-line
  },[resetFields])

  async function handleSubmit() {
    if(!update || !defaultData)
      handleTourServiceFee();
    else handleTourServiceFeeUpdate();
  }
  

  async function handleTourServiceFee() {
    if(!data.markType || !data.markUp)
      return false;
    
    setLoading(true);
    const res = await addMarkup(companyData?.id,{
      fareRuleType: 'SERVICE_FEE_TOUR',
      for: 'CORPORATE',
      markType: data.markType,
      markUp: parseInt(data.markUp),
      ruleName: 'Service fee for Corporate '+company
    });
    setLoading(false);
    if(res.return) {
      if(handleReturn) handleReturn();

      if(update)
        dispatch(setAlertMsg(['success','tour service charge updated.']))
    } else
      dispatch(setAlertMsg(['error','Failed setting tour service fee!']))

    if(!res.return && returnFalse && handleReturn) handleReturn(); 

  }

  async function handleTourServiceFeeUpdate() {
    setLoading(true);
    const res = await updateFareRule(defaultData?.id,{
      markType: data.markType,
      markUp: parseInt(data.markUp),
      ruleName: 'Service fee for Corporate '+company,
      // flightProvider: '',
      // flightType: '',
      // fareRuleType: 'SERVICE_FEE_TOUR',
      // for: 'CORPORATE',
    });
    setLoading(false);
    if(res.return) {
      if(handleReturn) handleReturn();

      if(update)
        dispatch(setAlertMsg(['success','tour service charge updated.']))
    } else
      dispatch(setAlertMsg(['error','Failed setting tour service fee!']))

    if(!res.return && returnFalse && handleReturn) handleReturn(); 

  }
  return (
    <div className='flex flex-col gap-4'>
      <div className=' flex items-stretch gap-4'>
        <TextField select value={data.markType} onChange={(ev) => setData({...data,markType: ev.target.value})} label='Markup Type' size='small' className='min-w-[150px]'>
          <MenuItem value='PERCENTAGE'>Percentage</MenuItem>
          <MenuItem value="VALUE">Value</MenuItem>
        </TextField>
        <TextField size='small' value={data.markUp} onChange={(ev) => setData({...data,markUp: ev.target.value})} label={"Markup Value"} />

      </div>
      {update ? (

        <button className='btn2 flex gap-2 self-start border-primary' 
          onClick={handleSubmit}
          disabled={loading}>
          {loading ? <div className='load'></div> : null}
          {!defaultData ? 'Add Fee' : 'Update Fee'}
        </button>
      ):null}

    </div>

  )
}
