import React, { useState } from 'react'
import { updateProfilePic } from '../../controllers/user/updateProfilePic';
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../redux/reducers/modal/snackBarSlice';
import { Cancel, Edit, Visibility } from '@mui/icons-material';
import ModalLocal from '../mini/ModalLocal';

export default function ProfilePicture({data,handleReturn}) {
  const [pic,setPic] = useState();
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);
  const [open,setOpen] = useState(false);

  // console.log(userData)

  async function handleSubmit(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    const formData = new FormData();
    formData.append("profile",pic)
    // console.log(formData.get("profile"))

    setLoading(true);
    const res = await updateProfilePic(formData,data.id)
    setLoading(false);
    if(res.return) {
      dispatch(setAlertMsg(['success','Profile picture updated.']))
      setOpen(false)
      if(handleReturn)
        handleReturn()
    } else dispatch(setAlertMsg(['error',res.msg]))

    return false;
  }
  return (
    <div className='w-full h-full relative'>
      <div className='absolute top-0 left-0 w-full h-full flex flex-wrap gap-2 justify-center items-center bg-primary/40'>
        {/* <div className='bg-white'></div> */}
        <Edit className='text-white rounded-md cursor-pointer hover:scale-110'
          onClick={() => setOpen(true)}
        />
        <Visibility className='text-white rounded-md cursor-pointer hover:scale-110'
          onClick={() => window.open(data.profile,'_blank')}
        />
      </div>
      {data.profile ? (
        <img src={data.profile}  alt='Profile' className='w-full h-full object-cover cursor-pointer' />
      ):null}
      <ModalLocal open={open} setOpen={setOpen}>
        <div className='flex flex-col gap-10 p-6 bg-secondary rounded-lg relative' name='profile_picture' id='prof_pic_form'>
          <Cancel className='absolute top-0 right-0 m-3 cursor-pointer' onClick={() => setOpen(false)} />
          <div className='flex flex-col gap-4 justify-between '>
            <b className='flex-1'>Logo / Profile Picture</b>
            <div className='w-[220px] '>
              <input type='file' name='profile' className='max-w-full'
                onChange={(ev) => setPic(ev.target.files[0])}
              />
            </div>
            <button className='btn2 flex items-center gap-2 ' onClick={handleSubmit}>
              {loading?<div className='load'></div>:null}
              Upload</button>
          </div>
        </div>        
      </ModalLocal>
    </div>
  )
  // return (
  //   <div className='flex flex-col gap-10' name='profile_picture' id='prof_pic_form'>
  //     <div className='flex gap-4 justify-between items-center'>
  //       <b className='flex-1'>Logo / Profile Picture</b>
  //       <div className='w-[220px] '>
  //         <input type='file' name='profile'
  //           onChange={(ev) => setPic(ev.target.files[0])}
  //         />
  //       </div>
  //       <button className='btn2 small flex items-center gap-2 ' onClick={handleSubmit}>
  //         {loading?<div className='load'></div>:null}
  //         Upload</button>
  //     </div>
  //   </div>
  // )
}
