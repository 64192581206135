import React from 'react'
import { getCurrencySymbol } from '../../../features/utils/currency'

export default function TransactionDetail({data}) {

  return (
    <div className='flex flex-col gap-5 px-2'>
      <h5># {data?.bookingId}</h5>
      <FlightInfo data={data.flightData.booked_flights} />
      <PriceSummary data={data.flightData} />
    </div>
  )
}

function PriceSummary({data}) {
  return (
    <div className='flex flex-col gap-5'>
      <h6>PriceSummary</h6>
      <div className='flex flex-col px-4'>
        {
          data.booked_travellers.map((passenger,i) => (
            <div key={i}>
              <div className='flex justify-between -ml-1'>
                <b>Traveler {i+1}</b>
                <b>{passenger.total_amount}</b> 
              </div>
              <div className='flex justify-between'>
               <div>Fare Amount</div> 
               <div>{passenger.fare_amount}</div> 
              </div>
              <div className='flex justify-between'>
               <div>Tax Amount</div> 
               <div>{passenger.tax_amount}</div> 
              </div>
            </div>
          ))
        }
      </div>
      <div className='flex justify-between px-3'>
        <h6>Trip Total : </h6>
        <h6>{getCurrencySymbol(data.currency)}{data.total_amount}</h6>
      </div>
    </div>
  )
}

function FlightInfo({data}) {
  return (
    <div className='flex flex-col gap-3'>
      <div className='flex flex-wrap gap-6 justify-between px-2'>
        {data.map((flight,i) => (
          <div key={i}>
            <div className='flex gap-3'>
              <img alt='turkish' src={flight.booked_segments[0].carrierIcon} width={20} height={17} />
              {flight.airline_detail.name}
            </div>
            <b className='py-2 inline-block'>
              {flight.origin.location} - {flight.destination.location}
              <span className='px-2 inline'>on</span>
              {(new Date(`${flight.origin.date}T${flight.origin.time}`).toDateString())} {flight.origin.time}
            </b>
            {/* <div className='p-2 font-bold flex gap-7'>
              <small className=''>{flight.cabin}</small>
              <small className=''>{flight.baggage || 0}kg</small>
            </div> */}
          </div>
        ))}
      </div>
      <hr />
    </div>
  )
}

