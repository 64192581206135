import { path } from "../../config";
import fetchServer from "../fetchServer";
import { store } from "../../redux/store";

export async function deactivateUser(id) {
  let result = {return: 0,msg: "Something went wrong!"}

  let token = store.getState().user.userData.accessToken;

  // console.log('req Got: ',data)

  await fetchServer({method: 'POST',url: path.api+"/settings/deactivate-user/"+id,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then((res) => {
    console.log('serv => ',res);
    if(res.data && !res?.error)
      result = {return: 1,msg: "Successfull",data: res.data.data}
    else if(res?.error?.message) result['msg'] = res?.error?.message;
  })
  .catch((e) => {
    console.log("Network Error: ",e)
  })

  return result;

}